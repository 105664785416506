import { useTheme } from "styled-components"
import { getSearchObject } from "utils/search"
import { useSetTheme } from "hooks/useSetTheme"
import { dayTheme } from "theme/dayTheme"
import { ESpacerSize } from "entities/common"
import {
  Button,
  Container,
  Icon,
  MPTypography,
  Spacer,
  Table,
  Typography,
} from "components"
import { SELF } from "constants/common"
import { SecureAccountStyled } from "./styles"
import {
  ACTIVATE_SERVICE,
  conditionAgreement,
  DEACTIVATE_SERVICE,
  DESCRIPTION,
  howItWorks,
  HOW_SERVICE_WORK,
  IOSDeeplink,
  ITS_FREE,
  OFFER_LINK,
  packageIncluded,
  questions,
  TITLE,
  WHAT_IS_GUARDIAN,
} from "./constants"

const TitleComponent = ({ children }: { children: any }) => (
  <Typography.H3>{children}</Typography.H3>
)

export const SecureAccount = () => {
  const {
    theme: externalTheme,
    active,
    appName,
    optionId,
    number,
  } = getSearchObject()
  const isActive = active === "true"
  const theme = useTheme()
  useSetTheme(externalTheme)
  const activeDeepLint =
    appName.toLowerCase() === IOSDeeplink
      ? `${appName}://change_option_status?optionId=${optionId}&active=true&number=${number}&some=coveragemap://`
      : `${appName}://change_option_status?optionId=${optionId}&active=true&number=${number}`

  const inactiveDeepLink =
    appName.toLowerCase() === IOSDeeplink
      ? `${appName}://change_option_status?optionId=${optionId}&active=false&number=${number}&some=coveragemap://`
      : `${appName}://change_option_status?optionId=${optionId}&active=false&number=${number}`
  const onPlugClick = () =>
    (window.location.href = isActive ? activeDeepLint : inactiveDeepLink)

  const ButtonArea = () => (
    <>
      {isActive ? null : <Spacer height={12} />}
      <SecureAccountStyled.PaddingContainer>
        {isActive ? (
          <>
            <MPTypography typographyType="Body2" textAlign="center">
              <SecureAccountStyled.UnderlineSpan
                onClick={() => window.open(OFFER_LINK, SELF)}
              >
                Подробнее об условиях оказания услуги Хранитель
              </SecureAccountStyled.UnderlineSpan>
            </MPTypography>
            <Spacer height={12} />
            <Button.MNPPlain onClick={onPlugClick}>
              <MPTypography
                typographyType="Body2Bold"
                color={theme.colors.text.critical}
              >
                {DEACTIVATE_SERVICE}
              </MPTypography>
            </Button.MNPPlain>
          </>
        ) : (
          <Button.MPPrimary onClick={onPlugClick}>
            <MPTypography
              color={dayTheme.colors.background.surface1}
              typographyType="Body2Bold"
            >
              {ACTIVATE_SERVICE}
            </MPTypography>
          </Button.MPPrimary>
        )}
      </SecureAccountStyled.PaddingContainer>
      <Spacer height={16} />
      {!isActive ? (
        <SecureAccountStyled.PaddingContainer>
          <MPTypography typographyType="Body2" textAlign="center">
            <>
              {conditionAgreement[0]}
              <br />
              {conditionAgreement[1]}
              {" "}
              {
                <SecureAccountStyled.UnderlineSpan
                  onClick={() => window.open(OFFER_LINK, SELF)}
                >
                  {conditionAgreement[2]}
                </SecureAccountStyled.UnderlineSpan>
              }
              {" "}
              {conditionAgreement[3]}
            </>
          </MPTypography>
        </SecureAccountStyled.PaddingContainer>
      ) : null}
      <Spacer height={ESpacerSize.M} />
    </>
  )
  return (
    <>
      <Container
        position="relative"
        page
        backgroundColor={theme.colors.background.surface1}
      >
        <SecureAccountStyled.ShieldContainer>
          <Icon.Guardian />
        </SecureAccountStyled.ShieldContainer>
        <SecureAccountStyled.SecureContainer>
          <Typography.H2>{TITLE}</Typography.H2>
          <Spacer height={ESpacerSize.S} />
          <MPTypography>{DESCRIPTION}</MPTypography>
          <Spacer height={ESpacerSize.XL} />
          <Typography.Body1Bold
            fontSize={20}
            lineHeight={25}
            color={theme.colors.speedometer.internet}
          >
            {ITS_FREE}
          </Typography.Body1Bold>
        </SecureAccountStyled.SecureContainer>
        <Table.Section
          title={HOW_SERVICE_WORK}
          titleComponent={TitleComponent}
          titlePadding={{ paddingTop: ESpacerSize.XL }}
          cellContainerMargin={{
            marginTop: ESpacerSize.M,
            marginBottom: ESpacerSize.L,
          }}
          hasDivider={false}
          generalBackground={theme.colors.background.surface0}
        >
          {howItWorks.map(({ id, title, icon }) => (
            <Table.CommonCell
              key={id}
              title={title}
              titleSpacer={12}
              startIcon={
                <SecureAccountStyled.IconWrapper
                  hasBackground
                  svgColor={theme.colors.text.primary}
                >
                  {icon}
                </SecureAccountStyled.IconWrapper>
              }
            />
          ))}
        </Table.Section>
        <Table.Section
          title={WHAT_IS_GUARDIAN}
          titleComponent={TitleComponent}
          titlePadding={{ paddingTop: ESpacerSize.XL }}
          cellContainerMargin={{
            marginTop: ESpacerSize.M,
            marginBottom: ESpacerSize.L,
          }}
          hasDivider={false}
          generalBackground={theme.colors.background.surface1}
        >
          {packageIncluded.map(({ id, title, subtitle, icon }) => (
            <Table.CommonCell
              key={id}
              hasDivider
              title={
                <Typography.Body1Bold fontSize={17}>
                  {title}
                </Typography.Body1Bold>
              }
              subTitle={<MPTypography>{subtitle}</MPTypography>}
              titleSpacer={12}
              startIcon={
                <SecureAccountStyled.IconWrapper
                  svgColor={theme.colors.speedometer.internet}
                >
                  {icon}
                </SecureAccountStyled.IconWrapper>
              }
            />
          ))}
        </Table.Section>
        <Table.Section
          title="Ответы на вопросы"
          titleComponent={TitleComponent}
          titlePadding={{ paddingTop: ESpacerSize.XL }}
          cellContainerMargin={{
            marginTop: ESpacerSize.M,
            marginBottom: ESpacerSize.L,
          }}
          generalBackground={theme.colors.background.surface1}
          isDividerUnderline
        >
          {questions.map((question, index) => (
            <Table.CommonCell
              key={index}
              title={question.title}
              endIcon={<Icon.ArrowRight />}
              onClick={() => (window.location.href = question.route)}
            />
          ))}
        </Table.Section>
        <Spacer height={ESpacerSize.S} />

        {isActive ? (
          <>
            <ButtonArea />
          </>
        ) : (
          <SecureAccountStyled.FixedFooter>
            <ButtonArea />
          </SecureAccountStyled.FixedFooter>
        )}
        {isActive ? null : <Spacer height={130} />}
      </Container>
    </>
  )
}
