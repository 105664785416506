import styled from "styled-components"
import { Icon, Typography } from "components"

export const ImageStyled = styled(Icon.EngineeringWorks)`
  margin: 22px auto 24px auto;
`

export const H4Styled = styled(Typography.H4)`
  margin: 0px 16px 8px;
  text-align: center;
`
