import { axiosInstance } from "../../axios"

export interface IResetPasswordPayload {
  additional: string
  number: string
}

export interface ILoginPayload {
  password: string
  number: string
  screen?: string
  appVersionName?: string
  system?: string
  appVersionCode?: string
  systemVersion?: string
  deviceId?: string
}

export interface IAdditionalNumberUpdate {
  number: string
  password: string
}

export const resetPasswordService = (data: IResetPasswordPayload) =>
  axiosInstance.post(`/gateway/send_password`, data)

export const loginService = (data: ILoginPayload) =>
  axiosInstance.post(`/gateway/login`, data)

export const additionalNumber = (data: IAdditionalNumberUpdate) =>
  axiosInstance.post(`/gateway/additional_number`, data)
