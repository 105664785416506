import React, { FC, memo } from "react"
import { useNavigate } from "react-router-dom"
import { Error } from "pages/Error"
import { routes } from "constants/routes"

export const SignatureUserNotIndividual: FC = memo(() => {
  const title = "Кажется, вы предприниматель"
  const subtitle =
    "Мы не можем подписать договор eSIM онлайн с юридическим лицом. Используйте учётную запись физического лица или обратитесь в офис Сбера."
  const buttonText = "Понятно"

  const navigate = useNavigate()

  const onConfirmClick = () => {
    navigate(routes.signature)
  }

  return (
    <Error
      title={title}
      subtitle={subtitle}
      buttonText={buttonText}
      onClick={onConfirmClick}
    />
  )
})
