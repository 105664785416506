import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
  ICustomerProfileRequest,
  ICustomerProfileResponse,
} from "entities/customerProfile"
import { IUserConfigResponse } from "entities/userConfig"
import {
  IGoskeyAuthRequest,
  IGoskeyAuthResponse,
  IGoskeyDeleteCacheResponse,
  IGoskeyDocumentRequest,
  IGoskeyDocumentResponse,
  IGoskeyPreauthRequest,
  IGoskeyPreauthResponse,
  IGoskeySendRequest,
  IGoskeySendResponse,
  ISendConfirmationRequest,
  ISendConfirmationResponse,
} from "entities/goskey"
import { IBannersRequest, IBannersResponse } from "entities/banners"
import { EApiUrls, ELinksCode, ICommonResponse } from "entities/common"
import {
  IAdditionalNumberAliasRequest,
  IAdditionalNumberDeleteRequest,
  IAdditionalNumberDeleteResponse,
  IAdditionalNumberOrderRequest,
  IAdditionalNumberResponse,
} from "entities/additionalNumber"
import {
  IConnectedAvailableRequest,
  IConnectedAvailableResponse,
} from "entities/connectedAvailable"
import { ITariffRequest, ITariffResponse } from "entities/tariff"
import { IContractRequest, IContractResponse } from "entities/contract"
import { ILinksResponse } from "entities/links"
import { getUserInfoHeader } from "utils/system"
import {
  ITariffOptionStatusRequest,
  ITariffOptionStatusResponse,
} from "entities/tariffOptionsStatus"
import { ITariffAuraRequest, ITariffAuraResponse } from "entities/tariffAura"
import { modalActions } from "store/slice/modalSlice"
import { IPrimeProfileV2Response } from "entities/prime"
import { ICheckNumberRequest, ICheckNumberResponse } from "entities/checkNumber"
import { IAuthParamsResponse } from "entities/authParams"
import { beautifulNumbersActions } from "store/slice/beautifulNumbersSlice"
import {
  IBeautifulNumbersReplaceRequest,
  IBeautifulNumbersRequest,
  IBeautifulNumbersReserveRequest,
  IBeautifulNumbersResponse,
} from "entities/beautifulNumbers"
import {
  IPersonalAdjustmentRequest,
  IPersonalInfoRequest,
  IPersonalInfoResponse,
} from "entities/personalInfo"
import {
  IPaymentAdjustmentNumberRequest,
  IPaymentAdjustmentAmountRequest,
} from "entities/paymentAdjustment"
import {
  ITimeslotRequest,
  ITimeslotResponse,
  TimeSlotData,
  INumberTransferCheckRequest,
  INumberTransferConfirmRequest,
  INumberTransferOrderRequest,
  IGetNumberTransferOrderResponse,
} from "entities/numberTransfer"
import { INumberInfoResponse } from "entities/numberInfo"
import { ICheckConfirmationRequest } from "entities/checkConfirmation"
import { ISendEmailConfirmationRequest } from "entities/emailConfirmation"
import { IUpdateEmailRequest } from "entities/updateEmail"
import { sendMetric } from "utils/metrics"
import {
  ICostDetailIntervalRequest,
  ICostDetailIntervalResponse,
  ICostDetailRequest,
} from "entities/costdetailInterval"
import { ICountriesResponse, IRoamingResponse } from "entities/roaming"
import {
  IServicePackageRequest,
  IServicePackageResponse,
} from "entities/secureAccount"
import {
  IHistoryCallsRequest,
  IHistoryCallsResponse,
  ITranscriptionCallsRequest,
  ITranscriptionCallsResponse,
} from "entities/historyCalls"
import { RootState, store } from "../index"
import { loginActions } from "../slice/loginSlice"
import { numberTransferActions } from "../slice/numberTransferSlice"
import { routes } from "constants/routes"

export const sberMobileApi = createApi({
  reducerPath: "sberMobileApi",
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOP_BASE_URL
        : process.env.REACT_APP_PRODUCTION_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).login.token
      headers.set("X-User-info", getUserInfoHeader())
      if (token) {
        headers.set("token", token)
      }

      return headers
    },
  }),
  tagTypes: ["ITariffResponse", "IAdditionalNumberResponse"],
  endpoints: (builder) => ({
    getTariff: builder.query<ITariffResponse, ITariffRequest>({
      query: ({ numbers }) => {
        return `/tariff-service/tariff/data?numbers=${numbers.join(",")}`
      },
      providesTags: ["ITariffResponse"],
    }),
    getCustomerProfile: builder.query<
      ICustomerProfileResponse,
      ICustomerProfileRequest
    >({
      query: ({ number }) => {
        if (number) {
          return `/client-service/customer-profile?additionalNumber=${number}`
        }
        return `/client-service/customer-profile`
      },
    }),
    getContract: builder.query<IContractResponse, IContractRequest>({
      query: ({ number }) => {
        if (number) {
          return `/client-service/contract?additionalNumber=${number}`
        }
        return `/client-service/customer-profile`
      },
    }),
    getLinks: builder.query<Record<ELinksCode, string>, void>({
      query: () => EApiUrls.Links,
      transformResponse: (baseQueryReturnValue) =>
        (baseQueryReturnValue as ILinksResponse).data.linksData.reduce(
          (acc, link) => ({ ...acc, [link.code]: link.link }),
          {} as Record<ELinksCode, string>
        ),
    }),
    getPrimePrivileges: builder.query<any, void>({
      query: () => `/tariff-service/tariff/data?numbers=9808908706,9800482958`,
    }),
    getInfoblock: builder.query<any, void>({
      query: () => `/user-detail-service/infoblock`,
    }),
    getBanners: builder.query<IBannersResponse, IBannersRequest>({
      query: ({ phones }) => {
        return `/user-detail-service/banners?numbers=${phones.join(",")}`
      },
    }),
    getOrder: builder.query<any, void>({
      query: () => `/number-transfer-service/order`,
    }),
    getAutoPay: builder.query<any, void>({
      query: () => `/payment-service/auto-pay`,
    }),
    getPrimeProfile: builder.query<any, void>({
      query: () => `/tariff-service/prime/profile/v2`,
    }),
    getPrimeProfileV2: builder.mutation<IPrimeProfileV2Response, void>({
      query: () => ({
        url: "/tariff-service/prime/profile/v2",
        responseHandler: async (response) => {
          if (response.ok) {
            const { data } = await response.json()
            store.dispatch(loginActions.setSberIdPrimeStatus(data.primeStatus))
            return { data }
          }
        },
      }),
    }),
    getSub: builder.query<any, void>({
      query: () => `/user-detail-service/sber/sub`,
    }),
    sberSubUnbind: builder.mutation<any, any>({
      query: (body) => ({
        url: `/user-detail-service/sber/sub/unbind`,
        method: "POST",
        body,
      }),
    }),
    getRecommendedAmount: builder.query<any, void>({
      query: () => `/payment-service/payment/recommended_amount`,
    }),
    getUserConfig: builder.query<IUserConfigResponse, void>({
      query: () => `/user-detail-service/settings/user_config`,
    }),
    getAdditionalNumber: builder.query<IAdditionalNumberResponse, void>({
      query: () => `/user-detail-service/additional_number`,
      providesTags: ["IAdditionalNumberResponse"],
    }),
    deleteAdditionalNumber: builder.mutation<
      IAdditionalNumberDeleteResponse,
      IAdditionalNumberDeleteRequest
    >({
      query: ({ number }) => ({
        url: `/user-detail-service/additional_number/${number}?force=false`,
        method: "DELETE",
      }),
      invalidatesTags: ["IAdditionalNumberResponse"],
    }),
    additionalNumberOrder: builder.mutation<
      IAdditionalNumberResponse,
      IAdditionalNumberOrderRequest
    >({
      query: (body) => ({
        url: `/user-detail-service/additional_number/order`,
        method: "POST",
        body,
      }),
    }),
    getConnectedAvailable: builder.query<
      IConnectedAvailableResponse,
      IConnectedAvailableRequest
    >({
      query: ({ phone }) => ({
        url: `/tariff-service/tariff/v2/connected-available?additionalNumber=${phone}`,
        method: "GET",
      }),
    }),
    getBeautifulNumbers: builder.query<
      IBeautifulNumbersResponse,
      IBeautifulNumbersRequest
    >({
      query: ({ pageNumber, mask, additionalNumber }) => ({
        url: `/user-detail-service/beautifulNumbers?msisdnType=SILVER&msisdnType=BRONZE&msisdnType=GOLD&msisdnType=NORMAL&pageNumber=${pageNumber}${
          mask ? `&mask=${mask}` : ""
        }${additionalNumber ? `&additionalNumber=${additionalNumber}` : ""}`,
        method: "GET",
        responseHandler: async (response) => {
          const data = await response.json()
          store.dispatch(beautifulNumbersActions.setMask(mask ?? ""))
          if (mask) {
            store.dispatch(beautifulNumbersActions.setCachedMask(mask))
            store.dispatch(
              beautifulNumbersActions.setBeautifulNumbersFilteredData(data.data)
            )
          } else {
            if (pageNumber === 1) {
              store.dispatch(
                beautifulNumbersActions.setBeautifulNumbersData(data.data)
              )
            } else {
              store.dispatch(
                beautifulNumbersActions.addBeautifulNumbersData(data.data)
              )
            }
          }
        },
      }),
    }),
    postBeautifulNumberReserve: builder.mutation<
      ICommonResponse,
      IBeautifulNumbersReserveRequest
    >({
      query: (body) => ({
        url: `/user-detail-service/beautifulNumbers/reserveMsisdn`,
        method: "POST",
        body,
        responseHandler: async (response) => {
          if (!response.ok) {
            const res = await response.json()
            store.dispatch(
              modalActions.openModal({
                isOpen: true,
                title: res.statusInfo.statusMessage,
                declineLabel: "Понятно",
              })
            )
          }
        },
      }),
    }),
    postBeautifulNumberRelease: builder.mutation<
      ICommonResponse,
      IBeautifulNumbersReserveRequest
    >({
      query: (body) => ({
        url: `/user-detail-service/beautifulNumbers/releaseMsisdn`,
        method: "POST",
        body,
        responseHandler: async (response) => {
          const { data } = await response.json()
          store.dispatch(beautifulNumbersActions.setIsLoaded(false))
          return data
        },
      }),
    }),
    postBeautifulNumberReplace: builder.mutation<
      ICommonResponse,
      IBeautifulNumbersReplaceRequest
    >({
      query: (body) => ({
        url: `/user-detail-service/beautifulNumbers/replaceMsisdn`,
        method: "POST",
        body,
      }),
    }),
    getGoskeyPreauth: builder.mutation<
      IGoskeyPreauthResponse,
      IGoskeyPreauthRequest
    >({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      query: ({ additionalNumber }) => ({
        url: additionalNumber
          ? `/goskey-service/esia/preauth?additionalNumber=${additionalNumber}`
          : `/goskey-service/esia/preauth`,
        method: "GET",
        responseHandler: async (response) => {
          const data = await response.json()
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // window.open(data?.data.url, "_blank").focus()
          window.location.href = data?.data.url
        },
      }),
    }),
    postGoskeyAuth: builder.query<IGoskeyAuthResponse, IGoskeyAuthRequest>({
      query: ({ additionalNumber, code, state }) => ({
        url: `/goskey-service/esia/auth`,
        method: "POST",
        body: {
          additionalNumber,
          code,
          state,
        },
      }),
    }),
    getPersonalInfo: builder.query<IPersonalInfoResponse, IPersonalInfoRequest>(
      {
        query: ({ additionalNumber }) => ({
          url: additionalNumber
            ? `/client-service/personal-info?additionalNumber=${additionalNumber}`
            : `/client-service/personal-info`,
          method: "GET",
        }),
      }
    ),
    postPersonalAdjustment: builder.mutation<
      ICommonResponse,
      IPersonalAdjustmentRequest
    >({
      query: (body) => ({
        url: "/feedback-service/adjustment/personal/v2",
        method: "POST",
        body,
      }),
    }),
    postSendConfirmation: builder.mutation<
      ISendConfirmationResponse,
      ISendConfirmationRequest
    >({
      query: (body) => ({
        url: `/user-detail-service/send_confirmation`,
        method: "POST",
        body,
      }),
    }),
    postCheckConfirmation: builder.mutation<
      ICommonResponse,
      ICheckConfirmationRequest
    >({
      query: (body) => ({
        url: `/user-detail-service/check_confirmation`,
        method: "POST",
        body,
      }),
    }),
    postSendEmailConfirmation: builder.mutation<
      ICommonResponse,
      ISendEmailConfirmationRequest
    >({
      query: (body) => ({
        url: `/user-detail-service/send_email_confirmation`,
        method: "POST",
        body,
      }),
    }),
    postUpdateEmail: builder.mutation<ICommonResponse, IUpdateEmailRequest>({
      query: (body) => ({
        url: `/user-detail-service/update/email`,
        method: "POST",
        body,
      }),
    }),
    postGoskeySend: builder.mutation<IGoskeySendResponse, IGoskeySendRequest>({
      query: (body) => ({
        url: `/goskey-service/send`,
        method: "POST",
        body,
        responseHandler: async () => {
          // window.open(routes.signatureGoskey)
          window.location.pathname = routes.signatureGoskey
        },
      }),
    }),
    postGoskeyDocument: builder.mutation<
      IGoskeyDocumentResponse,
      IGoskeyDocumentRequest
    >({
      query: (body) => ({
        url: `/goskey-service/document`,
        method: "POST",
        body,
      }),
      transformResponse: (response: any) => {
        store.dispatch(loginActions.setContract(response?.data?.document))
        return response.data
      },
    }),
    postTariffOptionStatus: builder.mutation<
      ITariffOptionStatusResponse,
      ITariffOptionStatusRequest
    >({
      query: (body) => ({
        url: `/tariff-service/tariff/option/status`,
        method: "POST",
        body,
      }),
    }),
    postTariffAura: builder.mutation<ITariffAuraResponse, ITariffAuraRequest>({
      query: (body) => ({
        url: `/tariff-service/aura`,
        method: "POST",
        body,
        responseHandler: async (response) => {
          if (response.ok) {
            sendMetric({
              Услуги: { Доступные: { Аура: ["Ввести код_Клик "] } },
            })
            window.location.pathname = routes.auraOperationStatus
          } else {
            window.location.pathname = `${routes.auraOperationStatus}?isNotSuccess=true`
          }
        },
      }),
    }),
    deleteGoskeyCache: builder.mutation<IGoskeyDeleteCacheResponse, void>({
      query: () => ({
        url: EApiUrls.GoskeyServiceCache,
        method: "DELETE",
      }),
    }),
    getUserDetailAuthParams: builder.query<IAuthParamsResponse, void>({
      query: () => ({
        url: `/user-detail-service/sber/auth/params`,
        method: "GET",
      }),
    }),
    postUserDetailServiceAuthLogin: builder.mutation<any, any>({
      query: (body) => ({
        url: `/user-detail-service/sber/auth/login`,
        method: "POST",
        body,
      }),
    }),
    getUserDetailServiceCheck: builder.mutation<
      ICheckNumberResponse,
      ICheckNumberRequest
    >({
      query: ({ phone }) => ({
        url: `/user-detail-service/sber/check?selectedNumber=${phone}`,
        method: "GET",
      }),
    }),
    postAdditionalNumberAlias: builder.mutation<
      IAdditionalNumberResponse,
      IAdditionalNumberAliasRequest
    >({
      query: (body) => ({
        url: `/user-detail-service/additional_number/alias`,
        method: "POST",
        body,
      }),
    }),
    postAdjustmentNumber: builder.mutation<
      ICommonResponse,
      IPaymentAdjustmentNumberRequest
    >({
      query: (body) => ({
        url: "/feedback-service/adjustment/number",
        method: "POST",
        body,
      }),
    }),
    postAdjustmentAmount: builder.mutation<
      ICommonResponse,
      IPaymentAdjustmentAmountRequest
    >({
      query: (body) => ({
        url: "/feedback-service/adjustment/amount",
        method: "POST",
        body,
      }),
    }),
    getNumberInfo: builder.query<INumberInfoResponse, { phone: string }>({
      query: ({ phone }) => ({
        url: `/user-detail-service/number/info?number=${phone}`,
        method: "GET",
      }),
    }),
    getCostDetailInterval: builder.query<
      ICostDetailIntervalResponse,
      ICostDetailIntervalRequest
    >({
      query: ({ number }) => {
        return `report-service/report/costdetail/interval?number=${number}`
      },
    }),
    getTimeslot: builder.query<ITimeslotResponse, ITimeslotRequest>({
      query: ({ number, date }) => {
        return {
          url: `/number-transfer-service/v2/timeslots/${number}`,
          params: { date },
          responseHandler: async (response) => {
            if (response.ok) {
              const { data } = await response.json()
              const slots = (store.getState() as RootState).numberTransfer.slots
              if (!slots.find((slot) => slot.slotDate === date)) {
                store.dispatch(
                  numberTransferActions.addSlots({
                    slots: (data as TimeSlotData).timeSlots,
                  })
                )
              }
              return (data as TimeSlotData).timeSlots
            }
          },
        }
      },
    }),
    postCostDetailInterval: builder.mutation<
      ICommonResponse,
      ICostDetailRequest
    >({
      query: (body) => ({
        url: `report-service/report/costdetail`,
        method: "POST",
        body,
      }),
    }),
    postNumberTransferCheck: builder.mutation<
      ICommonResponse,
      INumberTransferCheckRequest
    >({
      query: (body) => ({
        url: `number-transfer-service/number/check`,
        method: "POST",
        body,
      }),
    }),
    postNumberTransferConfirm: builder.mutation<
      ICommonResponse,
      INumberTransferConfirmRequest
    >({
      query: (body) => ({
        url: `number-transfer-service/number/confirm`,
        method: "POST",
        body,
      }),
    }),
    postNumberTransferOrder: builder.mutation<
      ICommonResponse,
      INumberTransferOrderRequest
    >({
      query: (body) => ({
        url: `number-transfer-service/order`,
        method: "POST",
        body,
      }),
    }),
    getNumberTransferOrder: builder.query<
      IGetNumberTransferOrderResponse,
      void
    >({
      query: () => `number-transfer-service/order`,
    }),
    getRoamingCountries: builder.query<ICountriesResponse, { phone?: string }>({
      query: ({ phone }) => ({
        url: `tariff-service/roaming/countries`,
        params: { additional_number: phone },
      }),
    }),
    getRoaming: builder.query<
      IRoamingResponse,
      { code: string; phone?: string }
    >({
      query: ({ phone, code }) => ({
        url: `tariff-service/roaming/v2/${code}`,
        params: { additional_number: phone },
      }),
    }),
    getPackageServices: builder.query<
      IServicePackageResponse,
      IServicePackageRequest
    >({
      query: ({ optionId, phone }) => ({
        url: `/tariff-service/service-package/${optionId}?additionalNumber=${phone}`,
        method: "GET",
      }),
    }),
    getHistoryCalls: builder.query<IHistoryCallsResponse, IHistoryCallsRequest>(
      {
        query: ({ phone }) => ({
          url: `tariff-service/secure-account/voicemail?additionalNumber=${phone}`,
          method: "GET",
        }),
      }
    ),
    getTranscriptionCalls: builder.query<
      ITranscriptionCallsResponse,
      ITranscriptionCallsRequest
    >({
      query: ({ phone, callId }) => ({
        url: `tariff-service/secure-account/voicemail/calls/${callId}?additionalNumber=${phone}`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useGetRoamingQuery,
  useGetRoamingCountriesQuery,
  useGetTariffQuery,
  useGetAdditionalNumberQuery,
  useGetAutoPayQuery,
  useGetBannersQuery,
  useGetInfoblockQuery,
  useGetPrimeProfileV2Mutation,
  useGetLinksQuery,
  useGetOrderQuery,
  useGetPrimePrivilegesQuery,
  useGetPrimeProfileQuery,
  useGetRecommendedAmountQuery,
  useGetSubQuery,
  useSberSubUnbindMutation,
  useGetUserConfigQuery,
  useGetCustomerProfileQuery,
  useGetContractQuery,
  useDeleteAdditionalNumberMutation,
  useAdditionalNumberOrderMutation,
  useGetConnectedAvailableQuery,
  useLazyGetBeautifulNumbersQuery,
  usePostBeautifulNumberReserveMutation,
  usePostBeautifulNumberReleaseMutation,
  usePostBeautifulNumberReplaceMutation,
  useGetGoskeyPreauthMutation,
  usePostGoskeyAuthQuery,
  useGetPersonalInfoQuery,
  usePostPersonalAdjustmentMutation,
  usePostSendConfirmationMutation,
  usePostCheckConfirmationMutation,
  usePostSendEmailConfirmationMutation,
  usePostUpdateEmailMutation,
  usePostGoskeySendMutation,
  usePostGoskeyDocumentMutation,
  usePostTariffOptionStatusMutation,
  usePostTariffAuraMutation,
  useDeleteGoskeyCacheMutation,
  useGetUserDetailAuthParamsQuery,
  usePostUserDetailServiceAuthLoginMutation,
  useGetUserDetailServiceCheckMutation,
  usePostAdditionalNumberAliasMutation,
  usePostAdjustmentAmountMutation,
  usePostAdjustmentNumberMutation,
  useLazyGetNumberInfoQuery,
  useGetCostDetailIntervalQuery,
  usePostCostDetailIntervalMutation,
  useGetTimeslotQuery,
  useLazyGetTimeslotQuery,
  usePostNumberTransferCheckMutation,
  usePostNumberTransferConfirmMutation,
  usePostNumberTransferOrderMutation,
  useGetNumberTransferOrderQuery,
  useGetPackageServicesQuery,
  useGetHistoryCallsQuery,
  useGetTranscriptionCallsQuery,
} = sberMobileApi
