import styled from "styled-components"
import { Table, Typography } from "components"

export const TableBody = styled(Table.Body)`
  position: relative;
  height: 100vh;
  align-items: center;
  background: ${(props) => props.theme.colors.background[1]};
  overflow: hidden;
  text-align: center;
  justify-content: space-between;
`

export const HeaderWrap = styled.div`
  align-self: flex-start;
  margin-left: -16px;
  div {
    background: ${(props) => props.theme.colors.background[1]};
  }
`

export const Title = styled(Typography.Body2)`
  font-size: 12px;
`

export const Footer = styled.div`
  justify-self: flex-end;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-bottom: 32px;
`

export const CurrentNumber = styled.span`
  white-space: nowrap;
`

export const FooterSubtitle = styled(Typography.Body2)`
  margin-bottom: 8px;
  width: 250px;
`
