import React, { FC, useEffect, useMemo, useState } from "react"
import { formatPhoneNumber } from "utils"
import { useGetUserConfigQuery } from "store/api"
import { normalizeUserConfig } from "entities/userConfig"
import { sendMetric } from "utils/metrics"
import { useActions } from "hooks/actions"
import {
  Typography,
  Icon,
  Sheet,
  Table,
  IPhoneNumber,
  RenderPhones,
} from "components"
import { Header } from "./styles"

interface IPhoneSelect {
  phones?: IFormattedPhones
  onChange?: (value: string) => void
  current: string
}

interface IFormattedPhones {
  availablePhones: IPhoneNumber[]
  unavailablePhones: IPhoneNumber[]
}

const DisplayPhones: FC<IPhoneSelect> = ({ phones, onChange, current }) => {
  const [isOpen, setOpen] = useState(false)
  const { data: userConfigData } = useGetUserConfigQuery()
  const { clearBeautifulNumbersData } = useActions()

  useEffect(() => {
    if (isOpen) {
      sendMetric({ ГлавныйЭкран: { ПривязанныеНомера: { Показ: [] } } })
    }
  }, [isOpen])

  const userConfigNormalized = useMemo(() => {
    return normalizeUserConfig(userConfigData)
  }, [userConfigData])

  const handlePhoneSelect = (phone: string) => {
    if (current !== phone) {
      clearBeautifulNumbersData()
    }
    sendMetric({
      ГлавныйЭкран: { ПривязанныеНомера: { ДополнительныйНомерКлик: [] } },
    })
    setOpen(false)
    onChange?.(phone)
  }

  return (
    <>
      <Header onClick={() => setOpen(!isOpen)}>
        <Typography.Body1>{formatPhoneNumber(current)}</Typography.Body1>
        <Icon.ArrowDown />
      </Header>
      <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
        <Sheet.CommonContainer horizontalPadding={0}>
          <Sheet.CommonHeader>
            <Typography.H4>Заменяемый номер</Typography.H4>
          </Sheet.CommonHeader>
          <Table.Body noPadding>
            {phones && (
              <>
                <Table.Section>
                  <RenderPhones
                    phones={phones}
                    handlePhoneSelect={handlePhoneSelect}
                    current={current}
                    unavailableTitle="Недоступно для замены"
                    unavailableSubtitle="Замена номера недоступна для номеров юридических лиц,
                    других операторов и разных регионов"
                    userConfigNormalized={userConfigNormalized}
                  />
                </Table.Section>
              </>
            )}
          </Table.Body>
        </Sheet.CommonContainer>
      </Sheet>
    </>
  )
}

export { DisplayPhones }
