import { FC } from "react"
import { CommonContainer } from "components/Sheet/styles"
import { v4 as uuidv4 } from "uuid"
import { ELinksCode } from "entities/common"
import { useGetLinksQuery } from "store/api"
import { EBonusOptionType } from "entities/tariff"
import { Typography, Button, Spacer } from "components"
import { BLANK } from "constants/common"
import { Icons, TypographyH4 } from "./styles"
import { ITariffServiceStatusProps } from "./interfaces"

export const TariffServiceStatus: FC<ITariffServiceStatusProps> = ({
  serviceData,
  onClose,
}) => {
  const { data: links } = useGetLinksQuery()
  const renderIcon = (
    enabledApps: Array<{ appCode: string; iconUrl: string }>
  ) => {
    return (
      <Icons>
        {enabledApps?.map(({ appCode, iconUrl }) => (
          <img key={uuidv4()} src={iconUrl} alt={appCode} />
        ))}
      </Icons>
    )
  }

  const renderButton = (contentType: EBonusOptionType) =>
    contentType === EBonusOptionType.SberSound && links ? (
      <Button.Primary
        onClick={() => window.open(links[ELinksCode.SberSound], BLANK)}
      >
        Открыть Звук
      </Button.Primary>
    ) : (
      <Button.Plain onClick={onClose}>Понятно</Button.Plain>
    )

  return (
    <CommonContainer>
      {serviceData && (
        <>
          <TypographyH4>{serviceData.title}</TypographyH4>
          <Spacer height={4} />
          <Typography.Body2>{serviceData.subTitle}</Typography.Body2>
          {serviceData?.enabledApps && (
            <>
              <Spacer height={18} />
              {renderIcon(serviceData?.enabledApps)}
            </>
          )}
          <Spacer height={34} />
          {/* пока с бека не приходит верный description по этой услуге */}
          {serviceData?.contentType !==
            EBonusOptionType.UnlimitedSberServices && (
            <Typography.Body1 style={{ textAlign: "center" }}>
              {serviceData.description}
            </Typography.Body1>
          )}
          <Spacer height={28} />
          {renderButton(serviceData?.contentType)}
        </>
      )}
    </CommonContainer>
  )
}
