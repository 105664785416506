import React, { ComponentType, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  ICommonField,
  TFormField,
  TFieldQuery,
  TFormFieldCommon,
} from "components/Form/interfaces"
import { useFormContext } from "react-hook-form"
import { useActions } from "hooks/actions"
import { RootState } from "store"

const withQuery = (
  Field: ComponentType<TFormField & ICommonField>
): ((
  props: TFormField & ICommonField & { query: TFieldQuery; watchField: string }
) => JSX.Element) => {
  return (props) => {
    const { onChange, query, name, watchField, ...other } = props
    const { addWatchValues } = useActions()
    const { setError, watch } = useFormContext()
    const { setValid } = useActions()
    const { values } = useSelector((state: RootState) => state.withQueryHoc)
    const [fieldProps, setFieldProps] = useState<TFormFieldCommon>()
    const watchFieldValue = watch(watchField)
    const [trigger, result] = query.query()

    const setFormError = () => {
      query.onError?.(query.error)
      setError(watchField ?? "", {
        message: query.error,
      })
    }

    const updateProps = (data: unknown) => {
      const {
        fieldProps: resultProps,
        hasError,
        isValid,
      } = query.result(data, props, watchFieldValue)
      setFieldProps(resultProps)
      if (hasError) {
        setFormError()
      }
      setValid({ isValid })
    }

    useEffect(() => {
      const watchValue = values?.[watchField]
      if (watchFieldValue && watchFieldValue !== watchValue) {
        const cachedData = query.cache(watchFieldValue)
        if (!cachedData) {
          trigger(query.parameters(watchFieldValue))
        } else {
          updateProps(cachedData)
        }
        addWatchValues({ values: { [watchField]: watchFieldValue } })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchFieldValue])

    useEffect(() => {
      if (result.data && result.isSuccess) {
        updateProps(result.data)
      }
      if (result.isError) {
        setFormError()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result])

    return (
      <Field
        {...other}
        {...fieldProps}
        name={name}
        onChange={onChange}
        query={query}
        watchField={watchField}
      />
    )
  }
}

export { withQuery }
