import { FieldFormat } from "components/Form"
import { IFile } from "components/UploadFile/UploadFile"
import {
  FieldExtensionType,
  IFormModule,
  TCustomValidator,
  TFieldValue,
} from "components/Form/interfaces"
import { sendMetric } from "utils/metrics"
import { emailPattern } from "constants/common"

export const formModules = (
  currentPhone: string,
  customValidator: TCustomValidator,
  email?: string
): IFormModule[] => {
  return [
    {
      title: "",
      fields: [
        {
          extensionType: FieldExtensionType.common,
          name: "docType",
          placeholder: "Тип документа",
          format: FieldFormat.select,
          initialValue: "Паспорт РФ",
          sheet: {
            title: "Тип документа",
          },
          validators: { required: "Укажите тип документа" },
          onClick: () =>
            sendMetric({
              "Корректировка рег. данных": {
                "Изменение перс.данных": ["Тип документа_Клик"],
              },
            }),
          onSelect: (docType: TFieldValue) =>
            sendMetric({
              "Корректировка рег. данных": {
                "Изменение перс.данных": { "Выбор типа_Клик": [docType] },
              },
            }),
          options: [
            { name: "Паспорт РФ", value: "Паспорт РФ" },
            {
              name: "Паспорт иностранного гражданина",
              value: "Паспорт иностранного гражданина",
            },
            {
              name: "Удостоверение личности моряка",
              value: "Удостоверение личности моряка",
            },
            {
              name: "Дипломатический паспорт",
              value: "Дипломатический паспорт",
            },
            {
              name: "ВНЖ лица без гражданства",
              value: "ВНЖ лица без гражданства",
            },
            { name: "Удостоверение беженца", value: "Удостоверение беженца" },
            {
              name: "Свидетельство о предоставлении временного убежища на территории РФ",
              value:
                "Свидетельство о предоставлении временного убежища на территории РФ",
            },
            { name: "Военное удостоверение", value: "Военное удостоверение" },
            {
              name: "Временное удостоверение личности гражданина РФ",
              value: "Временное удостоверение личности гражданина РФ",
            },
          ],
        },
      ],
    },
    {
      title: "Загрузите фотографии документов",
      subtitle: "Поля со звездочкой обязательны к заполнению",
      fields: [
        {
          extensionType: FieldExtensionType.common,
          name: "firstPage",
          placeholder: "Разворот с фотографией*",
          format: FieldFormat.file,
          onClick: () =>
            sendMetric({
              "Корректировка рег. данных": {
                "Изменение перс.данных": ["Разворот с фото_Клик"],
              },
            }),
          max: 1,
          initialValue: [],
          validators: {
            validate: (files: IFile[]) => {
              if (files?.length === 0) {
                return "Прикрепите разворот с фотографией"
              }
              if (files?.find((file) => file?.size > 1000000)) {
                return "Размер файла привышает 1Мб"
              }
              return true
            },
          },
        },
        {
          extensionType: FieldExtensionType.WithCustomValidation,
          name: "registrationPage",
          placeholder: "Разворот с регистрацией*",
          onClick: () =>
            sendMetric({
              "Корректировка рег. данных": {
                "Изменение перс.данных": ["Разворот с регистрацией_Клик"],
              },
            }),
          format: FieldFormat.file,
          watchField: "docType",
          initialValue: [],
          validators: {
            validate: (files: IFile[]) => {
              if (files?.find((file) => file?.size > 1000000)) {
                return "Размер файла привышает 1Мб"
              }
            },
          },
          customValidator,
        },
        {
          extensionType: FieldExtensionType.common,
          name: "previousDocPage",
          placeholder: "Последняя страница с данными предыдущего документа",
          onClick: () =>
            sendMetric({
              "Корректировка рег. данных": {
                "Изменение перс.данных": ["Последняя страница_Клик"],
              },
            }),
          format: FieldFormat.file,
          initialValue: [],
          validators: {
            validate: (files: IFile[]) => {
              if (files?.find((file) => file?.size > 1000000)) {
                return "Размер файла привышает 1Мб"
              }
              return true
            },
          },
        },
      ],
    },
    {
      title: "Укажите контактные данные",
      fields: [
        {
          extensionType: FieldExtensionType.common,
          name: "contactNumber",
          placeholder: "Контактный номер телефона",
          onClick: () =>
            sendMetric({
              "Корректировка рег. данных": {
                "Изменение перс.данных": ["Контактный номер_Клик"],
              },
            }),
          format: FieldFormat.phoneSelect,
          initialValue: currentPhone,
          sheet: {
            title: "Контактный номер телефона",
          },
          validators: { required: "Укажите контактный номер телефона" },
        },
        {
          extensionType: FieldExtensionType.common,
          name: "email",
          placeholder: "Укажите email для ответа",
          onClick: () =>
            sendMetric({
              "Корректировка рег. данных": {
                "Изменение перс.данных": ["Email_Клик"],
              },
            }),
          format: FieldFormat.text,
          initialValue: email ?? "",
          validators: {
            required: "Укажите email",
            pattern: {
              value: emailPattern,
              message: "Укажите корректный email",
            },
          },
        },
      ],
    },
  ]
}
