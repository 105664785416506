import { call, put, select } from "redux-saga/effects"
import {
  additionalNumber,
  loginService,
  resetPasswordService,
} from "entities/services/userService"
import { getSearchObject } from "utils/search"
import { getBitsFromHex } from "utils/converters"
import { ELoginStatus } from "entities/common"
import { EBannerType } from "entities/banners"
import {
  getAppVersionName,
  getDeviceId,
  getSystem,
  getSystemVersion,
} from "utils/system"
import { sendMetric } from "utils/metrics"
import { EPrimeStatus } from "entities/prime"
import { loginActions } from "../slice/loginSlice"
import { modalActions } from "../slice/modalSlice"
import { routes } from "constants/routes"

export function* loginStart({
  payload,
}: ReturnType<typeof loginActions.loginStart>): any {
  try {
    const { additional } = getSearchObject()
    const response = yield call(resetPasswordService, {
      additional: additional || "false",
      number: payload,
    })
    yield put(loginActions.setCurrentPhone(payload))
    yield put(loginActions.setSummary(response.data.data.summary))
    // 8-9 бит
    const bitsEighthNinth = getBitsFromHex(response.data.data.summary, 8, 2)
    switch (bitsEighthNinth) {
      case ELoginStatus.NotFound: {
        yield put(
          loginActions.loginFail(
            "Номер не найден. Проверьте корректность ввода номера."
          )
        )
        sendMetric({
          Авторизация: {
            ВводНомера: {
              Ошибка: ["Номер не найден. Проверьте корректность ввода номера."],
            },
          },
        })
        return
      }
      case ELoginStatus.NotSupported:
      case ELoginStatus.NotSupportedBig4: {
        yield put(loginActions.loginFail("Неподдерживаемый оператор"))
        sendMetric({
          Авторизация: {
            ВводНомера: {
              Ошибка: ["Неподдерживаемый оператор"],
            },
          },
        })
        return
      }
      case ELoginStatus.Success:
        // 16-21 биты
        const bits = getBitsFromHex(response.data.data.summary, 16, 6)
        // Если клиент b2c/b2b2c, есим, неидентифицирован и ему недоступны услуги связи
        if (bits === "000000" || bits === "001000") {
          if (response.data.data.contactNumber) {
            yield put(
              loginActions.setDisplayPhone(response.data.data.contactNumber)
            )
          }
        }
        yield put(loginActions.loginSuccess())
        if (window.location.pathname === routes.loginSberId) {
          window.location.pathname = routes.login
        }
        return
      default:
      // no default
    }
  } catch (e: any) {
    sendMetric({
      Авторизация: {
        ВводНомера: {
          Ошибка: [e?.response?.data?.statusInfo?.statusMessage],
        },
      },
    })
    if (window.location.pathname === routes.loginSberId) {
      yield put(
        modalActions.openModal({
          isOpen: true,
          title: "Информация",
          subtitle: e?.response?.data?.statusInfo?.statusMessage,
          declineLabel: "Закрыть",
        })
      )
    }
    yield put(
      loginActions.loginFail(e?.response?.data?.statusInfo?.statusMessage)
    )
  }
}

export function* sendSmsStart({
  payload,
}: ReturnType<typeof loginActions.sendSmsStart>): any {
  try {
    const number = yield select((state) => state.login.phone)
    const { currentPhone, summary } = yield select((state) => state.login)
    const { additional } = getSearchObject()
    // 16-21 биты
    const bits = getBitsFromHex(summary, 16, 6)
    // 8-9 бит
    const bitsEighthNinth = getBitsFromHex(summary, 8, 2)
    if (additional === "true") {
      yield call(additionalNumber, {
        number: currentPhone,
        password: payload,
      })
      yield put(loginActions.sendSmsSuccess())
      window.location.pathname = routes.main
    } else {
      const { data } = yield call(loginService, {
        screen: "",
        appVersionName: getAppVersionName(),
        appVersionCode: process.env.REACT_APP_VERSION_CODE,
        system: getSystem(),
        systemVersion: getSystemVersion(),
        deviceId: getDeviceId(),
        password: payload,
        number,
      })
      yield put(loginActions.setToken(data.data.token))
      yield put(loginActions.sendSmsSuccess())
      if (
        bitsEighthNinth === ELoginStatus.Success &&
        (bits === "000000" || bits === "001000")
      ) {
        window.location.pathname = routes.signature
      } else {
        const { code } = getSearchObject()
        const {
          sberId: { primeStatus },
        } = yield select((state) => state.login)
        if (code && primeStatus === EPrimeStatus.Active) {
          yield put(loginActions.setSberIdPrimeSheet(true))
        }
        window.location.pathname = routes.main
      }
    }
  } catch (e: any) {
    sendMetric({
      Авторизация: {
        ВводСмс: {
          Ошибка: [e?.response?.data?.statusInfo?.statusMessage],
        },
      },
    })
    if (
      e?.response?.data?.statusInfo?.statusCode === EBannerType.WaitInGoskey
    ) {
      window.location.pathname = routes.signatureGoskeyWait
    } else {
      yield put(
        loginActions.sendSmsFail(e?.response?.data?.statusInfo?.statusMessage)
      )
    }
  }
}
