import { FC, ReactElement } from "react"
import { Icon, Table, Typography } from "components"
import { Styled } from "./styles"

interface IInternalServices {
  icon: ReactElement
  title: string
  onClick: () => void
}

export const InternalServices: FC<IInternalServices> = (props) => {
  const { icon, title, onClick } = props

  return (
    <Table.CellDynamicColumns
      onClick={onClick}
      columns={[
        {
          content: <Styled.FirstColumn>{icon}</Styled.FirstColumn>,
        },
        {
          isMainColumn: true,
          content: <Typography.Body1Bold>{title}</Typography.Body1Bold>,
        },
        {
          content: (
            <Styled.ThirdColumn>
              <Icon.ArrowRight />
            </Styled.ThirdColumn>
          ),
        },
      ]}
    />
  )
}
