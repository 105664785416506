import styled from "styled-components"
import { Icon } from "components"

const HeadIcon = styled(Icon.SecureAccountShield)`
  width: 165px;
  height: 165px;
  margin: 0 auto 16px;
`

export const Styled = {
  HeadIcon,
}
