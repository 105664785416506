import { FC, ReactElement } from "react"
import { useSetTheme } from "hooks/useSetTheme"
import { EThemeType } from "theme/interfaces"
import { useTheme } from "styled-components"
import { dayTheme } from "theme/dayTheme"
import {
  Button,
  Container,
  MPTypography,
  Spacer,
  Table,
  Typography,
  Wrapper,
} from "components"
import { SELF } from "constants/common"

interface IFAQDetailsProps {
  sectionContent: {
    id: number
    icon: ReactElement
    content: string
  }[]
  sectionTitle: string
  buttonText: string
  externalTheme: EThemeType
  buttonLink: string
}
export const FAQDetails: FC<IFAQDetailsProps> = ({
  sectionContent,
  sectionTitle,
  buttonText,
  externalTheme,
  buttonLink,
}) => {
  useSetTheme(externalTheme)
  const onButtonClick = () => window.open(buttonLink, SELF)
  const theme = useTheme()
  return (
    <Container backgroundColor={theme.colors.background.surface1} page>
      <Spacer height={40} />
      <Table.Section
        title={sectionTitle}
        titlePadding={{ paddingBottom: 6 }}
        titleComponent={({ children }: any) => (
          <Typography.H2>{children}</Typography.H2>
        )}
        hasDivider={false}
      >
        {sectionContent.map((content) => (
          <Table.CommonCell
            backgroundColor={theme.colors.background.surface1}
            key={content.id}
            title={content.content}
            titleComponent={({ children }: any) => (
              <MPTypography startPadding={16}>{children}</MPTypography>
            )}
            startIcon={content.icon}
            startIconWidth="auto"
            titleSpacer={16}
          />
        ))}
      </Table.Section>
      <Spacer height={24} />
      <Wrapper>
        <Button.MPPrimary onClick={onButtonClick}>
          <Typography.Body2Bold
            color={dayTheme.colors.background.surface0}
            textTransform="uppercase"
          >
            {buttonText}
          </Typography.Body2Bold>
        </Button.MPPrimary>
      </Wrapper>
      <Spacer height={24} />
    </Container>
  )
}
