import { IFormModule } from "./interfaces"

export const getInitialValues = (modules: IFormModule[]) => {
  return modules.reduce(
    (acc, module) => ({
      ...acc,
      ...module.fields.reduce(
        (fieldAcc, field) => ({
          ...fieldAcc,
          [field.name]: field.initialValue,
        }),
        {}
      ),
    }),
    {}
  )
}
