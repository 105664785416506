import React, { FC, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { formatPhoneNumber } from "utils"
import { useDeleteAdditionalNumberMutation } from "store/api"
import { RootState } from "store"
import { useActions } from "hooks/actions"
import { Sheet } from "components"
import {
  Body1Styled,
  CancelButtonStyled,
  ConfirmButtonStyled,
  H4Styled,
  ImageStyled,
  RootStyled,
} from "./styles"
import deleteNumberImage from "./image/delete-number.png"

interface IDeleteNumberSheet {
  isOpen: boolean
  onClose(): void
  phone: string
}

const DeleteNumberSheet: FC<IDeleteNumberSheet> = ({
  isOpen,
  onClose,
  phone,
}) => {
  const navigate = useNavigate()
  const [trigger, result] = useDeleteAdditionalNumberMutation()
  const { phone: loginPhone, currentPhone } = useSelector(
    (state: RootState) => state.login
  )
  const { setCurrentPhone } = useActions()

  useEffect(() => {
    if (result.isSuccess) {
      onClose()
      navigate(-1)
    }
  }, [result, onClose, navigate])

  const onConfirm = () => {
    if (phone) {
      if (phone === currentPhone) {
        setCurrentPhone(loginPhone)
      }
      trigger({
        number: phone,
      })
    }
  }

  return (
    <Sheet isOpen={isOpen} onClose={onClose}>
      <RootStyled>
        <ImageStyled src={deleteNumberImage} />
        <H4Styled>Удалить привязанный номер?</H4Styled>
        <Body1Styled>{formatPhoneNumber(phone)}</Body1Styled>
        <ConfirmButtonStyled onClick={onClose}>
          Нет, не надо
        </ConfirmButtonStyled>
        <CancelButtonStyled onClick={onConfirm}>Да, удалить</CancelButtonStyled>
      </RootStyled>
    </Sheet>
  )
}

export { DeleteNumberSheet }
