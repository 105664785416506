import { useNavigate } from "react-router-dom"
import { useActions } from "hooks/actions"
import { useTheme } from "styled-components"
import { TFormDataValues } from "components/Form/interfaces"
import { formSelector, useSelector } from "hooks/useSelector"
import { Container, Header, Form, Spacer } from "components"
import { routes } from "constants/routes"
import { formEmail } from "./formData"

export const EmailAdjustment = () => {
  const theme = useTheme()
  const { values } = useSelector(formSelector)
  const navigate = useNavigate()
  const { setFormValues } = useActions()
  const handleFormSubmit = (data: TFormDataValues) => {
    setFormValues({ values: data })
    navigate(routes.emailAdjustmentConfirmation)
  }
  return (
    <Container page>
      <Header
        onBackClick={() => navigate(routes.emailConfirmation)}
        title="Email"
        background={theme.colors.background[1]}
      />
      <Spacer height={16} />
      <Form
        onSubmit={handleFormSubmit}
        modules={formEmail}
        initialValues={values}
        submitButton="Сохранить"
      />
    </Container>
  )
}
