import React, { FC, memo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Header } from "components/Header"
import { useGetConnectedAvailableQuery } from "store/api"
import { RootState } from "store"
import { useTheme } from "styled-components"
import { PullToRefresh } from "components/PullToRefresh"
import { EServicesTabs } from "entities/common"
import { useActions } from "hooks/actions"
import { useScrollToTop } from "hooks/useScrollToTop"
import { TabsItem, Tabs } from "components"
import { routes } from "constants/routes"
import { RootStyled } from "./styles"
import { ShowOptions } from "./ShowOptions"
import { Skeleton } from "./Skeleton"

export const Services: FC = memo(() => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { currentServicesTab } = useSelector((state: RootState) => state.common)
  const { setCurrentServicesTab } = useActions()
  if (
    window.location.pathname === routes.servicesConnected &&
    currentServicesTab !== EServicesTabs.Enabled
  ) {
    setCurrentServicesTab(EServicesTabs.Enabled)
  }
  if (
    window.location.pathname === routes.servicesAvailable &&
    currentServicesTab !== EServicesTabs.Available
  ) {
    setCurrentServicesTab(EServicesTabs.Available)
  }
  const {
    data: connectedAvailable,
    isLoading: connectedAvailableIsLoading,
    refetch,
    isFetching,
  } = useGetConnectedAvailableQuery({
    phone: currentPhone,
  })

  const onChangeTab = (tab: string) => {
    setCurrentServicesTab(tab as EServicesTabs)
    if (tab === EServicesTabs.Enabled) {
      navigate(routes.servicesConnected)
    } else {
      navigate(routes.servicesAvailable)
    }
  }

  useScrollToTop()
  return (
    <RootStyled>
      <Header
        back={false}
        title="Услуги"
        background={theme.colors.background[0]}
      />
      {connectedAvailableIsLoading ? (
        <Skeleton />
      ) : (
        <PullToRefresh
          onPullEnd={refetch}
          isFetching={isFetching}
          iconTopOffset="-30px"
        >
          <Tabs onChangeTab={onChangeTab} currentTab={currentServicesTab}>
            <TabsItem
              title={EServicesTabs.Enabled}
              component={
                <ShowOptions
                  options={connectedAvailable?.data?.enabledOptions}
                />
              }
            />
            <TabsItem
              title={EServicesTabs.Available}
              component={
                <ShowOptions
                  options={connectedAvailable?.data?.availableOptions}
                  isAvailableOptions
                />
              }
            />
          </Tabs>
        </PullToRefresh>
      )}
    </RootStyled>
  )
})
