import { FieldFormat, IFormModule } from "components/Form"
import { FieldExtensionType, TFieldValue } from "components/Form/interfaces"
import { sendMetric } from "utils/metrics"
import { emailPattern } from "constants/common"

const sendEmailMetric = (() => {
  let innerValue: TFieldValue
  return function (value: TFieldValue, email: string) {
    if ((innerValue ?? email) !== value) {
      sendMetric({ "Детализация на email": ["Смена email_Ввод"] })
      innerValue = value
    }
  }
})()

export const formModule = (
  periods: { name: string; value: string }[],
  currentPhone: string,
  email?: string
): IFormModule[] => [
  {
    title: "",
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "clientNumber",
        placeholder: "Для номера",
        format: FieldFormat.phoneSelect,
        sheet: {
          title: "Номер для детализации",
        },
        onClick: () =>
          sendMetric({ "Детализация на email": ["Выбор номера_Клик"] }),
        initialValue: currentPhone ?? "",
        validators: { required: "Укажите номер для детализации" },
        unavailableTitle: "Недоступно для заказа",
        unavailableSubtitle:
          "Заказ детализации недоступен для номеров юридических лиц и других операторов",
      },
      {
        extensionType: FieldExtensionType.common,
        name: "period",
        placeholder: "За период",
        sheet: {
          title: "Выберите период",
        },
        format: FieldFormat.select,
        initialValue: periods?.[0]?.value ?? "",
        options: periods,
        validators: { required: "Укажите период" },
        onSelect: (val) =>
          sendMetric({
            "Детализация на email": {
              "Выбор периода": [(val as string).slice(3)],
            },
          }),
      },
      {
        extensionType: FieldExtensionType.common,
        name: "email",
        placeholder: "Email",
        format: FieldFormat.text,
        validators: {
          required: "Укажите email",
          pattern: { value: emailPattern, message: "Укажите корректный email" },
        },
        initialValue: email ?? "",
        onOuterBlur: (value) => sendEmailMetric(value, email ?? ""),
      },
    ],
  },
]
