import { createGlobalStyle } from "styled-components"
import { ITheme } from "./interfaces"

export default createGlobalStyle<{ theme: ITheme }>`
  html, body, #root {
    min-height: 100vh;
    overscroll-behavior-y: none;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    ::-webkit-scrollbar {
      display: none;
    }
    background: ${(props) => props.theme.colors.background.root};
  }    
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  input {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
   }

  .sbid-button {
    width: 100%;
    border-radius: 16px;
  }
`
