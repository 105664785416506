import styled from "styled-components"

export const Container = styled.div`
  width: 75px;
  height: 39px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  h4 {
    position: relative;
    color: ${(props) => props.theme.colors.background[1]};
    z-index: 1;
  }
  svg {
    position: absolute;
    left: 0;
    top: 0;
  }
`
