import styled from "styled-components"

export const Primary = styled.button`
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.button.disabled
      : props.theme.colors.button.primary};
  border-radius: 16px;
  font-family: "SBSansUI Regular", serif;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.button.contentPrimary};
  padding: 17px 0;
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
`
