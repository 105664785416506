import styled from "styled-components"
import { Typography } from "components"

export const RootStyled = styled.div``

export const LinkStyled = styled(Typography.Body1)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.warning};
  margin-bottom: 16px;
`
