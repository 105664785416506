import React, { FC, memo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import { Header } from "components/Header"
import { IGoskeyAuthResponse } from "entities/goskey"
import { useActions } from "hooks/actions"
import { useTheme } from "styled-components"
import { Icon, Spacer, Table, Typography, Sheet, Input } from "components"
// TODO: SupportSheetContainer Вынести в общий компонент стили
import { SupportSheetContainer } from "../Menu/styles"
import { routes } from "constants/routes"
import { urls } from "constants/urls"
import {
  ButtonPlainStyled,
  ButtonPrimaryStyled,
  FooterStyled,
  RootStyled,
  WrapItemsStyled,
} from "./styles"
import { Item } from "./Item"

interface ISignatureCheckUserDataProps {
  data?: IGoskeyAuthResponse
}

export const SignatureCheckUserData: FC<ISignatureCheckUserDataProps> = memo(
  ({ data }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const { email } = useSelector((state: RootState) => state.login)
    const { setEmail } = useActions()
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState(false)
    const validateEmail = (): boolean =>
      !!email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )

    const onConfirm = () => {
      if (email !== "") {
        const hasError = !validateEmail()
        if (hasError) {
          setError(hasError)
          return
        }
      }
      navigate(routes.signatureCondition)
    }

    return (
      <RootStyled>
        <Header
          background={theme.colors.background[1]}
          title="Проверьте данные"
        />
        <WrapItemsStyled>
          <Item title="Фамилия Имя Отчество" value={data?.data.fio || ""} />
          <Item title="Дата рождения" value={data?.data.dateOfBirth || ""} />
          <Item title="Документ" value={data?.data.documentType || ""} />
          <Item
            title="Серия и номер документа"
            value={data?.data.seriesNumber || ""}
          />
          <Input
            placeholder="Введите Email"
            description={
              error
                ? "Введите корректный email"
                : "Добавим контактные данные в договор"
            }
            onChange={(value) => setEmail(value)}
            value={email}
            hasError={error}
          />
        </WrapItemsStyled>
        <Spacer height={160} />
        <FooterStyled>
          <ButtonPrimaryStyled onClick={onConfirm}>
            Все верно
          </ButtonPrimaryStyled>
          <ButtonPlainStyled onClick={() => setOpen(true)}>
            Данные не верны
          </ButtonPlainStyled>
        </FooterStyled>
        <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
          <SupportSheetContainer>
            <Typography.H4>Если данные не верны</Typography.H4>
            <Spacer height={28} />
            <Typography.Body1>
              Обновите данные на Госуслугах или в МФЦ и продолжите подписание
              онлайн.
            </Typography.Body1>
            <Spacer height={24} />
            <Typography.Body1>
              Или подпишите договор другим способом:
            </Typography.Body1>
            <Spacer height={12} />
            <Table.Body noPadding>
              <Table.Section>
                <Table.CommonCell
                  title="В офисе Сбера"
                  subTitle="Понадобится только паспорт"
                  startIcon={<Icon.Office />}
                  endIcon={<Icon.ArrowRight />}
                  onClick={() =>
                    navigate(routes.showExternalLink, {
                      state: {
                        link: urls.officeMap,
                        pathname: window.location.pathname,
                      },
                    })
                  }
                />
                {/*<Table.CommonCell*/}
                {/*  title="Вызвать курьера"*/}
                {/*  subTitle="В Москве, Санкт-Петербурге и других городах — на встрече с курьером нужно иметь при себе паспорт"*/}
                {/*  startIcon={<Icon.House />}*/}
                {/*  endIcon={<Icon.ArrowRight />}*/}
                {/*  onClick={() =>*/}
                {/*    window*/}
                {/*      .open(*/}
                {/*        `${urls.identificationEsim}${currentPhone}`,*/}
                {/*        "_blank"*/}
                {/*      )*/}
                {/*      ?.focus()*/}
                {/*  }*/}
                {/*/>*/}
              </Table.Section>
            </Table.Body>
          </SupportSheetContainer>
        </Sheet>
      </RootStyled>
    )
  }
)
