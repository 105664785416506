import { FieldFormat } from "components/Form"
import { IFile } from "components/UploadFile/UploadFile"
import {
  FieldExtensionType,
  IFormModule,
  TCustomValidator,
} from "components/Form/interfaces"
import { sendMetric } from "utils/metrics"
import { emailPattern } from "constants/common"

export const formModules = (
  customValidator: TCustomValidator
): IFormModule[] => [
  {
    title: "Откуда вернуть",
    fields: [
      {
        extensionType: FieldExtensionType.WithCustomValidation,
        name: "incorrectNumber",
        placeholder: "Введите ошибочный номер",
        format: FieldFormat.phone,
        initialValue: "",
        watchField: "refillingNumber",
        customValidator,
        onClick: () =>
          sendMetric({
            "Корректировка платежа по номеру": {
              "Ввод данных": ["Ввод номера_Клик"],
            },
          }),
      },
      {
        extensionType: FieldExtensionType.common,
        name: "documents",
        placeholder: "Прикрепите чек",
        format: FieldFormat.file,
        info: "На чеке должна быть дата, время и сумма платежа",
        initialValue: [],
        onClick: () =>
          sendMetric({
            "Корректировка платежа по номеру": {
              "Ввод данных": ["Прикрепить чек_Клик"],
            },
          }),
        max: 5,
        validators: {
          validate: (files: IFile[]) => {
            if (files.length === 0) {
              return "Прикрепите чек"
            }
            if (
              files.reduce((result, file) => result + file.size, 0) > 8000000
            ) {
              return "Размер файлов не должен превышать 8 Мб. Удалите лишние изображения"
            }
            return true
          },
        },
      },
    ],
  },
  {
    title: "Куда зачислить",
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "refillingNumber",
        placeholder: "Выберите номер для зачисления",
        format: FieldFormat.phoneSelect,
        sheet: {
          title: "Номер для зачисления",
          description: "Выберите номер, на который мы зачислим платёж",
        },
        initialValue: "",
        unavailableTitle: "Недоступно для корректировки",
        unavailableSubtitle:
          "Корректировка платежа недоступна для номеров юридических лиц и других операторов",
        validators: { required: "Укажите номер для зачисления" },
        onClick: () =>
          sendMetric({
            "Корректировка платежа по номеру": {
              "Ввод данных": ["Номер зачисления_Клик"],
            },
          }),
      },
      {
        extensionType: FieldExtensionType.common,
        name: "email",
        placeholder: "Укажите email для связи",
        format: FieldFormat.text,
        validators: {
          required: "Укажите email",
          pattern: { value: emailPattern, message: "Укажите корректный email" },
        },
        initialValue: "",
        onClick: () =>
          sendMetric({
            "Корректировка платежа по номеру": {
              "Ввод данных": ["Email_Клик"],
            },
          }),
      },
    ],
  },
]
