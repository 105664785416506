import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Player } from "@lottiefiles/react-lottie-player"
import successfulFeedback from "animations/successfulFeedback.json"
import { Secondary } from "components/Button/Secondary"
import { getSearchObject } from "utils/search"
import { EOptionStatus } from "entities/common"
import { useTheme } from "styled-components"
import { RootState } from "store"
import { useGetConnectedAvailableQuery } from "store/api"
import { Header } from "components"
import {
  FooterStyled,
  H4Styled,
  MainContent,
  RootStyled,
  SubtitleStyled,
  TextContent,
  WrapImageStyled,
} from "./styles"

export const ServiceConnectionStatus = () => {
  const theme = useTheme()
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const navigate = useNavigate()
  const { status } = getSearchObject()
  const statusMessage =
    status === EOptionStatus.Active ? "подключится" : "отключится"
  const { refetch } = useGetConnectedAvailableQuery({
    phone: currentPhone,
  })

  const subtitle = `Услуга ${statusMessage} в течение 15 минут`
  const onLeavingPage = () => {
    refetch()
    return navigate(-1)
  }

  return (
    <>
      <Header background={theme.colors.background[1]} back={false} />
      <RootStyled>
        <MainContent>
          <WrapImageStyled>
            <Player autoplay loop src={successfulFeedback} />
          </WrapImageStyled>
          <TextContent>
            <H4Styled>Заявка принята</H4Styled>
            <SubtitleStyled>{subtitle}</SubtitleStyled>
          </TextContent>
        </MainContent>
        <FooterStyled>
          <Secondary onClick={onLeavingPage} children="Отлично" />
        </FooterStyled>
      </RootStyled>
    </>
  )
}
