import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useActions } from "hooks/actions"
import { sendMetric } from "utils/metrics"
import { OperationStatus, Spacer } from "components"
import { routes } from "constants/routes"

export const PersonalInfoAdjustmentStatus = () => {
  const navigate = useNavigate()
  const {
    state: { isSuccess, statusMessage },
  } = useLocation()
  const { clearFormValues } = useActions()
  const title = isSuccess ? "Заявка принята" : "Заявка не отправлена"
  const buttonTitle = isSuccess ? "Отлично" : "Понятно"

  const handleSubmit = () => {
    clearFormValues()
    navigate(routes.menu)
  }

  useEffect(() => {
    if (isSuccess) {
      sendMetric({
        "Корректировка рег.данных": { "Отправка заявки": ["Успех_Показ"] },
      })
    } else {
      sendMetric({
        "Корректировка рег. данных": {
          "Отправка заявки": { Ошибка: [statusMessage] },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <OperationStatus
      title={title}
      isSuccess={isSuccess}
      continueHandler={handleSubmit}
      continueButtonTitle={buttonTitle}
    >
      {isSuccess ? (
        <span>Срок обработки заявки 5 дней</span>
      ) : (
        <>
          <span>Произошла ошибка</span>
          <br />
          <Spacer height={4} />
          <span>Пожалуйста, создайте заявку повторно</span>
        </>
      )}
    </OperationStatus>
  )
}
