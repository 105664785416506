import { ReactElement } from "react"
import styled from "styled-components"
import { EClientType } from "entities/common"
import { IFormattedPhones } from "pages/BeautifulNumbers/interfaces"
import { IPhoneNumber } from "components"

export const NoWrap = styled.span`
  white-space: nowrap;
`

export const formatPhoneNumber = (
  phone?: string,
  returnString?: boolean
): string | ReactElement => {
  if (!phone) {
    return returnString ? "" : <></>
  }
  const match = (phone[0] === "7" ? phone.substring(1) : phone).match(
    /^(\d{3})(\d{3})(\d{2})(\d{2})$/
  )
  if (match) {
    const result = `+7 (${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
    return returnString ? (
      result
    ) : (
      <NoWrap className="ym-hide-content">{result}</NoWrap>
    )
  }
  return returnString ? "" : <></>
}

export const prepareSelectorNumbers = (phones?: IPhoneNumber[]) =>
  phones &&
  phones.reduce(
    (formattedPhones: IFormattedPhones, phoneItem: IPhoneNumber) => {
      if ([EClientType.B2C, EClientType.B2B2C].includes(phoneItem.clientType)) {
        formattedPhones.availablePhones.push(phoneItem)
      } else {
        formattedPhones.unavailablePhones.push(phoneItem)
      }

      return formattedPhones
    },
    { availablePhones: [], unavailablePhones: [] }
  )
