import React, { FC, ReactElement } from "react"
import styled, { css } from "styled-components"
import ReactMarkdown from "react-markdown"
import { Divider } from "components/Divider"
import { Spacer, ProgressBar, Typography } from "components"

const Container = styled.div<{ backgroundColor?: string }>`
  ${(props) =>
    props?.backgroundColor && `background-color: ${props.backgroundColor};`}
  position: relative;
  display: flex;
  padding: 14px 16px;
  width: 100%;
  min-height: 64px;
`

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`
//ксли есть иконка, то используем position relative для правильного отображения разделителя
const DefaultContent = styled.div<{ hasEndIcon?: boolean }>`
  ${(props) => (props.hasEndIcon ? "position: relative;" : null)}
  display: flex;
  width: 100%;
`

const Text = styled.div<{ textGap?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.textGap ?? 5}px;
  justify-content: center;
  flex-grow: 1;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  a {
    color: ${(props) => props.theme.colors.text.warning};
    text-decoration: none;
  }
  strong {
    font-family: "SBSansUI Bold", serif;
  }
`
const StartSubIcon = styled.div<{ right: number; bottom: number }>`
  position: absolute;
  right: ${(props) => props.right}%;
  bottom: ${(props) => props.bottom}%;
`
const IconContainer = styled.div`
  position: relative;
  height: fit-content;
`
const Icon = styled.div<{
  isStart?: boolean
  color?: string
  width?: number | string
}>`
  display: flex;
  align-items: flex-start;
  width: ${(props) => props.width || "48px"};
  min-width: ${(props) => props.width || "48px"};
  max-width: ${(props) => props.width || "48px"};
  justify-content: ${(props) => (props.isStart ? "flex-start" : "flex-end")};
  path {
    ${(props) =>
      props.color &&
      css`
        fill: ${props.color};
      `}
  }
`

const Title = styled(Typography.Body1)<{ titleSpacer?: number }>`
  ${(props) => props?.titleSpacer && `padding-left: ${props.titleSpacer}px;`}
  text-align: initial;
`

const Subtitle = styled(Typography.Body2)<{ titleSpacer?: number }>`
  ${(props) => props?.titleSpacer && `padding-left: ${props.titleSpacer}px;`}
  text-align: initial;
`

const TextDivider = styled(Divider)<{
  titleSpacer?: number
  hasEndIcon?: boolean
}>`
  ${(props) => props?.titleSpacer && `padding-left: ${props.titleSpacer}px;`}
  position: absolute;
  bottom: 0;
  bottom: -14px;
  left: ${(props) => props.titleSpacer ?? 0}px;
  right: 0px;
  ${(props) => (props.hasEndIcon ? "width: calc(100% - 16px);" : null)}
`

const isPhoneString = (phone?: string | ReactElement) =>
  phone && typeof phone === "string" && !!phone.match(/\+7 \(/)

interface ICommonCell {
  title?: string | ReactElement
  titleComponent?: any
  subTitle?: string | ReactElement
  titleInfo?: string
  titleSpacer?: number
  subTitleInfo?: string
  startIcon?: ReactElement
  startSubIcon?: ReactElement
  startSubIconRight?: number
  startSubIconBottom?: number
  endIcon?: ReactElement
  footerIcon?: ReactElement
  hasDivider?: boolean
  progressBarInfo?: {
    color: string
    percentage: number
  }
  onClick?: () => void
  color?: string
  width?: number | string
  isPhoneNumber?: boolean
  footer?: ReactElement
  textGap?: number
  startIconWidth?: string
  subtitleHandler?: () => void
  backgroundColor?: string
}

const CommonCell: FC<ICommonCell> = ({
  title,
  titleComponent: CustomTitle,
  subTitle,
  titleInfo,
  titleSpacer,
  subTitleInfo,
  startIcon,
  startSubIcon,
  startSubIconRight = 0,
  startSubIconBottom = 0,
  endIcon,
  footerIcon,
  progressBarInfo,
  onClick,
  color,
  width,
  isPhoneNumber,
  footer,
  hasDivider,
  textGap,
  startIconWidth,
  subtitleHandler,
  backgroundColor,
}) => {
  const externalTitle = () =>
    title ? <CustomTitle>{title}</CustomTitle> : <CustomTitle />
  return (
    <Container backgroundColor={backgroundColor} onClick={onClick}>
      {startIcon && (
        <Icon width={startIconWidth} isStart color={color}>
          <IconContainer>
            {startIcon}
            {startSubIcon && (
              <StartSubIcon
                right={startSubIconRight}
                bottom={startSubIconBottom}
              >
                {startSubIcon}
              </StartSubIcon>
            )}
          </IconContainer>
        </Icon>
      )}
      <MainContent>
        <DefaultContent hasEndIcon={!!endIcon}>
          <Text textGap={textGap}>
            <Row>
              {CustomTitle ? (
                externalTitle()
              ) : (
                <Title
                  color={color}
                  titleSpacer={titleSpacer}
                  lineHeight={22}
                  className={
                    isPhoneNumber && isPhoneString(title)
                      ? "ym-hide-content"
                      : ""
                  }
                >
                  {title}
                </Title>
              )}
              {titleInfo && (
                <Typography.Body1Bold>{titleInfo}</Typography.Body1Bold>
              )}
            </Row>
            {subTitle && (
              <Row>
                <Subtitle
                  titleSpacer={titleSpacer}
                  onClick={subtitleHandler}
                  className={
                    isPhoneNumber && isPhoneString(subTitle)
                      ? "ym-hide-content"
                      : ""
                  }
                >
                  {typeof subTitle === "string" ? (
                    <ReactMarkdown
                      components={{
                        p({ children }) {
                          return <span>{children}</span>
                        },
                      }}
                    >
                      {subTitle}
                    </ReactMarkdown>
                  ) : (
                    subTitle
                  )}
                </Subtitle>
                {subTitleInfo && (
                  <Typography.Body2>{subTitleInfo}</Typography.Body2>
                )}
              </Row>
            )}
            {hasDivider && !endIcon ? (
              <TextDivider titleSpacer={titleSpacer} hasEndIcon={!!endIcon} />
            ) : null}
          </Text>
          {endIcon && <Icon width={width}>{endIcon}</Icon>}
          {hasDivider && endIcon ? (
            <TextDivider titleSpacer={titleSpacer} hasEndIcon={!!endIcon} />
          ) : null}
        </DefaultContent>
        {footerIcon && (
          <>
            <Spacer height={12} />
            <ProgressBar progressBarInfo={progressBarInfo} />
            <Spacer height={6} />
            <Icon width={width}>{footerIcon}</Icon>
          </>
        )}
        {footer && (
          <>
            <Spacer height={13} />
            {footer}
          </>
        )}
      </MainContent>
    </Container>
  )
}

export { CommonCell }
