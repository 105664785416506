import { FAQCommon } from "pages"
import { getSearchObject } from "utils/search"
import { Description, TITLE } from "./constants"

export const FavoriteNumber = () => {
  const { theme: externalTheme } = getSearchObject()
  return (
    <FAQCommon
      externalTheme={externalTheme}
      title={TITLE}
      children={<Description />}
    />
  )
}
