import { FC, memo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Typography } from "components/Typography"
import { Spacer } from "components/Spacer"
import { useActions } from "hooks/actions"
import { Sheet } from "components/Sheet"
import { ServiceStatus } from "components/ServiceStatus"
import { EBannerType } from "entities/banners"
import { colors } from "theme/nightTheme/colors"
import { RootState } from "store"
import { IBannerProps } from "../BannerGroup/interfaces"
import { routes } from "constants/routes"
import { Styled } from "./styles"

export const Banner: FC<IBannerProps> = memo(
  ({
    title,
    subtitle,
    confirmationText,
    gradient,
    type,
    image,
    serviceData,
    auraPrice,
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const { hideBanner } = useActions()
    const navigate = useNavigate()
    const { roamingCountry } = useSelector((state: RootState) => state.common)

    const onToPlug = () => {
      switch (type) {
        case EBannerType.Aura:
          return navigate(`${routes.auraDetails}?price=${auraPrice}`)
        case EBannerType.YA:
          return setIsOpen(true)
        case EBannerType.Roaming:
          return navigate(
            roamingCountry ? routes.roamingOptions : routes.roamingChooseCountry
          )
      }
    }

    return (
      <>
        {serviceData && (
          <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <ServiceStatus
              onClose={() => setIsOpen(false)}
              data={serviceData}
              isAvailableOptions
            />
          </Sheet>
        )}
        <Styled.Container gradient={gradient}>
          <Spacer height={12} />
          <Styled.Title color={colors.text.primary}>{title}</Styled.Title>
          <Styled.Subtitle>{subtitle}</Styled.Subtitle>
          <Styled.ConfirmationButton onClick={onToPlug}>
            <Typography.Body2 color={colors.text.primary}>
              {confirmationText}
            </Typography.Body2>
          </Styled.ConfirmationButton>
          <Styled.BannerIcon>{image}</Styled.BannerIcon>
          <Styled.CloseIcon onClick={() => hideBanner(type)} />
        </Styled.Container>
      </>
    )
  }
)
