import React, { FC } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { loginActions } from "store/slice/loginSlice"
import { Button, Sheet } from "components"
import { routes } from "constants/routes"
import { IShowInfoSheetProps } from "./interfaces"
import {
  WrapPrimaryButtonStyled,
  SheetContainer,
  SubtitleStyled,
  TitleStyled,
} from "./styles"

export const ShowInfoSheet: FC<IShowInfoSheetProps> = ({
  title,
  subtitle,
  isOpen,
  phone,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onClose = () => {
    dispatch(loginActions.setSberIdShowInfoSheet(false))
  }

  const onSubmit = () => {
    dispatch(loginActions.setError(""))
    dispatch(loginActions.setToken(""))
    dispatch(loginActions.loginStart(phone))
    navigate(routes.login)
  }

  return (
    <Sheet isOpen={isOpen} onClose={onClose}>
      <SheetContainer>
        <TitleStyled>{title}</TitleStyled>
        {subtitle && <SubtitleStyled>{subtitle}</SubtitleStyled>}
        <WrapPrimaryButtonStyled>
          <Button.Primary onClick={onSubmit}>Войти без Сбер ID</Button.Primary>
        </WrapPrimaryButtonStyled>
        <Button.Plain onClick={onClose}>Отменить</Button.Plain>
      </SheetContainer>
    </Sheet>
  )
}
