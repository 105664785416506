import { EContentType, EPeriodType } from "entities/common"

export const prepareConnectionFee = (
  rawMonthlyFee: number,
  rawConnectionFee: number,
  periodType: EPeriodType,
  isContentOption: boolean,
  isAvailableOptions: boolean
) => {
  let connectionPayment
  const monthlyFee = rawMonthlyFee < 0 ? 0 : rawMonthlyFee
  const connectionFee = rawConnectionFee < 0 ? 0 : rawConnectionFee

  switch (periodType) {
    case EPeriodType.Period:
      connectionPayment = monthlyFee
      break
    case EPeriodType.Single:
    default:
      connectionPayment = connectionFee
  }

  if (isContentOption && !isAvailableOptions) {
    return monthlyFee
  }

  return isContentOption ? monthlyFee + connectionFee : connectionPayment
}

export const preparePlugButtonText = (contentType: EContentType) => {
  switch (contentType) {
    case EContentType.SberSound:
      return "Открыть Звук"
    case EContentType.Ya:
      return "Настроить"
    default:
      return "Подключить"
  }
}
