import styled from "styled-components"
import { Primary } from "components/Button/Primary"
import { Icon } from "components"

export const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapBalance = styled.div<{ isBalanceEnabled?: boolean }>`
  display: flex;
  padding: 0px 16px 0px 24px;
  justify-content: ${(props) =>
    props.isBalanceEnabled ? "space-between" : "flex-end"};
`

export const PayButtonStyled = styled(Primary)`
  height: 46px;
  padding: 0;
  width: 154px;
  align-self: center;
`

export const PrimeIcon = styled(Icon.Prime)`
  color: ${(props) => props.theme.colors.background[0]};
`

export const CirclePlus = styled(Icon.CirclePlus)`
  color: ${(props) => props.theme.colors.text.secondary};
`
