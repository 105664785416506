import React, { FC, memo } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { v4 as uuidv4 } from "uuid"
import { Spacer } from "components"
import { IForm } from "./interfaces"
import { Styled } from "./styles"
import { getInitialValues } from "./utils"
import { Module } from "./Module"
import { FormButton } from "./FormButton"

const Form: FC<IForm> = memo(
  ({ onSubmit, modules, children, initialValues, padding, submitButton }) => {
    const methods = useForm({
      mode: "onBlur",
      reValidateMode: "onBlur",
      defaultValues: getInitialValues(modules),
      values: initialValues,
    })
    return (
      <FormProvider {...methods}>
        <Styled.Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Styled.Content padding={padding}>
            {modules.map((module) => (
              <Module
                title={module.title}
                subtitle={module.subtitle}
                fields={module.fields}
                key={uuidv4()}
              />
            ))}
            {children ? (
              children
            ) : (
              <>
                <Spacer flexGrow={1} />
                <FormButton submitButton={submitButton} />
              </>
            )}
          </Styled.Content>
        </Styled.Form>
      </FormProvider>
    )
  }
)

export { Form }
