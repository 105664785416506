import React, { FC, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { useActions } from "hooks/actions"
import { usePostNumberTransferCheckMutation } from "store/api"
import { IResponseErrorData } from "entities/common"
import { sendMetric } from "utils/metrics"
import { Container, Header, PhoneInputMask, Typography } from "components"
import { routes } from "constants/routes"
import { NumberTransferEntryStyled } from "./styles"
import { SheetNumberTransferInstructions } from "./SheetNumberTransferInstructions"

export const NumberTransferEntry: FC = () => {
  const theme = useTheme()
  const [isOpen, setOpen] = useState(true)
  const [movedNumber, setMovedNumber] = useState("")
  const [trigger, status] = usePostNumberTransferCheckMutation()
  const navigate = useNavigate()
  const [error, setError] = useState("")
  const { setNumberTransfer } = useActions()

  useEffect(() => {
    if (isOpen) {
      sendMetric({ MNP: { "Перенос номера": ["Условия переноса_Показ"] } })
    }
  }, [isOpen])

  useEffect(() => {
    if (status?.error && "data" in status.error && !error) {
      const errorData = status.error.data as IResponseErrorData
      const errorStatusMessage = errorData.statusInfo.statusMessage
      sendMetric({
        MNP: {
          "Перенос номера": { "Ввод номера": { Ошибка: [errorStatusMessage] } },
        },
      })
      setError(errorStatusMessage)
      status.reset()
    }

    if (status.isSuccess) {
      sendMetric({ MNP: { "Перенос номера": { "Ввод номера": ["Клик"] } } })
      setNumberTransfer(movedNumber)
      navigate(routes.numberTransferConfirmation)
    }
  }, [error, movedNumber, navigate, setError, setNumberTransfer, status])

  const onPhoneChange = (number: string) => {
    setError("")
    if (!status.isLoading && number && number.length === 10) {
      setMovedNumber(number)
      trigger({ number })
    }
  }
  return (
    <>
      <Container page backgroundColor={theme.colors.background[0]}>
        <Header title="Перенос номера" background={theme.colors.background[0]}>
          <NumberTransferEntryStyled.InfoIcon onClick={() => setOpen(true)} />
        </Header>

        <NumberTransferEntryStyled.InputWrap>
          <Typography.Body1Bold>
            Укажите номер, который хотите перенести в СберМобайл
          </Typography.Body1Bold>
          <PhoneInputMask
            placeholder="Переносимый номер"
            onChange={onPhoneChange}
            description={error}
            hasError={error?.length > 0}
            isLoading={status.isLoading}
            type="tel"
            hasClearIcon
            onClearIconHandler={() => setError("")}
          >
            <NumberTransferEntryStyled.Description>
              <Typography.Body2>
                Вводя номер, вы соглашаетесь{" "}
                <NumberTransferEntryStyled.DescriptionLink
                  onClick={() => setOpen(true)}
                >
                  с условиями переноса
                </NumberTransferEntryStyled.DescriptionLink>
              </Typography.Body2>
            </NumberTransferEntryStyled.Description>
          </PhoneInputMask>
        </NumberTransferEntryStyled.InputWrap>
      </Container>
      <SheetNumberTransferInstructions
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
