import { store } from "../store"
import { nightTheme } from "./nightTheme"
import { dayTheme } from "./dayTheme"
import { EThemeNames } from "./interfaces"

export const themes = {
  [EThemeNames.NightTheme]: nightTheme,
  [EThemeNames.DayTheme]: dayTheme,
  [EThemeNames.SystemTheme]: null,
}

export const isDarkTheme = () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    )
  } catch {
    return false
  }
}

export const getTheme = () => {
  switch (store.getState().settings.currentTheme) {
    case EThemeNames.DayTheme:
      return themes[EThemeNames.DayTheme]
    case EThemeNames.NightTheme:
      return themes[EThemeNames.NightTheme]
    case EThemeNames.SystemTheme: {
      if (isDarkTheme()) {
        return themes[EThemeNames.NightTheme]
      }
      return themes[EThemeNames.DayTheme]
    }
    default:
      return themes[EThemeNames.DayTheme]
  }
}
