import { memo, FC, ReactElement, useState, useEffect, MouseEvent } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { EContentType, EOptionStatus } from "entities/common"
import { usePostTariffOptionStatusMutation } from "store/api"
import { ITariffOptionStatusRequest } from "entities/tariffOptionsStatus"
import { RootState } from "store"
import { Badge, Button, Icon, Spacer, Typography } from "components"
import { ServicesSheet } from "../ServicesSheet"
import { ServicesSheetAssistant } from "../ServicesSheetAssistant"
import { routes } from "constants/routes"
import { Styled } from "./styles"

export interface IServicesCard {
  title: string
  subTitle: string
  contentType: string
  price: number
  isActive: boolean
  optionId: number
}

export type TCardHelper = {
  [key: string]: { icon: ReactElement; sheet: ReactElement }
}

export const ServicesCard: FC<IServicesCard> = memo((props) => {
  const { title, subTitle, contentType, price, isActive, optionId } = props
  const navigate = useNavigate()
  const theme = useTheme()
  const { currentPhone, phone } = useSelector((state: RootState) => state.login)

  const [isOpenSheet, setIsOpenSheet] = useState<boolean>(false)
  const [currentOptionStatus, setCurrentOptionStatus] =
    useState<EOptionStatus>()

  const [triggerTariffOptionStatus, status] =
    usePostTariffOptionStatusMutation()

  const prepareRequestData = (optionStatus: EOptionStatus) => {
    const requestData: ITariffOptionStatusRequest = {
      status: optionStatus,
      optionId: `${optionId}`,
    }
    if (phone === currentPhone) {
      requestData.phone = phone
    } else {
      requestData.additionalNumbers = currentPhone
    }

    return requestData
  }

  const sendData = (optionStatus: EOptionStatus) => {
    setCurrentOptionStatus(optionStatus)
    triggerTariffOptionStatus(prepareRequestData(optionStatus))
  }

  useEffect(() => {
    if (status.isSuccess || status.isError) {
      navigate(
        `${routes.operationStatusCommon}?isSuccess=${
          status.isSuccess ? true : false
        }&oprationType=${currentOptionStatus}&redirectUrl=${
          routes.secureAccountMain
        }`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, currentOptionStatus])

  const renderAuraSheet = () => {
    return (
      <ServicesSheet
        isOpen={isOpenSheet}
        onClose={() => setIsOpenSheet(!isOpenSheet)}
        title="Аура"
        subTitle="Предупредит о мошеннике прямо во время разговора"
        icon={<Icon.SecureAccountServicesAura />}
      >
        {isActive ? (
          <>
            <Button.MPPrimary onClick={() => navigate(routes.auraDetails)}>
              <Styled.SheetButtonPrimary>Подробнее</Styled.SheetButtonPrimary>
            </Button.MPPrimary>
            <Spacer height={8} />
            <Button.MNPPlain onClick={() => sendData(EOptionStatus.Disabled)}>
              <Styled.SheetButtonSecondary color={theme.colors.text.critical}>
                Отключить
              </Styled.SheetButtonSecondary>
            </Button.MNPPlain>
          </>
        ) : (
          <>
            <Button.MPPrimary onClick={() => navigate(routes.auraAgreement)}>
              <Styled.SheetButtonPrimary>Подключить</Styled.SheetButtonPrimary>
            </Button.MPPrimary>
            <Spacer height={8} />
            <Button.MNPPlain onClick={() => navigate(routes.auraDetails)}>
              <Styled.SheetButtonSecondary
                color={theme.colors.button.contentBrand}
              >
                Подробнее
              </Styled.SheetButtonSecondary>
            </Button.MNPPlain>
          </>
        )}
      </ServicesSheet>
    )
  }

  const renderAssistantSheet = () => {
    return (
      <ServicesSheetAssistant
        isOpen={isOpenSheet}
        onClose={() => setIsOpenSheet(!isOpenSheet)}
        title="Ассистент принимает звонки, когда вы не можете ответить"
        subTitle="и присылает текстовую и аудиозапись разговора"
      >
        {isActive ? (
          <>
            <Button.Black onClick={() => sendData(EOptionStatus.Disabled)}>
              <Styled.SheetButtonSecondary
                color={theme.colors.button.contentPrimary}
              >
                Отключить
              </Styled.SheetButtonSecondary>
            </Button.Black>
            <Styled.SheetAssistantText>
              <Styled.SheetAssistantLink
                onClick={() => navigate(routes.assistantAgreement)}
              >
                Подробнее об условиях оказания услуги Ассистент
              </Styled.SheetAssistantLink>
            </Styled.SheetAssistantText>
          </>
        ) : (
          <>
            <Button.Black onClick={() => sendData(EOptionStatus.Active)}>
              <Styled.SheetButtonPrimary>Подключить</Styled.SheetButtonPrimary>
            </Button.Black>
            <Styled.SheetAssistantText>
              Нажимая кнопку, я соглашаюсь
              <br />с{" "}
              <Styled.SheetAssistantLink
                onClick={() => navigate(routes.assistantAgreement)}
              >
                Условиями оказания услуги{" "}
              </Styled.SheetAssistantLink>
              Ассистент
            </Styled.SheetAssistantText>
          </>
        )}
      </ServicesSheetAssistant>
    )
  }

  const cardHelper: TCardHelper = {
    [EContentType.Aura]: {
      icon: <Icon.SecureAccountServicesAura />,
      sheet: renderAuraSheet(),
    },
    [EContentType.Ya]: {
      icon: <Icon.SecureAccountServicesAssistant />,
      sheet: renderAssistantSheet(),
    },
  }

  const handlerModalWindow = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsOpenSheet(!isOpenSheet)
  }

  return (
    <>
      {cardHelper[contentType].sheet}
      <Styled.Card>
        <Styled.Content>
          <Styled.IconContainer>
            {/* TODO: сделать нормальные интерфейсы для IAdditionalOption и EContentType,
         что бы был contentType: EContentType */}
            {cardHelper[contentType].icon}
          </Styled.IconContainer>
          <Typography.H3 fontSize="17" lineHeight="22">
            {title}
          </Typography.H3>
          <Spacer height={4} />
          <Typography.Body1
            fontSize={13}
            lineHeight={18}
            color={theme.colors.text.secondary}
          >
            {subTitle}
          </Typography.Body1>
        </Styled.Content>
        <Styled.Footer>
          <Styled.Price>
            <Typography.H3 fontSize="20" lineHeight="25" textAlign="center">
              {price}&nbsp;₽
            </Typography.H3>
            <Typography.Body1
              fontSize={11}
              lineHeight={13}
              textAlign="center"
              color={theme.colors.text.secondary}
            >
              в месяц
            </Typography.Body1>
          </Styled.Price>

          <Badge
            onClick={(e: MouseEvent<HTMLDivElement>) => handlerModalWindow(e)}
            text={isActive ? "Отключить" : "Подключить"}
            active={isActive}
          />
        </Styled.Footer>
      </Styled.Card>
    </>
  )
})
