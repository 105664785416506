import { FC, ReactElement } from "react"
import { Styled } from "./styles"

interface ICellDynamicColumns {
  columns: Array<{ isMainColumn?: boolean; content: ReactElement }>
  backgroundColor?: string
  onClick?: () => void
}

export const CellDynamicColumns: FC<ICellDynamicColumns> = (props) => {
  const { columns, backgroundColor, onClick } = props

  return (
    <Styled.Container backgroundColor={backgroundColor} onClick={onClick}>
      <Styled.Content>
        {columns.length > 0
          ? columns.map((item, index) => (
              <Styled.Column key={index} isMainColumn={item.isMainColumn}>
                {item.content}
              </Styled.Column>
            ))
          : null}
      </Styled.Content>
    </Styled.Container>
  )
}
