import { EClientType, ICommonResponse } from "../common"

export interface IUserConfig {
  number: string
  clientType: EClientType
  restricted: boolean
  featureConfig: { [key: string]: boolean }
  eSIMState: EESimState
  isInactiveEsim: boolean
}

export enum EESimState {
  NOT_ESIM = "NOT_ESIM",
  ESIM_IDENTIFIED = "ESIM_IDENTIFIED",
  ESIM_NOT_IDENTIFIED = "ESIM_NOT_IDENTIFIED",
}

export interface IUserConfigResponse extends ICommonResponse {
  data: {
    userConfigs: IUserConfig[]
  }
}

export interface IUserConfigNormalized {
  [key: string]: IUserConfig
}
