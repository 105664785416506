import styled from "styled-components"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

const Dialog = styled.div`
  width: 100%;
  max-width: 480px;
  margin: auto 16px;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.background[1]};
  border-radius: 10px;
  z-index: 20;
  h4,
  & > p,
  & > div {
    padding: 0 8px;
  }
  h4 {
    padding-top: 8px;
  }
`

export const ModalStyle = { Container, Backdrop, Dialog }
