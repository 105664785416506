import React, { FC, memo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SupportSheetContainer } from "pages/Menu/styles"
import { Icon, Sheet, Spacer, Table, Typography } from "components"
import { urls } from "constants/urls"
import { routes } from "constants/routes"
import { RootStyled, LinkStyled } from "./styles"

export const NoSmsSheet: FC = memo(() => {
  const [isOpen, setOpen] = useState(false)
  const navigate = useNavigate()
  // const { currentPhone } = useSelector((state: RootState) => state.login)

  return (
    <RootStyled>
      <LinkStyled onClick={() => setOpen(true)}>Не приходит СМС</LinkStyled>
      <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
        <SupportSheetContainer>
          <Typography.H4>
            Подпишите договор, чтобы пользоваться eSIM
          </Typography.H4>
          <Spacer height={28} />
          <Typography.Body1>
            Если не приходит СМС, попробуйте подписать договор онлайн позже.
            Возможны временные проблемы с отправкой сообщений.
          </Typography.Body1>
          <Spacer height={24} />
          <Typography.Body1>
            Или подпишите договор другим способом:
          </Typography.Body1>
          <Spacer height={12} />
          <Table.Body noPadding>
            <Table.Section>
              <Table.CommonCell
                title="В офисе Сбера"
                subTitle="Понадобится только паспорт"
                startIcon={<Icon.Office />}
                endIcon={<Icon.ArrowRight />}
                onClick={() =>
                  navigate(routes.showExternalLink, {
                    state: {
                      link: urls.officeMap,
                      pathname: window.location.pathname,
                    },
                  })
                }
              />
              {/*<Table.CommonCell*/}
              {/*  title="Вызвать курьера"*/}
              {/*  subTitle="В Москве, Санкт-Петербурге и других городах — на встрече с курьером нужно иметь при себе паспорт"*/}
              {/*  startIcon={<Icon.House />}*/}
              {/*  endIcon={<Icon.ArrowRight />}*/}
              {/*  onClick={() =>*/}
              {/*    window*/}
              {/*      .open(`${urls.identificationEsim}${currentPhone}`, "_blank")*/}
              {/*      ?.focus()*/}
              {/*  }*/}
              {/*/>*/}
            </Table.Section>
          </Table.Body>
        </SupportSheetContainer>
      </Sheet>
    </RootStyled>
  )
})
