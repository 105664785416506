import styled from "styled-components"

export const ProgressBar = styled.div<{
  progressBarInfo?: {
    color: string
    percentage: number
  }
}>`
  border-radius: 16px;
  height: 6px;
  background-color: ${(props) => props.theme.colors.divider};
  ::after {
    display: block;
    content: "";
    width: ${(props) => props?.progressBarInfo?.percentage}%;
    height: 100%;
    border-radius: 16px;
    background-color: ${(props) => props?.progressBarInfo?.color};
  }
`
