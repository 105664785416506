import { Icon } from "components/Icons"
import styled from "styled-components"

export const CheckIconStyled = styled(Icon.Check)`
  color: ${(props) => props.theme.colors.text.warning};
  height: 100%;
`

export const Section = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background[1]};
  border-radius: 16px;
  overflow: hidden;
`
