export const TITLE = "Оплата бонусами"
export const SUBTITLE =
  "Бонусами СберСпасибо можно оплатить до 99% услуг связи — в том числе стоимости тарифа."
export const SECTION_TITLE = "Как оплатить"
export const sectionContent = [
  {
    id: 1,
    content: "Войдите в приложение по СберID",
  },
  {
    id: 2,
    content: "Нажмите на «Пополнить» на главном экране",
  },
  {
    id: 3,
    content: "Укажите сумму бонусов, которую хотите списать",
  },
]
