import { prepareMonthValue } from "utils/date"

export const prepareDetailsPeriod = (date?: { min: string; max: string }) => {
  if (!date) {
    return []
  }

  const month = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ]

  const monthNumber = month.length
  const minDate = new Date(date.min)
  const maxDate = new Date(date.max)
  let currentYear = minDate.getFullYear()
  const isSameYear = minDate.getFullYear() === maxDate.getFullYear()

  const lastMonthInPeriod = isSameYear
    ? maxDate.getMonth()
    : maxDate.getMonth() +
      monthNumber * (maxDate.getFullYear() - minDate.getFullYear())

  const result = []

  for (let i = minDate.getMonth(); i <= lastMonthInPeriod; i++) {
    const monthIndex = i % monthNumber
    const isCurrentMonthFirstInPeriod = i === monthIndex
    if (monthIndex === 0 && !isCurrentMonthFirstInPeriod) {
      currentYear += 1
    }

    const monthValue = prepareMonthValue(monthIndex)
    result.push({
      value: `01.${monthValue}.${currentYear}`,
      name: `${month[monthIndex]}, ${currentYear}`,
    })
  }

  return result.reverse()
}
