import React, { FC, useEffect, useMemo, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { Typography, Spacer, Table } from "components"
import { ICommonField, TFormField } from "../Form/interfaces"
import { FormInput } from "../FormInput"
import { Icon } from "../Icons"
import { Sheet } from "../Sheet"
import { Styled } from "./styles"

interface ISelectField
  extends ICommonField,
    Required<Pick<TFormField, "options" | "sheet">> {
  value: string
}

const SelectField: FC<ISelectField> = ({
  value,
  name,
  placeholder,
  options,
  sheet,
  fieldRef,
  onChange,
  onBlur,
  description,
  hasError,
  startIcon,
  onClick,
  onSelect,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    if (currentValue !== value) {
      setCurrentValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const initialValue = useMemo(
    () =>
      options.find((option) => option.value === (currentValue || value))
        ?.name ?? "",
    [currentValue, options, value]
  )

  const handlePhoneSelect = (val: string) => {
    onSelect?.(val)
    setCurrentValue(val)
    onChange(val)
    setOpen(false)
  }
  return (
    <>
      <FormInput
        placeholder={placeholder}
        name={name}
        value={initialValue}
        initialValue={initialValue}
        onFocus={(event) => {
          onClick?.()
          setOpen(!!options.length)
          event.target.blur()
        }}
        onEndIconClick={() => setOpen(!!options.length)}
        endIcon={<Icon.ArrowDown />}
        fieldRef={fieldRef}
        onBlur={onBlur}
        onChange={onChange}
        description={description}
        hasError={hasError}
        startIcon={startIcon}
      />
      <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
        <Sheet.CommonContainer horizontalPadding={0}>
          <Sheet.CommonHeader>
            <Typography.H4>{sheet.title}</Typography.H4>
            <Spacer height={4} />
            {sheet.description && (
              <Typography.Body2>{sheet.description}</Typography.Body2>
            )}
          </Sheet.CommonHeader>
          <Table.Body noPadding>
            <Table.Section>
              {options.map((option) => (
                <Table.CommonCell
                  title={option.name}
                  key={uuidv4()}
                  onClick={() => handlePhoneSelect(option.value)}
                  endIcon={
                    option.value === currentValue ? (
                      <Styled.CheckIconStyled />
                    ) : undefined
                  }
                  isPhoneNumber
                />
              ))}
            </Table.Section>
          </Table.Body>
        </Sheet.CommonContainer>
      </Sheet>
    </>
  )
}

export { SelectField }
