import styled from "styled-components"

export const Plain = styled.button`
  background-color: transparent;
  border-radius: 16px;
  font-family: "SBSansUI Regular", serif;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.text.warning};
  padding: 17px 0;
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
`
