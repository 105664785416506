import { IUserConfigNormalized, IUserConfigResponse } from "./interfaces"

export const normalizeUserConfig = (
  data: IUserConfigResponse | undefined
): IUserConfigNormalized => {
  if (!data) {
    return {}
  }
  const res: IUserConfigNormalized = {}
  data.data.userConfigs.forEach((item) => {
    res[item.number] = item
  })
  return res
}
