import styled from "styled-components"

const Country = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Flag = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: center;
`

const Info = styled.div`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 0;
    right: -28px;
  }
  h3 {
    text-align: center;
  }
`

export const Styled = { Country, Flag, Info, Title }
