import { Icon } from "components"
import { routes } from "constants/routes"

export const TITLE = "Тариф СберKids"
export const SUBTITLE = "Ребёнок всегда на связи"
export const CONTENT = [
  {
    title: "Связь при нуле",
    subtitle: "Мессенджеры и звонки на любимый номер при любом балансе",
    icon: <Icon.Phone />,
    mpRoute: `https://lk.sbermobile.ru${routes.zeroConnection}`,
    route: routes.zeroConnection,
  },
  {
    title: "Звонки на любимый номер",
    subtitle: "Не расходуют пакет минут и доступны при блокировке",
    icon: <Icon.Heart />,
    mpRoute: `https://lk.sbermobile.ru${routes.favoriteNumber}`,
    route: routes.favoriteNumber,
  },
  {
    title: "Ребёнок на карте 2ГИС",
    subtitle: "Удобно отслеживать, где находится ребёнок",
    icon: <Icon.TwoGis />,
    mpRoute: `https://lk.sbermobile.ru${routes.twoGisGeolocation}`,
    route: routes.twoGisGeolocation,
  },
  {
    title: "Ограничение контента",
    subtitle: "Управляйте доступом ребёнка к видео и мессенджерам",
    icon: <Icon.PauseCircle />,
    mpRoute: `https://lk.sbermobile.ru${routes.contentRestriction}`,
    route: routes.contentRestriction,
  },
  {
    title: "Оплата бонусами",
    subtitle: "До 99% услуг связи можно оплатить бонусами СберСпасибо",
    icon: <Icon.Spasibo />,
    mpRoute: `https://lk.sbermobile.ru${routes.bonusPay}`,
    route: routes.bonusPay,
  },
  {
    title: "Безлимит на СберKids",
    subtitle: "Обучение финансовой грамотности в игровом формате",
    icon: <Icon.SberGradient />,
    mpRoute: `https://lk.sbermobile.ru${routes.noLimitSberKids}`,
    route: routes.noLimitSberKids,
  },
]
