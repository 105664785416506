import React, { FC, memo } from "react"
import { Header } from "components/Header"
import { useTheme } from "styled-components"
import {
  FooterStyled,
  H4Styled,
  ImgStyled,
  MainContent,
  RootStyled,
  SubtitleStyled,
  TextContent,
  ButtonPrimaryStyled,
  ButtonSecondaryStyled,
} from "./styles"
import img from "./images/error.png"

interface IErrorProps {
  title: string
  subtitle: string
  buttonText: string
  onClick: () => void
  isPrimary?: boolean
}

export const Error: FC<IErrorProps> = memo(
  ({ title, subtitle, onClick, isPrimary = false, buttonText }) => {
    const theme = useTheme()
    const ButtonStyled = () =>
      isPrimary ? (
        <ButtonPrimaryStyled onClick={onClick} children={buttonText} />
      ) : (
        <ButtonSecondaryStyled onClick={onClick} children={buttonText} />
      )

    return (
      <RootStyled>
        <Header background={theme.colors.background[1]} />
        <MainContent>
          <ImgStyled src={img} />
          <TextContent>
            <H4Styled>{title}</H4Styled>
            <SubtitleStyled>{subtitle}</SubtitleStyled>
          </TextContent>
        </MainContent>
        <FooterStyled>
          <ButtonStyled />
        </FooterStyled>
      </RootStyled>
    )
  }
)
