import styled from "styled-components"
import { Primary } from "components/Button/Primary"
import { Icon, Typography } from "components"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background[1]};
`

export const FooterStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 96px;
  max-width: 480px;
  bottom: 0px;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.background[1]};
`

export const ButtonPrimaryStyled = styled(Primary)`
  width: 100%;
  margin-bottom: 8px;
`

export const IndentStyled = styled.div`
  width: 100%;
  height: 96px;
  max-width: 480px;
`

export const H4Styled = styled(Typography.H4)`
  margin: 0px 24px 8px;
  text-align: center;
`

export const Body1Styled = styled(Typography.Body1)`
  margin: 0px 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const ImageStyled = styled(Icon.DocGoskluch)`
  margin: 8px auto 24px auto;
`
