import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TimeSlot } from "entities/numberTransfer"

export interface INumberTransferState {
  slots: TimeSlot[]
}

const initialState: INumberTransferState = {
  slots: [],
}

export const numberTransferSlice = createSlice({
  name: "numberTransfer",
  initialState,
  reducers: {
    addSlots: (state, { payload }: PayloadAction<INumberTransferState>) => {
      state.slots = [...state.slots, ...payload.slots]
    },
  },
})

export const numberTransferActions = numberTransferSlice.actions

export default numberTransferSlice.reducer
