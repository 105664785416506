export const colors = {
  transparent: {
    pure: "transparent",
    black: {
      10: "rgba(13, 13, 27, 0.1)",
    },
  },
  background: {
    0: "#F2F6F9",
    1: "#FFFFFF",
    root: "#E6EEF2",
    surface0: "#F4F8F9",
    surface1: "#FFFFFF",
  },
  text: {
    primary: "#0D0D1B",
    secondary: "#8995A2",
    warning: "#DF6308",
    critical: "#DB0000",
    disabled: "rgba(137, 149, 161, 0.35)",
    highlight: "rgba(223, 99, 8, 0.5)",
  },
  icon: {
    primary: "#8995A2",
    success: "#21A038",
    disabled: "rgba(13, 13, 27, 0.1)",
    warning: "#DF6308",
    critical: "#DB0000",
    mpPrimary: "#595966",
  },
  divider: "rgba(13, 13, 27, 0.1)", // +
  divider2: "rgba(16, 21, 37, 0.1)",
  speedometer: {
    internet: "#FF8000",
    call: "#FF541A",
    sms: "#FF3977",
  },
  button: {
    primary: "linear-gradient(90deg, #F8426E 0%, #FF8934 100%)",
    secondary: "#FFF3E5",
    disabled: "rgba(13, 13, 27, 0.2)",
    contentPrimary: "#FFFFFF",
    contentBrand: "#DF6308",
    black: "#000000",
  },
  toggle: {
    on: "#FFFFFF",
    off: "rgba(13, 13, 27, 0.1)",
  },
  stroke: {
    dark: "#0D0D1B",
    color: "rgba(223, 99, 8, 0.1)",
  },
  badge: {
    text: "#10152F",
    background: "rgba(16, 21, 47, 0.05)",
    textSelected: "#FFF",
    backgroundSelected: "#677888",
  },
}
