import styled from "styled-components"

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background[1]};
  border-radius: 12px;
  padding: 7px 12px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 12px 16px 20px 12px;
  cursor: pointer;
`
