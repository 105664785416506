import React, { FC, memo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Header } from "components/Header"
import { loginActions } from "store/slice/loginSlice"
import { Checkbox } from "components/Checkbox"
import {
  useGetLinksQuery,
  usePostGoskeyDocumentMutation,
  usePostSendConfirmationMutation,
} from "store/api"
import { ELinksCode } from "entities/common"
import { RootState } from "store"
import { useTheme } from "styled-components"
import { Icon, Table } from "components"
import { routes } from "constants/routes"
import {
  Body1Styled,
  ButtonPrimaryStyled,
  ButtonStyled,
  FooterStyled,
  IndentStyled,
  RootStyled,
  CheckboxWrapper,
} from "./styles"

export const SignatureCondition: FC = memo(() => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [processPersDataForSbolChecked, setProcessPersDataForSbolChecked] =
    useState(true)
  const [receiveAdvert, setReceiveAdvert] = useState(true)
  const [processPersData, setProcessPersData] = useState(true)
  const [triggerPostSendConfirmation] = usePostSendConfirmationMutation()
  const [triggerPostGoskeyDocument] = usePostGoskeyDocumentMutation()
  const { currentPhone, email, phone } = useSelector(
    (state: RootState) => state.login
  )
  const { data: links } = useGetLinksQuery()

  const onConfirm = () => {
    dispatch(loginActions.setShowSmsCode(true))
    navigate(`${routes.login}?goskey=true`)
    triggerPostSendConfirmation({
      number: currentPhone,
      type: "send_document_to_goskey",
    })
    const agreement = []
    if (receiveAdvert) {
      agreement.push("RECEIVE_ADVERT")
    }
    if (processPersData) {
      agreement.push("PROCESS_PERS_DATA")
    }
    if (processPersDataForSbolChecked) {
      agreement.push("PROCESS_PERS_DATA_FOR_SBOL")
    }
    triggerPostGoskeyDocument({
      additionalNumber: currentPhone === phone ? undefined : currentPhone,
      email,
      agreement,
    })
  }

  const onClickRules = () =>
    links &&
    links[ELinksCode.Rules] &&
    navigate(routes.showExternalLink, {
      state: {
        link: links[ELinksCode.CommunicationRules],
        pathname: window.location.pathname,
      },
    })

  return (
    <RootStyled>
      <Header
        background={theme.colors.background[1]}
        title="Условия договора"
      />
      <ButtonStyled>
        <Table.CommonCell
          title="Условия оказания услуг связи"
          startIcon={<Icon.Pdf />}
          endIcon={<Icon.ArrowRight />}
          onClick={onClickRules}
        />
      </ButtonStyled>
      <Body1Styled>Я согласен с условиями оказания услуг, включая:</Body1Styled>
      <CheckboxWrapper>
        <Checkbox
          label="Получение рекламной информации согласно п. 5.4.3 и приложению № 1 условий оказания услуг связи"
          checked={receiveAdvert}
          onChange={setReceiveAdvert}
        />
        <Checkbox
          label="Передачу и обработку персональных данных согласно п.п. 9.3–9.4  и приложению № 2 условий оказания услуг связи"
          checked={processPersData}
          onChange={setProcessPersData}
        />
        <Checkbox
          label="Передачу и обработку данных об абоненте согласно Приложению № 3 условий оказания услуг связи"
          checked={processPersDataForSbolChecked}
          onChange={setProcessPersDataForSbolChecked}
        />
      </CheckboxWrapper>
      <IndentStyled />
      <FooterStyled>
        <ButtonPrimaryStyled onClick={onConfirm}>
          Подключить
        </ButtonPrimaryStyled>
      </FooterStyled>
    </RootStyled>
  )
})
