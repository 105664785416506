import styled from "styled-components"

export const Field = styled.div`
  position: fixed;
  top: 64px;
  width: 100%;
  max-width: 480px;
  padding: 8px 16px;
  z-index: 2;
  display: flex;
  background: ${(props) => props.theme.colors.background[0]};
`
