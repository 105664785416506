export const maxWidth = 480
export const emailPattern =
  /^[\w-.]+@([\w-]+\.)+(aero|AERO|arpa|ARPA|asia|ASIA|biz|BIZ|cat|CAT|ru|RU|com|COM|coop|COOP|edu|EDU|gov|GOV|info|INFO|int|INT|jobs|JOBS|mil|MIL|mobi|MOBI|museum|MUSEUM|name|NAME|net|NET|org|ORG|pro|PRO|tel|TEL|travel|TRAVEL)$/
export const PAID = "PAID"
export const PRIME_MODAL_TITLE =
  "Для управления тарифами с подпиской СберПрайм необходимо скачать мобильное приложение"
export const APP_DOWNLOAD_LINK = "https://sbermobile.ru/app"
export const SBER_PRIME = "СберПрайм"
export const MOVE_TO = "Перейти"
export const OK = "Понятно"
export const YES_CONTINUE = "Да, продолжить"
export const DECLINE = "Отмена"
export const TOKEN = "Token"
export const SELF = "_self"
export const BLANK = "_blank"
export const IOS = "ios"
export const ANDROID = "android"
