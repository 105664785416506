import styled, { css } from "styled-components"
const Flex = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const IconWrapper = styled.div<{ hasBackground?: boolean }>`
  ${Flex}
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  ${(props) =>
    props.hasBackground
      ? `background: ${props.theme.colors.badge.background};`
      : undefined};
`
const PaddingContainer = styled.div<{ padding?: number }>`
    padding 0 ${(props) => props.padding ?? 16}px;
`

export const FAQCommonStyled = { IconWrapper, PaddingContainer }
