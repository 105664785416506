import { FC } from "react"
import { Typography } from "components/Typography"
import { SpanBold } from "./styles"
interface IPropsMPTypography {
  children?: any
  typographyType?: string
  startPadding?: number
  textAlign?: string
  textDecoration?: string
  color?: string
  onClick?: () => void
}
export const MPTypography: FC<IPropsMPTypography> = ({
  children,
  typographyType,
  startPadding,
  textAlign,
  textDecoration,
  color,
  onClick,
}) => {
  switch (typographyType) {
    case "Body2":
      return (
        <Typography.Body2
          textAlign={textAlign}
          textDecoration={textDecoration}
          fontSize={13}
          onClick={onClick}
          color={color}
        >
          {children}
        </Typography.Body2>
      )
    case "Body2Bold":
      return (
        <Typography.Body1Bold
          fontSize={14}
          lineHeight={16}
          color={color}
          onClick={onClick}
        >
          {children}
        </Typography.Body1Bold>
      )
    case "Body1Bold":
      return (
        <Typography.Body1Bold
          fontSize={17}
          lineHeight={22}
          color={color}
          onClick={onClick}
        >
          {children}
        </Typography.Body1Bold>
      )
    case "Body1BoldSpan":
      return <SpanBold onClick={onClick}>{children}</SpanBold>
    default:
      return (
        <Typography.Body1
          fontSize={17}
          lineHeight={22}
          startPadding={startPadding}
          onClick={onClick}
        >
          {children}
        </Typography.Body1>
      )
  }
}
