import { ICommonResponse } from "./common"

export interface ICountry {
  rusName: string
  enName: string
  code: string
  popular: boolean
}

export enum ERoamingImageType {
  Internet = "internet",
  IncomingCall = "incoming_call",
  OutgoingCall = "outgoing_call",
  Sms = "sms",
  CalCenter = "cal_center",
}

export interface IRoamingOption {
  optionId: number
  title: string
  description: string
  unit: string
  volume: number
  monthlyFee: number
  connectionFee: number
  coverageArea: string
  connectDescription: string
  contentType: string
  periodType: string
  canBeDisabled: boolean
  autoEnabled: boolean
  fullDescription: string
  subTitle: string
  active: boolean
}

interface IEnabledApp {
  appCode: string
  iconUrl: string
}

export interface IContentOption {
  optionId: number
  title: string
  subTitle: string
  description: string
  monthlyFee: number
  connectionFee: number
  availability: string
  contentType: string
  enabledApps: IEnabledApp[]
  fullDescription: string
  active: boolean
}

interface IPrice {
  label: string
  subTitle?: string
  cost: string
  imageType: ERoamingImageType
  currPerItem: string
  visible: boolean
}

export interface ICountriesResponse extends ICommonResponse {
  data: { countries: ICountry[] }
}

export interface IRoamingResponse extends ICommonResponse {
  data: {
    roamingOptions?: IRoamingOption[]
    contentOptions?: IContentOption[]
    prices: IPrice[]
    otherCountries: ICountry[]
  }
}
