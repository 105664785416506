import { FC } from "react"
import { v4 as uuidv4 } from "uuid"
import { Table } from "components/Table"
import { Typography } from "components/Typography"
import { EESimState } from "entities/userConfig"
import { Icon } from "components/Icons"
import { getTheme } from "theme"
import styled from "styled-components"
import { IPhoneNumber } from "components/PhoneSelect/interfaces"
import { IFormattedPhones } from "pages/BeautifulNumbers/interfaces"
import { Container, PhoneNumber } from "components"
import { IRenderPhonesProps } from "./interfaces"

export const CheckIconStyled = styled(Icon.Check)`
  color: ${(props) => props.theme.colors.text.warning};
  height: 100%;
`

export const RenderPhones: FC<IRenderPhonesProps> = ({
  phones,
  handlePhoneSelect,
  current,
  userConfigNormalized,
  unavailableTitle,
  unavailableSubtitle,
  backgroundColor,
  endIcon,
}) => {
  const prepareTitle = (alias: string, phone: string) =>
    alias === phone ? PhoneNumber.format(phone) : alias

  const prepareSubtitle = (alias: string, phone: string) =>
    alias !== phone ? PhoneNumber.format(phone) : ""

  const availablePhones = Array.isArray(phones)
    ? (phones as IPhoneNumber[])
    : (phones.availablePhones as unknown as IFormattedPhones)

  const renderIcon = (number: string) => {
    if (!number) {
      return <></>
    }
    if (
      [EESimState.ESIM_IDENTIFIED, EESimState.ESIM_NOT_IDENTIFIED].includes(
        userConfigNormalized?.[number]?.eSIMState
      )
    ) {
      return <Icon.ESim />
    }
    return <Icon.Sim />
  }
  return (
    <>
      <Table.Section backgroundColor={backgroundColor}>
        {(availablePhones as IPhoneNumber[]).map((phone: IPhoneNumber) => (
          <Table.CommonCell
            title={prepareTitle(phone.alias, phone.number)}
            subTitle={prepareSubtitle(phone.alias, phone.number)}
            key={uuidv4()}
            onClick={() => handlePhoneSelect(phone.number)}
            startIcon={renderIcon(phone.number)}
            endIcon={phone.number === current ? <CheckIconStyled /> : endIcon}
            isPhoneNumber
          />
        ))}
      </Table.Section>
      {!Array.isArray(phones) && !!phones?.unavailablePhones?.length && (
        <>
          <Container clearInnerPadding>
            <Typography.Body1Bold>{unavailableTitle}</Typography.Body1Bold>
            <Typography.Body1 color={getTheme().colors.text.secondary}>
              {unavailableSubtitle}
            </Typography.Body1>
          </Container>
          <Table.Section backgroundColor={backgroundColor}>
            {phones.unavailablePhones.map((phone) => (
              <Table.CommonCell
                color={getTheme().colors.text.disabled}
                title={prepareTitle(phone.alias, phone.number)}
                subTitle={prepareSubtitle(phone.alias, phone.number)}
                key={uuidv4()}
                startIcon={renderIcon(phone.number)}
                isPhoneNumber
              />
            ))}
          </Table.Section>
        </>
      )}
    </>
  )
}
