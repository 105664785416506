import React, { FC } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { formatPhoneNumber } from "utils"
import { RootState } from "store"
import { Icon, Table } from "components"
import { IBannersProps } from "../interfaces"
import { routes } from "constants/routes"

export const WaitInGoskey: FC<IBannersProps> = ({ isCommonCeil = false }) => {
  const navigate = useNavigate()
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const onGoskeyClick = () => {
    navigate(routes.signatureGoskeyWait)
  }

  const renderCommonCeil = () => (
    <Table.CommonCell
      title="Вернитесь в Госключ, чтобы подписать договор"
      subTitle={`для eSIM ${formatPhoneNumber(currentPhone, true)}`}
      startIcon={<Icon.AgreementNotSigned />}
      endIcon={<Icon.ArrowRight />}
      onClick={onGoskeyClick}
      isPhoneNumber
    />
  )
  return (
    <>
      {isCommonCeil ? (
        renderCommonCeil()
      ) : (
        <Table.Body className="ym-hide-content">
          <Table.Section>{renderCommonCeil()}</Table.Section>
        </Table.Body>
      )}
    </>
  )
}
