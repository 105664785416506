import { FC, ReactElement } from "react"
import { EMainColumn } from "../constants"
import { Styled } from "./styles"

interface ICellTwoColumn {
  leftColumn: ReactElement
  rightColumn: ReactElement
  mainColumn?: EMainColumn
  backgroundColor?: string
  onClick?: () => void
}

export const CellTwoColumn: FC<ICellTwoColumn> = (props) => {
  const {
    leftColumn,
    rightColumn,
    mainColumn = EMainColumn.LeftColumn,
    backgroundColor,
    onClick,
  } = props

  return (
    <Styled.Container backgroundColor={backgroundColor} onClick={onClick}>
      <Styled.Content>
        <Styled.Column
          isMainColumn={
            mainColumn === EMainColumn.LeftColumn ||
            mainColumn === EMainColumn.BothColumn
          }
        >
          {leftColumn}
        </Styled.Column>
        <Styled.Column
          isMainColumn={
            mainColumn === EMainColumn.RightColumn ||
            mainColumn === EMainColumn.BothColumn
          }
        >
          {rightColumn}
        </Styled.Column>
      </Styled.Content>
    </Styled.Container>
  )
}
