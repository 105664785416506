import { ReactNode } from "react"
import { Icon } from "components"

export const ACTIVATE_SERVICE = "ПОДКЛЮЧИТЬ УСЛУГУ"
export const DEACTIVATE_SERVICE = "ОТКЛЮЧИТЬ УСЛУГУ"
export const TITLE = "Хранитель"
export const DESCRIPTION = "Защищает ваши звонки и деньги от спама и мошенников"
export const ITS_FREE = "Это бесплатно"
export const HOW_SERVICE_WORK = "Как работает услуга"
export const WHAT_IS_GUARDIAN = "Из чего состоит Хранитель"
export const OFFER_LINK = "https://lk.sbermobile.ru/offer"
export const conditionAgreement = [
  "Нажимая кнопку, я соглашаюсь",
  "с",
  "Условиями оказания услуги",
  "Хранитель",
]
export const IOSDeeplink = "wheresignal"
export const packageIncluded: {
  id: number
  title: string
  subtitle: string
  icon: ReactNode
}[] = [
  {
    id: 0,
    title: "Возврат денег",
    subtitle:
      "Выплатим деньги по страховке, если мошенники всё же доберутся до вашего счёта в Сбере",
    icon: <Icon.Ruble />,
  },
  {
    id: 1,
    title: "Ловец",
    subtitle:
      "Фильтр вредоносных звонков  заблокирует мошенников и спам ещё до первого гудка",
    icon: <Icon.PhoneFilled />,
  },
  {
    id: 2,
    title: "Антиспам",
    subtitle:
      "Умная голосовая почта примет спам-звонок и сохранит для вас его текстовую и аудиозапись",
    icon: <Icon.WarningFilled />,
  },
]
export const howItWorks: { id: number; title: string; icon: ReactNode }[] = [
  {
    id: 1,
    title:
      "Активируйте Хранителя и укажите номер СберМобайл как основной в приложении СберБанк Онлайн",
    icon: <Icon.Sber />,
  },
  {
    id: 2,
    title: "Хранитель начнёт блокировать спам и опасные звонки",
    icon: <Icon.ShieldPlain />,
  },
  {
    id: 3,
    title:
      "Если злоумышленники всё же получат доступ к счёту во время звонка, вернём до 200 000 ₽",
    icon: <Icon.RublePlus />,
  },
]

export const questions: { title: string; route: string }[] = [
  {
    title: "Какую сумму мне вернут, если меня обманут мошенники?",
    route: "https://lk.sbermobile.ru/amount-to-refunded",
  },
  {
    title:
      "Можно ли вернуть деньги, если мошенники обманут во время звонка по мессенджеру?",
    route: "https://lk.sbermobile.ru/messenger-calls",
  },
  {
    title: "Условия возврата денег, если мошенники всё же обманут меня",
    route: "https://lk.sbermobile.ru/required-conditions",
  },
  {
    title: "Как сделать номер СберМобайла основным в СберБанке Онлайн?",
    route: "https://lk.sbermobile.ru/required-conditions/change-sber-number",
  },
  {
    title:
      "Зачем номер СберМобайла указывать в приложении СберБанк Онлайн как основной?",
    route: "https://lk.sbermobile.ru/sbermobile-number-as-main",
  },
  {
    title: "Что значит «нежелательные звонки»?",
    route: "https://lk.sbermobile.ru/unwanted-calls",
  },
  {
    title: "Как отключить услугу Хранитель?",
    route: "https://lk.sbermobile.ru/deactivate-guardian-service",
  },
]
