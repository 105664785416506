import styled from "styled-components"

export const SpanBold = styled.span`
  font-family: "SBSansUI Bold", serif;
  font-size: 17px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.text.primary};
`

export const SpanSecondary = styled.span`
  font-family: "SBSansUI Regular", serif;
  font-size: 17px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.text.secondary};
`
