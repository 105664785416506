import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { TCustomValidator, TFormDataValues } from "components/Form/interfaces"
import { useActions } from "hooks/actions"
import { RootState } from "store"
import { getInitialValues } from "components/Form/utils"
import { useGetCustomerProfileQuery } from "store/api"
import { IFile } from "components/UploadFile/UploadFile"
import { getSearchObject } from "utils/search"
import { sendMetric } from "utils/metrics"
import { Container, Header, Form } from "components"
import { routes } from "constants/routes"
import { formModules } from "./formData"
import { EDocType } from "./interfaces"

export const PersonalInfoAdjustment = () => {
  const theme = useTheme()
  const { values } = useSelector((state: RootState) => state.form)
  const { phone } = getSearchObject()

  const { data: customerData } = useGetCustomerProfileQuery({
    number: phone,
  })

  const navigate = useNavigate()
  const { setFormValues } = useActions()
  const handleFormSubmit = (data: TFormDataValues) => {
    sendMetric({
      "Корректировка рег. данных": {
        "Изменение перс.данных": ["Продолжить_Клик"],
      },
    })
    setFormValues({ values: data })
    navigate(routes.personalInfoAdjustmentSigning)
  }

  const validateNumber: TCustomValidator = async (value, options) => {
    if (
      options &&
      options.watchField &&
      [EDocType.PASSPORT_RF, EDocType.PASSPORT_TP].includes(
        options.watchField as EDocType
      )
    ) {
      return {
        fieldProps: { placeholder: "Разворот с регистрацией*" },
        isValid: !!(value as IFile[]).length,
      }
    } else {
      return {
        fieldProps: { placeholder: "Разворот с регистрацией" },
        isValid: true,
      }
    }
  }

  const modules = useMemo(
    () => formModules(phone, validateNumber, customerData?.data?.email),
    [phone, customerData]
  )

  return (
    <Container page backgroundColor={theme.colors.background[0]}>
      <Header
        onBackClick={() => navigate(routes.personalInfoConfirmation)}
        title="Изменение перс. данных"
      />
      <Form
        initialValues={values ?? getInitialValues(modules)}
        onSubmit={handleFormSubmit}
        modules={modules}
      />
    </Container>
  )
}
