import React, { FC, memo, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { formatPhoneNumber } from "utils/phone"
import { useGetLinksQuery, usePostSendConfirmationMutation } from "store/api"
import { ELinksCode } from "entities/common"
import { useActions } from "hooks/actions"
import { NoSmsSheet } from "pages/Login/components/EnterSmsCode/components/NoSmsSheet"
import { Input } from "components"
import { BLANK } from "constants/common"
import { SmsConfirmationStyles } from "./styles"
import { SMS_CODE_LENGTH, SMS_TIMEOUT } from "./constants"
import { ISmsConfirmationProps } from "./interfaces"

export const SmsConfirmation: FC<ISmsConfirmationProps> = memo(
  ({ onSuccess, isLoading, number, children, type, onClickDefaultRules }) => {
    const [triggerPostSendConfirmation] = usePostSendConfirmationMutation()
    const { displayPhone, error } = useSelector(
      (state: RootState) => state.login
    )
    const { setError } = useActions()
    const { data: links } = useGetLinksQuery()
    const [timeLeft, setTimeLeft] = useState(SMS_TIMEOUT)
    const minutes = ("00" + Math.floor(timeLeft / 60)).slice(-2)
    const seconds = ("00" + (timeLeft % 60)).slice(-2)

    useEffect(() => {
      setTimeout(
        function run(value, setValue) {
          if (value > 0) {
            setTimeout(run, 1000)
            setValue(timeLeft - 1)
          }
        },
        1000,
        timeLeft,
        setTimeLeft
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft])

    useEffect(() => {
      triggerPostSendConfirmation({
        number,
        type,
      })
    }, [number, triggerPostSendConfirmation, type])

    useEffect(() => {
      setError("")
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onCodeChange = (code: string) => {
      if (error) {
        setError("")
      }
      if (code?.length === SMS_CODE_LENGTH) {
        onSuccess(code)
      }
    }

    const onCodeRefresh = () => {
      triggerPostSendConfirmation({
        number,
        type,
      })
      setTimeLeft(SMS_TIMEOUT)
    }

    const onClickRules = () => {
      if (links && links[ELinksCode.Rules]) {
        onClickDefaultRules?.()
        window.open(links[ELinksCode.Rules], BLANK)?.focus()
      }
    }

    const getServiceRules = () =>
      children ?? (
        <>
          <SmsConfirmationStyles.Footnote>
            Вводя код из СМС, вы соглашаетесь
          </SmsConfirmationStyles.Footnote>
          <SmsConfirmationStyles.FootnoteLink onClick={onClickRules}>
            с Правилами сервиса
          </SmsConfirmationStyles.FootnoteLink>
        </>
      )

    return (
      <SmsConfirmationStyles.Wrapper>
        <SmsConfirmationStyles.H1>Введите код</SmsConfirmationStyles.H1>
        <SmsConfirmationStyles.WrapPhone>
          <SmsConfirmationStyles.Body1 as="span">
            Отправили СМС с кодом на&nbsp;номер
          </SmsConfirmationStyles.Body1>
          <SmsConfirmationStyles.Phone as="span">
            {formatPhoneNumber(number, true)}
          </SmsConfirmationStyles.Phone>
        </SmsConfirmationStyles.WrapPhone>
        <SmsConfirmationStyles.WrapInput>
          <Input
            autoFocus
            placeholder="Код из СМС"
            mask="999999"
            maskChar=""
            maskPlaceholder=""
            type="tel"
            onChange={onCodeChange}
            description={error}
            hasError={error?.length > 0}
            isLoading={isLoading}
            onFocus={() => window.scrollTo(0, 0)}
          />
        </SmsConfirmationStyles.WrapInput>
        <SmsConfirmationStyles.ServiceRulesWrap>
          {getServiceRules()}
        </SmsConfirmationStyles.ServiceRulesWrap>
        <SmsConfirmationStyles.Footer>
          {displayPhone && <NoSmsSheet />}
          {timeLeft > 0 && (
            <SmsConfirmationStyles.Body2>
              До повторной отправки кода осталось {minutes}:{seconds}
            </SmsConfirmationStyles.Body2>
          )}
          {!isLoading && timeLeft <= 0 && (
            <SmsConfirmationStyles.Link onClick={onCodeRefresh}>
              Повторить отправку кода
            </SmsConfirmationStyles.Link>
          )}
        </SmsConfirmationStyles.Footer>
      </SmsConfirmationStyles.Wrapper>
    )
  }
)
