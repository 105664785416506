import { Icon } from "components/Icons"
import { Reorder } from "framer-motion"
import styled from "styled-components"

export const CheckIconStyled = styled(Icon.Check)`
  color: ${(props) => props.theme.colors.text.warning};
  height: 100%;
`

export const ReaorderItem = styled(Reorder.Item)`
  position: relative;
  list-style-type: none;
`

export const HamburgerIconStyled = styled(Icon.Hamburger)`
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 9999999;
  color: ${(props) => props.theme.colors.icon.primary};
  touch-action: none;
`
