import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { useActions } from "hooks/actions"
import { TFormDataValues } from "components/Form/interfaces"
import { RootState } from "store"
import {
  useGetCostDetailIntervalQuery,
  useGetCustomerProfileQuery,
} from "store/api"
import { getInitialValues } from "components/Form/utils"
import { getSearchObject } from "utils/search"
import { sendMetric } from "utils/metrics"
import { Container, Form, Header, Loader, Spacer } from "components"
import { routes } from "constants/routes"
import { formModule } from "./formData"
import { prepareDetailsPeriod } from "./utils"

const OrderDetails: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { phone } = getSearchObject()
  const { setFormValues } = useActions()
  const { values } = useSelector((state: RootState) => state.form)
  const { data: costdetailData, isLoading } = useGetCostDetailIntervalQuery({
    number: phone,
  })

  const { data: customerData, isLoading: isCustomerLoading } =
    useGetCustomerProfileQuery({
      number: phone,
    })

  const module = useMemo(() => {
    return formModule(
      prepareDetailsPeriod(costdetailData?.data),
      phone,
      customerData?.data?.email
    )
  }, [costdetailData?.data, customerData?.data?.email, phone])

  const handleFormSubmit = (data: TFormDataValues) => {
    sendMetric({ "Детализация на email": ["Заказать_клик"] })
    setFormValues({ values: data })
    navigate(routes.orderDetailsConfirmation)
  }

  return (
    <>
      {(isLoading || isCustomerLoading) && <Loader />}
      <Container page backgroundColor={theme.colors.background[0]}>
        <Header title="Детализация" background={theme.colors.background[0]} />
        <Spacer height={8} />
        <Form
          onSubmit={handleFormSubmit}
          modules={module}
          initialValues={values ?? getInitialValues(module)}
          submitButton="Заказать"
        />
      </Container>
    </>
  )
}

export { OrderDetails }
