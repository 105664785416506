import moment from "moment"
import {
  FieldExtensionType,
  FieldFormat,
  IFormModule,
  TFieldValue,
} from "components/Form/interfaces"
import { useLazyGetTimeslotQuery } from "store/api"
import { IPersonalData } from "entities/personalInfo"
import { TimeSlot } from "entities/numberTransfer"
import { getSlotFromHour } from "utils/date"
import { RootState, store } from "store"
import { sendMetric } from "utils/metrics"
import { Icon } from "components"

export const formModules = (
  parametersMapper: (value: unknown) => unknown,
  setWarningTransferModal: ({
    isOpen,
    temporaryNumber,
  }: {
    isOpen: boolean
    temporaryNumber: TFieldValue
  }) => void,
  initialSlots?: TimeSlot[]
): IFormModule[] => [
  {
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "temporaryNumber",
        placeholder: "Заменяемый номер",
        format: FieldFormat.phoneSelect,
        initialValue: "",
        info: "После завершения переноса оказание услуг связи по номеру будет прекращено",
        validators: { required: "Укажите номер" },
        sheet: {
          title: "Выберите заменяемый номер",
        },
        startIcon: <Icon.FieldCross />,
        unavailableTitle: "Недоступно для переноса",
        unavailableSubtitle:
          "Перенос номера недоступен для номеров юридических лиц, других операторов и разных регионов",
        onSelect: (value) =>
          setWarningTransferModal({ isOpen: true, temporaryNumber: value }),
        onClick: () =>
          sendMetric({ MNP: { "Перенос номера": ["Заменяемый номер_Клик"] } }),
      },
    ],
  },
  {
    title: "Дата и время переноса",
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "transferDate",
        placeholder: "Дата",
        format: FieldFormat.date,
        initialValue: initialSlots
          ? moment(initialSlots[0].slotDate).format("DD.MM.YY")
          : "",
        validators: { required: "Укажите дату переноса" },
        maxDate: (() => {
          const date = new Date()
          return new Date(date.setDate(date.getDate() + 90))
        })(),
        minDate: (() => {
          const defaultDate = new Date()
          const slotDate = Date.parse(initialSlots?.[0].slotDate ?? "")
          return new Date(
            slotDate > 0
              ? slotDate
              : defaultDate.setDate(defaultDate.getDate() + 1)
          )
        })(),
        startIcon: <Icon.FieldDate />,
        onClick: () => sendMetric({ MNP: { "Перенос номера": ["Дата_Клик"] } }),
      },
      {
        extensionType: FieldExtensionType.WithQuery,
        name: "transferHour",
        placeholder: "Время",
        format: FieldFormat.select,
        initialValue: `${initialSlots?.[0].availableHours[0] ?? ""}`,
        validators: { required: "Укажите время переноса" },
        sheet: { title: "Выберите время" },
        options: initialSlots?.[0].availableHours.map((hour) => ({
          name: getSlotFromHour(hour),
          value: `${hour}`,
        })),
        query: {
          query: useLazyGetTimeslotQuery,
          parameters: parametersMapper,
          error: "Выбранная дата недоступна. Выберите другую дату",
          onError: (error) =>
            sendMetric({
              "Перенос номера": { Ошибка: [error] },
            }),
          result: (data, fieldProps, value) => {
            const date = moment(value as string, "DD.MM.YY").format()
            const { onChange, ...other } = fieldProps
            const options = (data as TimeSlot[])
              .find((slot) => slot.slotDate === date)
              ?.availableHours.map((hour) => ({
                name: getSlotFromHour(hour),
                value: `${hour}`,
              }))
            const selectValue = !!options?.length ? options[0].value : ""
            onChange(selectValue)
            return {
              fieldProps: { ...other, onChange, options, value: selectValue },
              hasError: !options,
              isValid: !!options,
            }
          },
          cache: (value) => {
            const date = moment(value as string, "DD.MM.YY").format()
            const slots = (store.getState() as RootState).numberTransfer.slots
            if (slots.find((slot) => slot.slotDate === date)) {
              return slots
            }
            return null
          },
        },
        watchField: "transferDate",
        startIcon: <Icon.FieldTime />,
        onClick: () =>
          sendMetric({ MNP: { "Перенос номера": ["Время_Клик"] } }),
      },
    ],
  },
]

export const formPersonalData = (data: IPersonalData): IFormModule[] => [
  {
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "fio",
        placeholder: "Фамилия Имя Отчество",
        format: FieldFormat.text,
        initialValue: data.clientFullName,
        disabled: true,
      },
      {
        extensionType: FieldExtensionType.common,
        name: "passport",
        placeholder: "Серия и номер документа",
        format: FieldFormat.text,
        initialValue: `${data.document.documentSeries} ${data.document.documentNumber}`,
        disabled: true,
      },
    ],
  },
]
