import { useState } from "react"
// import { useNavigate } from "react-router-dom"
import { Page } from "react-pdf"
import { pdfjs } from "react-pdf"
import { getSearchObject } from "utils/search"
import { useSetTheme } from "hooks/useSetTheme"
import { PdfSkeleton } from "pages/AuraAgreement/PdfSkeleton"
import uslugaHranitel from "./uslugaHranitel.pdf"
import { DocumentWrap, RootStyled, DocumentStyled } from "./styles"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/legacy/build/pdf.worker.min.js",
  import.meta.url
).toString()
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
export const Offer = () => {
  const { theme: externalTheme } = getSearchObject()
  useSetTheme(externalTheme)
  const [width, setWidth] = useState(0)
  const onPdfLoad = () => {
    setWidth(
      document.getElementsByTagName("canvas")[0]?.offsetParent?.clientWidth ?? 0
    )
  }

  return (
    <RootStyled padding={16}>
      <DocumentWrap>
        <DocumentStyled file={uslugaHranitel} loading={<PdfSkeleton />}>
          <Page
            pageNumber={1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            onLoadSuccess={onPdfLoad}
            width={width}
          />
        </DocumentStyled>
      </DocumentWrap>
    </RootStyled>
  )
}
