import React, { FC, memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store"
import { loginActions } from "store/slice/loginSlice"
import { Button, Sheet } from "components"
import {
  ImgStyled,
  SheetContainer,
  SubtitleStyled,
  TitleStyled,
} from "./styles"
import img from "./images/prime.png"

export const PrimeSheet: FC = memo(() => {
  const dispatch = useDispatch()
  const { sberId } = useSelector((state: RootState) => state.login)

  const onClose = () => {
    dispatch(loginActions.setSberIdPrimeSheet(false))
  }

  return (
    <Sheet isOpen={sberId.showPrimeSheet} onClose={onClose}>
      <SheetContainer>
        <ImgStyled src={img} />
        <TitleStyled>Поздравляем, у вас есть СберПрайм</TitleStyled>
        <SubtitleStyled>Тарифы со скидкой 40%</SubtitleStyled>
        <Button.Primary onClick={onClose}>Понятно</Button.Primary>
      </SheetContainer>
    </Sheet>
  )
})
