export const routes = {
  main: "/",
  secureAccountMain: "/secure-account-main",
  secureAccountGuardian: "/services/guardian",
  historyCalls: "/services/guardian/history-calls",
  guardianAgreement: "/services/guardian/agreement",

  // #regiond guardian answers
  pwaGuardianPrice: "/secure-account-main/answers/guardian-price",
  pwaHowItWorks: "/secure-account-main/answers/how-it-works",
  pwaIfIWasDeceived: "/secure-account-main/answers/if-was-deceived",
  pwaMessengerCalls: "/secure-account-main/answers/messenger-calls",
  pwaRequiredSecureConditions:
    "/secure-account-main/answers/required-secure-conditions",
  pwaChangeSberMainNumber:
    "/secure-account-main/answers/change-sber-main-number",
  pwaHowAuraWork: "/secure-account-main/answers/how-aura-work",
  pwaVoiceAssistantWork: "/secure-account-main/answers/voice-assistant-work",
  pwaCallRecordingStorage:
    "/secure-account-main/answers/call-recording-storage",
  pwaAmountToRefunded: "/secure-account-main/answers/amount-to-refunded",
  pwaInsuranceRules:
    "/secure-account-main/answers/amount-to-refunded/insurance-rules",
  pwaSbermobileNumberAsMain:
    "/secure-account-main/answers/sbermobile-number-as-main",
  pwaUnwantedCalls: "/secure-account-main/answers/unwanted-calls",
  pwaDeactivateDuardianService:
    "/secure-account-main/answers/deactivate-duardian-service",
  moneyBack: "/money-back",
  // #endregion

  secureAccount: "/secure-account",
  requiredSecureConditions: "/required-conditions",
  changeSberMainNumber: "/required-conditions/change-sber-number",
  offer: "/offer",
  login: "/login",
  loginSberId: "/sber_id.action",
  loginSberIdBind: "/login/sber-id/bind",
  menu: "/menu",
  topUp: "/top-up",
  myTariff: "/my-tariff",
  services: "/services",
  operationStatusCommon: "/services/operation-status",
  servicesConnected: "/services/connected",
  servicesAvailable: "/services/available",
  auraDetails: "/services/aura",
  auraSigning: "/services/aura/signing",
  auraAgreement: "/services/aura/agreement",
  auraOperationStatus: "/services/aura/status",
  assistantAgreement: "/services/assistant/agreement",
  servicesInternet: "/services/internet",
  serviceConnectionStatus: "/services/connection-status",
  servicesCall: "/services/call",
  servicesSms: "/services/Sms",
  phone: "/phone",
  phoneItem: "/phone/:phone",
  engineeringWorks: "/engineering-works",
  signature: "/signature",
  signatureOnline: "/signature/online",
  signatureCheck: "/signature/check",
  signatureCondition: "/signature/condition",
  signatureGoskey: "/signature/goskey",
  signatureGoskeyWait: "/signature/goskey-wait",
  theme: "/theme",
  showExternalLink: "/show-external-link",
  paymentAdjustment: "/payment-adjustment",
  paymentAdjustmentAmount: "/payment-adjustment-amount",
  paymentAdjustmentAmountContacts: "/payment-adjustment-amount/contacts",
  paymentAdjustmentNumber: "/payment-adjustment-number",
  paymentAdjustmentSinging: "/payment-adjustment-singing",
  paymentAdjustmentConfirmation: "/payment-adjustment-confirmation",
  beautifulNumbers: "/beautiful-numbers",
  beautifulNumbersChange: "/beautiful-numbers/change",
  beautifulNumbersChangeStatus: "/beautiful-numbers/change/status",
  beautifulNumbersConfirmation: "/beautiful-numbers/change/confirmation",
  personalInfo: "/personal-info",
  personalInfoConfirmation: "/personal-info/confirmation",
  personalInfoChanging: "/personal-info/changing",
  personalInfoAdjustment: "/personal-info/adjustment",
  personalInfoAdjustmentSigning: "/personal-info/adjustment/signing",
  personalInfoAdjustmentStatus: "/personal-info/adjustment/status",
  emailConfirmation: "/email/confirmation",
  emailAdjustment: "/email/adjustment",
  emailAdjustmentConfirmation: "/email/adjustment/confirmation",
  emailAdjustmentStatus: "/email/adjustment/status",
  orderDetails: "/order-details",
  orderDetailsConfirmation: "/order-details/confirmation",
  orderDetailsStatus: "/order-details/status",
  numberTransferDate: "/number-transfer/set-date",
  numberTransferEntry: "/number-transfer/entry",
  numberTransferConfirmation: "/number-transfer/confirmation",
  numberTransferSigning: "/number-transfer/signing",
  numberTransferStatus: "/number-transfer/status",
  roamingChooseCountry: "/roaming/choose-country",
  roamingOptions: "/roaming/options",
  supportChat: "/support/chat",
  supportSetPhone: "/support/set-phone",
  guardianPrice: "/guardian-price",
  howItWorks: "/how-it-works",
  ifIWasDeceived: "/if-i-was-deceived",
  amountToRefunded: "/amount-to-refunded",
  sbermobileNumberAsMain: "/sbermobile-number-as-main",
  unwantedCalls: "/unwanted-calls",
  deactivateDuardianService: "/deactivate-guardian-service",
  howAuraWork: "/how-aura-work",
  voiceAssistantWork: "/voice-assistant-work",
  callRecordingStorage: "/call-recording-storage",
  messengerCalls: "/messenger-calls",
  insuranceRules: "/insurance-rules",
  sberKids: "/sberkids",
  twoGisGeolocation: "/twogis-geolocation",
  bonusPay: "/bonus-pay",
  contentRestriction: "/content-restriction",
  favoriteNumber: "/favorite-number",
  noLimitSberKids: "/nolimit-sberkids",
  zeroConnection: "/zero-connection",
}
