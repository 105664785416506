import styled from "styled-components"
import { Icon } from "components/Icons"

export const WrapLoaderIconStyled = styled.div<{ icontopoffset?: string }>`
  position: absolute;
  width: auto;
  top: ${(props) => props.icontopoffset ?? "-22px"};
  left: 50%;
  transform: translate(-50%, 0px);
  height: 0;
  justify-content: end;
  display: none;
`

export const LoaderIconAnimation = styled(Icon.Loader)`
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 24px;
  height: 24px;

  .loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite,
      color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @-webkit-keyframes color {
    0% {
      stroke: ${(props) => props.theme.colors.text.secondary};
    }
    40% {
      stroke: ${(props) => props.theme.colors.text.secondary};
    }
    66% {
      stroke: ${(props) => props.theme.colors.text.secondary};
    }
    80%,
    90% {
      stroke: ${(props) => props.theme.colors.text.secondary};
    }
  }
  @keyframes color {
    0% {
      stroke: ${(props) => props.theme.colors.text.secondary};
    }
    40% {
      stroke: ${(props) => props.theme.colors.text.secondary};
    }
    66% {
      stroke: ${(props) => props.theme.colors.text.secondary};
    }
    80%,
    90% {
      stroke: ${(props) => props.theme.colors.text.secondary};
    }
  }
`

export const LoaderIcon = styled(Icon.Loader)<{ icontopoffset?: string }>`
  display: none;
  position: absolute;
  top: ${(props) => props.icontopoffset ?? "-22px"};
  left: 50%;
  transform: translate(-50%, 0px);
  width: 24px;
  height: 24px;
  .loader-path {
    stroke: ${(props) => props.theme.colors.text.secondary};
    stroke-dasharray: 15, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }
`

export const RenderDataWrapper = styled.div`
  transition: 300ms ease-out;
  position: relative;
  --pull-length: 0;

  &.loading,
  &.showLoader {
    transform: translate(0, calc(var(--pull-length) * 0.5px));
  }

  &.loading ${LoaderIcon} {
    display: unset;
    .loader-path {
      stroke-dasharray: calc(45 + var(--pull-length) * 0.5), 200;
      stroke-dashoffset: calc(-1 * var(--pull-length) * 0.5);
    }
  }
  &.showLoader {
    ${WrapLoaderIconStyled} {
      display: unset;
    }
    ${LoaderIcon} {
      display: none;
    }
  }
`
