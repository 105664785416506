import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { formatPhoneNumber } from "utils"
import { useTheme } from "styled-components"
import { sendMetric } from "utils/metrics"
import { OperationStatus, Spacer, Typography } from "components"
import { routes } from "constants/routes"
import { OrderDetailsStatusStyled } from "./styles"

export const OrderDetailsStatus = () => {
  const navigate = useNavigate()
  const {
    state: { isNotSuccess, number, email },
  } = useLocation()
  const theme = useTheme()
  const isSuccess = !isNotSuccess
  const title = isSuccess ? "Заявка принята" : "Заявка не отправлена"
  const buttonTitle = isSuccess ? "Отлично" : "Понятно"

  const handleSubmit = () => {
    navigate(routes.main)
  }

  useEffect(() => {
    if (isSuccess) {
      sendMetric({
        "Детализация на email": { "Создание заявки_Показ": ["Успех"] },
      })
    }
  }, [isSuccess])

  return (
    <OperationStatus
      title={title}
      isSuccess={isSuccess}
      continueHandler={handleSubmit}
      continueButtonTitle={buttonTitle}
    >
      {isSuccess ? (
        <Typography.Body1 color={theme.colors.text.secondary}>
          Детализация по номеру{" "}
          {
            <OrderDetailsStatusStyled.NoWrap>
              {formatPhoneNumber(number, true)}
            </OrderDetailsStatusStyled.NoWrap>
          }{" "}
          в формате PDF отправлена на 
          {
            <OrderDetailsStatusStyled.NoWrap>
              {email}
            </OrderDetailsStatusStyled.NoWrap>
          }
        </Typography.Body1>
      ) : (
        <>
          <span>Произошла ошибка</span>
          <br />
          <Spacer height={4} />
          <span>Пожалуйста, создайте заявку повторно</span>
        </>
      )}
    </OperationStatus>
  )
}
