import React, { FC, memo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { useGetConnectedAvailableQuery } from "store/api"
import { IOptionElement } from "entities/connectedAvailable"
import { EContentType } from "entities/common"
import { useScrollToTop } from "hooks/useScrollToTop"
import { RootStyled } from "pages/ServicesCall/styles"
import { Header, Spacer } from "components"
import { ShowOptions } from "../Services/ShowOptions"
import { AddLabelStyled } from "./styles"
import { Speedometer } from "./components/Speedometer"
import { getInternetOptions, getInternetPackageOptions } from "./utils"

export const ServicesInternet: FC = memo(() => {
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data: connectedAvailable, isLoading: connectedAvailableIsLoading } =
    useGetConnectedAvailableQuery({
      phone: currentPhone,
    })
  useScrollToTop()
  if (connectedAvailableIsLoading) {
    return <></>
  }

  const availableOptions = getInternetOptions(
    connectedAvailable?.data?.availableOptions
  ) as IOptionElement[]
  const enabledOptions = getInternetPackageOptions(
    connectedAvailable?.data?.enabledOptions
  ) as IOptionElement[]

  const isNotEmpty = (options: IOptionElement[]) => options?.length > 0

  return (
    <RootStyled>
      <Header title="Интернет" />
      <Spacer height={16} />
      <Speedometer speedometerType={EContentType.Internet} />
      {isNotEmpty(enabledOptions) && (
        <AddLabelStyled>Подключенные пакеты</AddLabelStyled>
      )}
      {enabledOptions && (
        <ShowOptions
          options={[
            {
              sectionName: "",
              serviceData: {
                options: enabledOptions,
                roamingOptions: [],
                contentOptions: [],
                specials: [],
              },
            },
          ]}
          isRenderEmptyOptions={false}
        />
      )}
      {isNotEmpty(availableOptions) && (
        <AddLabelStyled>Добавить</AddLabelStyled>
      )}
      <ShowOptions
        options={[
          {
            sectionName: "",
            serviceData: {
              options: availableOptions,
              roamingOptions: [],
              contentOptions: [],
              specials: [],
            },
          },
        ]}
        isAvailableOptions
        isRenderEmptyOptions={!isNotEmpty(enabledOptions)}
      />
    </RootStyled>
  )
})
