import styled from "styled-components"
import { Typography } from "../Typography"
import { Icon } from "../Icons"

export const RootStyled = styled.div``

export const ContainerStyled = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  position: relative;
`

export const PathStyled = styled.path`
  opacity: 0.2;
  stroke-width: 6px;
  stroke-linecap: round;
`

export const H4Styled = styled(Typography.H4)``

export const CaptionStyled = styled(Typography.Caption)<{ color?: string }>`
  margin-top: 1px;
  color: ${(props) => props.color};
`

export const WrapTextStyled = styled.div`
  position: absolute;
  width: 96px;
  text-align: center;
  margin-top: 55px;
`

export const WrapIconStyled = styled.div`
  position: absolute;
  width: 96px;
  text-align: center;
  margin-top: 19px;
`

export const CarryOverIcon = styled(Icon.CarryOver)<{
  color: string
  isActive: boolean
}>`
  color: ${(props) => props.color};
  position: absolute;
  right: -8px;
  top: -2px;
  opacity: ${(props) => (props.isActive ? 1 : 0.2)};
`
