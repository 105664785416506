import { ElementType, FC, ReactNode, TouchEvent } from "react"
import { Styled } from "./styles"

export enum EButtonType {
  primary = "primary",
  secondary = "secondary",
}

interface ISheet {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  disableDrag?: boolean
  closeButtonType?: EButtonType
}

interface IExtendedSheet {
  CommonContainer: ElementType<{
    paddingBottom?: number
    horizontalPadding?: number
    children: ReactNode
  }>
  CommonHeader: ElementType<{
    children: ReactNode
  }>
}

const Sheet: FC<ISheet> & IExtendedSheet = ({
  children,
  onClose,
  isOpen,
  disableDrag = false,
  closeButtonType,
}) => {
  const stopPropagation = (e: TouchEvent) => e.stopPropagation()

  const buttonHelper = {
    primary: <Styled.CloseIconPrimary onClick={onClose} />,
    secondary: <Styled.CloseIconSecondary onClick={onClose} />,
  }

  return (
    <Styled.Sheet
      isOpen={isOpen}
      onTouchStart={stopPropagation}
      onTouchMove={stopPropagation}
      onTouchEnd={stopPropagation}
      onClose={onClose}
      detent="content-height"
      disableDrag={disableDrag}
    >
      <Styled.Container>
        {closeButtonType ? buttonHelper[closeButtonType] : buttonHelper.primary}
        <Styled.Content>{children}</Styled.Content>
      </Styled.Container>
      <Styled.Backdrop onTap={onClose} />
    </Styled.Sheet>
  )
}

Sheet.CommonContainer = Styled.CommonContainer
Sheet.CommonHeader = Styled.CommonHeader

export { Sheet }
