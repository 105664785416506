import { EClientType } from "entities/common"
import { IUserConfig } from "entities/userConfig"
import { EFeatureConfigs } from "pages/Menu/interfaces"

export const prepareCurrentFeatureConfig = (
  currentPhone: string,
  userConfigs?: IUserConfig[]
) => userConfigs?.find((item) => item.number === currentPhone)?.featureConfig

export const isAnyB2COrB2B2C = (
  featureFlag: EFeatureConfigs,
  userConfigs?: IUserConfig[]
) =>
  !!userConfigs?.filter(
    ({ clientType, featureConfig }) =>
      [EClientType.B2C, EClientType.B2B2C].includes(clientType) &&
      featureConfig[featureFlag]
  ).length
