import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { sendMetric } from "utils/metrics"
import { OperationStatus, Spacer } from "components"
import { routes } from "constants/routes"

export const EmailAdjustmentStatus = () => {
  const navigate = useNavigate()
  const {
    state: { isSuccess, statusMessage },
  } = useLocation()
  const title = isSuccess ? "Почта добавлена" : "Почта не добавлена"
  const buttonTitle = "Понятно"

  useEffect(() => {
    if (isSuccess) {
      sendMetric({
        "Корректировка email": { "Отправка заявки": ["Успех_Показ"] },
      })
    } else {
      sendMetric({
        "Корректировка email": {
          "Отправка заявки": { Ошибка: [statusMessage] },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    navigate(routes.menu)
  }

  return (
    <OperationStatus
      title={title}
      isSuccess={isSuccess}
      continueHandler={handleSubmit}
      continueButtonTitle={buttonTitle}
    >
      {isSuccess ? (
        <span>Почта обновиться в профиле в течение 15 минут</span>
      ) : (
        <>
          <span>Произошла ошибка</span>
          <br />
          <Spacer height={4} />
          <span>Пожалуйста, создайте заявку повторно</span>
        </>
      )}
    </OperationStatus>
  )
}
