// TODO: Закинуть на бэк
export const urls = {
  orderSim: "https://sbermobile.ru/order-sim-app/",
  transfer: "https://sbermobile.ru/mnp/",
  faq: "https://sbermobile.ru/voprosy/",
  officeMap: "https://sbermobile.ru/officesmap/?type=pwa_mp",
  identificationEsim: "https://sbermobile.ru/identification_esim?esim_phone=",
  ussd: "https://sbermobile.ru/faq/ussd/",
  pay: "https://sbermobile.ru/payment/pay/?type=pwa_mp",
}
