export enum ENumberTransferProcess {
  Progress = "progress",
  Error = "error",
  Complete = "complete",
}

export enum ENumberTransferTitle {
  NumberTransfer = "Перенос номера",
  NumberTransfered = "Номер перенесён",
  TransferDeclined = "Перенос отклонён",
}
