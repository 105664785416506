import styled from "styled-components"
import { Icon, Typography } from "components"

export const RootStyled = styled.div`
  display: flex;
  position: relative;
`

export const LabelWrapStyled = styled(Typography.Body2Label)`
  padding-left: 50px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.primary};
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 20px;
    height: 20px;

    border: 1px solid ${(props) => props.theme.colors.text.secondary};
    background-color: ${(props) => props.theme.colors.background[1]};
    border-radius: 4px;

    transition: opacity 0.2s linear;
  }
`

export const CheckboxIcon = styled(Icon.Checkbox)`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  color: ${(props) => props.theme.colors.text.warning};

  transition: opacity 0.2s linear;
`

export const InputStyled = styled.input`
  -webkit-appearance: none;
  appearance: none;
  position: absolute;

  :checked + ${CheckboxIcon} {
    opacity: 1;
  }
  :checked ~ ${LabelWrapStyled}::before {
    opacity: 0;
  }
`
