import React, { FC, memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { useGetAdditionalNumberQuery, useGetTariffQuery } from "store/api"
import { RootState } from "store"
import { mapPackagesData } from "widgets/MyTariffWidget/utils"
import { useTheme } from "styled-components"
import moment from "moment"
import { getCurrentExtendedData, getPhonesFromData } from "pages/Home/utils"
import { RadialChart, Spacer } from "components"
import { ISpeedometerPros } from "./interfaces"
import {
  WrapDescriptionStyled,
  AutorenewalIconStyled,
  DescriptionStyled,
  WrapSpeedometer,
  AutorenewalBlockIconStyled,
  ExpDate,
} from "./styles"

export const Speedometer: FC<ISpeedometerPros> = memo(
  ({ speedometerType, showExpirationDate = true }) => {
    const theme = useTheme()
    const { data: numbersData } = useGetAdditionalNumberQuery()

    const { phone, currentPhone } = useSelector(
      (state: RootState) => state.login
    )
    const phonesList = useMemo(
      () => getPhonesFromData(numbersData?.data.additionalNumbers, phone),
      [numbersData, phone]
    )

    const { data } = useGetTariffQuery(
      {
        numbers: phonesList,
      },
      { skip: !numbersData }
    )

    const currentExtendedData = useMemo(
      () => getCurrentExtendedData(data?.data.extendedData, currentPhone),
      [data, currentPhone]
    )

    const tariffOptions = useMemo(
      () =>
        mapPackagesData(
          currentExtendedData?.connectedOptions.defaultOptions,
          currentExtendedData?.currentTariff,
          currentExtendedData?.balance.needPay
        ),
      [currentExtendedData]
    )

    const expDate = moment(currentExtendedData?.subscriptionFeeDate).format(
      "DD.MM.YY"
    )

    const option = tariffOptions.find(
      (item) => item.contentType === speedometerType
    )

    if (!option) {
      return <></>
    }

    return (
      <>
        <WrapSpeedometer>
          <RadialChart
            color={option.color}
            percent={option.percent}
            footer={option.footer}
            body={option.body}
            icon={option.icon}
            carryOverResidues={option.carryOverResidues}
            footerColor={theme.colors.text.primary}
          />
          <Spacer height={8} />
          {currentExtendedData?.subscriptionFeeDate && showExpirationDate && (
            <ExpDate>до {expDate}</ExpDate>
          )}
        </WrapSpeedometer>

        {option.carryOverResidues && (
          <WrapDescriptionStyled>
            {option.carryOverResidues.active ? (
              <AutorenewalIconStyled />
            ) : (
              <AutorenewalBlockIconStyled />
            )}
            <DescriptionStyled>
              {option.carryOverResidues?.description}
            </DescriptionStyled>
          </WrapDescriptionStyled>
        )}
      </>
    )
  }
)
