// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const getSystem = () => {
  try {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/android/i.test(userAgent)) {
      return "android"
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios"
    }
  } catch {
    return ""
  }

  return "desktop"
}

export const getDeviceType = () => {
  try {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/android/i.test(userAgent)) {
      return "android"
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iphone"
    }
  } catch {
    return ""
  }

  return "desktop"
}

export const getSystemVersion = () => {
  const nAgt = navigator.userAgent
  const nVer = navigator.appVersion
  let os = ""
  const clientStrings = [
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "Android", r: /Android/ },
  ]
  for (const id in clientStrings) {
    const cs = clientStrings[id]
    if (cs.r.test(nAgt)) {
      os = cs.s
      break
    }
  }

  let osVersion = ""

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1]
    os = "Windows"
  }

  switch (os) {
    case "Mac OS":
    case "Mac OS X":
    case "Android":
      osVersion =
        /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(
          nAgt
        )[1]
      break

    case "iOS":
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)
      osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0)
      break
  }
  return osVersion?.replaceAll("_", ".")
}

export const getAppVersionName = () => {
  if (!process.env.REACT_APP_VERSION) {
    return ""
  }
  const arr = process.env.REACT_APP_VERSION.split(".")
  return `${arr[0]}.${arr[1]}`
}

export const getAppVersionCode = () => {}

export const getUserInfoHeader = () => {
  try {
    return `SBTMA/${getAppVersionName()} ${getSystem()}/${getSystemVersion()}/PWA (${getDeviceType()})`
  } catch {
    return ""
  }
}

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
    .replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
    .toUpperCase()
}

export const getDeviceId = () => {
  const currentDeviceId = localStorage.getItem("deviceId")
  if (!currentDeviceId) {
    const newDeviceId = uuidv4()
    localStorage.setItem("deviceId", newDeviceId)
    return newDeviceId
  }

  return currentDeviceId
}
