import { getSearchObject } from "utils/search"
import { FAQDetails } from "pages"
import { IOSDeeplink } from "../SecureAccount/constants"
import {
  BUTTON_TEXT,
  CHANGE_SBER_MAIN_NUMBER,
  sectionContent,
} from "./constants"

export const ChangeSberMainNumber = () => {
  const { theme: externalTheme, appName } = getSearchObject()
  const deeplink =
    appName.toLowerCase() === IOSDeeplink
      ? `${appName}://open_sbol?some=coveragemap://`
      : `${appName}://open_sbol`
  return (
    <FAQDetails
      externalTheme={externalTheme}
      sectionContent={sectionContent}
      sectionTitle={CHANGE_SBER_MAIN_NUMBER}
      buttonText={BUTTON_TEXT}
      buttonLink={deeplink}
    />
  )
}
