import styled from "styled-components"

const FirstColumn = styled.div`
  position: relative;
  width: 36px;
  height: 100%;

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }
`

const ThirdColumn = styled.div`
  position: relative;
  width: 36px;
  height: 100%;

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 24px;
    height: 36px;
  }
`

export const Styled = { FirstColumn, ThirdColumn }
