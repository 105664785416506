import { FieldFormat, IFormModule } from "components/Form"
import { IFile } from "components/UploadFile/UploadFile"
import { FieldExtensionType } from "components/Form/interfaces"
import { sendMetric } from "utils/metrics"
import { emailPattern } from "constants/common"

export const formModulesStep1: IFormModule[] = [
  {
    title: "Откуда вернуть",
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "clientNumber",
        placeholder: "Введите ошибочный номер",
        format: FieldFormat.phoneSelect,
        sheet: {
          title: "Ошибочный номер",
          description:
            "Выберите номер, на который была зачислена ошибочная сумма платежа",
        },
        initialValue: "",
        validators: { required: "Укажите ошибочный номер" },
        unavailableTitle: "Недоступно для корректировки",
        unavailableSubtitle:
          "Корректировка платежа недоступна для номеров юридических лиц и других операторов",
        onClick: () =>
          sendMetric({
            "Корректировка платежа по сумме": {
              "Ввод информации по платежу": ["Ввод номера_Клик"],
            },
          }),
      },
      {
        extensionType: FieldExtensionType.common,
        name: "paymentDate",
        placeholder: "Дата платежа",
        format: FieldFormat.date,
        initialValue: "",
        validators: { required: "Укажите дату платежа" },
        maxDate: new Date(),
        onClick: () =>
          sendMetric({
            "Корректировка платежа по сумме": {
              "Ввод информации по платежу": ["Дата платежа_Клик"],
            },
          }),
      },
      {
        extensionType: FieldExtensionType.common,
        name: "paymentAmount",
        placeholder: "Сумма платежа",
        format: FieldFormat.text,
        initialValue: "",
        inputMode: "decimal",
        postfix: "₽",
        // ввод не более 600000
        pattern: /^(?!0+\d)(?!0+$)[1-5]?\d{0,5}$|(600000)$/,
        validators: {
          required: "Укажите сумму платежа",
          pattern: {
            value: /^[0-9]\d{0,5}$/,
            message: "Укажите корректную сумму платежа",
          },
        },
        onClick: () =>
          sendMetric({
            "Корректировка платежа по сумме": {
              "Ввод информации по платежу": ["Сумма платежа_Клик"],
            },
          }),
      },
      {
        extensionType: FieldExtensionType.common,
        name: "documents",
        placeholder: "Прикрепите чек",
        format: FieldFormat.file,
        info: "На чеке должна быть дата, время и сумма платежа",
        initialValue: [],
        max: 5,
        validators: {
          validate: (files: IFile[]) => {
            if (files.length === 0) {
              return "Прикрепите чек"
            }
            if (
              files.reduce((result, file) => result + file.size, 0) > 8000000
            ) {
              return "Размер файлов не должен превышать 8 Мб. Удалите лишние изображения"
            }
            return true
          },
        },
        onClick: () =>
          sendMetric({
            "Корректировка платежа по сумме": {
              "Ввод информации по платежу": ["Прикрепить чек_Клик"],
            },
          }),
      },
      {
        extensionType: FieldExtensionType.common,
        name: "email",
        placeholder: "Укажите email для связи",
        format: FieldFormat.text,
        validators: {
          required: "Укажите email",
          pattern: { value: emailPattern, message: "Укажите корректный email" },
        },
        initialValue: "",
        onClick: () =>
          sendMetric({
            "Корректировка платежа по сумме": {
              "Ввод информации по платежу": ["Email_Клик"],
            },
          }),
      },
    ],
  },
]
export const formModulesStep2: IFormModule[] = [
  {
    title: "Куда зачислить",
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "payerFio",
        placeholder: "ФИО абонента",
        format: FieldFormat.text,
        initialValue: "",
        onClick: () =>
          sendMetric({
            "Корректировка платежа по сумме": {
              "Ввод данных": ["Ввод ФИО_Клик"],
            },
          }),
        validators: { required: "Укажите ФИО абонента" },
      },
      {
        extensionType: FieldExtensionType.common,
        name: "bankName",
        placeholder: "Наименование банка",
        format: FieldFormat.text,
        initialValue: "",
        onClick: () =>
          sendMetric({
            "Корректировка платежа по сумме": {
              "Ввод данных": ["Ввод банка_Клик"],
            },
          }),
        validators: { required: "Укажите наименование банка" },
      },
      {
        extensionType: FieldExtensionType.common,
        name: "bankBik",
        placeholder: "БИК",
        format: FieldFormat.text,
        initialValue: "",
        maxLength: 9,
        inputMode: "decimal",
        pattern: /^[0-9]+$/,
        onClick: () =>
          sendMetric({
            "Корректировка платежа по сумме": {
              "Ввод данных": ["Ввод БИК_Клик"],
            },
          }),
        validators: {
          required: "Укажите БИК банка",
          pattern: {
            value: /^[0-9]{9}$/,
            message: "Укажите корректный БИК банка",
          },
        },
      },
      {
        extensionType: FieldExtensionType.common,
        name: "recipientPaymentAccount",
        placeholder: "Расчётный счёт получателя",
        format: FieldFormat.text,
        initialValue: "",
        maxLength: 20,
        inputMode: "decimal",
        pattern: /^[0-9]+$/,
        onClick: () =>
          sendMetric({
            "Корректировка платежа по сумме": {
              "Ввод данных": ["Ввод счета_Клик"],
            },
          }),
        validators: {
          required: "Укажите расчётный счёт получателя",
          pattern: {
            value: /^[0-9]{20}$/,
            message: "Укажите корректный расчётный счёт получателя",
          },
        },
      },
    ],
  },
]
