import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { getSearchObject } from "utils/search"
import { EOptionStatus } from "entities/common"
import { OperationStatus, Spacer } from "components"
import { routes } from "constants/routes"

export const OperationStatusCommon: FC = () => {
  const navigate = useNavigate()
  const { isSuccess, oprationType, redirectUrl } = getSearchObject()
  const title = isSuccess === "true" ? "Заявка принята" : "Заявка не отправлена"
  const buttonTitle = isSuccess === "true" ? "Отлично" : "Понятно"

  return (
    <>
      <OperationStatus
        title={title}
        isSuccess={isSuccess}
        continueHandler={() =>
          navigate(redirectUrl ? redirectUrl : routes.services)
        }
        continueButtonTitle={buttonTitle}
      >
        {isSuccess === "true" ? (
          <span>
            Услуга
            {oprationType === EOptionStatus.Active && " подключится "}
            {oprationType === EOptionStatus.Disabled && " отключится "}
            в течение 15 минут
          </span>
        ) : (
          <>
            <span>Произошла ошибка</span>
            <br />
            <Spacer height={4} />
            <span>Пожалуйста, повторите попытку</span>
          </>
        )}
      </OperationStatus>
    </>
  )
}
