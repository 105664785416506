import { FAQ } from "../../faqs/FAQ/FAQ"
import {
  FOOTER_INFO,
  requiredConditions,
  REQUIRED_CONDITIONS_TITLE,
} from "./constants"

export const PWARequiredSecureConditions = () => {
  return (
    <FAQ
      footerInfo={FOOTER_INFO}
      requiredConditions={requiredConditions}
      requiredConditionsTitle={REQUIRED_CONDITIONS_TITLE}
    />
  )
}
