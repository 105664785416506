import styled, { css } from "styled-components"

const Flex = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SecureContainer = styled.div`
  ${Flex}
  position: relative;
  margin-top: -16px;
  align-items: center;
  padding: 24px 16px;
  border-radius: 16px 16px 0 0;
  z-index: 2;
  background: ${(props) => props.theme.colors.background.surface1};
  p {
    text-align: center;
  }
`

const ShieldContainer = styled.div`
  ${Flex}
  position: relative;
  align-items: center;
  height: 280px;
  background: linear-gradient(
    174.5deg,
    #ffd337 4.39%,
    #ffbc35 22.63%,
    #fa5f05 79.96%,
    #ff4d01 92.35%
  );
`

const IconWrapper = styled.div<{ hasBackground?: boolean; svgColor?: string }>`
  ${Flex}
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  ${(props) =>
    props.hasBackground
      ? `background: ${props.theme.colors.icon.disabled};`
      : undefined};
  svg {
    color: ${(props) => props.svgColor ?? props.theme.colors.text.warning};
  }
`

const PaddingContainer = styled.div<{ padding?: number }>`
  padding: 0 ${(props) => props.padding ?? 16}px;
`

const UnderlineSpan = styled.span`
  color: ${(props) => props.theme.colors.text.primary};
  text-decoration: underline;
  cursor: pointer;
`

const FixedFooter = styled.div`
  position: fixed;
  width: 100%;
  max-width: 480px;
  bottom: 0;
  background: ${(props) => props.theme.colors.background.surface1};
`

export const SecureAccountStyled = {
  ShieldContainer,
  SecureContainer,
  IconWrapper,
  PaddingContainer,
  UnderlineSpan,
  FixedFooter,
}
