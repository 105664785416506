import React, { FC, useEffect } from "react"
import moment from "moment"
import "moment/locale/ru"
import { formatPrice } from "utils/price"
import { sendMetric } from "utils/metrics"
import { Typography } from "components"
import { Container } from "./styles"

interface IBalance {
  date: string
  value: number
}

const Balance: FC<IBalance> = ({ date, value }) => {
  const { rublesStr } = formatPrice(value)
  const fractional = value % 100
  const fractionalStr = ("0" + (value % 100)).slice(-2)
  moment.locale("ru")
  const offset = new Date().getTimezoneOffset()
  const dateString =
    Date.parse(date) > 0
      ? moment(Date.parse(date) - offset * 60 * 1000).calendar(null, {
          sameDay: "[сегодня] HH:mm",
          sameElse: "D MMMM HH:mm",
          lastDay: "D MMMM HH:mm",
          lastWeek: "D MMMM HH:mm",
        })
      : ""

  useEffect(() => {
    let res = ""
    if (value <= 4900) {
      res = "0-49₽"
    } else if (value > 4900 && value <= 50000) {
      res = "49-500₽"
    } else if (value > 50000 && value <= 300000) {
      res = "500-3000₽"
    } else if (value > 300000) {
      res = "Больше 3000₽"
    }
    sendMetric({ ГлавныйЭкран: { БалансПоказ: [res] } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container.Root>
      {dateString && (
        <Typography.Body2>Баланс на {dateString}</Typography.Body2>
      )}
      <Container.Balance>
        <Typography.H1>{rublesStr}</Typography.H1>
        <Container.Fractional>
          {fractional > 0 ? `,${fractionalStr}` : ""} ₽
        </Container.Fractional>
      </Container.Balance>
    </Container.Root>
  )
}

export { Balance }
