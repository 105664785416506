import { FC } from "react"
import { useSelector } from "react-redux"
import { useTheme } from "styled-components"
import { RootState } from "store"
import { useGetHistoryCallsQuery } from "store/api"
import { IHistoryCalls, IHistoryCallsData } from "entities/historyCalls"
import { Player } from "@lottiefiles/react-lottie-player"
import empty from "animations/empty.json"
import {
  Container,
  Header,
  Loader,
  Spacer,
  Table,
  Typography,
} from "components"
import { HistoryCallsItem } from "./components/HistoryCallsItem"
import { EMPTY_CALLS_BANNER } from "./constants"

export const HistoryCalls: FC = () => {
  const theme = useTheme()
  const { currentPhone } = useSelector((state: RootState) => state.login)

  const hisoryCallsResponse = useGetHistoryCallsQuery({
    phone: currentPhone,
  })

  const callsData = hisoryCallsResponse?.data?.data

  const getGroupedHistoryCalls = (dataArray: IHistoryCallsData[]) => {
    return (
      // используется spread оператор, т.к. исходный массив заморожен и дальнейшая сортировка невозможна
      [...dataArray]
        // сортировка объектов от более новой даты к старой
        .sort((a, b) => Date.parse(b.callDate) - Date.parse(a.callDate))
        .reduce(
          (acc, item, index) => {
            // модифицируем теплейт для первого элемента массива
            if (acc.length === 1 && index === 0) {
              acc[0] = {
                date: item.callDate,
                calls: [{ ...item }],
              }
            } else {
              const matchingDate = acc.find(
                (x) =>
                  new Date(x.date).getUTCDate() ===
                  new Date(item.callDate).getUTCDate()
              )
              // если есть соответствие по дате, пушим в calls иначе добавляем новый объект
              if (!!matchingDate) {
                const matchingDateIndex = acc.indexOf(matchingDate)
                acc[matchingDateIndex].calls = [
                  ...acc[matchingDateIndex].calls,
                  { ...item },
                ]
              } else {
                acc.push({ date: item.callDate, calls: [{ ...item }] })
              }
            }

            return acc
          },
          // темплейт для reduce
          [
            {
              date: "",
              calls: [{}],
            },
          ]
        )
    )
  }

  return (
    <>
      {!hisoryCallsResponse.isSuccess && <Loader />}
      <Container page backgroundColor={theme.colors.background.surface0}>
        <Header
          background={theme.colors.background.surface0}
          title="История звонков"
        />
        {callsData && callsData?.calls?.length > 0 ? (
          <HistoryCallsItem
            data={getGroupedHistoryCalls(callsData?.calls) as IHistoryCalls[]}
          />
        ) : (
          <Table.Body padding={37}>
            <Player autoplay loop src={empty} />
            <Typography.Body2 fontSize={17} lineHeight={22} textAlign="center">
              {EMPTY_CALLS_BANNER[0]}
              <br />
              {EMPTY_CALLS_BANNER[1]}
              <br />
              {EMPTY_CALLS_BANNER[2]}
            </Typography.Body2>
            <Spacer height={64} />
          </Table.Body>
        )}
      </Container>
    </>
  )
}
