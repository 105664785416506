import React, { FC, ReactNode, useEffect } from "react"
import { useSelector } from "react-redux"
import ReactMarkdown from "react-markdown"
import { RootState } from "store"
import { useActions } from "hooks/actions"
import { useTheme } from "styled-components"
import { Spacer, Typography, Button } from "components"
import { Modal } from "../Modal"

const useModalConfirm = (onConfirm: () => void) => {
  const { isConfirmClick } = useSelector((state: RootState) => state.modal)
  useEffect(() => {
    if (isConfirmClick) {
      onConfirm()
    }
  }, [isConfirmClick, onConfirm])
}

const useModalDecline = (onDecline: () => void) => {
  const { isDeclineClick } = useSelector((state: RootState) => state.modal)

  useEffect(() => {
    if (isDeclineClick) {
      onDecline()
    }
  }, [isDeclineClick, onDecline])
}

const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    isOpen,
    title,
    subtitle,
    content,
    confirmLabel,
    declineLabel,
    isPhoneNumber,
  } = useSelector((state: RootState) => state.modal)
  const theme = useTheme()
  const { closeModal, setConfirm, setDecline } = useActions()

  return (
    <>
      <Modal open={isOpen} onClose={() => closeModal()}>
        <Typography.H4>{title}</Typography.H4>
        {subtitle && (
          <>
            <Spacer height={8} />
            <Typography.Body1 color={theme.colors.text.secondary}>
              {subtitle}
            </Typography.Body1>
          </>
        )}
        {content && (
          <>
            <Spacer height={16} />
            <Typography.Body1
              as="div"
              lineHeight={22}
              className={isPhoneNumber ? "ym-hide-content" : ""}
            >
              <ReactMarkdown>{content}</ReactMarkdown>
            </Typography.Body1>
          </>
        )}
        <Spacer height={28} />
        {confirmLabel && (
          <>
            <Button.Primary onClick={() => setConfirm(true)}>
              {confirmLabel}
            </Button.Primary>
            <Spacer height={8} />
          </>
        )}
        <Button.Plain
          onClick={() => {
            closeModal()
            setDecline(true)
          }}
        >
          {declineLabel}
        </Button.Plain>
      </Modal>
      {children}
    </>
  )
}

export { ModalProvider, useModalConfirm, useModalDecline }
