import { FieldFormat, IFormModule } from "components/Form"
import { IPersonalData } from "entities/personalInfo"
import { FieldExtensionType } from "components/Form/interfaces"

export const formPersonalData = (data: IPersonalData): IFormModule[] => [
  {
    title: "",
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "fio",
        placeholder: "Фамилия Имя Отчество",
        format: FieldFormat.text,
        initialValue: data.clientFullName,
        disabled: true,
      },
      {
        extensionType: FieldExtensionType.common,
        name: "birthDate",
        placeholder: "Дата рождения",
        format: FieldFormat.text,
        initialValue: data.birthDate,
        disabled: true,
      },
      {
        extensionType: FieldExtensionType.common,
        name: "passport",
        placeholder: "Серия и номер документа",
        format: FieldFormat.text,
        initialValue: `${data.document.documentSeries} ${data.document.documentNumber}`,
        disabled: true,
      },
    ],
  },
]
export const formContactData = (email: string): IFormModule[] => [
  {
    title: "",
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "fio",
        placeholder: "Email",
        format: FieldFormat.text,
        initialValue: email,
        info: "На этот адрес будет приходить детализация и сообщения службы поддержки",
        disabled: true,
      },
    ],
  },
]
