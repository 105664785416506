import React, { FC, memo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { TCustomValidator, TFormDataValues } from "components/Form/interfaces"
import { useActions } from "hooks/actions"
import { RootState } from "store"
import { CommonContainer } from "components/Sheet/styles"
import { EOperatorCode } from "entities/common"
import { sendMetric } from "utils/metrics"
import { Container, Header, Form, Typography, Spacer, Button } from "components"
import { axiosInstance } from "../../axios"
import { routes } from "constants/routes"
import { operators, TOperator } from "./constants"
import { formModules } from "./formData"

const PaymentAdjustmentNumber: FC = memo(() => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { values } = useSelector((state: RootState) => state.form)
  const { setFormValues } = useActions()

  const handleFormSubmit = (data: TFormDataValues) => {
    sendMetric({
      "Корректировка платежа по номеру": { "Ввод данных": ["Продолжить_Клик"] },
    })
    setFormValues({ values: data })
    navigate(`${routes.paymentAdjustmentSinging}?type=number`)
  }

  const renderSheetBody = (
    sheetData: TOperator,
    operatorCode: EOperatorCode
  ) => (
    <CommonContainer>
      <Typography.H4>{sheetData.title}</Typography.H4>
      <Spacer height={34} />
      <Typography.Body1>{sheetData.text}</Typography.Body1>
      <Spacer height={28} />
      <Button.Primary
        onClick={
          operatorCode === EOperatorCode.Other
            ? () => navigate(routes.main)
            : () => window.open(`tel:${sheetData.phone}`, "_self")
        }
      >
        {sheetData.phone}
      </Button.Primary>
    </CommonContainer>
  )

  const errorMessage = "Введите номер СберМобайл"

  const validateNumber: TCustomValidator = async (value, options) => {
    if (typeof value !== "string") {
      return { isValid: false, message: "Укажите ошибочный номер" }
    }
    const phone = value.replace(/\D/g, "").slice(1)

    if (phone.length !== 10) {
      return { isValid: false, message: "Укажите ошибочный номер" }
    }

    if (options && options.watchField && options.watchField === phone) {
      return {
        isValid: false,
        message: "Номера для зачисления и ошибочный номер не должны совпадать",
      }
    }

    const response = await axiosInstance.get(
      `/user-detail-service/number/info?number=${phone}`
    )
    if (!response) {
      return { isValid: false, message: errorMessage }
    }
    const operatorCode = response.data.data.operatorCode as EOperatorCode

    const isValid = operatorCode === EOperatorCode.Sbertel
    if (!isValid) {
      sendMetric({
        "Корректировка платежа по номеру": {
          "Ввод данных": ["Другой оператор_Показ"],
        },
      })
      return {
        isValid,
        message: errorMessage,
        sheetContent: renderSheetBody(
          operators[operatorCode] ?? operators[EOperatorCode.Other],
          operators[operatorCode] ? operatorCode : EOperatorCode.Other
        ),
      }
    }
    return { isValid }
  }

  return (
    <Container page backgroundColor={theme.colors.background[0]}>
      <Header
        title="Корректировка платежа"
        subtitle="Ошибка в номере телефона"
        background={theme.colors.background[0]}
      />
      <Form
        onSubmit={handleFormSubmit}
        modules={formModules(validateNumber)}
        initialValues={values}
      />
    </Container>
  )
})

export { PaymentAdjustmentNumber }
