import styled from "styled-components"
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel"

const Carousel = styled(ResponsiveCarousel)`
  padding: 0 4px;
  margin: -12px 0 24px 0;
  .slide {
    padding: 0 4px;
  }
  .slide:first-child {
    padding-left: 8px;
  }
  .slide:last-child {
    padding-right: 8px;
  }
`

export const Styled = { Carousel }
