export enum ELinksCode {
  Gosuslugi = "gosuslugi",
  BuyPrime = "buyPrime",
  SignInGoskey = "signInGoskey",
  Rules = "rules",
  MyServices = "myServices",
  voprosy = "voprosy",
  coverageMap = "coverageMap",
  sberTariffs = "sberTariffs",
  CommunicationRules = "communicationRules",
  SmartAnswerDownload = "smartAnswerDownload",
  SmartAnswerManipulate = "smartAnswerManipulate",
  SmartAnswerMore = "smartAnswerMore",
  SberSound = "sberSound",
  RoamingMarkerUrl = "roamingMarkerUrl",
}

export enum EStatusCode {
  MaintenanceWork = "MAINTENANCE_WORK",
  Ok = "OK",
  NotSamePerson = "NOT_SAME_PERSON",
  ServiceIsUnavalilable = "SERVICE_IS_UNAVAILABLE",
  NotVerifiedData = "NOT_VERIFIED_DATA",
  UserIsNotIndividual = "USER_IS_NOT_INDIVIDUAL",
  BadCredentials = "BAD_CREDENTIALS",
  EmailServiceNotAvailable = "EMAIL_SERVICE_NOT_AVAILABLE",
  OperationTimeExpired = "OPERATION_TIME_EXPIRED",
  UnknownServerError = "UNKNOWN_SERVER_ERROR",
  OtrsError = "OTRS_ERROR",
  PasswordExpired = "PASSWORD_EXPIRED",
}

export enum EApiUrls {
  GoskeyServiceCache = "/goskey-service/cache",
  Links = "/user-detail-service/settings/links?system=WEB",
}

export interface ICommonResponse {
  time: string
  statusInfo: {
    statusCode: EStatusCode
    statusMessage: string
  }
}

export enum EClientType {
  B2C = "B2C",
  B2B2C = "B2B2C",
  B2B = "B2B",
  CorpSber = "Corp_Sber",
}

export enum EOperatorCode {
  Sbertel = "sbertel",
  Megafon = "megafon",
  Mts = "mts",
  Beeline = "beeline",
  Tele2 = "tele2",
  Other = "other",
}

export enum ELoginStatus {
  Success = "00",
  NotFound = "11",
  NotSupported = "10",
  NotSupportedBig4 = "01",
}

export interface IResponseErrorData {
  statusInfo: {
    statusMessage: string
    statusCode: string
  }
  time: string
}

export enum EContentType {
  Wifi = "WIFI",
  Internet = "INTERNET",
  Calls = "CALLS",
  Aura = "AURA",
  Messengers = "MESSENGERS",
  Socials = "SOCIALS",
  Music = "MUSIC",
  Video = "VIDEO",
  WorryLessSms = "WORRY_LESS_SMS",
  AutorenewalBlock = "AUTORENEWAL_BLOCK",
  Tethering = "TETHERING",
  Ya = "YA",
  CompSms = "COMP_SMS",
  CompInternet = "COMP_INTERNET",
  CompCal = "COMP_CAL",
  Sms = "SMS",
  SberSound = "SBER_SOUND",
  RoamingMarker = "ROAMING_MARKER",
  ConnectionFee = "CONNECTION_FEE",
  SecureAccount = "SECURE_ACCOUNT",
}

export enum EContentContainer {
  RoamingOptions = "roamingOptions",
  Specials = "specials",
  ContentOptions = "contentOptions",
  Options = "options",
}
// todo
export enum EOptionStatus {
  Active = "ACTIVE",
  Disabled = "DISABLED",
}

export enum EOptionErrorCode {
  NoMoney = "INSUFFICIENT_FUNDS_TO_ACTIVATE_SERVICE_TARIFF_OPTION",
}

export enum ESendConfirmationType {
  AuraActivation = "aura_activation",
  SendDocumentToGoskey = "send_document_to_goskey",
  ReplaceMsisdn = "replace_msisdn",
  AdjustmentPersonal = "adjustment_personal",
  AdjustmentNumber = "adjustment_number",
  AdjustmentAmount = "adjustment_amount",
  UpdateEmailProfile = "updateEmailProfile",
  CostDetail = "cost_detail",
  MNPConfirm = " mnp_confirm",
}

export enum EServicesTabs {
  Available = "Доступные",
  Enabled = "Подключенные",
}

export enum EPeriodType {
  Single = "SINGLE",
  Period = "PERIOD",
}

export enum ERoutes {
  PersonalInfoConfirmation = "personalInfoConfirmation",
  EmailConfirmation = "emailConfirmation",
  EmailAdjustment = "emailAdjustment",
  PersonalInfoAdjustment = "personalInfoAdjustment",
}

export interface IModalState {
  isOpen: boolean
  title: string
  subtitle?: string
  declineLabel?: string
}

export enum ESpacerSize {
  XS = 4,
  S = 8,
  M = 16,
  L = 24,
  XL = 32,
  XXL = 40,
}
