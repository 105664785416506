import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { EContentType, EOptionStatus } from "entities/common"
import { ITariffOptionStatusRequest } from "entities/tariffOptionsStatus"
import { RootState } from "store"
import { usePostTariffOptionStatusMutation } from "store/api"
import { useTheme } from "styled-components"
import { Button, Icon, Spacer } from "components"
import { ServicesSheet } from "../ServicesSheet"
import { ServicesSheetAssistant } from "../ServicesSheetAssistant"
import { routes } from "constants/routes"
import { Styled } from "./styles"

export const SecuritySheet = ({ data, isOpenSheet, setIsOpenSheet }: any) => {
  const { currentPhone, phone } = useSelector((state: RootState) => state.login)

  const theme = useTheme()
  const navigate = useNavigate()
  const [triggerTariffOptionStatus, status] =
    usePostTariffOptionStatusMutation()
  const prepareRequestData = (optionStatus: EOptionStatus) => {
    const requestData: ITariffOptionStatusRequest = {
      status: optionStatus,
      optionId: `${data?.optionId}`,
    }
    if (phone === currentPhone) {
      requestData.phone = phone
    } else {
      requestData.additionalNumbers = currentPhone
    }

    return requestData
  }
  const [currentOptionStatus, setCurrentOptionStatus] =
    useState<EOptionStatus>()
  const sendData = (optionStatus: EOptionStatus) => {
    setCurrentOptionStatus(optionStatus)
    triggerTariffOptionStatus(prepareRequestData(optionStatus))
  }
  useEffect(() => {
    if (status.isSuccess || status.isError) {
      navigate(
        `${routes.operationStatusCommon}?isSuccess=${
          status.isSuccess ? true : false
        }&oprationType=${currentOptionStatus}&redirectUrl=${routes.services}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, currentOptionStatus])
  switch (data?.contentType) {
    case EContentType.Aura:
      return (
        <ServicesSheet
          isOpen={isOpenSheet}
          onClose={() => setIsOpenSheet(false)}
          title="Аура"
          subTitle="Предупредит о мошеннике прямо во время разговора"
          icon={<Icon.SecureAccountServicesAura />}
        >
          {data?.isActive ? (
            <>
              <Button.MPPrimary onClick={() => navigate(routes.auraDetails)}>
                <Styled.SheetButtonPrimary>Подробнее</Styled.SheetButtonPrimary>
              </Button.MPPrimary>
              <Spacer height={8} />
              <Button.MNPPlain onClick={() => sendData(EOptionStatus.Disabled)}>
                <Styled.SheetButtonSecondary color={theme.colors.text.critical}>
                  Отключить
                </Styled.SheetButtonSecondary>
              </Button.MNPPlain>
            </>
          ) : (
            <>
              <Button.MPPrimary onClick={() => navigate(routes.auraAgreement)}>
                <Styled.SheetButtonPrimary>
                  Подключить
                </Styled.SheetButtonPrimary>
              </Button.MPPrimary>
              <Spacer height={8} />
              <Button.MNPPlain onClick={() => navigate(routes.auraDetails)}>
                <Styled.SheetButtonSecondary
                  color={theme.colors.button.contentBrand}
                >
                  Подробнее
                </Styled.SheetButtonSecondary>
              </Button.MNPPlain>
            </>
          )}
        </ServicesSheet>
      )
    case EContentType.Ya:
      return (
        <ServicesSheetAssistant
          isOpen={isOpenSheet}
          onClose={() => setIsOpenSheet(false)}
          title="Ассистент принимает звонки, когда вы не можете ответить"
          subTitle="и присылает текстовую и аудиозапись разговора"
        >
          {data?.active ? (
            <>
              <Button.Black onClick={() => sendData(EOptionStatus.Disabled)}>
                <Styled.SheetButtonSecondary
                  color={theme.colors.button.contentPrimary}
                >
                  Отключить
                </Styled.SheetButtonSecondary>
              </Button.Black>
              <Styled.SheetAssistantText>
                <Styled.SheetAssistantLink
                  onClick={() => navigate(routes.assistantAgreement)}
                >
                  Подробнее об условиях оказания услуги Ассистент
                </Styled.SheetAssistantLink>
              </Styled.SheetAssistantText>
            </>
          ) : (
            <>
              <Button.Black onClick={() => sendData(EOptionStatus.Active)}>
                <Styled.SheetButtonPrimary>
                  Подключить
                </Styled.SheetButtonPrimary>
              </Button.Black>
              <Styled.SheetAssistantText>
                Нажимая кнопку, я соглашаюсь
                <br />с{" "}
                <Styled.SheetAssistantLink
                  onClick={() => navigate(routes.assistantAgreement)}
                >
                  Условиями оказания услуги{" "}
                </Styled.SheetAssistantLink>
                Ассистент
              </Styled.SheetAssistantText>
            </>
          )}
        </ServicesSheetAssistant>
      )
    default:
      return <></>
  }
}
