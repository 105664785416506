import { ICommonResponse } from "./common"

export interface IConnectedAvailableRequest {
  phone: string
}

export interface IConnectedAvailableResponse extends ICommonResponse {
  data: IData
}

export enum EStatusType {
  Blocked = "BLOCKED",
  Unlocked = "UNLOCKED",
  Active = "ACTIVE",
  Disabled = "DISABLED",
}

export interface IData {
  enabledOptions: IOption[]
  availableOptions: IOption[]
}

export interface IOption {
  sectionName: string
  serviceData: IServiceData
}

export interface IServiceData {
  specials: ISpecial[]
  contentOptions: IContentOption[]
  roamingOptions: IRoaming[]
  options: IOptionElement[]
}

export interface IContentOption {
  optionId: number
  title: string
  subTitle: string
  description: string
  monthlyFee: number
  availability: string
  contentType: string
  enabledApps: IEnabledApp[]
  active: boolean
  statusType?: EStatusType
  statusTitle?: string
  iconUrl?: string
}

export interface IEnabledApp {
  appCode: string
  iconUrl: string
}

export interface IOptionElement {
  optionId: number
  title: string
  description: string
  unit: string
  volume?: number
  monthlyFee: number
  connectionFee: number
  coverageArea: string
  connectDescription: string
  contentType: string
  canBeDisabled: boolean
  subTitle: string
  active: boolean
  periodType?: string
  currentValue?: number
  totalValue?: number
  percentage?: number
  expDate?: Date
  statusType?: EStatusType
  statusTitle?: string
  iconUrl?: string
}

export interface ISpecial {
  id: number
  title: string
  subTitle: string
  connectDescription: string
  startDate: Date
  description: string
  url: null
  canBeDisabled: boolean
  status: string
  availabilityDescription: null
  serviceType: string
}

export type IRoaming = any
