import styled from "styled-components"
import { Icon } from "../Icons"

export const Header = styled.div`
  background-color: ${(props) => props.theme.colors.background[1]};
  border-radius: 12px;
  padding: 7px 12px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 12px 16px 26px 12px;
  cursor: pointer;
`

export const CheckIconStyled = styled(Icon.Check)`
  color: ${(props) => props.theme.colors.text.warning};
  height: 100%;
`
