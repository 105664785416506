import { EOperatorCode } from "entities/common"

export type TOperator = { title: string; text: string; phone: string }

export const operators: Record<EOperatorCode, TOperator> = {
  [EOperatorCode.Sbertel]: {
    title: "",
    text: "",
    phone: "",
  },
  [EOperatorCode.Beeline]: {
    title: "Билайн",
    text: "Введеный номер принадлежит компании «Билайн». Вы можете обратиться в службу поддержки своего оператора по номеру, указанному ниже",
    phone: "8 (800) 700-06-11",
  },
  [EOperatorCode.Megafon]: {
    title: "Мегафон",
    text: "Введеный номер принадлежит компании «Мегафон». Вы можете обратиться в службу поддержки своего оператора по номеру, указанному ниже",
    phone: "8 (800) 550-05-00",
  },
  [EOperatorCode.Mts]: {
    title: "МТС",
    text: "Введеный номер принадлежит компании «МТС». Вы можете обратиться в службу поддержки своего оператора по номеру, указанному ниже",
    phone: "8 (800) 250-08-90",
  },
  [EOperatorCode.Tele2]: {
    title: "Tele2",
    text: "Введеный номер принадлежит компании «Теле2». Вы можете обратиться в службу поддержки своего оператора по номеру, указанному ниже",
    phone: "8 (800) 555-06-11",
  },
  [EOperatorCode.Other]: {
    title: "Служба поддержки",
    text: "Обратитесь в службу поддержки вашего оператора",
    phone: "ОК",
  },
}
