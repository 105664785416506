import React, { FC } from "react"
import {
  EStatusType,
  IContentOption,
  IOptionElement,
  IRoaming,
  ISpecial,
} from "entities/connectedAvailable"
import moment from "moment"
import { formatPrice } from "utils/price"
import {
  EContentType,
  EContentContainer,
  EPeriodType,
  ESpacerSize,
} from "entities/common"
import { useTheme } from "styled-components"
import { EOptionAvailability } from "entities/tariff"
import { getOptionIcon } from "utils/option"
import { useSelector } from "hooks/useSelector"
import { RootState } from "store"
import { useActions } from "hooks/actions"
import { ServiceCell } from "components/Table/ServiceCell"
import { SpanSecondary } from "components/MPTypography/styles"
import { Badge, Icon, Spacer, Table, Typography } from "components"
import { prepareGb, prepareInternetUnit } from "./utils"
import {
  Body1BoldStyled,
  InternetStyled,
  PriceDescriptionStyled,
  PriceStyled,
} from "./styles"

interface IOption {
  data: ISpecial | IContentOption | IRoaming | IOptionElement
  arrayName: EContentContainer
  isAvailableService: boolean
  isRoamingServices?: boolean
}

export const Option: FC<IOption> = ({
  data,
  arrayName,
  isAvailableService = false,
  isRoamingServices = false,
}) => {
  const theme = useTheme()
  const { optionStatusTitle } = useSelector((state: RootState) => state.common)
  const { setOptionStatusTitle } = useActions()
  if (!optionStatusTitle && data.statusTitle) {
    setOptionStatusTitle(data.statusTitle)
  }
  const renderPrice = (monthlyFee: number, description?: string) => {
    // Непонятно почему приходят отрицательные цены, но раз уж приходят вероятно лучше рисовать 0
    const price = formatPrice(monthlyFee < 0 ? 0 : monthlyFee).value
    return (
      <PriceStyled description={description}>
        <Body1BoldStyled>{price}</Body1BoldStyled>
        {description && (
          <PriceDescriptionStyled>{description}</PriceDescriptionStyled>
        )}
      </PriceStyled>
    )
  }
  const renderSecurityPrice = (
    monthlyFee: number,
    description?: string,
    isSecurityOption?: boolean
  ) => {
    // Непонятно почему приходят отрицательные цены, но раз уж приходят вероятно лучше рисовать 0
    const price = formatPrice(monthlyFee < 0 ? 0 : monthlyFee).rubles
    return (
      <PriceStyled description={description}>
        {isSecurityOption && <Spacer height={ESpacerSize.M} />}
        <Typography.H3 fontSize="34" lineHeight="41">
          {price} <SpanSecondary>₽</SpanSecondary>
        </Typography.H3>
        {description && (
          <PriceDescriptionStyled>{description}</PriceDescriptionStyled>
        )}
      </PriceStyled>
    )
  }
  const isBlocked = data.statusType === EStatusType.Blocked
  const startSubIcon = () => (isBlocked ? <Icon.Blocked /> : undefined)

  const renderMinutes = (minutes: string, description: string) => {
    return (
      <PriceStyled description={description}>
        <Body1BoldStyled>{minutes}</Body1BoldStyled>
        <PriceDescriptionStyled>{description}</PriceDescriptionStyled>
      </PriceStyled>
    )
  }

  const renderInternet = (gbStartContent: string, description: string) => {
    return (
      <InternetStyled>
        <Body1BoldStyled>{gbStartContent}</Body1BoldStyled>
        <PriceDescriptionStyled>{description}</PriceDescriptionStyled>
      </InternetStyled>
    )
  }

  const renderFooter = (startContent: string, description: string) => (
    <InternetStyled>
      <PriceDescriptionStyled>{`${startContent} ${description}`}</PriceDescriptionStyled>
    </InternetStyled>
  )

  const renderBadge = (isActive: boolean) => (
    <Badge text={isActive ? "Отключить" : "Подключить"} active={isActive} />
  )

  if (arrayName === EContentContainer.Specials) {
    return (
      <Table.CommonCell
        title={data.title}
        subTitle={`${data.connectDescription} ${moment(data.startDate).format(
          "DD.MM.YY"
        )}`}
        startIcon={<Icon.Percent />}
        startSubIcon={startSubIcon()}
      />
    )
  }

  if (arrayName === EContentContainer.ContentOptions) {
    const isLongDescription = data.description.length > 7
    const width = isLongDescription ? "128px" : "max-content"
    //В настоящий момент, контентные опции включенные в тариф приходят в доступных услугах. Соответственно, вместо суммы, мы пишем, что услуга включена в тариф.
    //Поднял вопрос о том, чтобы они приходили как им и положенно, в подключенных.
    const description =
      data.availability === EOptionAvailability.Free ? (
        <Typography.Body2 textAlign="right">
          Включено
          <br />в тариф
        </Typography.Body2>
      ) : (
        data.description
      )
    return (
      <Table.CommonCell
        title={data.title}
        subTitle={`${data.enabledApps
          .map((app: any) => app.appCode)
          .join(", ")} не расходуют основной пакет интернета`}
        startIcon={getOptionIcon(data.contentType, arrayName)}
        endIcon={renderPrice(data.monthlyFee, description)}
        width={width}
        footer={isRoamingServices ? renderBadge(data.active) : undefined}
        startSubIcon={startSubIcon()}
      />
    )
  }

  if (
    arrayName === EContentContainer.Options ||
    arrayName === EContentContainer.RoamingOptions
  ) {
    const isPeriodicPackage = data?.periodType === EPeriodType.Period
    const isEndIcon = isAvailableService || isPeriodicPackage
    const period = data?.subTitle
    const packetEnding = `до ${moment(data?.expDate).format("DD.MM.YY")}`
    const percentage = data?.percentage
    const badge = isRoamingServices ? renderBadge(data.active) : undefined

    switch (data.contentType) {
      case EContentType.Calls: {
        const color = theme.colors.speedometer.call
        const callsPrice = formatPrice(data.monthlyFee).value
        const callsCurrentValue = data.currentValue
        const dataUnit = isAvailableService ? "" : data.unit.toLowerCase()

        const endIcon = isEndIcon
          ? renderMinutes(callsPrice, period)
          : undefined
        const footerIcon = !isAvailableService
          ? renderFooter(`${callsCurrentValue} ${dataUnit}`, packetEnding)
          : undefined
        return (
          <Table.CommonCell
            title={`${data.title} +${
              isAvailableService ? data.volume : data.totalValue
            } ${data?.unit?.toLowerCase?.()}`}
            subTitle={data.description}
            startIcon={getOptionIcon(data.contentType, arrayName)}
            endIcon={endIcon}
            width="max-content"
            footerIcon={!isBlocked ? footerIcon : undefined}
            progressBarInfo={!isBlocked ? { color, percentage } : undefined}
            footer={badge}
            startSubIcon={startSubIcon()}
          />
        )
      }

      case EContentType.AutorenewalBlock:
        return (
          <Table.CommonCell
            title={data.title}
            subTitle={data.description}
            startIcon={getOptionIcon(data.contentType, arrayName)}
            endIcon={renderPrice(data.monthlyFee)}
          />
        )
      case EContentType.Sms:
      case EContentType.WorryLessSms: {
        const color = theme.colors.speedometer.sms
        const endIcon = isEndIcon
          ? renderPrice(data.monthlyFee, data.connectDescription)
          : undefined
        const footerIcon = !isAvailableService
          ? renderFooter(
              `${data.currentValue} ${data.unit.toLowerCase()}`,
              packetEnding
            )
          : undefined
        return (
          <Table.CommonCell
            title={data.title}
            subTitle={data.subTitle}
            startIcon={getOptionIcon(data.contentType, arrayName)}
            endIcon={endIcon}
            width="max-content"
            footerIcon={!isBlocked ? footerIcon : undefined}
            progressBarInfo={!isBlocked ? { color, percentage } : undefined}
            footer={badge}
            startSubIcon={startSubIcon()}
          />
        )
      }
      case EContentType.Internet: {
        const color = theme.colors.speedometer.internet
        const gbValue = prepareGb(
          isAvailableService ? data.volume : data.totalValue ?? data.volume
        )
        const currentGbBalance = String(
          prepareGb(data.currentValue ?? data.volume)
        )
        const currentInternetValue = formatPrice(data.monthlyFee).value

        const endIcon = isEndIcon
          ? renderInternet(currentInternetValue, period)
          : undefined
        const footerIcon = !isAvailableService
          ? renderFooter(
              `${currentGbBalance} ${prepareInternetUnit(data.currentValue)}`,
              packetEnding
            )
          : undefined
        return (
          <Table.CommonCell
            title={`${data.title} +${gbValue} ${data.unit.toUpperCase()}`}
            subTitle={data.description}
            startIcon={getOptionIcon(data.contentType, arrayName)}
            endIcon={endIcon}
            width="max-content"
            footerIcon={!isBlocked ? footerIcon : undefined}
            progressBarInfo={!isBlocked ? { color, percentage } : undefined}
            footer={badge}
            startSubIcon={startSubIcon()}
          />
        )
      }
      case EContentType.RoamingMarker:
        return (
          <Table.CommonCell
            title={data.title}
            subTitle={data.subTitle}
            startIcon={getOptionIcon(data.contentType)}
            footer={badge}
            startSubIcon={startSubIcon()}
          />
        )
      case EContentType.Tethering:
        return (
          <Table.CommonCell
            title={data.title}
            subTitle={data.subTitle}
            startIcon={getOptionIcon(data.contentType, arrayName)}
            endIcon={renderPrice(data.monthlyFee, data.connectDescription)}
            width="max-content"
            startSubIcon={startSubIcon()}
          />
        )
      // case EContentType.Aura:
      //   return (
      //     <Table.CommonCell
      //       title={data.title.replace("–", " – ")}
      //       subTitle={data.description}
      //       startIcon={getOptionIcon(data.contentType, arrayName)}
      //       endIcon={renderPrice(data.monthlyFee)}
      //       startSubIcon={startSubIcon()}
      //     />
      //   )
      case EContentType.Aura:
      case EContentType.SecureAccount:
      case EContentType.Ya:
        return (
          <ServiceCell
            title={data.title}
            subTitle={data.subTitle}
            startIcon={getOptionIcon(data.contentType, arrayName)}
            endIcon={renderSecurityPrice(
              data.monthlyFee,
              data.connectDescription,
              true
            )}
            width="max-content"
            startSubIcon={startSubIcon()}
          />
        )
      case EContentType.SberSound:
        return (
          <Table.CommonCell
            title={data.title}
            subTitle={data.connectDescription}
            startIcon={getOptionIcon(data.contentType, arrayName)}
            endIcon={renderPrice(data.monthlyFee)}
            width="max-content"
            startSubIcon={startSubIcon()}
          />
        )
      case EContentType.CompSms:
      case EContentType.CompInternet:
      case EContentType.CompCal:
      case EContentType.Messengers:
      case EContentType.Socials:
      case EContentType.Music:
      case EContentType.Video:
      case "DEFAULT":
        return (
          <Table.CommonCell
            title={data.title}
            subTitle={data.description}
            startIcon={getOptionIcon(data.contentType, arrayName)}
            endIcon={renderPrice(data.monthlyFee, data?.connectDescription)}
            width="max-content"
            startSubIcon={startSubIcon()}
          />
        )
      default:
        return <></>
    }
  }

  return <></>
}
