import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { EBannerType } from "entities/banners"

export interface IBannerState {
  [EBannerType.Aura]: boolean
  [EBannerType.Bezlimit20]: boolean
  [EBannerType.YA]: boolean
  [EBannerType.EsimEnabled]: boolean
  [EBannerType.Roaming]: boolean
}

const initialState: IBannerState = {
  [EBannerType.Aura]: true,
  [EBannerType.Bezlimit20]: true,
  [EBannerType.YA]: true,
  [EBannerType.EsimEnabled]: true,
  [EBannerType.Roaming]: true,
}

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    hideBanner: (state, { payload }: PayloadAction<keyof IBannerState>) => {
      state[payload] = false
    },
  },
})

export const bannerActions = bannerSlice.actions

export default bannerSlice.reducer
