import { ReactNode } from "react"
import { Icon, MPTypography } from "components"

export const TITLE = "Как работает услуга Хранитель?"
export const DESCRIPTION = [
  "Хранитель использует технологии, которые в связке защищают ваши звонки и деньги.",
]
export const howItWorks: { id: number; title: any; icon: ReactNode }[] = [
  {
    id: 1,
    title: () => (
      <div>
        <MPTypography typographyType="Body1">
          <MPTypography typographyType="Body1BoldSpan">Ловец</MPTypography>{" "}
          фильтрует входящие звонки, автоматически определяет нежелательные
          вызовы и перенаправляет их в умную голосовую почту Антиспам.
        </MPTypography>
      </div>
    ),
    icon: <Icon.Ruble />,
  },
  {
    id: 2,
    title: () => (
      <MPTypography typographyType="Body1">
        <MPTypography typographyType="Body1BoldSpan">Антиспам</MPTypography>{" "}
        примет за вас нежелательный звонок, а вам направит запись разговора и
        его текстовую расшифровку — их можно будет посмотреть в приложении
        СберМобайл в разделе «Безопасность».
      </MPTypography>
    ),
    icon: <Icon.PhoneFilled />,
  },
  {
    id: 3,
    title: () => (
      <MPTypography typographyType="Body1">
        <MPTypography typographyType="Body1BoldSpan">
          Возврат денег
        </MPTypography>{" "}
        предусмотрен на случай, если мошенникам всё же удастся обойти защиту и
        обмануть вас. Услуга включает страхование ваших денег на картах и счетах
        в Сбере от действий мошенников во время разговора.
      </MPTypography>
    ),
    icon: <Icon.WarningFilled />,
  },
]
