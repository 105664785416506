import React, { FC, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { sendMetric } from "utils/metrics"
import { Icon, Table } from "components"

export const SignedByUser: FC = () => {
  useEffect(() => {
    sendMetric({
      ГлавныйЭкран: {
        ВиджетДоговораЕСимПоказ: [
          "ДоговорПодписанСкороОнПоявитсяВВашемПрофиле",
        ],
      },
    })
  }, [])

  return (
    <Table.Section key={uuidv4()}>
      <Table.CommonCell
        title="Договор подписан"
        subTitle="Скоро он появится в вашем профиле"
        startIcon={<Icon.AgreementSigned />}
      />
    </Table.Section>
  )
}
