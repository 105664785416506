import styled, { css } from "styled-components"
import { Body2 } from "components/Typography/Body"
import { Caption } from "components/Typography/Caption"
import { Icon } from "components"

const IconCss = css`
  min-height: 36px;
  min-width: 36px;
  width: 36px;
  color: ${(props) => props.theme.colors.icon.primary};
`

export const WrapSpeedometer = styled.div`
  align-self: center;
`

export const WrapDescriptionStyled = styled.div`
  margin: 34px 16px 0px 16px;
  display: flex;
  gap: 12px;
`

export const DescriptionStyled = styled(Body2)`
  color: ${(props) => props.theme.colors.text.primary};
`

export const AutorenewalIconStyled = styled(Icon.Autorenewal)`
  ${IconCss}
`

export const AutorenewalBlockIconStyled = styled(Icon.AutorenewalBlock)`
  ${IconCss}
`
export const ExpDate = styled(Caption)`
  text-align: center;
  margin-top: 16px;
`
