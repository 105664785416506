import { getSearchObject } from "utils/search"
import { FAQ } from "pages/FAQ"
import {
  FOOTER_INFO,
  requiredConditions,
  REQUIRED_CONDITIONS_TITLE,
} from "./constants"

export const RequiredSecureConditions = () => {
  const { theme: externalTheme } = getSearchObject()

  return (
    <FAQ
      externalTheme={externalTheme}
      footerInfo={FOOTER_INFO}
      requiredConditions={requiredConditions}
      requiredConditionsTitle={REQUIRED_CONDITIONS_TITLE}
    />
  )
}
