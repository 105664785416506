import styled from "styled-components"
import { H4 } from "components/Typography/Headline"
import { Body1 } from "components/Typography/Body"

export const SheetContainer = styled.div`
  padding: 16px 0 24px 0;
  background: ${(props) => props.theme.colors.background[1]};
  border-radius: 15px 15px 0px 0px;
`

export const TitleStyled = styled(H4)`
  margin: 8px 24px 16px 24px;
`

export const SubtitleStyled = styled(Body1)`
  margin: 0px 24px 16px 24px;
`

export const WrapPrimaryButtonStyled = styled.div`
  padding: 12px 16px 8px 16px;
`
