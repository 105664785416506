import styled from "styled-components"
import { Player } from "@lottiefiles/react-lottie-player"

export const WrapLoaderIconStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  gap: 12px;
  z-index: 99999999;
  ::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
    z-index: -1;
  }
`

export const PlayerStyled = styled(Player)`
  height: 44px;
  width: 44px;
`
