import React, { FC, memo, ReactElement } from "react"
import {
  ContainerStyled,
  IconWrapStyled,
  RightContainerWrapStyled,
  RootStyled,
  SubtitleStyled,
  TitleStyled,
} from "./styles"

interface IItemProps {
  title?: string
  subtitle?: string
  icon?: ReactElement
}

export const Item: FC<IItemProps> = memo(({ title, subtitle, icon }) => {
  return (
    <RootStyled>
      <ContainerStyled>
        <IconWrapStyled>{icon}</IconWrapStyled>
        <RightContainerWrapStyled>
          <TitleStyled>{title}</TitleStyled>
          <SubtitleStyled>{subtitle}</SubtitleStyled>
        </RightContainerWrapStyled>
      </ContainerStyled>
    </RootStyled>
  )
})
