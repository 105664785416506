import styled from "styled-components"
import { Secondary } from "components/Button/Secondary"
import { Typography } from "components"

export const RootStyled = styled.div``

export const ImageStyled = styled.img`
  margin: 118px auto 24px auto;
  display: flex;
  width: 50%;
`

export const Body1Styled = styled(Typography.Body1)`
  text-align: center;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const ButtonStyled = styled(Secondary)`
  margin: 32px 16px;
  width: calc(100% - 32px);
  max-width: calc(480px - 32px);
`

export const WrapImageStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  margin: 118px auto 0px auto;
`
