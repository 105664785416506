import styled from "styled-components"

const ListStyled = styled.ul`
  padding-left: 24px;
`
const MarkerStyled = styled.li`
  ::marker {
    color: ${(props) => props.theme.colors.text.primary};
  }
`
const PaddingContainer = styled.div<{ padding?: number }>`
  padding: 0 ${(props) => props.padding ?? 16}px;
`

const UnderlineSpan = styled.span`
  color: ${(props) => props.theme.colors.text.primary};
  text-decoration: underline;
  cursor: pointer;
`

export const Styled = {
  ListStyled,
  MarkerStyled,
  PaddingContainer,
  UnderlineSpan,
}
