import { FAQDetails } from "../../faqs/FAQDetails"
import {
  BUTTON_TEXT,
  CHANGE_SBER_MAIN_NUMBER,
  sectionContent,
} from "./constants"

export const PWAChangeSberMainNumber = () => {
  return (
    <FAQDetails
      sectionContent={sectionContent}
      sectionTitle={CHANGE_SBER_MAIN_NUMBER}
      buttonText={BUTTON_TEXT}
      buttonLink={"https://online.sberbank.ru/CSAFront/index.do"}
    />
  )
}
