import { H4 } from "components/Typography/Headline"
import styled from "styled-components"

export const Icons = styled.div`
  display: flex;
  margin-top: 24px;
  img {
    border-radius: 50%;
    height: 48px;
    border: 3px solid white;
    margin: auto -4px;
  }
`

export const TypographyH4 = styled(H4)`
  padding: 0 30px;
  text-align: center;
`
