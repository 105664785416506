import { FC, Fragment } from "react"
import { useTheme } from "styled-components"
import { Container, Header, MPTypography, Spacer, Typography } from "components"
import { FAQCommonStyled } from "./styles"

interface IFAQCommon {
  title: string
  description?: string[]
  children?: any
}
export const FAQCommon: FC<IFAQCommon> = (props) => {
  const { title, description, children } = props
  const theme = useTheme()

  return (
    <Container backgroundColor={theme.colors.background.surface1} page>
      <Header background={theme.colors.background.surface1} />
      <FAQCommonStyled.PaddingContainer>
        <Typography.H2>{title}</Typography.H2>
      </FAQCommonStyled.PaddingContainer>
      {description &&
        description.map((thesis, index) => (
          <Fragment key={index}>
            <Spacer height={index === 0 ? 32 : 24} />
            <FAQCommonStyled.PaddingContainer>
              <MPTypography typographyType="Body1">{thesis}</MPTypography>
            </FAQCommonStyled.PaddingContainer>
          </Fragment>
        ))}

      <Spacer height={16} />
      {children}
    </Container>
  )
}
