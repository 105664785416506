import React, { FC } from "react"
import { RefCallBack } from "react-hook-form/dist/types/form"
import { UploadFile, FormInput, DateField, SelectField } from "components"
import { PhoneSelectField } from "../PhoneSelectField"
import { IFile } from "../UploadFile/UploadFile"
import { FieldFormat, ICommonField, TFormField } from "./interfaces"

const Field: FC<TFormField & ICommonField> = ({
  format,
  name,
  placeholder,
  sheet,
  onChange,
  onSelect,
  onBlur,
  hasError,
  value,
  description,
  fieldRef,
  disabled,
  max,
  options,
  maxLength,
  type,
  inputMode,
  postfix,
  pattern,
  unavailableTitle,
  unavailableSubtitle,
  maxDate,
  minDate,
  startIcon,
  onClick,
  onOuterBlur,
}) => {
  switch (format) {
    case FieldFormat.text:
      return (
        <FormInput
          placeholder={placeholder}
          description={description}
          value={value as string}
          hasError={hasError}
          onChange={onChange}
          onBlur={(val) => {
            onBlur(val)
            onOuterBlur?.(val)
          }}
          name={name}
          fieldRef={fieldRef}
          disabled={disabled}
          maxLength={maxLength}
          type={type}
          inputMode={inputMode}
          postfix={postfix}
          pattern={pattern}
          autoFocus={false}
          startIcon={startIcon}
          onClick={onClick}
        />
      )
    case FieldFormat.date:
      return (
        <DateField
          placeholder={placeholder}
          description={description}
          value={value as string}
          hasError={hasError}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          fieldRef={fieldRef}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          startIcon={startIcon}
          onClick={onClick}
        />
      )
    case FieldFormat.phone:
      return (
        <FormInput
          placeholder={placeholder}
          description={description}
          value={value as string}
          hasError={hasError}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          fieldRef={fieldRef}
          type="tel"
          disabled={disabled}
          startIcon={startIcon}
          onClick={onClick}
        />
      )
    case FieldFormat.select:
      return (
        <SelectField
          placeholder={placeholder}
          description={description}
          value={value as string}
          hasError={hasError}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          fieldRef={fieldRef}
          disabled={disabled}
          sheet={sheet ?? { title: "" }}
          options={options ?? []}
          startIcon={startIcon}
          onClick={onClick}
          onSelect={onSelect}
        />
      )
    case FieldFormat.phoneSelect:
      return (
        <PhoneSelectField
          placeholder={placeholder}
          value={value as string}
          sheet={sheet ?? { title: "" }}
          onChange={onChange}
          onSelect={onSelect}
          onBlur={onBlur}
          name={name}
          fieldRef={fieldRef}
          disabled={disabled}
          unavailableTitle={unavailableTitle}
          unavailableSubtitle={unavailableSubtitle}
          startIcon={startIcon}
          description={description}
          onClick={onClick}
        />
      )
    case FieldFormat.file:
      return (
        <UploadFile
          placeholder={placeholder}
          info={description}
          name={name}
          fieldRef={fieldRef as RefCallBack}
          value={value as IFile[]}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          max={max}
          hasError={hasError}
          onClick={onClick}
        />
      )
    default:
      return <></>
  }
}

export { Field }
