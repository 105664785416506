import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { formatPhoneNumber } from "utils"
import { getSearchObject } from "utils/search"
import { sendMetric } from "utils/metrics"
import { RootState } from "store"
import { OperationStatus, Spacer } from "components"
import { routes } from "constants/routes"

export const ChangeNumberStatus = () => {
  const navigate = useNavigate()
  const { replacedPhone, newMsisdn, isNotSuccess } = getSearchObject()
  const { currentCategory } = useSelector(
    (state: RootState) => state.beautifulNumbers
  )
  const isSuccess = !isNotSuccess
  const title = isSuccess ? "Заявка принята" : "Заявка не отправлена"
  const buttonTitle = isSuccess ? "Отлично" : "Понятно"

  useEffect(() => {
    sendMetric({
      Ещё: { "Красивые номер": { "Отправка заявки_Показ": [currentCategory] } },
    })
  }, [currentCategory])

  return (
    <OperationStatus
      continueHandler={() => navigate(routes.main)}
      title={title}
      continueButtonTitle={buttonTitle}
    >
      {isSuccess ? (
        <>
          <span>{`Заменим номер ${formatPhoneNumber(
            replacedPhone,
            true
          )}`}</span>
          <br />
          <span>{`на ${formatPhoneNumber(newMsisdn, true)}`}</span>
          <br />
          в течение 5 минут
        </>
      ) : (
        <>
          <span>Произошла ошибка</span>
          <br />
          <Spacer height={4} />
          <span>Пожалуйста, повторите попытку</span>
        </>
      )}
    </OperationStatus>
  )
}
