import styled from "styled-components"
import { Body1, Body2 } from "components/Typography/Body"

export const RootStyled = styled.div`
  gap: 12px;
  height: 56px;
  margin: 16px 0px;
  background: ${(props) => props.theme.colors.background[1]};
  border: ${(props) => `1px dashed ${props.theme.colors.background[1]}`};
  border-radius: 8px;
`

export const TitleStyled = styled(Body2)`
  color: ${(props) => props.theme.colors.text.secondary};
  margin: 8px 16px 1.5px 16px;
`

export const ValueStyled = styled(Body1)`
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0px 16px;
`
