import React, { FC, memo, useEffect, useState } from "react"
import { formatPhoneNumber } from "utils"
import {
  useAdditionalNumberOrderMutation,
  useGetAdditionalNumberQuery,
} from "store/api"
import { sendMetric } from "utils/metrics"
import { Typography, Icon, Sheet } from "components"
import { Container } from "./styles"
import { LinkNumbers } from "./LinkNumbers/LinkNumbers"
import { IPhoneSelect } from "./interfaces"

const PhoneSelect: FC<IPhoneSelect> = memo(
  ({ phones, onChange, current, setIsChangeOrderLoading, alias }) => {
    const [draggablePhones, setDraggablePhones] = useState(phones ?? [])
    const [isOpen, setOpen] = useState(false)
    const [wasDropped, setWasDropped] = useState(false)
    const [trigger, status] = useAdditionalNumberOrderMutation()
    const { refetch: numberDataRefetch } = useGetAdditionalNumberQuery()
    const title = alias === current ? formatPhoneNumber(current) : alias

    useEffect(() => {
      if (status.isSuccess) {
        numberDataRefetch()
        setIsChangeOrderLoading(false)
        setWasDropped(false)
        setOpen(false)
      }
    }, [status.isSuccess, numberDataRefetch, setIsChangeOrderLoading])

    useEffect(() => {
      if (isOpen) {
        sendMetric({ ГлавныйЭкран: { ПривязанныеНомера: { Показ: [] } } })
      }
    }, [isOpen])

    const onClose = () => {
      if (wasDropped && draggablePhones) {
        trigger({
          numbers: draggablePhones.map(({ number }, index) => ({
            number,
            order: index,
          })),
        })
        setIsChangeOrderLoading(true)
      } else {
        setOpen(false)
      }
    }

    return (
      <>
        <Container onClick={() => setOpen(!isOpen)}>
          <Typography.Body1>{title}</Typography.Body1>
          <Icon.ArrowDown />
        </Container>
        <Sheet isOpen={isOpen} disableDrag onClose={onClose}>
          <LinkNumbers
            onChange={onChange}
            onClose={onClose}
            draggablePhones={draggablePhones}
            setDraggablePhones={setDraggablePhones}
            setWasDropped={setWasDropped}
          />
        </Sheet>
      </>
    )
  }
)

export { PhoneSelect }
