import { ReactElement } from "react"
import moment from "moment"
import {
  EBonusOptionType,
  EPeriodType,
  EQuantityType,
  EReceiveStatus,
  IAdditionalOption,
  IBalance,
  IBonusOption,
  ICurrentTariff,
  IDefaultOption,
  IFee,
  TExtendedData,
} from "entities/tariff"
import { EContentType } from "entities/common"
import { EContentType as EServiceContentType } from "entities/common"
import { IRadialChart } from "components/RadialChart"
import { formatPrice } from "utils/price"
import { sendMetric } from "utils/metrics"
import { getTheme } from "theme"
import { Icon, Typography } from "components"
import { Periods } from "constants/dates"
import { MyTariffHeaderColored } from "./styles"

export const getContentOptionIcon = (code: string) => {
  switch (code) {
    case EServiceContentType.Messengers:
      return <Icon.Messager />
    case EServiceContentType.Socials:
      return <Icon.Social />
    case EServiceContentType.Music:
      return <Icon.Music2 />
    case EServiceContentType.Video:
      return <Icon.Video />
    default:
      return <></>
  }
}

export const isNeedPaySoon = (
  fee: IFee | undefined,
  subscriptionFeeDate: string | undefined,
  balance: IBalance | undefined
): boolean => {
  if (!fee || !subscriptionFeeDate || !balance) {
    return false
  }
  const offset = new Date().getTimezoneOffset()
  const feeDate = Date.parse(subscriptionFeeDate) - offset * 60 * 1000
  return (
    balance.balanceValue < fee.discountedFee &&
    ((fee.periodType === EPeriodType.month &&
      feeDate - Date.now() <= 3 * 24 * 60 * 60 * 1000) ||
      (fee.periodType === EPeriodType.week &&
        feeDate - Date.now() <= 24 * 60 * 60 * 1000))
  )
}

export const isNeedPayConnectionFee = (options: IAdditionalOption[]) =>
  !!options.find((option) => option.contentType === EContentType.ConnectionFee)

const getFeeString = (fee: IFee) => {
  const feeValue = formatPrice(fee?.discountedFee)?.value
  return `${feeValue}/${Periods[fee?.periodType]}`
}

export const getTariffHeader = (tariffData: TExtendedData | undefined) => {
  if (!tariffData) {
    return <>{"Мой тариф"}</>
  } else if (tariffData.receiveStatus === EReceiveStatus.NoTariff) {
    return (
      <MyTariffHeaderColored color={getTheme().colors.text.warning}>
        {"Тарифный план не выбран"}
      </MyTariffHeaderColored>
    )
  }
  const { currentTariff, subscriptionFeeDate, balance } = tariffData
  const { fee } = currentTariff
  if (
    isNeedPayConnectionFee(tariffData.availableOptions.additionalOptions) &&
    ((fee.discountedFee > 0 &&
      fee.discountedFee >
        balance.balanceValue - tariffData.currentTariff.connectionFee) ||
      (balance.balanceValue > 0 &&
        balance.balanceValue < tariffData.currentTariff.connectionFee &&
        fee.discountedFee === 0))
  ) {
    sendMetric({
      ГлавныйЭкран: {
        СтатусТарифа: ["ТарифНеАктивирован"],
      },
    })
    return (
      <MyTariffHeaderColored color={getTheme().colors.text.critical}>
        {"Тариф не активирован"}
      </MyTariffHeaderColored>
    )
  }
  if (balance.needPay) {
    sendMetric({
      ГлавныйЭкран: {
        СтатусТарифа: ["ТарифНеОплачен"],
      },
    })
    return (
      <MyTariffHeaderColored color={getTheme().colors.text.critical}>
        {"Тариф не оплачен"}
      </MyTariffHeaderColored>
    )
  }
  const offset = new Date().getTimezoneOffset()
  const feeDate = Date.parse(subscriptionFeeDate) - offset * 60 * 1000
  if (isNeedPaySoon(fee, subscriptionFeeDate, balance)) {
    sendMetric({
      ГлавныйЭкран: {
        СтатусТарифа: ["СкороСписание"],
      },
    })
    return (
      <MyTariffHeaderColored>{`Списание по тарифу ${moment(feeDate).format(
        "DD.MM.YY"
      )}`}</MyTariffHeaderColored>
    )
  }
  sendMetric({
    ГлавныйЭкран: {
      СтатусТарифа: ["СвязьАктивна"],
    },
  })
  return <>{"Мой тариф"}</>
}

export const getTariffSubHeader = (tariffData: TExtendedData | undefined) => {
  if (!tariffData) {
    return ""
  } else if (tariffData.receiveStatus === EReceiveStatus.NoTariff) {
    return <Typography.Body2>Нет абонентской платы</Typography.Body2>
  }
  const { currentTariff, subscriptionFeeDate, balance } = tariffData
  const { fee } = currentTariff
  if (isNeedPayConnectionFee(tariffData.availableOptions.additionalOptions)) {
    if (balance.balanceValue === 0 && fee.discountedFee === 0) {
      return `Нужно оплатить ${
        tariffData.currentTariff.connectionFee / 100
      } ₽ за подключение. Пополните баланс.`
    }
    if (
      fee.discountedFee > 0 &&
      fee.discountedFee >
        balance.balanceValue - tariffData.currentTariff.connectionFee
    ) {
      return `${getFeeString(fee)}, + ${
        tariffData.currentTariff.connectionFee / 100
      } ₽ за подключение. Пополните баланс на ${Math.ceil(
        (tariffData.currentTariff.connectionFee - balance.balanceValue) / 100
      )} ₽`
    }
    if (
      balance.balanceValue > 0 &&
      balance.balanceValue < tariffData.currentTariff.connectionFee &&
      fee.discountedFee === 0
    ) {
      return `Нужно оплатить ${
        tariffData.currentTariff.connectionFee / 100
      } ₽ за подключение. Пополните баланс на ${Math.ceil(
        (tariffData.currentTariff.connectionFee - balance.balanceValue) / 100
      )} ₽`
    }
  }
  if (isNeedPaySoon(fee, subscriptionFeeDate, balance)) {
    return `${getFeeString(fee)}, пополните баланс на ${`${Math.ceil(
      (fee.discountedFee - balance.balanceValue) / 100
    )}`.replace(".", ",")} ₽`
  }
  return `${getFeeString(fee)} Дата продления ${moment(
    subscriptionFeeDate
  ).format("DD.MM.YY")}`
}

export const RadialChartColors: Partial<Record<EContentType, string>> = {
  [EContentType.Sms]: "#FF3977",
  [EContentType.Calls]: "#FF541A",
  [EContentType.Internet]: "#FF8000",
}

export const RadialChartIcons: Partial<Record<EContentType, ReactElement>> = {
  [EContentType.Sms]: <Icon.EmailRed />,
  [EContentType.Calls]: <Icon.PhoneOrange />,
  [EContentType.Internet]: <Icon.WebOrange />,
}

export const getCurrentValue = (option: IDefaultOption): string => {
  switch (option.contentType) {
    case EContentType.Internet:
      return `${
        option.currentValue >= 1024
          ? `${Math.round((option.currentValue * 10) / 1024) / 10}`.replace(
              ".",
              ","
            )
          : option.currentValue
      }`
    case EContentType.Sms:
    case EContentType.Calls:
      return `${option.currentValue}`
  }
  return ""
}

export const getTotalValue = (option: IDefaultOption): string => {
  switch (option.contentType) {
    case EContentType.Internet:
      return `из ${
        option.currentValue >= 1024
          ? `${Math.round((option.totalValue * 10) / 1024) / 10} ГБ`.replace(
              ".",
              ","
            )
          : `${option.totalValue} МБ`
      }`
    case EContentType.Sms:
      return `из ${option.totalValue} SMS`
    case EContentType.Calls:
      return `из ${option.totalValue} МИН`
  }
  return ""
}

const mapCarryOverResidues = (
  tariff: ICurrentTariff | undefined,
  contentType: EContentType
): IBonusOption | undefined => {
  switch (contentType) {
    case EContentType.Sms:
      return
    case EContentType.Calls:
      return tariff?.bonusOptions?.find(
        (el) =>
          el.contentType === EBonusOptionType.calls ||
          el.contentType === EBonusOptionType.callsAndInternet
      )
    case EContentType.Internet:
      return tariff?.bonusOptions?.find(
        (el) =>
          el.contentType === EBonusOptionType.internet ||
          el.contentType === EBonusOptionType.callsAndInternet
      )
  }
}

const mapIRadialChart = (
  option: IDefaultOption | undefined,
  contentType: EContentType,
  tariff: ICurrentTariff | undefined,
  needPay: boolean | undefined
): IRadialChart => ({
  color: RadialChartColors[contentType] ?? "",
  icon: RadialChartIcons[contentType] ?? <></>,
  percent:
    !needPay && option
      ? option.percentage > 100
        ? 100
        : option.percentage
      : 0,
  body: option ? getCurrentValue(option) : "",
  footer: option ? getTotalValue(option) : "",
  carryOverResidues: needPay
    ? undefined
    : mapCarryOverResidues(tariff, contentType),
  contentType,
})

export const mapPackagesData = (
  options: IDefaultOption[] | undefined,
  tariff: ICurrentTariff | undefined,
  needPay: boolean | undefined
) =>
  Object.values(EContentType).reduce((acc, el: EContentType) => {
    const smsPackage = options?.find(
      (option) => option.contentType === EContentType.WorryLessSms
    )
    const packageData = options?.find((option) => option.contentType === el)
    switch (el) {
      case EContentType.Calls:
        return packageData
          ? [
              ...acc,
              mapIRadialChart(packageData, EContentType.Calls, tariff, needPay),
            ]
          : [
              ...acc,
              {
                ...mapIRadialChart(
                  packageData,
                  EContentType.Calls,
                  tariff,
                  needPay
                ),
                percent: 0,
                body: tariff?.minutesQuantity?.price
                  ? `${tariff?.minutesQuantity.price / 100} ₽`.replace(".", ",")
                  : "--",
                footer: "МИН",
              } as IRadialChart,
            ]
      case EContentType.Sms:
        return packageData
          ? [
              ...acc,
              mapIRadialChart(
                {
                  ...packageData,
                  totalValue:
                    packageData.totalValue + (smsPackage?.totalValue ?? 0),
                  currentValue:
                    packageData.currentValue + (smsPackage?.currentValue ?? 0),
                  percentage: smsPackage?.currentValue
                    ? Math.ceil(
                        ((packageData.currentValue + smsPackage.currentValue) /
                          (packageData.totalValue + smsPackage.totalValue)) *
                          100
                      )
                    : packageData.percentage,
                },
                EContentType.Sms,
                tariff,
                needPay
              ),
            ]
          : [
              ...acc,
              {
                ...mapIRadialChart(
                  packageData,
                  EContentType.Sms,
                  tariff,
                  needPay
                ),
                percent: smsPackage?.percentage ?? 0,
                body:
                  smsPackage?.currentValue ||
                  (tariff?.smsQuantity?.price
                    ? `${tariff.smsQuantity.price / 100} ₽`.replace(".", ",")
                    : "--"),
                footer: "СМС",
              } as IRadialChart,
            ]
      case EContentType.Internet:
        return packageData
          ? [
              ...acc,
              mapIRadialChart(
                packageData,
                EContentType.Internet,
                tariff,
                needPay
              ),
            ]
          : [
              ...acc,
              {
                ...mapIRadialChart(
                  packageData,
                  EContentType.Internet,
                  tariff,
                  needPay
                ),
                percent:
                  !needPay &&
                  tariff?.mbQuantity?.type === EQuantityType.unlimited
                    ? 100
                    : 0,
                body:
                  tariff?.mbQuantity?.type === EQuantityType.unlimited
                    ? "∞"
                    : tariff?.mbQuantity?.price
                    ? `${tariff.mbQuantity.price / 100} ₽`
                    : "0",
                footer: "ГБ",
              } as IRadialChart,
            ]
    }
    return acc
  }, [] as IRadialChart[])
