import { memo, FC } from "react"
import { IOptionElement } from "entities/connectedAvailable"
import { ServicesCard } from "../ServicesCard"
import { Styled } from "./styles"

export interface IServicesCardCarousel {
  slides: IOptionElement[]
}

export const ServicesCardCarousel: FC<IServicesCardCarousel> = memo((props) => {
  const { slides } = props

  return slides.length > 0 ? (
    <Styled.Carousel
      onSwipeMove={(e) => {
        e.stopPropagation()
        return false
      }}
      onSwipeStart={(e) => e.stopPropagation()}
      onSwipeEnd={(e) => e.stopPropagation()}
      emulateTouch
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      centerMode
    >
      {slides.map((element, index) => (
        <ServicesCard
          key={index}
          title={element.title}
          subTitle={element.subTitle}
          contentType={element.contentType}
          price={element.monthlyFee}
          isActive={element.active}
          optionId={element.optionId}
        />
      ))}
    </Styled.Carousel>
  ) : (
    <></>
  )
})
