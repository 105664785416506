import spinner from "animations/spinner.json"
import { Typography } from "components/Typography"
import { PlayerStyled, WrapLoaderIconStyled } from "./style"

export const Loader = ({ title }: { title?: string }) => (
  <WrapLoaderIconStyled>
    <PlayerStyled autoplay loop src={spinner} />
    {title && <Typography.Body2>{title}</Typography.Body2>}
  </WrapLoaderIconStyled>
)
