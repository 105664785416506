import styled from "styled-components"

const Content = styled.div<{ padding?: string }>`
  padding: ${(props) => props.padding ?? "0 16px 32px 16px"};
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`

export const Styled = { Fields, Form, Content }
