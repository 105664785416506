import React, { FC, memo, useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  useGetGoskeyPreauthMutation,
  useDeleteGoskeyCacheMutation,
} from "store/api"
import { RootState } from "store"
import { Error } from "pages/Error"

export const SignatureNotSamePerson: FC = memo(() => {
  const { currentPhone, phone } = useSelector((state: RootState) => state.login)
  const title = "eSIM оформлена на другое лицо"
  const subtitle =
    "Войдите в Госуслуги под учётной записью того лица, на которое оформлена eSIM"
  const buttonText = "Понятно"
  const [triggerDeleteCache, result] = useDeleteGoskeyCacheMutation()
  const [triggerGoskeyPreauth] = useGetGoskeyPreauthMutation()

  useEffect(() => {
    if (result.isSuccess) {
      triggerGoskeyPreauth({
        additionalNumber: currentPhone !== phone ? currentPhone : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGoskeyPreauth, result.isSuccess])

  const onConfirmClick = useCallback(() => {
    triggerDeleteCache()
  }, [triggerDeleteCache])

  return (
    <Error
      title={title}
      subtitle={subtitle}
      buttonText={buttonText}
      onClick={onConfirmClick}
    />
  )
})
