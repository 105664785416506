import styled from "styled-components"
import { H1 } from "components/Typography/Headline"
import { Body1 } from "components/Typography/Body"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background[1]};
`

export const TitleStyled = styled(H1)`
  margin: 40px 32px 16px;
`

export const SubtitleStyled = styled(Body1)`
  margin: 0px 32px 24px 32px;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const InputWrapStyled = styled.div`
  user-select: initial;
  padding: 0px 16px;
  display: flex;
  border-radius: 8px;
`
