import styled from "styled-components"
import InputMask from "react-input-mask"
import { Player } from "@lottiefiles/react-lottie-player"
import { Icon } from "../Icons"
import { Typography } from "../Typography"

export const RootStyled = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
`

export const InputStyled = styled(InputMask)<{
  placeholderTop: boolean
  isLoading?: boolean
  hasError?: boolean
  hasClearIcon: boolean
  hasStartIcon: boolean
}>`
  font-family: SB Sans Interface, sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.hasStartIcon ? "52px" : "16px")};
  padding-right: ${(props) => (props.hasClearIcon ? "52px" : "16px")};
  padding-top: ${(props) => (props.placeholderTop ? "12px" : "0")};
  gap: 12px;
  height: 56px;
  left: 0;
  background: ${(props) => props.theme.colors.background[1]};
  border: ${(props) =>
    props.hasError
      ? `1px solid ${props.theme.colors.text.warning}`
      : `1px solid ${props.theme.colors.transparent.black[10]}`};
  border-color: ${(props) =>
    props.hasError
      ? `${props.theme.colors.text.warning}`
      : `${props.theme.colors.transparent.black[10]}`};
  border-radius: 8px;
  font-size: 16px;
  caret-color: ${(props) => props.theme.colors.text.warning};
  color: ${(props) => props.theme.colors.text.primary};

  :focus {
    padding-left: ${(props) => (props.hasStartIcon ? "51px" : "15px")};
    border: ${(props) =>
      props.hasError
        ? `2px solid ${props.theme.colors.text.warning}`
        : `2px solid ${props.theme.colors.text.primary}`};
  }

  :autofill {
    box-shadow: inset 0 0 0 50px ${(props) => props.theme.colors.background[1]};
  }

  :-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px
      ${(props) => props.theme.colors.background[1]};
    -webkit-text-fill-color: ${(props) => props.theme.colors.text.primary};
  }

  ::placeholder {
    font-family: SB Sans Interface, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.text.secondary};
    text-align: left;
  }
`

export const TopPlaceholder = styled.span<{ hasStartIcon: boolean }>`
  font-family: SB Sans Interface, sans-serif;
  font-weight: 400;
  position: absolute;
  font-size: 11px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.text.secondary};
  top: 9px;
  left: ${(props) => (props.hasStartIcon ? "52px" : "16px")};
`

export const DescriptionStyled = styled(Typography.Body2)<{
  hasError?: boolean
}>`
  margin-top: 4px;
  margin-left: 16px;
  color: ${(props) =>
    props.hasError
      ? props.theme.colors.text.warning
      : props.theme.colors.text.secondary};
`

export const DeleteCircleIconStyled = styled(Icon.DeleteCircle)`
  margin: 6px;
`

export const PlayerStyled = styled(Player)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
  height: 24px;
  width: 24px;
`

export const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 28px;
  padding: 0;
  transform: translate(0, -50%);
  background-size: contain;
`

export const StartIconWrapper = styled(StyledButton)<{ hasError?: boolean }>`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  left: 18px;
  color: orange;
  top: 28px;

  svg {
    color: ${(props) =>
      props.hasError
        ? props.theme.colors.text.warning
        : props.theme.colors.text.secondary};
  }
`

export const ClearIconWrapper = styled(StyledButton)`
  text-align: center;
  right: 18px;
  width: 23px;
  height: 23px;
`
