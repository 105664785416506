import { FC, ReactElement } from "react"
import { useTheme } from "styled-components"
import { dayTheme } from "theme/dayTheme"
import {
  Button,
  Container,
  Header,
  MPTypography,
  Spacer,
  Table,
  Typography,
  Wrapper,
} from "components"
import { BLANK } from "constants/common"

interface ISectionContent {
  id: number
  icon: ReactElement
  content: string
}

interface IFAQDetails {
  sectionContent: ISectionContent[]
  sectionTitle: string
  buttonText: string
  buttonLink: string
}

export const FAQDetails: FC<IFAQDetails> = (props) => {
  const { sectionContent, sectionTitle, buttonText, buttonLink } = props
  const onButtonClick = () => window.open(buttonLink, BLANK)
  const theme = useTheme()
  return (
    <Container backgroundColor={theme.colors.background.surface1} page>
      <Header background={theme.colors.background.surface1} />
      <Table.Section
        title={sectionTitle}
        titlePadding={{ paddingTop: 0 }}
        titleComponent={({ children }: any) => (
          <Typography.H2>{children}</Typography.H2>
        )}
        hasDivider={false}
      >
        {sectionContent.map((content) => (
          <Table.CommonCell
            backgroundColor={theme.colors.background.surface1}
            key={content.id}
            title={content.content}
            titleComponent={({ children }: any) => (
              <MPTypography startPadding={16}>{children}</MPTypography>
            )}
            startIcon={content.icon}
            startIconWidth="auto"
            titleSpacer={16}
          />
        ))}
      </Table.Section>
      <Spacer height={24} />
      <Wrapper>
        <Button.MPPrimary onClick={onButtonClick}>
          <Typography.Body2Bold
            color={dayTheme.colors.background.surface0}
            textTransform="uppercase"
          >
            {buttonText}
          </Typography.Body2Bold>
        </Button.MPPrimary>
      </Wrapper>
      <Spacer height={24} />
    </Container>
  )
}
