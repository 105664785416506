import React, { FC, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { useGetRoamingCountriesQuery } from "store/api"
import { ICountry } from "entities/roaming"
import { useActions } from "hooks/actions"
import { RootState } from "store"
import { useSelector } from "hooks/useSelector"
import {
  Container,
  Header,
  Icon,
  Table,
  Input,
  Loader,
  Spacer,
} from "components"
import { routes } from "constants/routes"
import { Countries } from "./Countries"
import { Field } from "./styles"

const ChooseCountry: FC = () => {
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { roamingCountry } = useSelector((state: RootState) => state.common)
  const theme = useTheme()
  const navigate = useNavigate()
  const { setRoamingCountry } = useActions()
  const { data, isLoading } = useGetRoamingCountriesQuery({
    phone: currentPhone,
  })
  const popularCountries = useMemo(
    () =>
      data?.data.countries
        .filter((country) => country.popular)
        .sort((a, b) => (a.rusName < b.rusName ? -1 : 1)),
    [data]
  )
  const otherCountries = useMemo(
    () =>
      data?.data.countries
        .filter((country) => !country.popular)
        .sort((a, b) => (a.rusName < b.rusName ? -1 : 1)),
    [data]
  )
  const [inputValue, setInputValue] = useState("")

  const onBackClick = () =>
    roamingCountry ? navigate(-1) : navigate(routes.main)
  const handleChange = (value: string) => {
    setInputValue(value)
  }

  const handleOnClick = (country: ICountry) => {
    setRoamingCountry(country)
    navigate(routes.roamingOptions)
  }

  const filteredCountries = useMemo(
    () =>
      (inputValue.length > 0
        ? data?.data.countries.filter(
            (country) =>
              country.rusName.search(new RegExp(inputValue, "i")) > -1
          )
        : []) ?? [],
    [data, inputValue]
  )

  return (
    <>
      {isLoading && <Loader />}
      <Container page backgroundColor={theme.colors.background[0]}>
        <Header
          title="Роуминг"
          background={theme.colors.background[0]}
          onBackClick={onBackClick}
        />
        <Field>
          <Input
            endIcon={inputValue.length < 2 ? undefined : <Icon.Cross />}
            value={inputValue}
            placeholder="Выберите страну"
            onChange={handleChange}
            endIconHandler={() => setInputValue("")}
          />
        </Field>
        <Spacer height={76} />
        {popularCountries && otherCountries && inputValue.length === 0 && (
          <Table.Body>
            <Countries
              title="Популярные"
              countries={popularCountries}
              onClick={handleOnClick}
            />
            <Countries
              title="Другие"
              countries={otherCountries}
              onClick={handleOnClick}
            />
          </Table.Body>
        )}
        {filteredCountries.length > 0 && inputValue.length > 0 && (
          <Table.Body>
            <Countries countries={filteredCountries} onClick={handleOnClick} />
          </Table.Body>
        )}
      </Container>
    </>
  )
}

export { ChooseCountry }
