import moment from "moment"
import {
  IAdditionalDataResponse,
  IAdditionalNumber,
} from "entities/additionalNumber"
import {
  IAdditionalOption,
  ITariffResponse,
  TExtendedData,
} from "entities/tariff"
import { isNeedPaySoon } from "widgets/MyTariffWidget/utils"
import { formatPhoneNumber } from "utils"
import { IModalState } from "store/slice/modalSlice"
import { sendMetric } from "utils/metrics"
import { EContentType } from "entities/common"
import { IOption } from "entities/connectedAvailable"
import { IBannersResponse } from "entities/banners"
import { ENumberTransferProcess } from "components/NumberTransferBanner/interfaces"
import { INumberTransferOrderStatus } from "entities/numberTransfer"
import { formatPrice } from "utils/price"
import { prepareOptions } from "pages/ServicesInternet/utils"
import { TItemData } from "pages/Services/ShowOptions/interfaces"
import { IPhoneNumber } from "components"

const packageServices = [
  EContentType.Internet,
  EContentType.Calls,
  EContentType.WorryLessSms,
  EContentType.CompSms,
  EContentType.Sms,
]

export const getPhonesFromData = (
  data: IAdditionalNumber[] | undefined,
  defaultValue: string
) =>
  (data ? [...data] : [])
    ?.sort((a, b) => a.order - b.order)
    ?.reduce((acc: string[], el) => [...acc, el.number], [defaultValue]) ?? [
    defaultValue,
  ]

export const getPhonesWithAdditionalData = (
  numbersData?: IAdditionalDataResponse
): IPhoneNumber[] | undefined => {
  if (!numbersData) {
    return
  }

  const { additionalNumbers, ...other } = numbersData
  return [
    {
      ...other,
      order: 0,
      id: numbersData.number,
      isPrimary: true,
    },
    ...additionalNumbers.map((phone: IAdditionalNumber) => ({
      ...phone,
      id: phone.number,
      isPrimary: false,
    })),
  ].sort((a, b) => Math.sign(a.order - b.order))
}

export const getNumberData = (
  data: IAdditionalDataResponse | undefined,
  phone: string,
  currentPhone: string
) => {
  if (!data) {
    return
  }

  if (phone === currentPhone) {
    const { additionalNumbers, ...numberData } = data
    return {
      isMyOwnNumber: true,
      ...numberData,
    }
  }
  return data.additionalNumbers.find((el) => el.number === currentPhone)
}

export const getCurrentExtendedData = (
  data: TExtendedData[] | undefined,
  phone: string
) => data && data.find((el) => el.number === phone)

export const getNeedPayNumberList = (data: TExtendedData[]): string[] => {
  return data.reduce(
    (acc: string[], el) =>
      isNeedPaySoon(el.currentTariff.fee, el.subscriptionFeeDate, el.balance)
        ? [...acc, el.number]
        : acc,
    []
  )
}

export const getDiscountedFee = (number: string, data?: TExtendedData[]) => {
  const numberData = getCurrentExtendedData(data, number)

  return numberData
    ? Math.ceil(numberData.currentTariff.fee.discountedFee / 100)
    : 0
}

export const getAmountNeedToPayTariff = (
  number: string,
  data?: TExtendedData[]
) => {
  const numberData = getCurrentExtendedData(data, number)

  return numberData
    ? Math.ceil(
        (numberData.currentTariff.fee.discountedFee -
          numberData.balance.balanceValue) /
          100
      )
    : 0
}

export const prepareAmountPay = (
  currentPhone: string,
  data?: ITariffResponse
) => {
  if (!data?.data?.extendedData) {
    return 0
  }
  if (getAmountNeedToPayTariff(currentPhone, data.data.extendedData) > 0) {
    return getAmountNeedToPayTariff(currentPhone, data.data.extendedData)
  } else {
    return getDiscountedFee(currentPhone, data.data.extendedData)
  }
}

export const getContentForNeedPayModal = (
  numbers: string[],
  data: TExtendedData[]
): IModalState => {
  const modalContent = {
    isOpen: true,
    title: "Скоро списание абонентской платы — пополните баланс",
    confirmLabel: "Пополнить",
    declineLabel: "Не сейчас",
    isPhoneNumber: true,
  }
  sendMetric({
    ГлавныйЭкран: { МодальноеОкно: { [modalContent.title]: "Показ" } },
  })
  moment.locale("ru")
  const offset = new Date().getTimezoneOffset()
  if (numbers.length === 1) {
    const numberData = getCurrentExtendedData(data, numbers[0])
    const date = numberData ? Date.parse(numberData.subscriptionFeeDate) : 0
    return numberData
      ? {
          ...modalContent,
          subtitle: `Дата списания ${moment(date - offset * 60 * 1000).format(
            "DD MMMM"
          )}`,
          content: `Сейчас на вашем номере  \n${formatPhoneNumber(
            numbers[0],
            true
          )} не хватает ${`${Math.ceil(
            (numberData.currentTariff.fee.discountedFee -
              numberData.balance.balanceValue) /
              100
          )}`.replace(".", ",")} ₽`,
        }
      : modalContent
  }
  return {
    ...modalContent,
    content: `Сейчас средств не хватает на номерах:
        ${numbers.reduce(
          (acc, n) => `${acc}  \n${formatPhoneNumber(n, true)}`,
          ""
        )}`,
  }
}

export const getPackageServices = (data?: IOption[]) =>
  data
    ? prepareOptions(data)?.filter((item) =>
        packageServices.includes((item as TItemData).contentType)
      )
    : undefined

export const getBannerServices = (data?: IOption[]) =>
  data
    ? prepareOptions(data)?.filter((item) =>
        [EContentType.Aura, EContentType.Ya].includes(
          (item as TItemData).contentType
        )
      )
    : undefined

export const getAvailableBanners = (
  currentPhone: string,
  bannerData?: IBannersResponse
) =>
  (
    bannerData?.data.bannersData.find((item) => item.number === currentPhone)
      ?.banners ?? []
  ).map(({ bannerType }) => bannerType)
export const hasNumberTransferBanner = (
  numberTransferData: INumberTransferOrderStatus,
  currentPhone: string
) =>
  currentPhone ===
  (ENumberTransferProcess.Complete === numberTransferData.statuses[0]?.status
    ? numberTransferData.statuses[0]?.number
    : numberTransferData.statuses[0]?.temporaryNumber)

export const calculateAuraFee = (
  additionalOptions?: IAdditionalOption[]
): string => {
  if (!additionalOptions) return "0"
  const aura = additionalOptions.find(
    ({ contentType }) => contentType === EContentType.Aura
  )

  return aura
    ? formatPrice(
        formatPrice(aura.connectionFee).rubles +
          formatPrice(aura.connectionFee).rubles
      ).rublesStr
    : "0"
}
