import styled from "styled-components"
import { Body1Bold } from "components/Typography/Body"

export const RootStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.background[0]};
  flex-direction: column;
  padding-bottom: 21px;
  ::after {
    content: "";
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 480px;
    height: 21px;
    background-color: ${(props) => props.theme.colors.background[0]};
  }
`

export const AddLabelStyled = styled(Body1Bold)`
  margin: 32px 24px 0px;
`
