import { ESpacerSize } from "entities/common"
import { SecureAccountStyled } from "processes/secureAccount/SecureAccount/styles"
import { MPTypography, Spacer } from "components"

export const TITLE = "Звонки на любимый номер"
export const Description = () => (
  <SecureAccountStyled.PaddingContainer>
    <Spacer height={ESpacerSize.M} />
    <MPTypography typographyType="Body1">
      Не расходуют пакет минут и доступны даже при блокировке номера.
    </MPTypography>
    <Spacer height={ESpacerSize.M} />
    <MPTypography typographyType="Body1">
      <MPTypography typographyType="Body1BoldSpan">
        Чтобы подключить услугу
      </MPTypography>
      , с номера ребёнка нужно набрать *777*, ввести любимый номер из 10 цифр,
      добавить # и нажать кнопку вызова.
    </MPTypography>
  </SecureAccountStyled.PaddingContainer>
)
