import React from "react"
import { EContentContainer, EContentType } from "entities/common"
import { Icon } from "components"
import SecureAccount from "./images/SecureAccount.png"

export const getOptionIcon = (code: string, arrayName?: string) => {
  if (arrayName === EContentContainer.Specials) {
    return <Icon.Percent />
  }
  switch (code) {
    case EContentType.Wifi:
      return <Icon.Web />
    case EContentType.Internet:
    case EContentType.RoamingMarker:
      return <Icon.Web />
    case EContentType.Calls:
      return <Icon.Phone />
    case EContentType.Aura:
      return <Icon.SecureAccountServicesAura />
    case EContentType.Messengers:
      return <Icon.Messager />
    case EContentType.Socials:
      return <Icon.Social />
    case EContentType.Music:
      return <Icon.Music2 />
    case EContentType.Video:
      return <Icon.Video />
    case EContentType.WorryLessSms:
      return <Icon.Email />
    case EContentType.AutorenewalBlock:
      return <Icon.AutorenewalBlock />
    case EContentType.Tethering:
      return <Icon.Tethering />
    case EContentType.Ya:
      return <Icon.SecureAccountServicesAssistant />
    case EContentType.SberSound:
      return <Icon.Zvooq />
    case EContentType.SecureAccount:
      return <img alt="Безопасность" src={SecureAccount} />
    default:
      return <></>
  }
}
