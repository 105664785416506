import { useEffect } from "react"

const useScripts = (url: string, callback: () => void) => {
  useEffect(() => {
    let script = document.querySelector(
      `script[src="${url}"]`
    ) as HTMLScriptElement

    if (!script) {
      script = document.createElement("script")
      script.id = "chat_script"
      script.type = "text/javascript"
      script.src = url
      document.body.appendChild(script)
      script.addEventListener("load", callback)
      script.addEventListener("error", callback)
    }

    script.addEventListener("load", callback)
    script.addEventListener("error", callback)

    return () => {
      script.removeEventListener("load", callback)
      script.removeEventListener("error", callback)
      script.remove()
    }
  }, [url, callback])
}

export { useScripts }
