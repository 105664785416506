import { ReactElement, FC } from "react"
import { IBannerData } from "entities/numberTransfer"
import { Table, PhoneNumber } from "components"
import { ENumberTransferTitle } from "./interfaces"
import { prepareBannerData } from "./utils"

export const NumberTransferBanner: FC<{ data: IBannerData }> = ({ data }) => {
  if (!data) {
    return null
  }

  const { number, status, temporaryNumber, transferDate } = data
  const {
    title,
    startIcon,
  }: { title: ENumberTransferTitle | ReactElement; startIcon: ReactElement } =
    prepareBannerData(status, transferDate)
  return (
    <Table.Section>
      <Table.CommonCell
        title={title}
        subTitle={
          <p>
            <PhoneNumber phone={number} /> вместо
            <br />
            <PhoneNumber phone={temporaryNumber} />
          </p>
        }
        startIcon={startIcon}
      />
    </Table.Section>
  )
}
