import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CloseIconPrimary } from "components/Sheet/styles"
import auraDemo from "audio/aura_demo_edit.mp3"
import { sendMetric } from "utils/metrics"
import { Table } from "components"
import {
  SubtitleStyled,
  ButtonsWrap,
  RootStyled,
  ButtonPlain,
  IconPlay,
  IconPause,
  AuraIcon,
  AuraRobotIcon,
  CarouselStyled,
  Title,
  PlainButtonChildren,
} from "./styles"

export const AuraDetails = () => {
  const firstLegendTitle =
    "Во время разговора предупредим, что звонок мошеннический"
  const firstLegendSubTitle =
    "Ваш собеседник не услышит нашей подсказки. Вы сами решите, продолжать разговор или прекратить общение"
  const secondLegendTitle = "Распознаем подозрительные фразы вашего собеседника"
  const secondLegendSubTitle =
    "Наш робот проанализировал более 50 000 диалогов и теперь легко распознает характерные фразы телефонных мошенников"

  const ref = useRef(new Audio(auraDemo))
  const navigate = useNavigate()
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlay = () => {
    if (!isPlaying) {
      sendMetric({
        Услуги: { Доступные: { Аура: ["Прослушать образец_Клик"] } },
      })
    }
  }

  useEffect(() => {
    ref.current.addEventListener("ended", () => setIsPlaying(false))
    const refCopy = ref
    return () => {
      refCopy.current.removeEventListener("ended", () => setIsPlaying(false))
    }
  }, [])

  const onPlayClick = () => {
    setIsPlaying((prev) => {
      if (!prev) {
        ref.current.play()
      } else {
        ref.current.pause()
      }
      return !prev
    })
  }

  const FirstLegend = () => (
    <div>
      <AuraIcon />
      <Title>{firstLegendTitle}</Title>
      <SubtitleStyled lineHeight={22}>{firstLegendSubTitle}</SubtitleStyled>
    </div>
  )

  const SecondLegend = () => (
    <div>
      <AuraRobotIcon />
      <Title>{secondLegendTitle}</Title>
      <SubtitleStyled lineHeight={22}>{secondLegendSubTitle}</SubtitleStyled>
    </div>
  )

  return (
    <RootStyled padding={16}>
      <Table.Body noPadding>
        <CloseIconPrimary
          onClick={() => {
            ref.current.pause()
            navigate(-1)
          }}
        />
      </Table.Body>
      <CarouselStyled
        emulateTouch
        showArrows={false}
        showStatus={false}
        showThumbs={false}
      >
        <FirstLegend />
        <SecondLegend />
      </CarouselStyled>
      <ButtonsWrap>
        <ButtonPlain onClick={onPlayClick}>
          <PlainButtonChildren onClick={handlePlay}>
            {!isPlaying ? <IconPlay /> : <IconPause />} Прослушать образец
          </PlainButtonChildren>
        </ButtonPlain>
      </ButtonsWrap>
    </RootStyled>
  )
}
