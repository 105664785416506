import { FC, ReactElement } from "react"

export interface ITabBarItem {
  title: string
  icon: ReactElement
  iconActive: ReactElement
  component: ReactElement
  path?: string
  alias?: string[]
  onClick?: () => void
  metric?: any
}

const TabBarItem: FC<ITabBarItem> = ({ component }) => component

export { TabBarItem }
