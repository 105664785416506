import { Icon } from "components/Icons"
import { prepareMonthValue } from "utils/date"
import { NoWrap } from "utils"
import { ENumberTransferProcess, ENumberTransferTitle } from "./interfaces"

const prepareNumberTransferDate = (date: Date) => {
  const formatTime = (time: number) => (time < 10 ? `0${time}` : time)
  const day = formatTime(date.getDate())
  // получаемы дни в отличие от месяцев соответствуют календарный, поэтому условие "< 10", для дней соответветствующих условию добавляем 0 в начале для форматирования
  const month = prepareMonthValue(date.getMonth())
  const year = date.getFullYear()
  const startTime = date.getHours()
  return `${day}.${month}.${year} c ${formatTime(startTime)}:00-${formatTime(
    startTime + 1
  )}:00`
}

export const prepareBannerData = (status: string, transferDate: Date) => {
  switch (status) {
    case ENumberTransferProcess.Progress:
      return {
        title: (
          <>
            <span>{ENumberTransferTitle.NumberTransfer}</span>
            <br />
            <NoWrap>{prepareNumberTransferDate(new Date(transferDate))}</NoWrap>
          </>
        ),
        startIcon: <Icon.NumberTransferProgress />,
      }
    case ENumberTransferProcess.Error:
      return {
        title: ENumberTransferTitle.TransferDeclined,
        startIcon: <Icon.NumberTransferDecline />,
      }
    case ENumberTransferProcess.Complete:
      return {
        title: ENumberTransferTitle.NumberTransfered,
        startIcon: <Icon.NumberTransferSuccess />,
      }
    default:
      return {
        title: ENumberTransferTitle.NumberTransfer,
        startIcon: <Icon.NumberTransferProgress />,
      }
  }
}
