import { FC, ReactElement, ReactNode } from "react"
import { Sheet } from "components"
import { Styled } from "./styles"

interface IServicesSheet {
  title: string
  subTitle?: string
  icon: ReactElement
  text?: string
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

export const ServicesSheet: FC<IServicesSheet> = (props) => {
  const { title, subTitle, icon, text, children, isOpen, onClose } = props

  return (
    <Sheet isOpen={isOpen} onClose={onClose}>
      <Styled.Container>
        <Styled.Title>{title}</Styled.Title>
        <Styled.IconContainer>{icon}</Styled.IconContainer>
        <Styled.SubTitle>{subTitle}</Styled.SubTitle>
        {text && <Styled.Text>{text}</Styled.Text>}
        {children}
      </Styled.Container>
    </Sheet>
  )
}
