import React, { FC, memo } from "react"
import { RootStyled, TitleStyled, ValueStyled } from "./styles"

interface IItemProps {
  title: string
  value: string
}

export const Item: FC<IItemProps> = memo(({ title, value }) => {
  return (
    <RootStyled>
      <TitleStyled>{title}</TitleStyled>
      <ValueStyled>{value}</ValueStyled>
    </RootStyled>
  )
})
