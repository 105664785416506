import styled from "styled-components"
import { Primary } from "components/Button/Primary"
import { Body1 } from "../../components/Typography/Body"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background[1]};
`

export const FooterStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 96px;
  max-width: 480px;
  bottom: 0px;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.background[1]};
`

export const ButtonPrimaryStyled = styled(Primary)`
  width: 100%;
  margin-bottom: 8px;
`

export const IndentStyled = styled.div`
  width: 100%;
  height: 96px;
  max-width: 480px;
`

export const ButtonStyled = styled.div`
  border: 1px solid #d2d6d9;
  border-radius: 16px;
  margin: 8px 16px 0px;
  cursor: pointer;
`

export const Body1Styled = styled(Body1)`
  margin: 24px 16px 8px;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 17px 18px 16px 25px;
`
