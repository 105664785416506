import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IBeautifulNumber } from "entities/beautifulNumbers"
import { ENumberTypes } from "pages/BeautifulNumbers/interfaces"

interface IBeautifulNumbers {
  data: IBeautifulNumber[]
  filteredData: IBeautifulNumber[]
  mask: string
  cachedMask: string
  newMsisdn: string
  replacedPhone: string
  isLoaded: boolean
  currentCategory: ENumberTypes
}

const initialState: IBeautifulNumbers = {
  data: [],
  filteredData: [],
  mask: "",
  newMsisdn: "",
  replacedPhone: "",
  isLoaded: false,
  cachedMask: "",
  currentCategory: ENumberTypes.Normal,
}

export const beautifulNumbersSlice = createSlice({
  name: "beautifulNumbers",
  initialState,
  reducers: {
    setBeautifulNumbersData: (
      state,
      { payload }: PayloadAction<IBeautifulNumber[]>
    ) => {
      state.data = payload
    },
    setBeautifulNumbersFilteredData: (
      state,
      { payload }: PayloadAction<IBeautifulNumber[]>
    ) => {
      state.filteredData = payload
    },
    clearBeautifulNumbersData: () => {
      return initialState
    },
    addBeautifulNumbersData: (
      state,
      { payload }: PayloadAction<IBeautifulNumber[]>
    ) => {
      state.data = payload.map((element) => {
        const phones = state.data?.find(
          (el) => el.msisdnType === element.msisdnType
        )?.msisdns
        return { ...element, msisdns: [...(phones ?? []), ...element.msisdns] }
      })
    },
    setMask: (state, { payload }: PayloadAction<string>) => {
      state.mask = payload
    },
    setCachedMask: (state, { payload }: PayloadAction<string>) => {
      state.cachedMask = payload
    },
    setNewMsisdn: (state, { payload }: PayloadAction<string>) => {
      state.newMsisdn = payload
    },
    setReplacedPhone: (state, { payload }: PayloadAction<string>) => {
      state.replacedPhone = payload
    },
    setIsLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoaded = payload
    },
    setCurrentCategory: (state, { payload }: PayloadAction<ENumberTypes>) => {
      state.currentCategory = payload
    },
  },
})

export const beautifulNumbersActions = beautifulNumbersSlice.actions

export default beautifulNumbersSlice.reducer
