import { IOption, IServiceData } from "entities/connectedAvailable"
import { EContentType } from "entities/common"
import { TItemData } from "../Services/ShowOptions/interfaces"

const internetOptions = [
  EContentType.Internet,
  EContentType.Tethering,
  EContentType.AutorenewalBlock,
]

const internetPackageOptions = [EContentType.Internet]

const callOptions = [EContentType.Calls]

const smsOptions = [
  EContentType.WorryLessSms,
  EContentType.CompSms,
  EContentType.Sms,
]

export const prepareOptions = (data?: IOption[]) => {
  if (!data) {
    return undefined
  }

  const servicesData = data.reduce((accumulator: TItemData, currentValue) => {
    return [...accumulator, currentValue.serviceData]
  }, [])

  return Object.values(
    servicesData.reduce(
      (accumulator: IServiceData, currentValue: IServiceData) => {
        return {
          contentOptions: accumulator.contentOptions.concat(
            currentValue.contentOptions
          ),
          options: accumulator.options.concat(currentValue.options),
          roamingOptions: accumulator.roamingOptions.concat(
            currentValue.roamingOptions
          ),
          specials: accumulator.specials.concat(currentValue.specials),
        }
      },
      {
        contentOptions: [],
        options: [],
        roamingOptions: [],
        specials: [],
      }
    )
  ).flat()
}

export const getInternetOptions = (data?: IOption[]) =>
  data
    ? prepareOptions(data)?.filter((item) =>
        internetOptions.includes((item as TItemData).contentType)
      )
    : undefined

export const getInternetPackageOptions = (data?: IOption[]) =>
  data
    ? prepareOptions(data)?.filter((item) =>
        internetPackageOptions.includes((item as TItemData).contentType)
      )
    : undefined

export const getCallOptions = (data?: IOption[]) =>
  data
    ? prepareOptions(data)?.filter((item) =>
        callOptions.includes((item as TItemData).contentType)
      )
    : undefined

export const getSmsOptions = (data?: IOption[]) =>
  data
    ? prepareOptions(data)?.filter((item) =>
        smsOptions.includes((item as TItemData).contentType)
      )
    : undefined
