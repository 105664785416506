import { FAQWithButton } from "pages"
import { getSearchObject } from "utils/search"
import { ANDROID, IOS } from "constants/common"
import {
  BUTTON_TEXT,
  sectionContent,
  SECTION_TITLE,
  SUBTITLE,
  TITLE,
} from "./constants"

export const TwoGisGeolocation = () => {
  const { theme: externalTheme, system, appName } = getSearchObject()
  const buttonLink = () => {
    switch ((system ?? "").toLowerCase()) {
      case IOS:
        return `${appName}://open_external?source=https://apps.apple.com/ru/app/id481627348`
      case ANDROID:
        return `${appName}://open_external?source=https://apk.2gis.ru/`
      // ссылка для PWA
      default:
        return ""
    }
  }

  return (
    <FAQWithButton
      buttonText={BUTTON_TEXT}
      buttonLink={buttonLink()}
      subtitle={SUBTITLE}
      externalTheme={externalTheme}
      title={TITLE}
      sectionContent={sectionContent}
      sectionTitle={SECTION_TITLE}
      system={system}
    />
  )
}
