import styled from "styled-components"
import { Body1Bold } from "components/Typography/Body"
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel"

const Carousel = styled(ResponsiveCarousel)`
  .slide {
    padding: 0 4px;
  }
  .slide:first-child {
    padding-left: 0;
  }
  .slide:last-child {
    padding-right: 0;
  }
`

const EnabledPackagesTitle = styled(Body1Bold)`
  margin-left: 24px;
`

const EnabledPackagesWrap = styled.div`
  gap: 10px;
  margin-top: 10px;
`

export const Styled = { Carousel, EnabledPackagesTitle, EnabledPackagesWrap }
