import React from "react"

const firstTelSymbols = ["7", "8", "9"]

export const calculateSpace = (string: string): number =>
  string.match(/[\D]/g)?.length ?? 0

export const formatInputValue = (rawValue: string) =>
  rawValue.replace(/\D/g, "")

export const formatPhoneNumber = (phone?: string): string => {
  if (!phone) {
    return ""
  }
  const match = (phone[0] === "7" ? phone.substring(1) : phone).match(
    /^(\d{3})(\d{3})(\d{2})(\d{2})$/
  )
  return match ? `+7 (${match[1]}) ${match[2]}-${match[3]}-${match[4]}` : ""
}

export const onPhoneInput = (
  e: React.ChangeEvent<HTMLInputElement>,
  inputValue: string
) => {
  let totalValue
  let selectionStart = e.target.selectionStart ?? 0

  const isCursorInTheMiddle = e.target.value.length !== selectionStart
  const isDeletingInTheMiddle =
    inputValue.length > e?.target.value.length && isCursorInTheMiddle
  let formattedValue = formatInputValue(e.target.value)

  const cursorMove = isDeletingInTheMiddle || isCursorInTheMiddle ? 0 : 1

  if (firstTelSymbols.indexOf(formattedValue[0]) !== -1) {
    if (formattedValue.length === 1 || formattedValue.length >= 10) {
      if (formattedValue[0] === "7") {
        selectionStart += cursorMove
      }
      if (formattedValue[0] === "9") {
        formattedValue = `7${formattedValue}`
        selectionStart += cursorMove + 1
      }
      if (formattedValue[0] === "8") {
        formattedValue = `7${formattedValue.substring(1)}`
        selectionStart += cursorMove
      }
    }

    totalValue = `+${formattedValue[0]} `
    selectionStart += cursorMove

    if (formattedValue.length > 1) {
      totalValue = `${totalValue}(${formattedValue.substring(1, 4)}`
      selectionStart += cursorMove
    }
    if (formattedValue.length > 4) {
      totalValue = `${totalValue}) ${formattedValue.substring(4, 7)}`
      selectionStart += 2 * cursorMove
    }
    if (formattedValue.length > 7) {
      totalValue = `${totalValue}-${formattedValue.substring(7, 9)}`
      selectionStart += cursorMove
    }
    if (formattedValue.length > 9) {
      totalValue = `${totalValue}-${formattedValue.substring(9, 11)}`
      selectionStart += cursorMove
    }

    const isSpaceWasAdded =
      calculateSpace(e.target.value) !== calculateSpace(totalValue)

    if (isSpaceWasAdded && isCursorInTheMiddle) {
      selectionStart += 1
    }
    if (isSpaceWasAdded && isDeletingInTheMiddle) {
      selectionStart += -1
    }
  } else if (formattedValue.length !== 0) {
    totalValue = `+${formattedValue.substring(0, 11)}`
    selectionStart += cursorMove
  } else {
    totalValue = formattedValue.substring(0, 11)
  }

  return [selectionStart, totalValue]
}
