import styled from "styled-components"

export const Root = styled.div<{ active?: boolean; onClick?: () => void }>`
  padding: 5.5px 12px;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 8px;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.badge.background
      : props.theme.colors.badge.backgroundSelected};
  p {
    color: ${(props) =>
      props.active
        ? props.theme.colors.badge.text
        : props.theme.colors.badge.textSelected};
  }
`
