import React, { FC, memo } from "react"
import { useSelector } from "react-redux"
import { useGetConnectedAvailableQuery } from "store/api"
import { IOptionElement } from "entities/connectedAvailable"
import { RootState } from "store"
import { EContentType } from "entities/common"
import { useScrollToTop } from "hooks/useScrollToTop"
import { Speedometer } from "pages/ServicesInternet/components/Speedometer"
import { Header, Spacer } from "components"
import { ShowOptions } from "../Services/ShowOptions"
import { getCallOptions } from "../ServicesInternet/utils"
import { RootStyled, AddLabelStyled } from "./styles"

export const ServicesCall: FC = memo(() => {
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data: connectedAvailable, isLoading: connectedAvailableIsLoading } =
    useGetConnectedAvailableQuery({
      phone: currentPhone,
    })
  useScrollToTop()
  if (connectedAvailableIsLoading) {
    return <></>
  }

  const availableOptions = getCallOptions(
    connectedAvailable?.data?.availableOptions
  ) as IOptionElement[]

  const enabledOptions = getCallOptions(
    connectedAvailable?.data?.enabledOptions
  ) as IOptionElement[]

  const isNotEmpty = (options: IOptionElement[]) => options?.length > 0

  return (
    <RootStyled>
      <Header title="Звонки" />
      <Spacer height={16} />
      <Speedometer speedometerType={EContentType.Calls} />
      {isNotEmpty(enabledOptions) && (
        <AddLabelStyled>Подключенные пакеты</AddLabelStyled>
      )}
      <ShowOptions
        options={[
          {
            sectionName: "",
            serviceData: {
              options: enabledOptions,
              roamingOptions: [],
              contentOptions: [],
              specials: [],
            },
          },
        ]}
        isRenderEmptyOptions={false}
      />
      {isNotEmpty(availableOptions) && (
        <AddLabelStyled>Добавить</AddLabelStyled>
      )}
      <ShowOptions
        options={[
          {
            sectionName: "",
            serviceData: {
              options: availableOptions,
              roamingOptions: [],
              contentOptions: [],
              specials: [],
            },
          },
        ]}
        isAvailableOptions
        isRenderEmptyOptions={!isNotEmpty(enabledOptions)}
      />
    </RootStyled>
  )
})
