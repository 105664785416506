import React, { FC, memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Reorder } from "framer-motion"
import { v4 as uuidv4 } from "uuid"
import { useGetUserConfigQuery } from "store/api"
import { normalizeUserConfig } from "entities/userConfig"
import { RootState } from "store"
import { useActions } from "hooks/actions"
import { sendMetric } from "utils/metrics"
import { useTheme } from "styled-components"
import { CellDivider } from "components/Table/Section"
import { prepareCurrentFeatureConfig } from "utils/userConfig"
import { Icon, Sheet, Table, Typography } from "components"
import { IPhoneNumber } from "../interfaces"
import { SortablePhone } from "../SortableItems/SortableItems"
import { endIcon, prepareSubtitle, prepareTitle, renderIcon } from "../utils"
import { routes } from "constants/routes"
import { Section } from "./styles"
import { ILinkNumbersProps } from "./interfaces"

export const LinkNumbers: FC<ILinkNumbersProps> = memo(
  ({
    onChange,
    onClose,
    draggablePhones,
    setDraggablePhones,
    setWasDropped,
  }) => {
    const [primaryPhone, ...additionalPhones] = draggablePhones
    const theme = useTheme()
    const navigate = useNavigate()
    const { setShowSmsCode, clearBeautifulNumbersData } = useActions()
    const { data: userConfigData } = useGetUserConfigQuery()
    const { currentPhone } = useSelector((state: RootState) => state.login)
    const userConfigNormalized = useMemo(() => {
      return normalizeUserConfig(userConfigData)
    }, [userConfigData])

    const currentFeatureConfig = useMemo(
      () =>
        prepareCurrentFeatureConfig(
          currentPhone,
          userConfigData?.data?.userConfigs
        ),
      [currentPhone, userConfigData?.data?.userConfigs]
    )

    const handlePhoneSelect = (phone: string) => {
      if (currentPhone !== phone) {
        clearBeautifulNumbersData()
      }
      sendMetric({
        ГлавныйЭкран: { ПривязанныеНомера: { ДополнительныйНомерКлик: [] } },
      })
      onChange?.(phone)
      onClose()
    }

    const handleAddPhone = () => {
      sendMetric({
        ГлавныйЭкран: { ПривязанныеНомера: { ДобавитьНомерКлик: [] } },
      })
      setShowSmsCode(false)
      navigate(`${routes.login}?additional=true`)
    }

    const onReorder = (phones: IPhoneNumber[]) => {
      setWasDropped(true)
      setDraggablePhones([primaryPhone, ...phones])
    }

    return (
      <Sheet.CommonContainer horizontalPadding={0}>
        <Sheet.CommonHeader>
          <Typography.H4>Привязанные номера</Typography.H4>
        </Sheet.CommonHeader>

        <Table.Body noPadding>
          {draggablePhones.length > 0 && (
            <Section>
              <Table.CommonCell
                title={prepareTitle(primaryPhone.alias, primaryPhone.number)}
                subTitle={prepareSubtitle(
                  primaryPhone.alias,
                  primaryPhone.number
                )}
                onClick={() => handlePhoneSelect(primaryPhone.number)}
                startIcon={renderIcon(
                  userConfigNormalized,
                  primaryPhone.number
                )}
                endIcon={endIcon(primaryPhone, currentPhone)}
                isPhoneNumber
              />
              <CellDivider />
              <Reorder.Group
                as="div"
                axis="y"
                onReorder={onReorder}
                values={additionalPhones}
              >
                {additionalPhones.map((phone: IPhoneNumber) => (
                  <SortablePhone
                    handlePhoneSelect={handlePhoneSelect}
                    phonesCount={additionalPhones.length}
                    key={phone.number}
                    phone={phone}
                  />
                ))}
              </Reorder.Group>
            </Section>
          )}
          {currentFeatureConfig?.canBindPhoneNumber && (
            <Table.CommonCell
              title="Добавить номер"
              key={uuidv4()}
              onClick={handleAddPhone}
              startIcon={<Icon.CirclePlus />}
              color={theme.colors.text.warning}
            />
          )}
        </Table.Body>
      </Sheet.CommonContainer>
    )
  }
)
