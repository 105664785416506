import styled from "styled-components"

export const MPPrimary = styled.button`
  background: linear-gradient(180deg, #ff9900 0%, #ff5c00 40.44%, #ff367e 100%);
  border-radius: 16px;
  font-family: "SBSansUI Regular", serif;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.button.contentPrimary};
  padding: 17px 0;
  font-weight: 700;
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
`
