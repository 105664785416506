import React from "react"
import { useSelector } from "react-redux"
import { Outlet, Navigate } from "react-router-dom"
import { RootState } from "store"

const PrivateRoute = () => {
  const { token } = useSelector((state: RootState) => state.login)

  return token ? <Outlet /> : <Navigate to="/login" />
}

export { PrivateRoute }
