import React, { FC } from "react"
import ContentLoader from "react-content-loader"
import {
  FooterStyled,
  FooterItemStyled,
  WrapRadialChartsStyled,
} from "widgets/MyTariffWidget/styles"
import { useTheme } from "styled-components"
import { Table } from "components"
import { CellContainer } from "./styles"

const SPEED = 1.2
const INTERVAL = 0.25

const TableBodySkeleton = () => {
  const theme = useTheme()
  return (
    <Table.Body>
      <Table.Section>
        <ContentLoader
          speed={SPEED}
          height={64}
          interval={INTERVAL}
          backgroundColor={theme.colors.stroke.color}
          foregroundColor={theme.colors.background[1]}
          width="100%"
        >
          <rect x="16" y="14" rx="24" ry="24" width="36" height="36" />
          <rect
            x="64"
            y="14"
            rx="18"
            ry="18"
            width="calc(100% - 80px)"
            height="36"
          />
        </ContentLoader>
      </Table.Section>
    </Table.Body>
  )
}

const RadialSkeleton = () => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={SPEED}
      interval={INTERVAL}
      height={86}
      width={96}
      backgroundColor={theme.colors.stroke.color}
      foregroundColor={theme.colors.background[1]}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 6C24.804 6 6 24.804 6 48C6 55.9689 8.21642 63.4109 12.0648 69.7536C12.9243 71.1701 12.4727 73.0151 11.0562 73.8746C9.63969 74.734 7.79465 74.2825 6.93519 72.866C2.53357 65.6115 0 57.097 0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 57.097 93.4664 65.6115 89.0648 72.866C88.2054 74.2825 86.3603 74.734 84.9438 73.8746C83.5273 73.0151 83.0757 71.1701 83.9352 69.7536C87.7836 63.4109 90 55.9689 90 48C90 24.804 71.196 6 48 6Z"
      />
      <rect
        x="calc(50% - 12px)"
        y="25"
        rx="21"
        ry="21"
        width="24"
        height="24"
      />
      <rect x="calc(50% - 27px)" y="70" rx="8" ry="8" width="54" height="16" />
    </ContentLoader>
  )
}

const ServiceSkeleton = () => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={SPEED}
      interval={INTERVAL}
      height={41}
      width={60}
      backgroundColor={theme.colors.stroke.color}
      foregroundColor={theme.colors.background[1]}
      fill="none"
    >
      <rect x="calc(50% - 12px)" y="0" rx="24" ry="24" width="24" height="24" />
      <rect x="calc(50% - 30px)" y="28" rx="6" ry="6" width="60" height="13" />
    </ContentLoader>
  )
}

const Skeleton: FC = () => {
  const theme = useTheme()
  return (
    <>
      <ContentLoader
        speed={SPEED}
        interval={INTERVAL}
        height={296}
        backgroundColor={theme.colors.transparent.black["10"]}
        foregroundColor={theme.colors.background[1]}
      >
        <rect x="12" y="12" rx="19" ry="19" width="197" height="38" />
        <rect
          x="12"
          y="60"
          rx="16"
          ry="16"
          width="calc(100% - 24px)"
          height="128"
        />
        <rect x="24" y="212" rx="9" ry="9" width="75" height="18" />
        <rect x="24" y="238" rx="16" ry="16" width="122" height="40" />
      </ContentLoader>
      <Table.Body>
        <Table.Section>
          <Table.Cell>
            <CellContainer>
              <ContentLoader
                speed={SPEED}
                interval={INTERVAL}
                height={36}
                backgroundColor={theme.colors.stroke.color}
                foregroundColor={theme.colors.background[1]}
                fill="none"
              >
                <rect x="0" y="0" rx="8" ry="8" width="159" height="16" />
                <rect
                  x="calc(100% - 16px)"
                  y="0"
                  rx="16"
                  ry="16"
                  width="16"
                  height="16"
                />
                <rect x="0" y="23" rx="6" ry="6" width="79" height="13" />
              </ContentLoader>
              <WrapRadialChartsStyled hasAllOptions>
                <RadialSkeleton />
                <RadialSkeleton />
                <RadialSkeleton />
              </WrapRadialChartsStyled>
              <FooterStyled>
                <FooterItemStyled>
                  <ServiceSkeleton />
                </FooterItemStyled>
                <FooterItemStyled>
                  <ServiceSkeleton />
                </FooterItemStyled>
                <FooterItemStyled>
                  <ServiceSkeleton />
                </FooterItemStyled>
                <FooterItemStyled>
                  <ServiceSkeleton />
                </FooterItemStyled>
              </FooterStyled>
            </CellContainer>
          </Table.Cell>
        </Table.Section>
      </Table.Body>
      <TableBodySkeleton />
      <TableBodySkeleton />
      <TableBodySkeleton />
    </>
  )
}

export { Skeleton }
