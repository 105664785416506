import React, { memo, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import { usePostBeautifulNumberReplaceMutation } from "store/api"
import { getSearchObject } from "utils/search"
import {
  ESendConfirmationType,
  EStatusCode,
  IResponseErrorData,
} from "entities/common"
import { useTheme } from "styled-components"
import { useActions } from "hooks/actions"
import {
  Container,
  Header,
  ServiceFailModal,
  SmsConfirmation,
} from "components"
import { routes } from "constants/routes"

export const BeautifulNumbersConfirmation = memo(() => {
  const [isOpen, setIsOpen] = useState(false)
  const { setError, openModal } = useActions()
  const navigate = useNavigate()
  const theme = useTheme()
  const [replaceNumberTrigger, { isSuccess, isLoading, error, reset }] =
    usePostBeautifulNumberReplaceMutation()
  const { replacedPhone, newMsisdn } = getSearchObject()
  const { phone } = useSelector((state: RootState) => state.login)

  const onSuccess = (code: string) => {
    if (replacedPhone !== phone) {
      replaceNumberTrigger({
        newMsisdn,
        additionalNumber: replacedPhone,
        smsCode: code,
      })
    } else {
      replaceNumberTrigger({
        newMsisdn,
        smsCode: code,
      })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      navigate(
        `${routes.beautifulNumbersChangeStatus}?replacedPhone=${replacedPhone}&newMsisdn=${newMsisdn}`
      )
    }

    if (error && "data" in error) {
      const errorData = error.data as IResponseErrorData
      if (typeof errorData === "string") {
        return setIsOpen(true)
      }

      switch (errorData?.statusInfo.statusCode) {
        case EStatusCode.BadCredentials:
          setError("Неверный код")
          reset()
          break
        case EStatusCode.PasswordExpired:
          openModal({
            isOpen: true,
            title: "Информация",
            subtitle: errorData?.statusInfo.statusMessage,
            declineLabel: "Понятно",
          })
          return navigate(-1)
        default:
          navigate(`${routes.beautifulNumbersChangeStatus}?isNotSuccess=true`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isSuccess, navigate, reset])

  return (
    <>
      <ServiceFailModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Container page>
        <Header title="Подтверждение" background={theme.colors.background[1]} />
        <SmsConfirmation
          number={replacedPhone}
          onSuccess={onSuccess}
          type={ESendConfirmationType.ReplaceMsisdn}
          isLoading={isLoading}
        />
      </Container>
    </>
  )
})
