import { routes } from "constants/routes"

export const TITLE = "Безопасность"

export const SUBTITLE = [
  "Набор услуг для надёжной защиты",
  "от спамеров и мошенников",
]
export const INTERTNAL_SERVICES = [
  "История звонков",
  "Возврат денег",
  "Сообщить о спам звонке",
]

export const questions: {
  title: string
  route: string
}[] = [
  {
    title: "Сколько стоит услуга Хранитель?",
    route: `${routes.pwaGuardianPrice}`,
  },
  {
    title: "Как работает услуга Хранитель?",
    route: `${routes.pwaHowItWorks}`,
  },
  {
    title: "Что делать, если меня обманут мошенники?",
    route: `${routes.pwaIfIWasDeceived}`,
  },
  {
    title:
      "Можно ли вернуть деньги, если мошенники обманут во время звонка по мессенджеру?",
    route: `${routes.pwaMessengerCalls}`,
  },
  {
    title: "Условия возврата денег, если мошенники всё же обманут меня",
    route: `${routes.pwaRequiredSecureConditions}`,
  },
  {
    title: "Какую сумму мне вернут, если меня обманут мошенники?",
    route: `${routes.pwaAmountToRefunded}`,
  },
  {
    title: "Как работает Аура",
    route: `${routes.pwaHowAuraWork}`,
  },
  {
    title: "Как работает голосовой помощник Ассистент?",
    route: `${routes.pwaVoiceAssistantWork}`,
  },
  {
    title: "Сколько дней хранятся записи звонков?",
    route: `${routes.pwaCallRecordingStorage}`,
  },
]
