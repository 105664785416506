import { useNavigate } from "react-router-dom"
import { MPTypography, Spacer } from "components"
import { FAQCommon } from "../../faqs/FAQCommon"
import { Styled } from "./styles"
import {
  DESCRIPTION,
  fullConditions,
  insuranceRulesLink,
  list,
  MONEY_REFUND_CONDITIONS,
  REFUND_CASES,
  TITLE,
} from "./constants"

const Footer = () => {
  const navigate = useNavigate()

  return (
    <Styled.PaddingContainer>
      <Spacer height={16} />

      <MPTypography typographyType="Body1Bold">
        {MONEY_REFUND_CONDITIONS}
      </MPTypography>
      <Spacer height={24} />
      <MPTypography typographyType="Body1">{REFUND_CASES}</MPTypography>
      <Spacer height={24} />

      <Styled.ListStyled>
        {list.map((element, index) => (
          <>
            {index !== 0 && <Spacer height={24} />}
            <Styled.MarkerStyled key={index}>
              <MPTypography typographyType="Body1">{element}</MPTypography>
            </Styled.MarkerStyled>
          </>
        ))}
      </Styled.ListStyled>
      <Spacer height={32} />
      <MPTypography typographyType="Body2">
        <>
          {fullConditions[0]}
          {" "}
          {
            <Styled.UnderlineSpan onClick={() => navigate(insuranceRulesLink)}>
              {fullConditions[1]}
            </Styled.UnderlineSpan>
          }
        </>
      </MPTypography>
      <Spacer height={32} />
    </Styled.PaddingContainer>
  )
}

export const PWAAmountToRefunded = () => {
  return (
    <FAQCommon title={TITLE} description={DESCRIPTION} children={<Footer />} />
  )
}
