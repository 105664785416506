import { Body } from "./Body"
import { Section } from "./Section"
import { Cell } from "./Cell"
import { CommonCell } from "./CommonCell"
import { CellTwoColumn } from "./cells/CellTwoColumn"
import { CellDynamicColumns } from "./cells/CellDynamicColumns"

export const Table = {
  Body,
  Section,
  Cell,
  CommonCell,
  CellTwoColumn,
  CellDynamicColumns,
}
