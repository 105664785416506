import styled, { css } from "styled-components"
import { Typography } from "components"

const flexCss = css`
  display: flex;
  flex-direction: column;
`

export const OperationStatusStyles = {
  ModalText: styled(Typography.Body1)`
    text-align: center;
    padding: 0 16px;
  `,
  WrapImage: styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    min-width: 233px;
  `,
  Root: styled.div`
    ${flexCss}
    padding-top: 26px;
    width: 100%;
    height: 100vh;
    max-width: 480px;
    gap: 8px;
    background: ${(props) => props.theme.colors.background[1]};
  `,
  MainContent: styled.div`
    ${flexCss}
    gap: 24px;
  `,
  TextContent: styled.div`
    ${flexCss}
    gap: 8px;
  `,
  H4: styled(Typography.H4)`
    text-align: center;
  `,
  Subtitle: styled(Typography.Body1)`
    text-align: center;
    color: ${(props) => props.theme.colors.text.secondary};
    margin: 0 24px;
  `,
  Footer: styled.div`
    position: fixed;
    width: 100%;
    height: 96px;
    bottom: 0px;
    padding: 8px 16px;
    max-width: 480px;
  `,
}
