import styled from "styled-components"

export const Bordered = styled.button<{ fullWidth?: boolean }>`
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.transparent.black[10]};
  font-family: "SBSansUI Regular", serif;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.text.secondary};
  padding: 17px 16px;
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  text-align: center;
  display: flex;
  gap: 17px;
  align-items: center;
  cursor: pointer;
`
