import styled from "styled-components"
import { Icon, Typography } from "components"

const Element = styled.div`
  background-color: transparent;
`

const TitleContainer = styled.div`
  padding: 16px 16px 8px;
`

const Container = styled.div`
  display: flex;
  padding: 16px 16px 0;
  width: 100%;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
`

const IconContainer = styled.div`
  position: relative;
  display: flex;
  flex: 0;
  min-width: 52px;

  svg {
    position: absolute;
    top: -4px;
    left: 0;
    width: 36px;
    height: 36px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  padding-bottom: 16px;
  min-width: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.divider};
`
const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 4px;
`

const ContentHeaderPhone = styled(Typography.Body1)<{
  color: string
}>`
  font-size: 17px;
  line-height: 22px;
  color: ${(props) => props.color};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const ContentHeaderTime = styled(Typography.Body1)`
  font-size: 13px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.text.secondary};
  min-width: 40px;
`

const CallType = styled.div``

const VoiceMessage = styled.div<{ isNewMessage: boolean }>`
  position: relative;
  padding: 3px 8px 3px 28px;
  ${(props) => props.isNewMessage && `padding-right: 18px}`}
  border-radius: 8px 8px 8px 0;
  margin-top: 8px;
  background-color: ${(props) => props.theme.colors.divider};
`

const VoiceMessageText = styled.div`
  & > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`
const IconRecordingCall = styled(Icon.RecordingCall)`
  position: absolute;
  top: 2px;
  left: 4px;
`

const ListenMarker = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.text.critical};
`

export const Styled = {
  Element,
  TitleContainer,
  Container,
  Content,
  IconContainer,
  ContentContainer,
  ContentHeader,
  ContentHeaderPhone,
  ContentHeaderTime,
  CallType,
  VoiceMessage,
  VoiceMessageText,
  IconRecordingCall,
  ListenMarker,
}
