import React, {
  FC,
  KeyboardEvent,
  memo,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react"
import ReactInputMask from "react-input-mask"
import spinner from "animations/spinner.json"
import { Icon } from "components"
import {
  DescriptionStyled,
  InputStyled,
  RootStyled,
  TopPlaceholder,
  ClearIconWrapper,
  StartIconWrapper,
  PlayerStyled,
} from "./styles"

interface IProps {
  placeholder?: string
  description?: string
  value?: string
  onChange?(value: string): void
  mask?: string
  maskChar?: string
  maskPlaceholder?: string
  hasError?: boolean
  onBlur?(value?: string): void
  isLoading?: boolean
  type?: string
  startIcon?: ReactElement
  hasClearIcon?: boolean
  autoFocus?: boolean
  maxLength?: number
  endIconHandler?(): void
  endIcon?: ReactElement
  clearIconHandler?(): void
  autoComplete?: string
  onFocus?: () => void
  readOnly?: boolean
  onKeyUp?: (e: KeyboardEvent) => void
}

export const Input: FC<IProps> = memo(
  ({
    placeholder,
    onChange,
    value,
    mask = "",
    maskChar,
    maskPlaceholder,
    description,
    hasError,
    onBlur,
    isLoading,
    type,
    startIcon,
    hasClearIcon = false,
    autoFocus = false,
    maxLength,
    endIconHandler,
    endIcon,
    clearIconHandler,
    autoComplete,
    onFocus,
    readOnly,
    onKeyUp,
  }) => {
    const hasStartIcon = Boolean(startIcon)
    const hasEndIcon = !!endIcon
    const [innerValue, setInnerValue] = useState(value || "")
    const [wasRequestSent, setWasRequestSent] = useState(false)
    const inputRef = useRef() as MutableRefObject<ReactInputMask>
    const isPlaceholderTop = innerValue.trim().length !== 0

    const onInnerBlur = () => {
      if (onBlur) {
        onBlur(innerValue)
      }
    }

    const onChangeInner = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInnerValue(e.target.value)
      if (onChange && e.target.value !== innerValue) {
        onChange(e.target.value)
      }
    }

    const onEndIconClick = () => {
      endIconHandler?.()
    }

    const onClearIconClick = () => {
      clearIconHandler?.()
      return setInnerValue("")
    }

    useEffect(() => {
      if (isLoading) {
        setWasRequestSent(true)
      }

      if ((!isLoading && wasRequestSent) || autoFocus) {
        const ref = inputRef as unknown as MutableRefObject<HTMLInputElement>
        ref.current?.focus()
      }
    }, [isLoading, wasRequestSent, autoFocus])

    return (
      <RootStyled>
        {isLoading && <PlayerStyled autoplay loop src={spinner} />}
        {startIcon && (
          <StartIconWrapper hasError={hasError}>{startIcon}</StartIconWrapper>
        )}
        {isPlaceholderTop && (
          <TopPlaceholder hasStartIcon={hasStartIcon}>
            {placeholder}
          </TopPlaceholder>
        )}
        <InputStyled
          ref={inputRef}
          placeholder={placeholder}
          placeholderTop={isPlaceholderTop}
          value={value === undefined ? innerValue : value}
          onBlur={onInnerBlur}
          onChange={onChangeInner}
          mask={mask}
          maskChar={maskChar}
          maskPlaceholder={maskPlaceholder}
          isLoading={isLoading}
          type={type}
          hasError={hasError}
          hasClearIcon={hasClearIcon}
          hasStartIcon={hasStartIcon}
          disabled={isLoading}
          maxLength={maxLength}
          autoComplete={autoComplete}
          onFocus={onFocus}
          readOnly={readOnly}
          onKeyUp={onKeyUp}
        />
        {hasClearIcon && (
          <ClearIconWrapper onClick={onClearIconClick}>
            <Icon.ClearIcon />
          </ClearIconWrapper>
        )}

        {hasEndIcon && (
          <ClearIconWrapper onClick={onEndIconClick}>
            {endIcon}
          </ClearIconWrapper>
        )}

        <DescriptionStyled hasError={hasError}>{description}</DescriptionStyled>
      </RootStyled>
    )
  }
)
