import { memo, FC } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { EBannerType } from "entities/banners"
import { Banner } from "components"
import { Styled } from "./styles"
import { defaultBanner } from "./constants"
import { IBannerGroupProps } from "./interfaces"

export const BannerGroup: FC<IBannerGroupProps> = memo(
  ({ bannerServices, featureConfig, currentBunners, auraPrice }) => {
    const bannerState = useSelector((state: RootState) => state.banner)
    const banners = defaultBanner.filter(
      ({ type, featureFlag }) =>
        bannerState[type] &&
        featureConfig?.[featureFlag] &&
        (type === EBannerType.Roaming || currentBunners?.includes(type))
    )
    const centerSlidePercentage = banners.length === 1 ? 100 : 96

    return (
      <>
        <Styled.Carousel
          onSwipeMove={(e) => {
            e.stopPropagation()
            return false
          }}
          onSwipeStart={(e) => e.stopPropagation()}
          onSwipeEnd={(e) => e.stopPropagation()}
          emulateTouch
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          centerMode
          centerSlidePercentage={centerSlidePercentage}
        >
          {banners.map(
            ({ title, subtitle, confirmationText, gradient, type, image }) => (
              <Banner
                title={title}
                subtitle={subtitle}
                confirmationText={confirmationText}
                gradient={gradient}
                type={type}
                image={image}
                serviceData={bannerServices?.find(
                  ({ contentType }) => contentType === type
                )}
                auraPrice={auraPrice}
              />
            )
          )}
        </Styled.Carousel>
      </>
    )
  }
)
