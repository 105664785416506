import React, { FC, useEffect, useState, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import { v4 as uuidv4 } from "uuid"
import {
  IContentOption,
  IOptionElement,
  IRoaming,
  ISpecial,
  IOption,
} from "entities/connectedAvailable"
import { EContentContainer, EContentType } from "entities/common"
import { ServiceStatus } from "components/ServiceStatus"
import { EOptionAvailability } from "entities/tariff"
import { useTheme } from "styled-components"
import { useGetUserConfigQuery } from "store/api"
import { prepareCurrentFeatureConfig } from "utils/userConfig"
import { SecuritySheet } from "processes"
import { Icon, Sheet, Table, Typography } from "components"
import { Option } from "../Option"
import { Empty } from "../Empty"
import { EmptyAvailableOptionsWrap, PlainButton } from "../styles"
import { urls } from "constants/urls"
import { routes } from "constants/routes"
import { IShowOptions, TItemData } from "./interfaces"

export const ShowOptions: FC<IShowOptions> = ({
  // setServiceStatusContent передается только для подключенный пакетов на главно экране
  setServiceStatusContent,
  options,
  isAvailableOptions = false,
  isRenderEmptyOptions = true,
  padding = 16,
  onHomePage,
}) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSecuritySheet, setIsOpenSecuritySheet] = useState(false)
  const [data, setData] = useState(null)
  const [isContentOption, setIsContentOption] = useState(false)
  const theme = useTheme()
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { optionStatusTitle } = useSelector((state: RootState) => state.common)
  const { isOpen: isModalOpen } = useSelector((state: RootState) => state.modal)

  const { data: userConfigData } = useGetUserConfigQuery()
  const currentFeatureConfig = useMemo(
    () =>
      prepareCurrentFeatureConfig(
        currentPhone,
        userConfigData?.data?.userConfigs
      ),
    [currentPhone, userConfigData?.data?.userConfigs]
  )

  const onServiceClick = (item: TItemData, arrayName: EContentContainer) => {
    /* при рендере компонента внутри map необходимо выносить всплывающее окно в родительский компонент,
     * передавать setServiceStatusContent и за счет сеттера передавать данные */
    if (EContentType.SecureAccount === item.contentType) {
      return navigate(
        `${routes.secureAccountGuardian}?fromUrl=showOptions&optionId=${item.optionId}`
      )
    }
    if (!!setServiceStatusContent) {
      setServiceStatusContent({
        data: item,
        isAvailableOptions,
        isContentOption: arrayName === EContentContainer.ContentOptions,
      })
    } else {
      setData(item)
      if ([EContentType.Ya, EContentType.Aura].includes(item.contentType)) {
        setData(item)
        setIsOpenSecuritySheet(true)
      } else {
        setData(item)
        setIsContentOption(arrayName === EContentContainer.ContentOptions)
        setIsOpen(true)
      }
    }
  }

  const renderServiceData = (
    list: Array<ISpecial | IContentOption | IRoaming | IOptionElement>,
    arrayName: EContentContainer,
    isAvailableService?: boolean
  ) => {
    return list.map((item) => {
      if (
        item.contentType === EContentType.Aura &&
        !currentFeatureConfig?.isAuraEnabled
      ) {
        return <></>
      }

      return (
        <div key={uuidv4()} onClick={() => onServiceClick(item, arrayName)}>
          <Option
            data={item}
            arrayName={arrayName}
            isAvailableService={isAvailableService ?? false}
          />
        </div>
      )
    })
  }

  const checkIsEmpty = (option?: IOption[]) => {
    let res = true
    const items = option || []
    items.forEach((item) => {
      const serviceData = item?.serviceData
      if (
        serviceData?.options?.filter(
          (optionItem) => optionItem.contentType !== EContentType.Wifi
        ).length > 0 ||
        serviceData.contentOptions.length > 0 ||
        serviceData.specials.length > 0
      ) {
        res = false
      }
    })
    return res
  }

  const renderEmptyOptions = () =>
    isAvailableOptions ? (
      <EmptyAvailableOptionsWrap>
        <Typography.Body1 color={theme.colors.text.secondary} lineHeight={22}>
          Доступных услуг пока нет,
          <br />
          но они скоро появятся
        </Typography.Body1>
      </EmptyAvailableOptionsWrap>
    ) : (
      <Empty />
    )

  useEffect(() => {
    if (isModalOpen) {
      setIsOpen(false)
    }
  }, [isModalOpen])
  //   isActive,
  // isOpenSheet,
  // setIsOpenSheet,
  // sendData,

  return (
    <div>
      <SecuritySheet
        data={data}
        isOpenSheet={isOpenSecuritySheet}
        setIsOpenSheet={setIsOpenSecuritySheet}
      />
      <Sheet
        isOpen={isOpen && !setServiceStatusContent}
        onClose={() => setIsOpen(false)}
      >
        <ServiceStatus
          onClose={() => setIsOpen(false)}
          data={data}
          isAvailableOptions={isAvailableOptions}
          isContentOption={isContentOption}
        />
      </Sheet>
      {checkIsEmpty(options) && isRenderEmptyOptions && renderEmptyOptions()}
      {!checkIsEmpty(options) && (
        <>
          {!isAvailableOptions && optionStatusTitle && !onHomePage && (
            <Table.Body padding={padding}>
              <Table.Section backgroundColor={"rgba(16, 21, 37, 0.05)"}>
                <Table.CommonCell
                  titleInfo={optionStatusTitle}
                  startIcon={<Icon.BlockedBig />}
                  footer={
                    <PlainButton
                      onClick={() =>
                        window.open(`${urls.pay}&phone=${currentPhone}`)
                      }
                    >
                      Пополнить баланс
                    </PlainButton>
                  }
                />
              </Table.Section>
            </Table.Body>
          )}
          {options && (
            <Table.Body padding={padding}>
              {options
                .filter(
                  (item) =>
                    !(
                      item?.serviceData?.options?.length === 1 &&
                      item?.serviceData?.options?.[0]?.contentType ===
                        EContentType.Wifi &&
                      item?.serviceData?.contentOptions?.length === 0 &&
                      item?.serviceData?.specials?.length === 0
                    )
                )
                .map(
                  (item) =>
                    !!(
                      item.serviceData.contentOptions.filter(
                        (i) =>
                          i.availability !== EOptionAvailability.Unavailable
                      ).length ||
                      item.serviceData.options.length ||
                      item.serviceData.specials.length
                    ) && (
                      <Table.Section
                        key={uuidv4()}
                        title={item.sectionName}
                        spacedView
                      >
                        {renderServiceData(
                          item.serviceData.contentOptions,
                          EContentContainer.ContentOptions,
                          isAvailableOptions
                        )}
                        {renderServiceData(
                          item.serviceData.options,
                          EContentContainer.Options,
                          isAvailableOptions
                        )}
                        {renderServiceData(
                          item.serviceData.specials,
                          EContentContainer.Specials,
                          isAvailableOptions
                        )}
                        {renderServiceData(
                          item.serviceData.roamingOptions,
                          EContentContainer.RoamingOptions,
                          isAvailableOptions
                        )}
                      </Table.Section>
                    )
                )}
            </Table.Body>
          )}
        </>
      )}
    </div>
  )
}
