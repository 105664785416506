import styled from "styled-components"
import { Icon, Typography } from "components"

const InputWrap = styled.div`
  user-select: initial;
  padding: 13px 16px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  border-radius: 8px;
`

const Description = styled.div`
  margin-top: 8px;
  margin-left: 16px;
`

const DescriptionLink = styled(Typography.Body2Link)`
  cursor: pointer;
  text-decoration: underline;
  margin: 0 3px;
  color: ${(props) => props.theme.colors.text.secondary};
`

const InfoIcon = styled(Icon.InfoIcon)`
  cursor: pointer;
`

const MNPCondition = styled(Typography.Body1)`
  padding-left: 8px;
`

const List = styled.ol`
  padding-left: 20px;
`

export const NumberTransferEntryStyled = {
  InputWrap,
  Description,
  DescriptionLink,
  InfoIcon,
  MNPCondition,
  List,
}
