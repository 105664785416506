import React, { FC } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { modalActions } from "store/slice/modalSlice"
import { Icon, Table } from "components"
import { IBannersProps } from "../interfaces"
import { routes } from "constants/routes"

export const Signed: FC<IBannersProps> = ({
  contractUrl,
  isCommonCeil = false,
  isShowPage = true,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onClickContract = () => {
    if (contractUrl) {
      navigate(routes.showExternalLink, {
        state: {
          link: contractUrl,
          pathname: window.location.pathname,
        },
      })
    } else {
      dispatch(
        modalActions.openModal({
          isOpen: true,
          title: "Не удалось загрузить договор",
          subtitle: "Попробуйте обратиться в поддержку",
          declineLabel: "Закрыть",
        })
      )
    }
  }

  const renderCommonCeil = () => (
    <Table.CommonCell
      title="Договор об оказании услуг связи"
      startIcon={<Icon.Pdf />}
      endIcon={<Icon.ArrowRight />}
      onClick={onClickContract}
    />
  )

  return isShowPage ? (
    <>
      {isCommonCeil ? (
        renderCommonCeil()
      ) : (
        <Table.Body>
          <Table.Section>{renderCommonCeil()}</Table.Section>
        </Table.Body>
      )}
    </>
  ) : (
    <></>
  )
}
