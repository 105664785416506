import { FC } from "react"
import { useSetTheme } from "hooks/useSetTheme"
import { EThemeType } from "theme/interfaces"
import { useTheme } from "styled-components"
import {
  Badge,
  Container,
  MPTypography,
  Spacer,
  Table,
  Typography,
} from "components"
import { FAQStyled } from "./styles"

interface IFAQProps {
  footerInfo: string
  requiredConditions: {
    id: number
    title: string
    buttonText?: string
    route?: string
  }[]
  requiredConditionsTitle: string
  externalTheme: EThemeType
}
export const FAQ: FC<IFAQProps> = ({
  footerInfo,
  requiredConditions,
  requiredConditionsTitle,
  externalTheme,
}) => {
  useSetTheme(externalTheme)
  const theme = useTheme()
  return (
    <Container backgroundColor={theme.colors.background.surface1} page>
      <Spacer height={40} />
      <Table.Section
        title={requiredConditionsTitle}
        titleComponent={({ children }: any) => (
          <Typography.H2>{children}</Typography.H2>
        )}
        hasDivider={false}
        titlePadding={{ paddingTop: 32 }}
        cellContainerMargin={{ marginTop: 32, marginBottom: 24 }}
      >
        {requiredConditions.map((condition) => (
          <Table.CommonCell
            key={condition.id}
            backgroundColor={theme.colors.background.surface1}
            title={condition.title}
            titleComponent={({ children }: any) => (
              <MPTypography startPadding={16}>{children}</MPTypography>
            )}
            titleSpacer={12}
            textGap={12}
            startIcon={
              <FAQStyled.IconWrapper hasBackground>
                <Typography.Body1Bold>{condition.id}</Typography.Body1Bold>
              </FAQStyled.IconWrapper>
            }
            subTitle={
              condition?.buttonText && (
                <Badge text={condition.buttonText} active={false} />
              )
            }
            subtitleHandler={() =>
              (window.location.href = condition?.route ?? "")
            }
          />
        ))}
      </Table.Section>
      <Typography.Body2 padding={16}>{footerInfo}</Typography.Body2>
    </Container>
  )
}
