import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { Icon, Table } from "components"
import { IBannersProps } from "../interfaces"
import { routes } from "constants/routes"

export const NotSigned: FC<IBannersProps> = ({ isCommonCeil = false }) => {
  const navigate = useNavigate()

  const onSignClick = () => {
    navigate(routes.signature)
  }

  const renderCommonCeil = () => (
    <Table.CommonCell
      title="Договор об оказании услуг связи"
      subTitle="Нужно подписать договор"
      startIcon={<Icon.AgreementNotSigned />}
      endIcon={<Icon.ArrowRight />}
      onClick={onSignClick}
    />
  )

  return (
    <>
      {isCommonCeil ? (
        renderCommonCeil()
      ) : (
        <Table.Body>
          <Table.Section>{renderCommonCeil()}</Table.Section>
        </Table.Body>
      )}
    </>
  )
}
