import React, { Children, FC, ReactElement } from "react"
import { Divider } from "components/Divider"
import styled, { useTheme } from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { Typography } from "../Typography"

const Title = styled.div<{
  titlePadding?: { paddingTop?: number; paddingBottom?: number }
}>`
  padding: ${(props) => props.titlePadding?.paddingTop ?? 16}px 16px
    ${(props) => props.titlePadding?.paddingBottom ?? 0}px 16px;
  position: relative;
  svg {
    position: absolute;
    right: 16px;
    top: 4px;
  }
`
const Container = styled.div<{ generalBackground?: string }>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.generalBackground && `background: ${props.generalBackground}`}
`
const CellContainer = styled.div<{
  spacedView?: boolean
  generalBackground?: string
  cellContainerMargin?: { marginTop?: number; marginBottom?: number }
  backgroundColor: string
}>`
  margin: ${(props) => props.cellContainerMargin?.marginTop ?? 8}px 0
    ${(props) =>
      props.cellContainerMargin?.marginBottom &&
      `${props.cellContainerMargin?.marginBottom}px`};
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.spacedView
      ? "transparent"
      : props.generalBackground ?? props.backgroundColor};
  border-radius: 16px;
  gap: ${(props) => (props.spacedView ? "8px" : "0")};
`

export const CellDivider = styled(Divider)`
  margin: 0 16px;
  width: auto;
`
const CellWrapper = styled.div<{ spacedView?: boolean }>`
  background-color: ${(props) =>
    !props.spacedView ? "transparent" : props.theme.colors.background[1]};
  border-radius: 16px;
`
interface ISection {
  title?: string
  children: ReactElement[] | ReactElement | React.ReactNode | any
  spacedView?: boolean
  backgroundColor?: string
  endIcon?: ReactElement
  titleComponent?: any
  onClick?: () => void
  generalBackground?: string
  hasDivider?: boolean
  isDividerUnderline?: boolean
  titlePadding?: { paddingTop?: number; paddingBottom?: number }
  cellContainerMargin?: { marginTop?: number; marginBottom?: number }
}
const Section: FC<ISection> = ({
  title,
  children,
  spacedView,
  backgroundColor,
  endIcon,
  titleComponent: CustomTitle,
  onClick,
  generalBackground,
  hasDivider = true,
  titlePadding,
  cellContainerMargin,
  isDividerUnderline = false,
}) => {
  const theme = useTheme()
  return (
    <Container generalBackground={generalBackground}>
      {title && (
        <Title titlePadding={titlePadding} onClick={onClick}>
          {CustomTitle ? (
            <CustomTitle>{title}</CustomTitle>
          ) : (
            <Typography.H6>{title}</Typography.H6>
          )}
          {endIcon}
        </Title>
      )}
      <CellContainer
        spacedView={spacedView}
        backgroundColor={backgroundColor ?? theme.colors.background[1]}
        generalBackground={generalBackground}
        cellContainerMargin={cellContainerMargin}
      >
        {Children.toArray(children).map((cell, i) =>
          !spacedView ? (
            <React.Fragment key={uuidv4()}>
              {i > 0 && hasDivider && !isDividerUnderline ? (
                <CellDivider />
              ) : null}
              {cell}
              {hasDivider && isDividerUnderline ? <CellDivider /> : null}
            </React.Fragment>
          ) : (
            <CellWrapper spacedView={spacedView} key={uuidv4()}>
              {cell}
            </CellWrapper>
          )
        )}
      </CellContainer>
    </Container>
  )
}

export { Section }
