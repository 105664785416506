import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router"
import { formatPhoneNumber } from "utils"
import { formatPrice } from "utils/price"
import { usePostBeautifulNumberReleaseMutation } from "store/api"
import { RootState } from "store"
import { useActions } from "hooks/actions"
import { Button, Header, Spacer, Typography } from "components"
import { routes } from "constants/routes"
import {
  CurrentNumber,
  Footer,
  FooterSubtitle,
  HeaderWrap,
  TableBody,
  Title,
} from "./styles"

export const ChangeNumber = () => {
  const [releaseNumber] = usePostBeautifulNumberReleaseMutation()
  const { phone } = useSelector((state: RootState) => state.login)
  const {
    state: { currentNumber, newNumber, price },
  } = useLocation()
  const navigate = useNavigate()
  const { setIsLoaded } = useActions()

  const onClickContinue = () => {
    navigate(
      `${routes.beautifulNumbersConfirmation}?replacedPhone=${currentNumber}&newMsisdn=${newNumber}`
    )
  }

  const onBackClick = () => {
    if (currentNumber !== phone) {
      releaseNumber({ msisdn: newNumber, additionalNumber: currentNumber })
    } else {
      releaseNumber({ msisdn: newNumber })
    }
    setIsLoaded(true)
    navigate(-1)
  }

  return (
    <TableBody padding={16}>
      <div>
        <HeaderWrap>
          <Header onBackClick={onBackClick} title="Смена номера" />
        </HeaderWrap>
        <Spacer height={90} />
        <Title textAlign="center">ВАШ НОВЫЙ НОМЕР</Title>
        <Spacer height={9} />
        <Typography.H1>{formatPhoneNumber(newNumber)}</Typography.H1>
        <Spacer height={20} />
        <Typography.Body1>
          После смены номера старый номер
          <CurrentNumber> {formatPhoneNumber(currentNumber)} </CurrentNumber>
          станет недоступен
        </Typography.Body1>
        <Spacer height={16} />
        <Typography.Body1>
          Не забудьте отключить настроенные на него автоплатежи и
          скорректировать ваши контактные данные в других сервисах
        </Typography.Body1>
      </div>
      <Footer>
        <Typography.H1>{formatPrice(price).value}</Typography.H1>
        <FooterSubtitle textAlign="center">
          При переходе с вашего баланса спишется указанная сумма
        </FooterSubtitle>
        <Button.Primary onClick={onClickContinue}>Продолжить</Button.Primary>
      </Footer>
    </TableBody>
  )
}
