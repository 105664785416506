import React, { FC } from "react"
import { Player } from "@lottiefiles/react-lottie-player"
import exit from "animations/exit.json"
import { Sheet } from "components"
import {
  Body1Styled,
  ButtonPrimaryStyled,
  ButtonSecondaryStyled,
  H4Styled,
  SheetContainer,
  WrapImageStyled,
} from "./styles"

interface IConfirmLogoutProps {
  isOpen: boolean
  onClose(): void
  onConfirm(): void
}

export const ConfirmLogout: FC<IConfirmLogoutProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Sheet isOpen={isOpen} onClose={onClose}>
      <SheetContainer>
        <WrapImageStyled>
          <Player autoplay loop src={exit} />
        </WrapImageStyled>
        <H4Styled>Выйти из приложения?</H4Styled>
        <Body1Styled>
          При следующем входе нужно будет авторизоваться заново
        </Body1Styled>
        <ButtonPrimaryStyled onClick={onClose}>Отмена</ButtonPrimaryStyled>
        <ButtonSecondaryStyled onClick={onConfirm}>
          Да, выйти
        </ButtonSecondaryStyled>
      </SheetContainer>
    </Sheet>
  )
}
