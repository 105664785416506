import React, { FC, memo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { useGetConnectedAvailableQuery } from "store/api"
import { IOptionElement } from "entities/connectedAvailable"
import { EContentType } from "entities/common"
import { useScrollToTop } from "hooks/useScrollToTop"
import { Speedometer } from "pages/ServicesInternet/components/Speedometer"
import { RootStyled } from "pages/ServicesCall/styles"
import { Header, Spacer } from "components"
import { ShowOptions } from "../Services/ShowOptions"
import { getSmsOptions } from "../ServicesInternet/utils"
import { AddLabelStyled } from "./styles"

export const ServicesSms: FC = memo(() => {
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data: connectedAvailable, isLoading: connectedAvailableIsLoading } =
    useGetConnectedAvailableQuery({
      phone: currentPhone,
    })
  useScrollToTop()
  if (connectedAvailableIsLoading) {
    return <></>
  }

  const availableOptions = getSmsOptions(
    connectedAvailable?.data?.availableOptions
  ) as IOptionElement[]

  const enabledOptions = getSmsOptions(
    connectedAvailable?.data?.enabledOptions
  ) as IOptionElement[]

  const isNotEmpty = (options: IOptionElement[]) => options?.length > 0

  return (
    <RootStyled>
      <Header title="СМС" />
      <Spacer height={16} />
      {/* На данный момент для СМС не показываем дату окончания действия тарифа, чтобы не путать пользователя, так как добавили в спидометр отображение покате СМС без забот. */}
      <Speedometer
        speedometerType={EContentType.Sms}
        showExpirationDate={false}
      />
      {isNotEmpty(enabledOptions) && (
        <AddLabelStyled>Подключенные пакеты</AddLabelStyled>
      )}
      <ShowOptions
        options={[
          {
            sectionName: "",
            serviceData: {
              options: enabledOptions,
              roamingOptions: [],
              contentOptions: [],
              specials: [],
            },
          },
        ]}
        isRenderEmptyOptions={false}
      />
      {isNotEmpty(availableOptions) && (
        <AddLabelStyled>Добавить</AddLabelStyled>
      )}
      <ShowOptions
        options={[
          {
            sectionName: "",
            serviceData: {
              options: availableOptions,
              roamingOptions: [],
              contentOptions: [],
              specials: [],
            },
          },
        ]}
        isAvailableOptions
        isRenderEmptyOptions={!isNotEmpty(enabledOptions)}
      />
    </RootStyled>
  )
})
