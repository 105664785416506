import styled, { ThemedStyledProps } from "styled-components"
import { ITheme } from "theme/interfaces"

type TContainerStyles = {
  page?: boolean
  backgroundColor?: string
  clearInnerPadding?: boolean
  position?: string
}

const getBackgroundColor = (
  props: ThemedStyledProps<TContainerStyles, ITheme>
) => {
  if (props.backgroundColor) {
    return props.backgroundColor
  }
  if (props.page) {
    return props.theme.colors.background[1]
  }
  return "transparent"
}

export const Container = styled.div<TContainerStyles>`
  ${(props) => props.position && `position: ${props.position};`}
  min-height: ${(props) => (props.page ? "100vh" : "100%")};
  width: 100%;
  display: flex;
  padding: ${(props) => (props.page ? 0 : 16)}px;
  flex-direction: column;
  justify-content: ${(props) => (props.page ? "flex-start" : "center")};
  background-color: ${(props) => getBackgroundColor(props)};
  & > p,
  & > div {
    ${(props) =>
      !props.page &&
      `
      ${!props.clearInnerPadding && "padding: 0 8px;"}
      width: 100%;
    `}
  }
  overflow-x: hidden;
`
