import React, { memo, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  ESendConfirmationType,
  EStatusCode,
  IModalState,
  IResponseErrorData,
} from "entities/common"
import { useTheme } from "styled-components"
import {
  usePostSendEmailConfirmationMutation,
  usePostUpdateEmailMutation,
} from "store/api"
import { useGetLinksQuery } from "store/api"
import { ELinksCode } from "entities/common"
import { useActions } from "hooks/actions"
import { formSelector, loginSelector, useSelector } from "hooks/useSelector"
import { Plain } from "components/Button/Plain"
import { CommonModal } from "components/CommonModal"
import { sendMetric } from "utils/metrics"
import {
  Container,
  Header,
  Spacer,
  Typography,
  Input,
  Sheet,
  Table,
  Icon,
} from "components"
import { routes } from "constants/routes"
import { BLANK } from "constants/common"
import { EmailAjustmentConfirmationStyled } from "./styles"
import { SMS_CODE_LENGTH, SMS_TIMEOUT } from "./constants"

export const EmailAjustmentConfirmation = memo(() => {
  const [isOpen, setOpen] = useState(false)
  const [modalState, setModalState] = useState<IModalState>({
    isOpen: false,
    title: "",
  })
  const [statusCode, setStatusCode] = useState("")
  const { error, selectedPhone, phone, smsCode } = useSelector(loginSelector)
  const { setError } = useActions()
  const navigate = useNavigate()
  const [trigger, { error: errorConfirmationStatus }] =
    usePostSendEmailConfirmationMutation()
  const [
    triggerUpdateEmail,
    { isSuccess, isLoading, error: updateEmailError, reset: updateEmailReset },
  ] = usePostUpdateEmailMutation()
  const { values } = useSelector(formSelector)
  const theme = useTheme()

  const email = values?.email as unknown as string

  useEffect(() => {
    if (modalState.isOpen) {
      return
    }

    if (isSuccess) {
      sendMetric({ "Корректировка email": { "Ввод email-кода": ["Успех"] } })
      navigate(routes.emailAdjustmentStatus, {
        state: {
          isSuccess: true,
          statusMessage: "",
        },
      })
    }

    if (updateEmailError && "data" in updateEmailError) {
      const errorData = updateEmailError.data as IResponseErrorData
      const updateEmailStatusCode = errorData?.statusInfo.statusCode

      switch (updateEmailStatusCode) {
        case EStatusCode.BadCredentials:
          sendMetric({
            "Корректировка email": {
              "Ввод email-кода": {
                Ошибка: [errorData?.statusInfo.statusMessage],
              },
            },
          })
          setError("Неверный код")
          updateEmailReset()
          break

        case EStatusCode.OperationTimeExpired:
          sendMetric({
            "Корректировка email": {
              "Ввод email-кода": {
                Ошибка: [errorData?.statusInfo.statusMessage],
              },
            },
          })
          setStatusCode(updateEmailStatusCode)
          setModalState({
            title: "Время сессии истекло.",
            subtitle: "Попробуйте внести изменения снова",
            isOpen: true,
          })
          break

        default:
          navigate(routes.emailAdjustmentStatus, {
            state: {
              isSuccess: false,
              statusMessage: errorData?.statusInfo.statusMessage,
            },
          })
      }
    }

    if (errorConfirmationStatus && "data" in errorConfirmationStatus) {
      const errorData = errorConfirmationStatus.data as IResponseErrorData
      const emailConfirmationStatusCode = errorData?.statusInfo.statusCode
      if (
        emailConfirmationStatusCode === EStatusCode.EmailServiceNotAvailable
      ) {
        setStatusCode(EStatusCode.EmailServiceNotAvailable)
        setModalState({
          title: "Сервис временно недоступен",
          declineLabel: "Назад",
          isOpen: true,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errorConfirmationStatus,
    isSuccess,
    modalState.isOpen,
    navigate,
    updateEmailError,
    updateEmailReset,
  ])

  useEffect(() => {
    trigger({
      type: ESendConfirmationType.UpdateEmailProfile,
      email,
    })
  }, [email, trigger])

  const isMainNumber = phone === selectedPhone

  const onSuccess = (code: string) => {
    triggerUpdateEmail({
      additionalNumber: isMainNumber ? undefined : selectedPhone,
      emailCode: code,
      email,
      smsCode,
    })
  }

  const { data: links } = useGetLinksQuery()
  const [timeLeft, setTimeLeft] = useState(150)
  useEffect(() => {
    setTimeout(
      function run(value, setValue) {
        if (value > 0) {
          setTimeout(run, 1000)
          setValue(timeLeft - 1)
        }
      },
      1000,
      timeLeft,
      setTimeLeft
    )
  }, [timeLeft])

  const onCodeChange = (code: string) => {
    if (error) {
      setError("")
    }
    if (code?.length === SMS_CODE_LENGTH) {
      onSuccess(code)
    }
  }

  const onCodeRefresh = () => {
    trigger({
      type: ESendConfirmationType.UpdateEmailProfile,
      email,
    })
    setTimeLeft(SMS_TIMEOUT)
  }

  const onClickRules = () => {
    if (links && links[ELinksCode.Rules]) {
      window.open(links[ELinksCode.Rules], BLANK)?.focus()
    }
  }

  const declineHandler = () => {
    if (statusCode === EStatusCode.EmailServiceNotAvailable) {
      navigate(routes.emailAdjustment)
    }
    if (statusCode === EStatusCode.OperationTimeExpired) {
      navigate(routes.phone, {
        state: {
          phone: selectedPhone,
          isMainNumber,
        },
      })
    }
  }

  const getServiceRules = () => (
    <>
      <CommonModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false, title: "" })}
        title={modalState.title}
        subtitle={modalState?.subtitle}
        declineLabel={modalState?.declineLabel}
        preventCloseOnOutsideClick
        declineHandler={declineHandler}
      />
      <EmailAjustmentConfirmationStyled.Footnote>
        Вводя код из email, вы соглашаетесь
      </EmailAjustmentConfirmationStyled.Footnote>
      <EmailAjustmentConfirmationStyled.FootnoteLink onClick={onClickRules}>
        с Правилами сервиса
      </EmailAjustmentConfirmationStyled.FootnoteLink>
    </>
  )

  return (
    <>
      <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
        <Sheet.CommonContainer horizontalPadding={0}>
          <Sheet.CommonHeader>
            <Typography.H4>Если не пришло письмо</Typography.H4>
          </Sheet.CommonHeader>
          <Table.Body noPadding>
            <Table.Section>
              <Table.CommonCell
                title="Проверьте, что правильно ввели email"
                startIcon={<Icon.Email />}
              />
              <Table.CommonCell
                title="Проверьте папку «Спам». Отправитель письма — noreply@sberbank&#8209;tele.com"
                startIcon={<Icon.Info />}
              />
            </Table.Section>
          </Table.Body>
          <Plain onClick={() => setOpen(false)}>Понятно</Plain>
        </Sheet.CommonContainer>
      </Sheet>
      <Container page>
        <Header title="Подтверждение" background={theme.colors.background[1]} />
        <EmailAjustmentConfirmationStyled.Wrapper>
          <EmailAjustmentConfirmationStyled.H1>
            Введите код
          </EmailAjustmentConfirmationStyled.H1>

          <EmailAjustmentConfirmationStyled.SubtitleWrap>
            <Typography.Body1 color={theme.colors.text.secondary}>
              Отправили код
            </Typography.Body1>
            <Spacer height={6} />
            <Typography.Body1>{`на ${email}`}</Typography.Body1>
            <Spacer height={28} />
          </EmailAjustmentConfirmationStyled.SubtitleWrap>

          <EmailAjustmentConfirmationStyled.WrapInput>
            <Input
              autoFocus
              placeholder="Код из email"
              mask="999999"
              maskChar=""
              maskPlaceholder=""
              type="tel"
              onChange={onCodeChange}
              description={error}
              hasError={error?.length > 0}
              isLoading={isLoading}
            />
          </EmailAjustmentConfirmationStyled.WrapInput>
          <EmailAjustmentConfirmationStyled.ServiceRulesWrap>
            {getServiceRules()}
          </EmailAjustmentConfirmationStyled.ServiceRulesWrap>
          <EmailAjustmentConfirmationStyled.Footer>
            <EmailAjustmentConfirmationStyled.Link
              onClick={() => {
                sendMetric({
                  "Корректировка email": {
                    "Ввод email-кода": ["Не пришло письмо_Клик"],
                  },
                })
                setOpen(true)
              }}
            >
              Не пришло письмо
            </EmailAjustmentConfirmationStyled.Link>
            <Spacer height={16} />
            {timeLeft > 0 && (
              <EmailAjustmentConfirmationStyled.Body2>
                До повторной отправки кода осталось {timeLeft} сек
              </EmailAjustmentConfirmationStyled.Body2>
            )}
            {!isLoading && timeLeft <= 0 && (
              <EmailAjustmentConfirmationStyled.Link onClick={onCodeRefresh}>
                Повторить отправку кода
              </EmailAjustmentConfirmationStyled.Link>
            )}
          </EmailAjustmentConfirmationStyled.Footer>
        </EmailAjustmentConfirmationStyled.Wrapper>
      </Container>
    </>
  )
})
