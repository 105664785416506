import React, { FC, memo } from "react"
import { Player } from "@lottiefiles/react-lottie-player"
import empty from "animations/empty.json"
import { Body1Styled, RootStyled, WrapImageStyled } from "./styles"

export const Empty: FC = memo(() => {
  return (
    <RootStyled>
      <WrapImageStyled>
        <Player autoplay loop src={empty} />
      </WrapImageStyled>
      <Body1Styled>У вас нет подключённых услуг</Body1Styled>
    </RootStyled>
  )
})
