import React, { FC } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { useScripts } from "hooks/useScripts"
import { Header, Container, PhoneNumber } from "components"
import { CHAT_CONFIG, CHAT_SRC } from "constants/chatConfig"
import "./styles.css"
import { routes } from "constants/routes"
import { Chat } from "./styles"

const SupportChat: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const {
    state: { phone, isMain, backToMainScreen },
  } = useLocation()

  useScripts(CHAT_SRC, CHAT_CONFIG(!isMain ? phone : undefined))

  return (
    <Container page backgroundColor={theme.colors.background[0]}>
      <Header
        onBackClick={() =>
          backToMainScreen ? navigate(routes.main) : navigate(-1)
        }
        title="Поддержка"
        subtitle={PhoneNumber.format(phone)}
        background={theme.colors.background[0]}
      />
      <Chat id="chat-app" />
    </Container>
  )
}

export { SupportChat }
