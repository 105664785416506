import { RootState } from "./index"

// Если версия store несовместима с предыдущей, то нужно подвинуть счетчик в STORAGE_VERSION
export const STORAGE_VERSION = "1"

export const saveToLocalStorage = (state: Partial<RootState>) => {
  try {
    const serialisedState = JSON.stringify(state)
    localStorage.setItem("persistentState", serialisedState)
  } catch (e) {
    console.warn(e)
  }
}

export const loadFromLocalStorage = () => {
  try {
    const serialisedState = localStorage.getItem("persistentState")
    if (serialisedState === null) return undefined
    if (localStorage.getItem("storageVersion") !== STORAGE_VERSION) {
      localStorage.setItem("storageVersion", STORAGE_VERSION)
      saveToLocalStorage({})
      return undefined
    }
    return JSON.parse(serialisedState)
  } catch (e) {
    console.warn(e)
    return undefined
  }
}
