import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import {
  useGetAdditionalNumberQuery,
  useGetTariffQuery,
  useLazyGetBeautifulNumbersQuery,
} from "store/api"
import { useTheme } from "styled-components"
import { useActions } from "hooks/actions"
import { sendMetric } from "utils/metrics"
import { prepareSelectorNumbers } from "utils/phone"
import {
  getCurrentExtendedData,
  getPhonesFromData,
  getPhonesWithAdditionalData,
} from "pages/Home/utils"
import { Header, Icon, Input, Tabs, TabsItem, DisplayPhones } from "components"
import { HeaderWrap, TableBody } from "./styles"
import { EMsisdnType, ENumberTypes } from "./interfaces"
import { NumbersList } from "./NumbersList/NumbersList"
import { prepareAvailableCurrentPhone } from "./utils"

export const BeautifulNumbers = () => {
  const {
    setReplacedPhone,
    setIsLoaded,
    setMask,
    setNewMsisdn,
    clearBeautifulNumbersData,
    setCurrentCategory,
  } = useActions()
  const navigate = useNavigate()
  const theme = useTheme()

  const [, setScrollToTop] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const { phone } = useSelector((state: RootState) => state.login)
  const {
    mask,
    replacedPhone,
    data,
    filteredData,
    cachedMask,
    currentCategory,
  } = useSelector((state: RootState) => state.beautifulNumbers)
  const numberSectionRef = useRef() as MutableRefObject<HTMLDivElement>

  const hasMask = !!mask
  const [inputValue, setInputValue] = useState(mask)
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data: numbersData, isLoading: isAdditionalNumbersLoading } =
    useGetAdditionalNumberQuery()

  const phones = useMemo(
    () => getPhonesWithAdditionalData(numbersData?.data),
    [numbersData?.data]
  )
  const selectorNumbers = prepareSelectorNumbers(phones)

  const [currentNumber, setCurrentNumber] = useState("")

  useEffect(() => {
    if (phones) {
      setCurrentNumber(
        replacedPhone
          ? replacedPhone
          : prepareAvailableCurrentPhone(currentPhone, phones, selectorNumbers)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phones])

  const prepareBeautifulPhones = (msisdn: string) =>
    (mask ? filteredData : data)?.find(
      ({ msisdnType }: { msisdnType: string }) => msisdnType === msisdn
    )

  const onChangeTab = (tab: string) => {
    sendMetric({
      Ещё: { "Красивые номер": { Категория_Клик: [tab] } },
    })
    setCurrentCategory(tab as ENumberTypes)
    numberSectionRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  const clearBeautifulnumbersStore = () => {
    if (currentNumber !== currentPhone) {
      clearBeautifulNumbersData()
    }
  }

  const additionalNumber = phone !== currentNumber ? currentNumber : ""
  const [trigger, result] = useLazyGetBeautifulNumbersQuery()
  const isLoading =
    result.isFetching || result.isLoading || isAdditionalNumbersLoading

  const phonesList = useMemo(
    () => getPhonesFromData(numbersData?.data.additionalNumbers, currentNumber),
    [numbersData, currentNumber]
  )
  const { data: tariffData } = useGetTariffQuery(
    {
      numbers: phonesList,
    },
    { skip: !numbersData }
  )
  const currentExtendedData = useMemo(
    () => getCurrentExtendedData(tariffData?.data.extendedData, currentNumber),
    [tariffData, currentNumber]
  )
  const isDataEmpty = inputValue ? filteredData.length === 0 : data.length === 0

  useEffect(() => {
    if (pageNumber === 1 && !mask && !data.length) {
      trigger({ pageNumber, additionalNumber })
    }
  }, [trigger, pageNumber, additionalNumber, mask, data])

  useEffect(() => {
    if (
      mask &&
      ((mask !== cachedMask && filteredData.length) || !filteredData.length)
    ) {
      trigger({ pageNumber, mask: inputValue, additionalNumber })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, pageNumber, additionalNumber, mask])

  const onPhoneChange = (value: string) => {
    setCurrentNumber(value)
    setPageNumber(1)
    setScrollToTop(true)
  }

  const onClearIcon = () => {
    setInputValue("")

    if (hasMask) {
      setPageNumber(1)
      setMask("")
    }
  }

  const onBackClick = () => {
    clearBeautifulnumbersStore()
    setReplacedPhone("")
    setIsLoaded(false)
    setNewMsisdn("")
    setMask("")
    navigate(-1)
  }

  return (
    <TableBody>
      <HeaderWrap>
        <Header
          background={theme.colors.background[1]}
          onBackClick={onBackClick}
        />
      </HeaderWrap>
      <DisplayPhones
        current={currentNumber}
        onChange={onPhoneChange}
        phones={selectorNumbers}
      />
      <Input
        endIcon={inputValue.length < 4 ? undefined : <Icon.SubmitArrowRight />}
        clearIconHandler={onClearIcon}
        hasClearIcon={inputValue.length < 4}
        endIconHandler={() => {
          sendMetric({
            Ещё: {
              "Красивые номер": { "Поиск по маске_Клик": [currentCategory] },
            },
          })
          setMask(inputValue)
        }}
        value={inputValue}
        placeholder="Введите от 4 до 7 цифр для поиска"
        onKeyUp={(e) =>
          e.key === "Enter" && inputValue.length >= 4
            ? setMask(inputValue)
            : undefined
        }
        onChange={(value) => {
          if (hasMask && value.length === 0) {
            setPageNumber(1)
            setMask("")
          }
          setInputValue(value.replace(/\D/g, ""))
        }}
        maxLength={7}
        type="tel"
      />
      <Tabs onChangeTab={onChangeTab}>
        <TabsItem
          title={ENumberTypes.Normal}
          component={
            <NumbersList
              beautifulPhones={prepareBeautifulPhones(EMsisdnType.Normal)}
              balance={currentExtendedData?.balance.balanceValue}
              pageNumber={pageNumber}
              isLoading={isLoading}
              trigger={trigger}
              setPageNumber={setPageNumber}
              currentNumber={currentNumber}
              setScrollToTop={setScrollToTop}
              numberSectionRef={numberSectionRef}
              isDataEmpty={isDataEmpty}
            />
          }
        />
        <TabsItem
          title={ENumberTypes.Bronze}
          component={
            <NumbersList
              beautifulPhones={prepareBeautifulPhones(EMsisdnType.Bronze)}
              balance={currentExtendedData?.balance.balanceValue}
              pageNumber={pageNumber}
              isLoading={isLoading}
              trigger={trigger}
              setPageNumber={setPageNumber}
              currentNumber={currentNumber}
              setScrollToTop={setScrollToTop}
              numberSectionRef={numberSectionRef}
              isDataEmpty={isDataEmpty}
            />
          }
        />
        <TabsItem
          title={ENumberTypes.Silver}
          component={
            <NumbersList
              beautifulPhones={prepareBeautifulPhones(EMsisdnType.Silver)}
              balance={currentExtendedData?.balance.balanceValue}
              pageNumber={pageNumber}
              isLoading={isLoading}
              trigger={trigger}
              setPageNumber={setPageNumber}
              currentNumber={currentNumber}
              setScrollToTop={setScrollToTop}
              numberSectionRef={numberSectionRef}
              isDataEmpty={isDataEmpty}
            />
          }
        />
        <TabsItem
          title={ENumberTypes.Gold}
          component={
            <NumbersList
              beautifulPhones={prepareBeautifulPhones(EMsisdnType.Gold)}
              balance={currentExtendedData?.balance.balanceValue}
              pageNumber={pageNumber}
              isLoading={isLoading}
              trigger={trigger}
              setPageNumber={setPageNumber}
              currentNumber={currentNumber}
              setScrollToTop={setScrollToTop}
              numberSectionRef={numberSectionRef}
              isDataEmpty={isDataEmpty}
            />
          }
        />
      </Tabs>
    </TableBody>
  )
}
