import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TFormDataValues } from "components/Form/interfaces"

export interface IWithQueryHocState {
  values?: TFormDataValues
}

const initialState: IWithQueryHocState = {
  values: undefined,
}

export const withQueryHocSlice = createSlice({
  name: "withQueryHoc",
  initialState,
  reducers: {
    addWatchValues: (state, { payload }: PayloadAction<IWithQueryHocState>) => {
      state.values = { ...state.values, ...payload.values }
    },
  },
})

export const withQueryHocActions = withQueryHocSlice.actions

export default withQueryHocSlice.reducer
