import { RootState, store } from "store"
import { ChatTheme } from "theme/interfaces"

declare global {
  interface Window {
    sberCareChat: { init: (config: unknown) => void }
  }
}
const CHAT_ROOT_SRC = "/dist/5.7.0/"
export const CHAT_SRC = `${CHAT_ROOT_SRC}loader_bundle_chat.min.js`
const API_REST_URL = "/v2/api/user-detail-service/messenger/open_chat"

export const CHAT_CONFIG = (() => {
  let currentAdditionalNumber: string | undefined = ""
  return (additionalNumber?: string) => {
    if (currentAdditionalNumber !== additionalNumber) {
      currentAdditionalNumber = additionalNumber
      localStorage.setItem("retailChatSession", "")
      localStorage.setItem("retailChatToken", "")
    }
    const theme = (store.getState() as RootState).settings.currentTheme
    return () =>
      !!window.sberCareChat &&
      window.sberCareChat.init({
        startForm: "Chat", // 'Chat' | 'Icon' | 'Bot' | 'Elena' | 'Conversations'
        conversations: ["Chat"], //каждый должен быть описан в конфиге | 'Elena'

        applicationName: "sberCareChat", // для вызова публичных методов
        mainBundlePath: CHAT_ROOT_SRC, // путь до js файла
        mountContainerId: "chat-app", // id контейнера для рендера чата
        theme: {
          name: ChatTheme[theme], // "default" | "light" | "dark"
          primaryColor: "#00ff00", // цвет смайликов
          secondaryColor: "#404040", // цвет графических кнопок
        },
        search: {
          enabled: true,
          minLength: 2,
        },
        size: {
          width: "100%", // string
          height: "calc(100vh - 64px)", // string
        },
        headerIsEnabled: false,
        logMetrika: false,
        yandexCounterId: 1175048, //счетчик для метрики
        logError: false, // Zabbix метрика
        chatEnabled: true,
        chatInContent: false,
        deepLink: "sbermobilechat",

        Chat: {
          browserVersion: "web",
          uploadDocumentUrl:
            "https://messenger.online.sberbank.ru:8877/api/media/uploadPartnerDocument",
          getDocumentUrl:
            "https://messenger.online.sberbank.ru:8877/api/media/getPartnerDocument",
          uploadFile: true,
          fileUploadFormats: "jpg,jpeg,png,pdf,doc,docx,rtf,txt",
          fileUploadMax: 5120000,
          type: "messenger",
          name: "СберМобайл",
          iconImgUrl: `${CHAT_ROOT_SRC}/support.png`, // если iconType: 'custom'
          apiRestUrl: currentAdditionalNumber
            ? `${API_REST_URL}?additional_number=${currentAdditionalNumber}`
            : API_REST_URL,
          apiWSUrl: "wss://messenger.online.sberbank.ru:443/api/",
          isNewOperatorPlace: false,
          firstMessage: {
            suggestions: [
              {
                text: "Оплатить услуги связи",
                order_id: 0,
              },
              {
                text: "Изменить тариф",
                order_id: 1,
              },
              {
                text: "Добавить трафик",
                order_id: 2,
              },
              {
                text: "Нет доступа в Интернет",
                order_id: 3,
              },
              {
                text: "Блокировка/разблокировка SIM-карты",
                order_id: 4,
              },
            ],
          },
          domain: "sbermobile.pwa", // по умолчанию location.host
          suggestionShowTime: 60,
          initTimeout: 30,
          reconnectMaxCount: 2,
          reconnectDuration: 10,
          dictionary: {
            headerTitle: "Служба поддержки",
            botName: " ",
            inputPlaceholder: "Напишите сообщение...",
            connectError: {
              title: "Чат временно недоступен",
              subTitle: "Пожалуйста, обратитесь в контактный центр",
              phones: [
                {
                  number: "901",
                  text: "Бесплатные звонки с мобильного",
                },
                {
                  number: "+7 (499) 651-44-44",
                  text: "Звонки из любой точки мира",
                },
              ],
            },
            reconnectError: {
              title: "Чат завершен",
              subTitle: "Спасибо, что обратились к нам. Мы всегда рады помочь!",
              buttonText: "Начать новый чат",
            },
            errorClosed: {
              title: "Чат временно недоступен",
              subTitle: "Пожалуйста, обратитесь в контактный центр",
              phones: [
                {
                  number: "901",
                  text: "Бесплатные звонки с мобильного",
                },
                {
                  number: "+7 (499) 651-44-44",
                  text: "Звонки из любой точки мира",
                },
              ],
              buttonText: "Обновить чат",
            },
            techBreak: {
              title: "На данный момент отправка сообщений невозможна",
              subTitle: "Попробуйте зайти позже",
            },
          },
        },
      })
  }
})()
