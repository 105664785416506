import styled from "styled-components"
import { H1 } from "components/Typography/Headline"
import { Body2 } from "components/Typography/Body"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background[1]};
`

export const TitleStyled = styled(H1)`
  margin: 40px 32px 32px;
`

export const ButtonContainerStyled = styled.div`
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`

export const WrapContent = styled.div`
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
`

export const FooterLabelStyled = styled(Body2)`
  text-align: center;
  margin-top: 8px;
`
