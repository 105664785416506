import React, { FC, memo, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import {
  useGetAdditionalNumberQuery,
  useGetConnectedAvailableQuery,
  useGetTariffQuery,
} from "store/api"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import {
  EBonusOptionType,
  EDiscountType,
  EOptionAvailability,
  EQuantityType,
  IBonusOption,
} from "entities/tariff"
import { formatPrice } from "utils/price"
import { sendMetric } from "utils/metrics"
import { ISpecial } from "entities/connectedAvailable"
import { useScrollToTop } from "hooks/useScrollToTop"
import { getCurrentExtendedData, getPhonesFromData } from "pages/Home/utils"
import {
  DiscountFlag,
  Header,
  Icon,
  Sheet,
  Table,
  TariffServiceStatus,
} from "components"
import { routes } from "constants/routes"
import {
  Body2Styled,
  H1Styled,
  H4Styled,
  OldPriceStyled,
  PriceStyled,
  RootStyled,
  WrapFooterStyled,
  WrapPriceStyled,
} from "./styles"

export const MyTariff: FC = memo(() => {
  const navigate = useNavigate()
  const { phone, currentPhone } = useSelector((state: RootState) => state.login)
  const { data: numbersData } = useGetAdditionalNumberQuery()
  const optionsWithDescription = [
    EBonusOptionType.SberSound,
    EBonusOptionType.SberhitOptions,
    EBonusOptionType.UnlimitedSberServices,
  ]
  const [bonusOptionSheet, setBonusOptionSheet] = useState<
    IBonusOption | ISpecial | null
  >(null)

  const phonesList = useMemo(
    () => getPhonesFromData(numbersData?.data.additionalNumbers, phone),
    [numbersData, phone]
  )

  const { data: connectedAvailable } = useGetConnectedAvailableQuery({
    phone: currentPhone,
  })

  const { data } = useGetTariffQuery({
    numbers: phonesList,
  })

  const currentExtendedData = useMemo(
    () => getCurrentExtendedData(data?.data.extendedData, currentPhone),
    [data, currentPhone]
  )

  const untilDate = useMemo(
    () =>
      currentExtendedData?.subscriptionFeeDate &&
      `Дата продления ${moment(currentExtendedData?.subscriptionFeeDate).format(
        "DD.MM.YY"
      )}`,
    [currentExtendedData]
  )

  useEffect(() => {
    if (currentExtendedData?.currentTariff.name) {
      sendMetric({
        МойТариф: { Открытие: [currentExtendedData?.currentTariff.name] },
      })
    }
  }, [currentExtendedData?.currentTariff.name])

  const getIcon = (code: string) => {
    switch (code) {
      case "WIFI":
        return <Icon.Web />
      case "INTERNET":
        return <Icon.Web />
      case "UNLIMITED_SBERMOBILE_CALLS":
      case "CALLS":
        return <Icon.Phone />
      case "AURA":
        return <Icon.Shield />
      case "MESSENGERS":
        return <Icon.Messager />
      case "SOCIALS":
        return <Icon.Social />
      case "MUSIC":
        return <Icon.Music2 />
      case "VIDEO":
        return <Icon.Video />
      case "UNLIMITED_SBER_SERVICES":
        return <Icon.Sber />
      case "CARRYOVER_RESIDUES":
      case "CARRYOVER_GB":
      case "CARRYOVER_MINUTES":
        return <Icon.AutorenewalBlock />
      case "INSURANCE":
        return <Icon.Umbrella />
      case "SALUT":
        return <Icon.Salut />
      case "SBER_SOUND":
        return <Icon.Zvooq />
      case "SBERHIT_OPTIONS":
        return <Icon.Present />
      case "TELEGRAM_OPTION":
        return <Icon.Telegram />
      default:
        return <></>
    }
  }

  useScrollToTop()

  const renderPaidServices = () => {
    const services = (
      currentExtendedData?.connectedOptions?.contentOptions || []
    ).filter(
      (item) => item.active && item.availability === EOptionAvailability.Paid
    )
    if (services.length === 0) {
      return <></>
    }
    return (
      <Table.Section title="Подключённые услуги">
        {services.map((item) => (
          <Table.CommonCell
            key={uuidv4()}
            title={item.title}
            subTitle={`${item.enabledApps
              .map((app) => app.appCode)
              .join(", ")} не расходуют основной пакет интернета`}
            startIcon={getIcon(item.contentType)}
          />
        ))}
      </Table.Section>
    )
  }

  const renderDiscount = () => {
    const discountItem = connectedAvailable?.data?.enabledOptions?.find(
      (item) => item.sectionName === "Акции и скидки"
    )
    const resItems = discountItem?.serviceData?.specials
    return resItems ? (
      <Table.Section title="Акции и скидки">
        {resItems.map((resItem) => (
          <Table.CommonCell
            title={resItem?.title}
            subTitle={`${resItem?.connectDescription} ${moment(
              resItem?.startDate
            ).format("DD.MM.YY")}`}
            startIcon={<Icon.Percent />}
            endIcon={<Icon.ArrowRight />}
            onClick={() => setBonusOptionSheet(resItem)}
          />
        ))}
      </Table.Section>
    ) : (
      <></>
    )
  }

  const renderFreeServices = () => {
    const services = (
      currentExtendedData?.connectedOptions?.contentOptions || []
    ).filter((item) => item.active && item.availability === "FREE")
    if (services.length === 0) {
      return null
    }
    return services.map((item) => (
      <Table.CommonCell
        title={item.title}
        subTitle={`${item.enabledApps
          .map((app) => app.appCode)
          .join(", ")} не расходуют основной пакет интернета`}
        startIcon={getIcon(item.contentType)}
      />
    ))
  }

  const renderBonusOptions = () => {
    const options = currentExtendedData?.currentTariff?.bonusOptions || []
    if (options.length === 0) {
      return null
    }
    const isCarryOverContains = options
      .map((item) => item.contentType)
      .includes(EBonusOptionType.callsAndInternet)
    return options
      .filter(
        (item) =>
          !(
            isCarryOverContains &&
            (item.contentType === EBonusOptionType.calls ||
              item.contentType === EBonusOptionType.internet)
          )
      )
      .map((item) => (
        <Table.CommonCell
          key={uuidv4()}
          title={item.title}
          subTitle={item.subTitle}
          startIcon={getIcon(item.contentType)}
          endIcon={
            optionsWithDescription.includes(item.contentType) ? (
              <Icon.ArrowRight />
            ) : undefined
          }
          onClick={
            optionsWithDescription.includes(item.contentType)
              ? () => setBonusOptionSheet(item)
              : undefined
          }
        />
      ))
  }

  const getMbQuantityTitle = () => {
    if (
      currentExtendedData?.currentTariff?.mbQuantity?.type ===
      EQuantityType.unlimited
    ) {
      return "∞ гигабайт"
    }
    return `${Math.floor(
      (currentExtendedData?.currentTariff?.mbQuantity?.volume || 0) / 1024
    )} гигабайт`
  }

  const getMinutesQuantityTitle = () => {
    if (
      currentExtendedData?.currentTariff?.minutesQuantity?.type ===
      EQuantityType.unlimited
    ) {
      return "∞ минут"
    }
    return `${currentExtendedData?.currentTariff.minutesQuantity.volume} минут`
  }

  const getSmsQuantityTitle = () => {
    if (
      currentExtendedData?.currentTariff?.smsQuantity?.type ===
      EQuantityType.unlimited
    ) {
      return "∞ смс"
    }
    return `${currentExtendedData?.currentTariff.smsQuantity.volume} смс`
  }

  const onBack = () => {
    navigate(routes.main)
  }

  return (
    <>
      <Sheet
        isOpen={!!bonusOptionSheet}
        onClose={() => setBonusOptionSheet(null)}
      >
        <TariffServiceStatus
          serviceData={bonusOptionSheet as IBonusOption & ISpecial}
          onClose={() => setBonusOptionSheet(null)}
        />
      </Sheet>
      <RootStyled>
        <Header title="Мой тариф" onBackClick={onBack} />
        <Table.Body>
          <Table.Section>
            <Table.Cell>
              <H4Styled>{currentExtendedData?.currentTariff.name}</H4Styled>
              <Body2Styled>{untilDate}</Body2Styled>
              <WrapFooterStyled>
                <WrapPriceStyled>
                  <H1Styled>
                    {
                      formatPrice(
                        currentExtendedData?.currentTariff?.fee
                          ?.discountedFee || 0
                      ).rublesStr
                    }
                  </H1Styled>
                  {currentExtendedData?.currentTariff?.fee?.discountedFee !==
                    currentExtendedData?.currentTariff?.fee?.fee && (
                    <OldPriceStyled>
                      {(currentExtendedData?.currentTariff.fee.fee || 0) / 100}
                    </OldPriceStyled>
                  )}
                  <PriceStyled>₽/мес</PriceStyled>
                </WrapPriceStyled>
                {(currentExtendedData?.currentTariff?.fee?.discountType ===
                  EDiscountType.prime ||
                  currentExtendedData?.currentTariff?.fee?.discountType ===
                    EDiscountType.subsFeeDiscount) && (
                  <DiscountFlag
                    discount={`-${currentExtendedData?.currentTariff.fee.discount}${currentExtendedData?.currentTariff.fee.discountUnit}`}
                    type={currentExtendedData?.currentTariff.fee.discountType}
                  />
                )}
              </WrapFooterStyled>
            </Table.Cell>
          </Table.Section>
          <Table.Section title="Включено в тариф">
            {!!currentExtendedData?.currentTariff.mbQuantity.volume && (
              <Table.CommonCell
                title={getMbQuantityTitle()}
                subTitle={
                  currentExtendedData?.currentTariff.mbQuantity.description
                }
                startIcon={<Icon.Web />}
              />
            )}
            {!!currentExtendedData?.currentTariff.minutesQuantity.volume && (
              <Table.CommonCell
                title={getMinutesQuantityTitle()}
                subTitle={
                  currentExtendedData?.currentTariff.minutesQuantity.description
                }
                startIcon={<Icon.Phone />}
              />
            )}
            {(currentExtendedData?.currentTariff.smsQuantity.volume || 0) >
              0 && (
              <Table.CommonCell
                title={getSmsQuantityTitle()}
                subTitle={
                  currentExtendedData?.currentTariff.smsQuantity.description
                }
                startIcon={<Icon.Sms />}
              />
            )}
            {renderFreeServices()}
            {renderBonusOptions()}
            <Table.CommonCell
              title="Общие условия тарифа"
              startIcon={<Icon.Pdf />}
              endIcon={<Icon.ArrowRight />}
              onClick={() => {
                if (currentExtendedData?.currentTariff.url) {
                  navigate(routes.showExternalLink, {
                    state: {
                      link: currentExtendedData?.currentTariff.url,
                      pathname: window.location.pathname,
                    },
                  })
                }
              }}
            />
          </Table.Section>
          {renderPaidServices()}
          {renderDiscount()}
        </Table.Body>
      </RootStyled>
    </>
  )
})
