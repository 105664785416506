import { ICommonResponse } from "./common"

export interface ICustomerProfileRequest {
  number?: string
}

export enum EGoskeySignType {
  UKEP = "UKEP",
  UNEP = "UNEP",
}

export interface ICustomerProfileResponse extends ICommonResponse {
  data: {
    profileStatusTitle: string
    firstName: string
    simType: "ESIM" | "SIM"
    profileStatus: string
    esimPlatform: string
    email: string
    middleName: string
    documentStatus: string
    lastName: string
    goskeySignType: EGoskeySignType
  }
}
