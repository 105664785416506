import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { sendMetric } from "utils/metrics"
import { useActions } from "hooks/actions"
import { Icon, Table } from "components"
import { routes } from "constants/routes"

export const DetailsSection: FC<{ currentPhone: string }> = ({
  currentPhone,
}) => {
  const { clearFormValues } = useActions()
  const navigate = useNavigate()
  return (
    <Table.Section>
      <Table.CommonCell
        title="Заказать детализацию"
        startIcon={<Icon.Pie />}
        endIcon={<Icon.ArrowRight />}
        onClick={() => {
          clearFormValues()
          sendMetric({ "Главный экран": ["Заказать детализацию_Клик"] })
          navigate(`${routes.orderDetails}?phone=${currentPhone}`)
        }}
      />
    </Table.Section>
  )
}
