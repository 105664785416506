import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getSearchObject } from "utils/search"
import { EPrimeStatus } from "entities/prime"
import { setCookie } from "utils/cookie"

interface ILoginState {
  error: string
  isLoading: boolean
  showSmsCode: boolean
  phone: string
  token: string
  currentPhone: string
  displayPhone: string
  selectedPhone: string
  smsCode: string
  contract: string
  summary: string
  email: string
  lastUpdate: number
  signature: string
  sberId: {
    nonce: string
    number: string
    clientFullName: string
    showInfoSheet: boolean
    showBindAnotherNumberSheet: boolean
    showPrimeSheet: boolean
    primeStatus: EPrimeStatus | null
  }
}

const initialState: ILoginState = {
  error: "",
  isLoading: false,
  showSmsCode: false,
  phone: "",
  token: "",
  currentPhone: "",
  displayPhone: "",
  selectedPhone: "",
  smsCode: "",
  contract: "",
  summary: "",
  email: "",
  signature: "",
  lastUpdate: Date.now(),
  sberId: {
    nonce: "",
    number: "",
    clientFullName: "",
    showInfoSheet: false,
    showBindAnotherNumberSheet: false,
    showPrimeSheet: false,
    primeStatus: null,
  },
}

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginStart: (state, { payload }: PayloadAction<string>) => {
      state.error = ""
      state.summary = ""
      state.isLoading = true
      const { additional } = getSearchObject()
      if (additional !== "true") {
        state.phone = payload
      }
    },
    loginSuccess: (state) => {
      state.isLoading = false
      state.showSmsCode = true
      state.sberId.showInfoSheet = false
    },
    loginFail: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false
      state.error = payload
    },
    sendSmsStart: (state, {}: PayloadAction<string>) => {
      state.isLoading = true
      state.error = ""
    },
    sendSmsSuccess: (state) => {
      state.isLoading = false
      state.displayPhone = ""
    },
    sendSmsFail: (state, { payload }: PayloadAction<string>) => {
      state.error = payload
      state.isLoading = false
    },
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload
      setCookie("Token", payload)
    },
    setShowSmsCode: (state, { payload }: PayloadAction<boolean>) => {
      state.showSmsCode = payload
    },
    setError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload
    },
    setSignature: (state, { payload }: PayloadAction<string>) => {
      state.signature = payload
    },
    clear: () => {
      return initialState
    },
    resetError: (state) => {
      state.error = initialState.error
    },
    setCurrentPhone: (state, { payload }: PayloadAction<string>) => {
      state.currentPhone = payload
    },
    setDisplayPhone: (state, { payload }: PayloadAction<string>) => {
      state.displayPhone = payload
    },
    setSelectedPhone: (state, { payload }: PayloadAction<string>) => {
      state.selectedPhone = payload
    },
    setSmsCode: (state, { payload }: PayloadAction<string>) => {
      state.smsCode = payload
    },
    setContract: (state, { payload }: PayloadAction<string>) => {
      state.contract = payload
    },
    setSummary: (state, { payload }: PayloadAction<string>) => {
      state.summary = payload
    },
    setEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload
    },
    setLastUpdate: (state, { payload }: PayloadAction<number>) => {
      state.lastUpdate = payload
    },
    setNonce: (state, { payload }: PayloadAction<string>) => {
      state.sberId.nonce = payload
    },
    setSberIdNumber: (state, { payload }: PayloadAction<string>) => {
      state.sberId.number = payload
    },
    setSberIdClientFullName: (state, { payload }: PayloadAction<string>) => {
      state.sberId.clientFullName = payload
    },
    setSberIdShowInfoSheet: (state, { payload }: PayloadAction<boolean>) => {
      state.sberId.showInfoSheet = payload
    },
    setSberIdBindAnotherNumberSheet: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.sberId.showBindAnotherNumberSheet = payload
    },
    setSberIdPrimeSheet: (state, { payload }: PayloadAction<boolean>) => {
      state.sberId.showPrimeSheet = payload
    },
    setSberIdPrimeStatus: (state, { payload }: PayloadAction<EPrimeStatus>) => {
      state.sberId.primeStatus = payload
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    resetPhone: (state) => {
      state.phone = initialState.phone
    },
  },
})

export const loginActions = loginSlice.actions

export default loginSlice.reducer
