import React, {
  FC,
  memo,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"
import spinner from "animations/spinner.json"
import { Icon } from "components"
import {
  DescriptionStyled,
  InputStyled,
  RootStyled,
  ClearIconWrapper,
  TopPlaceholder,
  StartIconWrapper,
  PlayerStyled,
} from "./styles"
import { formatInputValue, onPhoneInput } from "./utils"

interface IPhoneInputMaskProps {
  placeholder?: string
  description?: string
  onChange?(value: string): void
  hasError?: boolean
  onBlur?(value?: string): void
  isLoading?: boolean
  type?: string
  startIcon?: ReactElement
  hasClearIcon?: boolean
  onClearIconHandler?: () => void
  onFocus?: () => void
  value?: string
  endIcon?: ReactElement
  onEndIconClick?: () => void
  children?: ReactNode
}

export const PhoneInputMask: FC<IPhoneInputMaskProps> = memo(
  ({
    placeholder,
    onChange,
    description,
    hasError,
    onBlur,
    isLoading,
    type,
    startIcon,
    hasClearIcon = false,
    onClearIconHandler,
    value,
    onFocus,
    endIcon,
    onEndIconClick,
    children,
  }) => {
    const hasStartIcon = !!startIcon
    const [inputValue, setInputValue] = useState("")
    useEffect(() => {
      if (value) {
        setInputValue(value)
      }
    }, [inputValue, value])
    const [currentSelectionStart, setCurrentSelectionStart] = useState<
      number | null
    >(0)
    const [wasRequestSent, setWasRequestSent] = useState(false)

    const isInputNotEmpty = inputValue.trim().length !== 0

    const ref = useRef() as MutableRefObject<HTMLInputElement>

    useEffect(() => {
      if (isLoading) {
        setWasRequestSent(true)
      }

      if (!isLoading && wasRequestSent) {
        ref.current?.focus()
      }
    }, [isLoading, wasRequestSent])

    useEffect(() => {
      if (currentSelectionStart && ref) {
        ref.current.selectionStart = currentSelectionStart
        ref.current.selectionEnd = currentSelectionStart
      }
    }, [ref, inputValue, currentSelectionStart])

    const onKeyDownHandler = (
      e: React.ChangeEvent<HTMLInputElement> &
        React.KeyboardEvent<HTMLDivElement>
    ) => {
      const isKeyBackspace = e.key === "Backspace"
      const trimmedInputValue = e.target.value.trim()
      if (isKeyBackspace && trimmedInputValue.length <= 2) {
        setInputValue("")
      }
    }

    const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const [selectionStart, totalValue] = onPhoneInput(event, inputValue)
      if (onChange) {
        onChange(formatInputValue(totalValue as string).substring(1))
      }
      setCurrentSelectionStart(selectionStart as number)
      setInputValue(totalValue as string)
    }

    const onInnerBlur = () => onBlur?.(inputValue)

    const onClearIconClick = () => {
      onClearIconHandler?.()
      setInputValue("")
    }

    return (
      <RootStyled>
        {isLoading && <PlayerStyled autoplay loop src={spinner} />}
        {startIcon && (
          <StartIconWrapper hasError={hasError}>{startIcon}</StartIconWrapper>
        )}
        {isInputNotEmpty && (
          <TopPlaceholder hasStartIcon={hasStartIcon}>
            {placeholder}
          </TopPlaceholder>
        )}
        <InputStyled
          ref={ref}
          placeholder={placeholder}
          placeholderTop={isInputNotEmpty}
          value={inputValue}
          onBlur={onInnerBlur}
          onInput={handlePhoneInput}
          isLoading={isLoading}
          type={type}
          hasError={hasError}
          hasClearIcon={hasClearIcon}
          hasStartIcon={hasStartIcon}
          onKeyDown={onKeyDownHandler}
          disabled={isLoading}
          className="ym-disable-keys"
          onFocus={onFocus}
        />
        {hasClearIcon && isInputNotEmpty && !isLoading && (
          <ClearIconWrapper onClick={onClearIconClick}>
            <Icon.ClearIcon />
          </ClearIconWrapper>
        )}
        {endIcon && (
          <ClearIconWrapper onClick={onEndIconClick}>
            {endIcon}
          </ClearIconWrapper>
        )}
        <DescriptionStyled>{description}</DescriptionStyled>
        {!hasError && !description && children}
      </RootStyled>
    )
  }
)
