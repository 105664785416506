import React, { FC, useEffect, useState, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { useActions } from "hooks/actions"
import { CommonContainer } from "components/Sheet/styles"
import { useGetUserConfigQuery } from "store/api"
import { RootState } from "store"
import { prepareCurrentFeatureConfig } from "utils/userConfig"
import { sendMetric } from "utils/metrics"
import {
  Container,
  Header,
  Icon,
  Table,
  Sheet,
  Typography,
  Button,
  Spacer,
} from "components"
import { routes } from "constants/routes"

const PaymentAdjustment: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { clearFormValues } = useActions()
  const [isOpen, setOpen] = useState(true)
  const { currentPhone } = useSelector((state: RootState) => state.login)

  const { data: userConfigData } = useGetUserConfigQuery()

  const currentFeatureConfig = useMemo(
    () =>
      prepareCurrentFeatureConfig(
        currentPhone,
        userConfigData?.data?.userConfigs
      ),
    [currentPhone, userConfigData?.data?.userConfigs]
  )

  useEffect(() => {
    clearFormValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container page backgroundColor={theme.colors.background[0]}>
      <Header
        title="Корректировка платежа"
        background={theme.colors.background[0]}
      >
        <Icon.InfoIcon
          onClick={() => {
            sendMetric({ "Корректировка платежа": ["Правила_Клик"] })
            setOpen(true)
          }}
        />
      </Header>
      <Table.Body noPadding={false} padding={16}>
        <Table.Section>
          {currentFeatureConfig?.isPaymentAdjustmentErrorNumberEnabled && (
            <Table.CommonCell
              startIcon={<Icon.AdjustmentNumber />}
              title="Ошибка в номере телефона"
              key={uuidv4()}
              onClick={() => {
                sendMetric({
                  "Корректировка платежа": ["Ошибка в номере_Клик"],
                })
                navigate(routes.paymentAdjustmentNumber)
              }}
              endIcon={<Icon.ArrowRight />}
            />
          )}
          {currentFeatureConfig?.isPaymentAdjustmentErrorPaymentEnabled && (
            <Table.CommonCell
              startIcon={<Icon.AdjustmentAmount />}
              title="Ошибка в сумме пополнения"
              key={uuidv4()}
              onClick={() => {
                sendMetric({ "Корректировка платежа": ["Ошибка в сумме_Клик"] })
                navigate(routes.paymentAdjustmentAmount)
              }}
              endIcon={<Icon.ArrowRight />}
            />
          )}
        </Table.Section>
      </Table.Body>
      <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
        <CommonContainer>
          <Typography.H4>Правила</Typography.H4>
          <Spacer height={34} />
          <Typography.Body1>
            Для возврата денежных средств необходимо быть владельцем банковской
            карты и СИМ-карты. На реквизиты 3-х лиц возврат не производится.
            <br />
            <br />
            Если на ошибочном номере недостаточно денежных средств для полного
            переноса, то будет перенесена часть суммы платежа.
          </Typography.Body1>
          <Spacer height={28} />
          <Button.Plain onClick={() => setOpen(false)}>Понятно</Button.Plain>
        </CommonContainer>
      </Sheet>
    </Container>
  )
}

export { PaymentAdjustment }
