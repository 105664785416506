import { EContentType, ICommonResponse } from "./common"
import { EStatusType } from "./connectedAvailable"

export enum EService {
  social = "SOCIALS",
  music = "MUSIC",
  video = "VIDEO",
  messenger = "MESSENGERS",
}

export enum EReceiveStatus {
  Ok = "OK",
  NoTariff = "NO_TARIFF",
  DeprecatedBaseTariff = "DEPRECATED_BASE_TARIFF",
}

export enum EPeriodType {
  month = "MONTH",
  week = "WEEK",
  day = "DAY",
}

export enum EOptionAvailability {
  Free = "FREE",
  Paid = "PAID",
  Unavailable = "UNAVAILABLE",
}

export enum EBonusOptionType {
  internet = "CARRYOVER_GB",
  calls = "CARRYOVER_MINUTES",
  callsAndInternet = "CARRYOVER_RESIDUES",
  SberSound = "SBER_SOUND",
  UnlimitedSberServices = "UNLIMITED_SBER_SERVICES",
  SberhitOptions = "SBERHIT_OPTIONS",
}

export enum EDiscountType {
  none = "NONE",
  prime = "PRIME",
  subsFeeDiscount = "SUBS_FEE_DISCOUNT",
}

export enum EQuantityType {
  finite = "FINITE",
  infinite = "INFINITE",
  unlimited = "UNLIMITED",
}

export interface ITariffResponse extends ICommonResponse {
  data: {
    extendedData: TExtendedData[]
  }
}

export interface ICurrentTariff {
  id: number
  number: string
  name: string
  description?: any
  connectDescription?: any
  minutesQuantity: IMinutesQuantity
  mbQuantity: IMbQuantity
  smsQuantity: ISmsQuantity
  contentOptions: IContentOption[]
  spasiboPercent: number
  badge?: any
  headerService?: any
  url: string
  urlSpasibo?: any
  priceValueTariff?: any
  valueTariff?: any
  tariffLine: ITariffLine
  fee: IFee
  tariffOrder: number
  bonusOptions: IBonusOption[]
  periodType: string
  discountedFee: number
  discountUnit?: any
  discountType?: EDiscountType
  discount: number
  monthlyFee: number
  connectionFee: number
}

export type TExtendedData = {
  number: string
  receiveStatus: EReceiveStatus
  receiveDate: string
  currentTariff: ICurrentTariff
  balance: IBalance
  subscriptionFeeDate: string
  connectedOptions: IConnectedOptions
  availableOptions: IAvailableOptions
  valueAddedServices: IValueAddedServices
}

export interface ITariffRequest {
  numbers: string[] | number[]
}

export interface StatusInfo {
  statusMessage: string
  statusCode: string
}

export interface IMinutesQuantity {
  type: EQuantityType
  volume: number
  price: number
  description: string
}

export interface IMbQuantity {
  type: EQuantityType
  volume: number
  description: string
  price?: number
}

export interface ISmsQuantity {
  type: EQuantityType
  volume: number
  price: number
  description: string
}

export interface IEnabledApp {
  appCode: string
  iconUrl: string
}

export interface IContentOption {
  statusType?: EStatusType
  optionId: number
  title: string
  subTitle: string
  description: string
  monthlyFee: number
  active: boolean
  availability: string
  contentType: EService
  enabledApps: IEnabledApp[]
}

export interface ITariffLine {
  code: string
  kit: boolean
  promo: boolean
  wow: boolean
  sortType: string
  lineOrder: number
}

export interface IFee {
  fee: number
  discountedFee: number
  discount: number
  discountUnit?: any
  periodType: EPeriodType
  discountType?: EDiscountType
}

export interface IBonusOption {
  optionId: number
  title: string
  subTitle: string
  description: string
  connectDescription: string
  monthlyFee: number
  active: boolean
  availability: string
  contentType: EBonusOptionType
  enabledApps: IEnabledApp[]
}

export interface IBalance {
  number: string
  balanceValue: number
  limitValue: number
  needPay: boolean
}

export interface IDefaultOption {
  optionId: number
  currentValue: number
  totalValue: number
  percentage: number
  expDate: string
  unit: string
  monthlyFee: number
  connectFee: number
  contentType: EContentType
  periodType: string
  canBeDisabled: boolean
}

export interface IConnectedOptions {
  number: string
  contentOptions: IContentOption[]
  additionalOptions: IAdditionalOption[]
  roamingOptions: any[]
  defaultOptions: IDefaultOption[]
}

export interface IAdditionalOption {
  optionId: number
  title: string
  subTitle: string
  description: string
  unit: string
  volume: number
  coverageArea: string
  monthlyFee: number
  connectionFee: number
  active: boolean
  connectDescription: string
  contentType: EContentType
  periodType: string
  canBeDisabled: boolean
  statusType?: EStatusType
}

export interface IAvailableOptions {
  number: string
  additionalOptions: IAdditionalOption[]
}

export interface IWifi {
  optionId: number
  title: string
  enabled: boolean
}

export interface IValueAddedServices {
  wifi: IWifi
}
