import styled from "styled-components"

const Container = styled.div<{ backgroundColor?: string }>`
  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}
  display: flex;
  padding: 14px 16px;
  width: 100%;
  min-height: 64px;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Column = styled.div<{ isMainColumn?: boolean }>`
  position: relative;
  flex: ${(props) => (props.isMainColumn ? "1 0 0" : "0")};
`

export const Styled = {
  Container,
  Content,
  Column,
}
