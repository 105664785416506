import React, { FC } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { useActions } from "hooks/actions"
import { TFormDataValues } from "components/Form/interfaces"
import { RootState } from "store"
import { sendMetric } from "utils/metrics"
import { Container, Form, Header } from "components"
import { routes } from "constants/routes"
import { formModulesStep1 } from "./formData"

const PaymentAdjustmentAmount: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { values } = useSelector((state: RootState) => state.form)
  const { setFormValues } = useActions()
  const handleFormSubmit = (data: TFormDataValues) => {
    sendMetric({
      "Корректировка платежа по номеру": {
        "Ввод информации по платежу": ["Продолжить_Клик"],
      },
    })
    setFormValues({ values: data })
    navigate(routes.paymentAdjustmentAmountContacts)
  }
  return (
    <Container page backgroundColor={theme.colors.background[0]}>
      <Header
        title="Корректировка платежа"
        subtitle="Ошибка в сумме пополнения"
        background={theme.colors.background[0]}
      />
      <Form
        onSubmit={handleFormSubmit}
        modules={formModulesStep1}
        initialValues={values}
      />
    </Container>
  )
}

export { PaymentAdjustmentAmount }
