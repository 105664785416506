import React, { FC, memo } from "react"
import { useNavigate } from "react-router-dom"
import { useGetLinksQuery } from "store/api"
import { ELinksCode } from "entities/common"
import { Error } from "pages/Error"
import { routes } from "constants/routes"
import { BLANK } from "constants/common"

export const SignatureNotVerifiedData: FC = memo(() => {
  const title = "Проблема с данными на Госуслугах"
  const subtitle =
    "Зайдите на Госуслуги и проверьте, что ваша учётная запись и номер телефона подтверждены. После этого снова попробуйте подписать договор eSIM."
  const buttonText = "Перейти в Госуслуги"

  const navigate = useNavigate()
  const { data: links } = useGetLinksQuery()

  const onConfirmClick = () => {
    if (links && links[ELinksCode.Gosuslugi]) {
      window.open(links[ELinksCode.Gosuslugi], BLANK)
      navigate(routes.signature)
    }
  }

  return (
    <Error
      title={title}
      subtitle={subtitle}
      buttonText={buttonText}
      onClick={onConfirmClick}
      isPrimary
    />
  )
})
