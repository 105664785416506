import { useEffect, useState, FC, useRef, MutableRefObject } from "react"
import { useSelector } from "react-redux"
import SignatureCanvas from "react-signature-canvas"
import { formatPhoneNumber } from "utils"
import { RootState } from "store"
import { useActions } from "hooks/actions"
import { colors } from "theme/dayTheme/colors"
import { Spacer, Typography, Button } from "components"
import { SigningStyled } from "./styles"
import { ISigningProps } from "./interfaces"

export const Signing: FC<ISigningProps> = ({
  buttonTitle = "Продолжить",
  onContinue,
  children,
  phone,
}) => {
  const [width, setWidth] = useState(0)
  const { setSignature } = useActions()
  const sigCanvasRef = useRef() as MutableRefObject<typeof SignatureCanvas>
  const [isShowSignature, setIsShowSignature] = useState(true)
  const [isEmptyCanvas, setIsEmptyCanvas] = useState(true)
  const { currentPhone } = useSelector((state: RootState) => state.login)

  const onCanvasBegin = () => {
    setIsShowSignature(false)
  }
  const onContinueClick = () => {
    const signature = sigCanvasRef.current
      .getTrimmedCanvas()
      .toDataURL()
      .replace(/data:.*base64,/, "")
    setSignature(signature)
    onContinue?.(signature)
  }

  const onCanvasEnd = () => {
    setIsEmptyCanvas(sigCanvasRef.current.isEmpty())
  }
  const onClearCanvas = () => {
    sigCanvasRef.current.clear()
    setIsShowSignature(true)
    setIsEmptyCanvas(true)
  }

  useEffect(() => {
    setWidth(
      document.getElementsByTagName("canvas")[0]?.offsetParent?.clientWidth ?? 0
    )
  }, [])

  return (
    <SigningStyled.Root>
      {children ?? (
        <Typography.Body1 lineHeight={22}>
          {`Подпись владельца номера `}
          {formatPhoneNumber(phone ?? currentPhone)}
        </Typography.Body1>
      )}
      <SigningStyled.Container>
        <SigningStyled.SignatureCanvasWrap>
          <SignatureCanvas
            onBegin={onCanvasBegin}
            onEnd={onCanvasEnd}
            ref={sigCanvasRef}
            penColor={colors.stroke.dark}
            canvasProps={{ width, height: width }}
          />
          <SigningStyled.ClearButton onClick={onClearCanvas}>
            Очистить
          </SigningStyled.ClearButton>
          {isShowSignature && (
            <SigningStyled.Signature>
              <SigningStyled.SignatureIcon />
              <Spacer height={10} />
              <Typography.Body1 color={colors.text.primary} lineHeight={22}>
                Подпишите
              </Typography.Body1>
            </SigningStyled.Signature>
          )}
        </SigningStyled.SignatureCanvasWrap>
      </SigningStyled.Container>
      <Spacer flexGrow={1} height={12} />
      <Button.Primary onClick={onContinueClick} disabled={isEmptyCanvas}>
        {buttonTitle}
      </Button.Primary>
    </SigningStyled.Root>
  )
}
