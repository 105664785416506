import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { Table } from "components/Table"
import { Badge, Container, Header, MPTypography, Typography } from "components"
import { FAQStyled } from "./styles"

interface IRequiredConditions {
  id: number
  title: string
  buttonText?: string
  route?: string
}

interface IFAQ {
  footerInfo: string
  requiredConditions: IRequiredConditions[]
  requiredConditionsTitle: string
}

export const FAQ: FC<IFAQ> = (props) => {
  const { footerInfo, requiredConditions, requiredConditionsTitle } = props
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Container backgroundColor={theme.colors.background.surface1} page>
      <Header background={theme.colors.background.surface1} />
      <Table.Section
        title={requiredConditionsTitle}
        titleComponent={({ children }: any) => (
          <Typography.H2>{children}</Typography.H2>
        )}
        hasDivider={false}
        titlePadding={{ paddingTop: 0 }}
        cellContainerMargin={{ marginTop: 32, marginBottom: 24 }}
      >
        {requiredConditions.map((condition) => (
          <Table.CommonCell
            key={condition.id}
            backgroundColor={theme.colors.background.surface1}
            title={condition.title}
            titleComponent={({ children }: any) => (
              <MPTypography startPadding={16}>{children}</MPTypography>
            )}
            titleSpacer={12}
            textGap={12}
            startIcon={
              <FAQStyled.IconWrapper hasBackground>
                <Typography.Body1Bold>{condition.id}</Typography.Body1Bold>
              </FAQStyled.IconWrapper>
            }
            subTitle={
              condition?.buttonText && (
                <Badge text={condition.buttonText} active={false} />
              )
            }
            subtitleHandler={() =>
              condition.route ? navigate(condition.route) : null
            }
          />
        ))}
      </Table.Section>
      <Typography.Body2 padding={16}>{footerInfo}</Typography.Body2>
    </Container>
  )
}
