export const TITLE = "Какую сумму мне вернут, если меня обманут мошенники?"
export const DESCRIPTION = [
  "Если ситуация по всем признакам попадает под страховой случай, вам вернут всю сумму ущерба, но не больше 1 000 000 рублей. ",
  "До 200 000 рублей выплатят по страховке за один случай, где вы из-за противоправных действий мошенников перевели им деньги или сняли сумму в банкомате для дальнейшей передачи. Количество таких случаев и выплат по ним не ограничено.",
]
export const MONEY_REFUND_CONDITIONS =
  "В каких случаях возможна выплата до 1 000 000 рублей"
export const REFUND_CASES =
  "Злоумышленники незаконно списали деньги с ваших застрахованных карт или счетов в Сбере следующими способами:"
export const list = [
  "сделали перевод на другой счёт;",
  "оплатили вашей картой покупки или услуги, выманив у вас информацию о ней обманным путём;",
  "списали деньги с карты или счёта, украв для этого ваши данные, выманив у вас обманом информацию по карте или счёту, а также использовав поддельные документы или настоящие, но полученные мошенническим путём;",
  "получили незаконный доступ к вашему мобильному банку и привязали банковское приложение к чужому номеру телефона;",
  "получили незаконный доступ к вашему личному кабинету в Сбербанк Онлайн, завладев нужной для этого информацией.",
]

export const fullConditions = [
  "Полные условия страхования и возмещения ущерба можно найти в",
  "Правилах страхования.",
]
export const insuranceRulesLink = "https://lk.sbermobile.ru/insurance-rules"
