import React, { FC, memo } from "react"
import { Typography } from "components"
import { Root } from "./styles"

interface IBadge {
  active?: boolean
  text: string
  onClick?: (e?: any) => void
}

const Badge: FC<IBadge> = memo((props) => {
  const { active, text, onClick } = props
  return (
    <Root active={active} onClick={onClick}>
      <Typography.Body2>{text}</Typography.Body2>
    </Root>
  )
})

export { Badge }
