import styled from "styled-components"

export const Calendar = styled.div`
  background-color: ${(props) => props.theme.colors.background[1]};
  width: 100%;
  border-radius: 8px;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.transparent.black[10]};
  padding: 20px 16px;
  .react-datepicker__header {
    border: none;
    background: transparent;
    padding: 0;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__current-month {
    text-transform: capitalize;
    font-family: SB Sans Interface, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    padding-top: 4px;
    color: ${(props) => props.theme.colors.text.primary};
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    padding: 6px 0 3px 0;
  }
  .react-datepicker__day {
    width: 32px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SB Sans Interface, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    color: ${(props) => props.theme.colors.text.primary};
  }
  .react-datepicker__day-name {
    width: 32px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SB Sans Interface, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    color: ${(props) => props.theme.colors.text.secondary};
  }
  .react-datepicker__day--today,
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
  }
  .react-datepicker__day--outside-month {
    color: ${(props) => props.theme.colors.text.secondary};
  }
  .react-datepicker__day--disabled {
    color: ${(props) => props.theme.colors.text.secondary};
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover,
  .react-datepicker__day:active,
  .react-datepicker__month-text:active,
  .react-datepicker__quarter-text:active,
  .react-datepicker__year-text:active {
    background-color: transparent;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day.react-datepicker__day--selected {
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.button.contentBrand};
    color: ${(props) => props.theme.colors.button.contentPrimary};
  }
  .react-datepicker__navigation {
    top: 20px;
  }
  .react-datepicker__navigation--previous {
    left: 16px;
  }
  .react-datepicker__navigation--next {
    right: 16px;
  }
`
