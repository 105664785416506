import { getSearchObject } from "utils/search"
import { FAQCommon } from "pages"
import { Table } from "components"
import { DESCRIPTION, howItWorks, TITLE } from "./constants"
import { HowItWorksStyled } from "./styles"

const List = () => (
  <>
    {howItWorks.map(({ id, title, icon }) => (
      <Table.CommonCell
        key={id}
        titleComponent={title}
        titleSpacer={12}
        startIcon={
          <HowItWorksStyled.IconWrapper>{icon}</HowItWorksStyled.IconWrapper>
        }
      />
    ))}
  </>
)
export const HowItWorks = () => {
  const { theme: externalTheme } = getSearchObject()
  return (
    <>
      <FAQCommon
        externalTheme={externalTheme}
        title={TITLE}
        description={DESCRIPTION}
        children={<List />}
      />
    </>
  )
}
