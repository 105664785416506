import React, { FC, memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { Header } from "components/Header"
import {
  useGetCustomerProfileQuery,
  useGetGoskeyPreauthMutation,
} from "store/api"
import { RootState } from "store"
import { EGoskeySignType } from "entities/customerProfile"
import { useTheme } from "styled-components"
import { Icon, Spacer } from "components"
import {
  ButtonStyled,
  FooterStyled,
  H4Styled,
  ImgStyled,
  IndentStyled,
  RootStyled,
  SubtitleStyled,
} from "./styles"
import { Item } from "./Item"

export const SignatureOnline: FC = memo(() => {
  const [trigger] = useGetGoskeyPreauthMutation()
  const { currentPhone, phone } = useSelector((state: RootState) => state.login)
  const { data: customerData } = useGetCustomerProfileQuery({
    number: currentPhone,
  })

  const theme = useTheme()

  const subtitle = useMemo(() => {
    if (customerData?.data?.goskeySignType === EGoskeySignType.UNEP) {
      return "Для этого понадобится приложение Госключ"
    } else if (customerData?.data?.goskeySignType === EGoskeySignType.UKEP) {
      return "Для этого понадобится загранпаспорт нового образца с биометрией и приложение Госключ"
    }
    return ""
  }, [customerData?.data?.goskeySignType])

  const onConfirmClick = () => {
    trigger({
      additionalNumber: currentPhone !== phone ? currentPhone : undefined,
    })
  }

  return (
    <RootStyled>
      <Header background={theme.colors.background[1]} />
      <Spacer height={8} />
      <ImgStyled />
      <Spacer height={24} />
      <H4Styled>Онлайн-подписание</H4Styled>
      <SubtitleStyled>
        Чтобы всё было безопасно и официально, мы используем специальные сервисы
      </SubtitleStyled>
      <Item
        title="Авторизуйтесь через Госуслуги"
        subtitle="В договор автоматически добавятся ваши данные"
        icon={<Icon.CoatOfArms />}
      />
      <Item
        title="Выпустите электронную подпись"
        subtitle={subtitle}
        icon={<Icon.Key />}
      />
      <Item title="Подпишите договор" icon={<Icon.Document />} />
      <IndentStyled />
      <FooterStyled>
        <ButtonStyled onClick={onConfirmClick}>Продолжить</ButtonStyled>
      </FooterStyled>
    </RootStyled>
  )
})
