import styled from "styled-components"
import { Typography } from "components"

const SubtitleWrap = styled.div`
  padding-left: 16px;
`

const H1 = styled(Typography.H1)`
  padding-left: 16px;
  margin-top: 74px;
  margin-bottom: 17px;
`
const WrapPhone = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 27px;
  padding-left: 16px;
`
const Body1 = styled(Typography.Body1)`
  color: ${(props) => props.theme.colors.text.secondary};
`
const Phone = styled(Typography.Body1)`
  color: ${(props) => props.theme.colors.text.primary};
  white-space: nowrap;
`
const WrapInput = styled.div`
  width: 100%;
  border-radius: 8px;
`
const Body2 = styled(Typography.Body2)`
  color: ${(props) => props.theme.colors.text.secondary};
`
const Footer = styled.div`
  width: 100%;
  max-width: 480px;
  margin-top: 32px;
  padding-left: 16px;
`
const Link = styled(Typography.Body1)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.warning};
`
const ServiceRulesWrap = styled.div`
  margin-top: 16px;
  padding-left: 16px;
`
const Footnote = styled(Typography.Body2)`
  color: ${(props) => props.theme.colors.text.secondary};
`
const FootnoteLink = styled(Typography.Body2Link)`
  cursor: pointer;
  text-decoration: underline;
  margin: 0 3px;
`
const Wrapper = styled.div`
  display: flex;
  padding: 0 16px;
  flex-direction: column;
`

export const EmailAjustmentConfirmationStyled = {
  SubtitleWrap,
  H1,
  WrapPhone,
  Body1,
  Phone,
  WrapInput,
  Body2,
  Footer,
  Link,
  Footnote,
  FootnoteLink,
  Wrapper,
  ServiceRulesWrap,
}
