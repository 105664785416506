import { FAQCommon } from "pages"
import { getSearchObject } from "utils/search"
import { DESCRIPTION, TITLE } from "./constants"

export const HowAuraWork = () => {
  const { theme: externalTheme } = getSearchObject()
  return (
    <FAQCommon
      externalTheme={externalTheme}
      title={TITLE}
      description={DESCRIPTION}
    />
  )
}
