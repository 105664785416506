import styled from "styled-components"

export const TabBarWrapper = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  padding: 4px;
  flex-direction: row;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.transparent.black[10]};
  margin-bottom: 4px;
`

export const TabBarItem = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isActive ? props.theme.colors.toggle.on : "initial"};
  border-radius: 8px;

  p {
    color: ${(props) =>
      props.isActive
        ? props.theme.colors.text.primary
        : props.theme.colors.text.secondary};
  }
`
