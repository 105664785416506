import { Table } from "components"
import { FAQCommon } from "../../faqs/FAQCommon"
import { DESCRIPTION, howItWorks, TITLE } from "./constants"
import { HowItWorksStyled } from "./styles"

const List = () => (
  <>
    {howItWorks.map(({ id, title, icon }) => (
      <Table.CommonCell
        key={id}
        titleComponent={title}
        titleSpacer={12}
        startIcon={
          <HowItWorksStyled.IconWrapper>{icon}</HowItWorksStyled.IconWrapper>
        }
      />
    ))}
  </>
)

export const PWAHowItWorks = () => {
  return (
    <FAQCommon title={TITLE} description={DESCRIPTION} children={<List />} />
  )
}
