export const TITLE = "Ребёнок на карте 2ГИС"
export const SUBTITLE =
  "В «Друзьях на карте» приложения 2ГИС вы сможете видеть местоположение и заряд смартфона вашего ребёнка в реальном времени. Приложение не расходует пакет интернета."
export const BUTTON_TEXT = "СКАЧАТЬ ПРИЛОЖЕНИЕ"
export const SECTION_TITLE = "Как подключить"
export const sectionContent = [
  {
    id: 1,
    content: "Запустите «Друзей на карте» в 2ГИС и выдайте нужные разрешения",
  },
  {
    id: 2,
    content: "Сделайте то же самое на смартфоне ребёнка",
  },
  {
    id: 3,
    content: "Отправьте ребёнку приглашение в друзья и получите подтверждение",
  },
]
