import React, { FC } from "react"
import { Container, Icon, Table } from "components"
import { H4Styled, ImageStyled } from "./styles"

export const EngineeringWorks: FC = () => {
  const onClick100 = () => {
    window.open("tel:*100#")
  }
  const onClick101 = () => {
    window.open("tel:*101#")
  }

  return (
    <Container page>
      <ImageStyled />
      <H4Styled>
        Личный кабинет временно недоступен, мы работаем над этим
      </H4Styled>
      <Table.Section>
        <Table.CommonCell
          title="*100#"
          subTitle="Узнать баланс"
          startIcon={<Icon.Phone />}
          endIcon={<Icon.ArrowRight />}
          onClick={onClick100}
        />
        <Table.CommonCell
          startIcon={<Icon.Phone />}
          title="*101#"
          subTitle="Остаток минут, гигабайт и СМС"
          endIcon={<Icon.ArrowRight />}
          onClick={onClick101}
        />
      </Table.Section>
    </Container>
  )
}
