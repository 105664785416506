import { useNavigate } from "react-router-dom"
import { getSearchObject } from "utils/search"
import { useSetTheme } from "hooks/useSetTheme"
import { ESpacerSize } from "entities/common"
import { useTheme } from "styled-components"
import { Container, Icon, Spacer, Table, Typography } from "components"
import { CONTENT, SUBTITLE, TITLE } from "./constants"
import { SberKidsStyled } from "./styles"
import kidImage from "./kidImage.png"

export const SberKids = () => {
  const { theme: externalTheme, system } = getSearchObject()
  const theme = useTheme()
  const navigate = useNavigate()
  useSetTheme(externalTheme)
  const contentClickHandler = (
    route: string,
    mpRoute: string,
    isMP: boolean
  ) => {
    //если мобильное приложение, то обрабатываем одним образом, если нет, другим
    if (isMP) {
      return (window.location.href = mpRoute)
    } else {
      return navigate(route)
    }
  }
  return (
    <Container
      position="relative"
      page
      backgroundColor={theme.colors.background.surface1}
    >
      <SberKidsStyled.ImgStyled src={kidImage} />
      <SberKidsStyled.SberKidsContainer>
        <Typography.H3 fontSize="22" lineHeight="28">
          {TITLE}
        </Typography.H3>
        <Spacer height={ESpacerSize.S} />
        <Typography.Body2 fontSize={15} lineHeight={20}>
          {SUBTITLE}
        </Typography.Body2>
        <Spacer height={ESpacerSize.S} />
        <Table.Section
          titlePadding={{ paddingTop: ESpacerSize.XL }}
          cellContainerMargin={{
            marginTop: ESpacerSize.M,
            marginBottom: ESpacerSize.L,
          }}
          hasDivider={false}
          generalBackground={theme.colors.background.surface1}
        >
          {CONTENT.map(({ title, subtitle, icon, route, mpRoute }, index) => (
            <Table.CommonCell
              key={index}
              hasDivider
              title={
                <Typography.Body1Bold fontSize={17}>
                  {title}
                </Typography.Body1Bold>
              }
              subTitle={
                <Typography.Body2 fontSize={15} lineHeight={20}>
                  {subtitle}
                </Typography.Body2>
              }
              titleSpacer={12}
              startIcon={
                <SberKidsStyled.IconWrapper>{icon}</SberKidsStyled.IconWrapper>
              }
              endIcon={<Icon.ArrowRight />}
              onClick={() => contentClickHandler(route, mpRoute, !!system)}
            />
          ))}
        </Table.Section>
      </SberKidsStyled.SberKidsContainer>
    </Container>
  )
}
