import styled, { css } from "styled-components"
import { Primary } from "components/Button/Primary"
import { Document } from "react-pdf"
import { getTheme } from "theme"
import { Table, Typography } from "components"

const ButtonDisable = css`
  background: ${getTheme().colors.transparent.black[10]};
`

export const HeaderWrap = styled.div`
  margin-left: -16px;
  div {
    padding-bottom: 8px;
  }
`
export const RootStyled = styled(Table.Body)`
  height: 100vh;
  padding-bottom: 32px;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.background[1]};
`

export const CheckboxWrap = styled.div`
  margin-bottom: 24px;
`

export const Title = styled(Typography.Body1)`
  text-align: center;
`

export const DocumentWrap = styled.div`
  min-height: 0;
  height: 100%;
  padding-bottom: 16px;
  div {
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    div {
      min-width: initial !important;
    }
  }
`

export const DocumentStyled = styled(Document)`
  height: 100%;
  div {
    height: 100%;
  }
`

export const PrimaryButton = styled(Primary)<{ isDisabled: boolean }>`
  justify-self: flex-end;
  ${(props) => (props.isDisabled ? ButtonDisable : "")};
`
