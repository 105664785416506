import { EESimState, IUserConfigNormalized } from "entities/userConfig"
import { formatPhoneNumber } from "utils"
import { Icon, IPhoneNumber } from "components"
import { CheckIconStyled } from "./LinkNumbers/styles"

export const prepareTitle = (alias?: string, phone?: string) =>
  alias === phone ? (formatPhoneNumber(phone, true) as string) : alias

export const prepareSubtitle = (alias?: string, phone?: string) =>
  alias !== phone ? (formatPhoneNumber(phone, true) as string) : ""

export const renderIcon = (
  userConfigNormalized: IUserConfigNormalized,
  number?: string
) => {
  if (!number) {
    return <></>
  }
  if (
    [EESimState.ESIM_IDENTIFIED, EESimState.ESIM_NOT_IDENTIFIED].includes(
      userConfigNormalized?.[number]?.eSIMState
    )
  ) {
    return <Icon.ESim />
  }
  return <Icon.Sim />
}

export const endIcon = (phone: IPhoneNumber, currentPhone: string) =>
  phone.isPrimary && phone.number === currentPhone ? (
    <CheckIconStyled />
  ) : undefined
