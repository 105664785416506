import React, { memo, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import { usePostNumberTransferConfirmMutation } from "store/api"
import {
  ESendConfirmationType,
  EStatusCode,
  IResponseErrorData,
} from "entities/common"
import { useTheme } from "styled-components"
import { useActions } from "hooks/actions"
import { sendMetric } from "utils/metrics"
import {
  Container,
  Header,
  ServiceFailModal,
  SmsConfirmation,
} from "components"
import { routes } from "constants/routes"

export const NumberTransferConfirmation = memo(() => {
  const theme = useTheme()
  const [triger, status] = usePostNumberTransferConfirmMutation()
  const [isOpen, setIsOpen] = useState(false)
  const { setError, setSmsCode, openModal } = useActions()
  const navigate = useNavigate()
  const { numberTransfer } = useSelector((state: RootState) => state.common)

  const onSuccess = (code: string) => {
    setSmsCode(code)
    triger({
      number: numberTransfer,
      code,
    })
  }

  useEffect(() => {
    if (status.isSuccess) {
      sendMetric({
        MNP: { "Подтверждение СМС": { "Ввод СМС-кода": ["Клик"] } },
      })
      navigate(routes.numberTransferDate)
    }

    if (status?.error && "data" in status.error) {
      const errorData = status.error.data as IResponseErrorData
      const sendErrorCodeMetric = (errorMessage: string) =>
        sendMetric({
          MNP: {
            "Подтверждение СМС": {
              "Ввод СМС-кода": { Ошибка: [errorMessage] },
            },
          },
        })
      if (typeof errorData === "string") {
        sendErrorCodeMetric(errorData)
        return setIsOpen(true)
      }

      sendErrorCodeMetric(errorData.statusInfo.statusMessage)
      switch (errorData?.statusInfo.statusCode) {
        case EStatusCode.BadCredentials:
          setError("Неверный код")
          status.reset()
          break
        case EStatusCode.PasswordExpired:
          openModal({
            isOpen: true,
            title: "Информация",
            subtitle: errorData?.statusInfo.statusMessage,
            declineLabel: "Понятно",
          })
          return navigate(-1)
        default:
          return setIsOpen(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, openModal, status])

  return (
    <>
      <ServiceFailModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        declineHandler={() => navigate(routes.menu)}
      />
      <Container page>
        <Header title="Подтверждение" background={theme.colors.background[1]} />
        <SmsConfirmation
          number={numberTransfer}
          onSuccess={onSuccess}
          type={ESendConfirmationType.MNPConfirm}
          isLoading={status.isLoading}
          onClickDefaultRules={() =>
            sendMetric({
              MNP: {
                "Подтверждение СМС": {
                  "Ввод СМС-кода": ["Правила сервиса_Клик"],
                },
              },
            })
          }
        />
      </Container>
    </>
  )
})
