import styled from "styled-components"
import { Typography } from "components"

export const RootStyled = styled.div`
  width: 100%;
  padding: 14px 16px;
`

export const ContainerStyled = styled.div`
  display: flex;
`

export const IconWrapStyled = styled.div`
  width: 48px;
  min-width: 48px;
  height: 100%;
`

export const RightContainerWrapStyled = styled.div`
  align-self: center;
`

export const TitleStyled = styled(Typography.Body1)``

export const SubtitleStyled = styled(Typography.Body2)`
  margin-top: 2px;
`
