import styled from "styled-components"
import { Typography } from "components"

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 17px;
`
const Balance = styled.div`
  display: flex;
  align-items: baseline;
`
const Fractional = styled(Typography.Body1)`
  color: ${(props) => props.theme.colors.text.secondary};
  white-space: break-spaces;
`

export const Container = { Root, Balance, Fractional }
