import React, { FC } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { loginActions } from "store/slice/loginSlice"
import { formatPhoneNumber } from "utils"
import { Button, Sheet } from "components"
import { routes } from "constants/routes"
import { IBindAnotherNumberSheetProps } from "./interfaces"
import {
  WrapPrimaryButtonStyled,
  SheetContainer,
  SubtitleStyled,
  TitleStyled,
} from "./styles"

export const BindAnotherNumberSheet: FC<IBindAnotherNumberSheetProps> = ({
  isOpen,
  phone,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onClose = () => {
    dispatch(loginActions.setSberIdBindAnotherNumberSheet(false))
  }

  const onSubmit = () => {
    dispatch(loginActions.setError(""))
    dispatch(loginActions.setSberIdBindAnotherNumberSheet(false))
    dispatch(loginActions.setLoading(false))
    navigate(routes.loginSberIdBind)
  }

  const onSubmitWithoutSberId = () => {
    dispatch(loginActions.setError(""))
    dispatch(loginActions.setToken(""))
    dispatch(loginActions.setShowSmsCode(false))
    dispatch(loginActions.setSberIdBindAnotherNumberSheet(false))
    navigate(routes.login)
  }

  return (
    <Sheet isOpen={isOpen} onClose={onClose}>
      <SheetContainer>
        <TitleStyled>{`Привязка Сбер ID к аккаунту приложения ${formatPhoneNumber(
          phone,
          true
        )} будет удалена`}</TitleStyled>
        <SubtitleStyled>Хотите привязать другой номер?</SubtitleStyled>
        <WrapPrimaryButtonStyled>
          <Button.Primary onClick={onSubmit}>Да</Button.Primary>
        </WrapPrimaryButtonStyled>
        <Button.Plain onClick={onSubmitWithoutSberId}>
          Войти без Сбер ID
        </Button.Plain>
      </SheetContainer>
    </Sheet>
  )
}
