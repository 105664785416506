import {
  ReactNode,
  Ref,
  HTMLInputTypeAttribute,
  HTMLAttributes,
  ReactElement,
} from "react"
import { UseLazyQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import { IFile } from "../UploadFile/UploadFile"

export enum FieldFormat {
  date = "date",
  file = "file",
  text = "text",
  phoneSelect = "phoneSelect",
  select = "select",
  phone = "phone",
}

export interface ICommonField {
  name: string
  onChange: (value: TFieldValue) => void
  onBlur: (value: TFieldValue) => void
  value: TFieldValue
  hasError?: boolean
  description?: string
  placeholder: string
  fieldRef: Ref<unknown>
  disabled?: boolean
  startIcon?: ReactElement
  onClick?: () => void
  onSelect?: (value: TFieldValue) => void
}

export type TFieldQuery = {
  query: UseLazyQuery<any>
  parameters: (value: unknown) => unknown
  result: (
    data: unknown,
    fieldProps: TFormField & ICommonField,
    value: TFieldValue
  ) => {
    fieldProps: TFormField & ICommonField
    hasError: boolean
    isValid: boolean
  }
  cache: (value: unknown) => unknown
  error: string
  onError?: (error: string) => void
}

export enum FieldExtensionType {
  common,
  WithQuery,
  WithCustomValidation,
}

export type TFieldWithQuery = {
  extensionType: FieldExtensionType.WithQuery
  watchField: string
  query: TFieldQuery
}

export type TFieldWithCustomValidation = {
  extensionType: FieldExtensionType.WithCustomValidation
  customValidator: TCustomValidator
}

export type TFormFieldCommon = Pick<
  HTMLAttributes<HTMLInputElement>,
  "inputMode"
> & {
  name: string
  placeholder: string
  format: FieldFormat
  initialValue: TFieldValue
  info?: string
  validators?: any
  customValidator?: TCustomValidator
  disabled?: boolean
  onSelect?: (value: TFieldValue) => void
  sheet?: {
    title: string
    description?: string
  }
  options?: {
    name: string
    value: string
  }[]
  watchField?: string
  query?: TFieldQuery
  max?: number
  maxLength?: number
  type?: HTMLInputTypeAttribute
  postfix?: string
  pattern?: RegExp
  unavailableTitle?: string
  unavailableSubtitle?: string
  maxDate?: Date
  minDate?: Date
  startIcon?: ReactElement
  onClick?: () => void
  onOuterBlur?: (val: TFieldValue) => void
}

export type TFormExtendedField =
  | TFieldWithQuery
  | TFieldWithCustomValidation
  | { extensionType: FieldExtensionType.common }

export type TFormField = TFormFieldCommon & TFormExtendedField

export interface IFormModule {
  title?: string
  subtitle?: string
  fields: TFormField[]
}

export type TFieldValue = string | number | IFile[]

type TCustomValidatorOptions = {
  watchField?: string
}

export type TCustomValidator = (
  value: TFieldValue,
  options?: TCustomValidatorOptions
) => Promise<{
  message?: string
  sheetContent?: ReactNode
  isValid: boolean
  fieldProps?: Partial<TFormFieldCommon>
}>

export type TFormDataValues = { [key: string]: TFieldValue }

export interface IForm {
  onSubmit: (data: TFormDataValues) => void
  modules: IFormModule[]
  children?: ReactNode
  padding?: string
  initialValues?: TFormDataValues
  submitButton?: string
}
