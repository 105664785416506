export enum EThemeNames {
  DayTheme = "day-theme",
  NightTheme = "night-theme",
  SystemTheme = "system-theme",
}

export enum EThemeMetricNames {
  DayTheme = "Всегда светлая",
  NightTheme = "Всегда темная",
  SystemTheme = "Системная",
}

export const ChatTheme: Record<EThemeNames, string> = {
  [EThemeNames.DayTheme]: "light",
  [EThemeNames.NightTheme]: "dark",
  [EThemeNames.SystemTheme]: "default",
}

export enum EThemeType {
  Light = "light",
  Dark = "dark",
}

export interface ITheme {
  name: string
  colors: {
    base?: any // нецелевое, нужно изавляться
    gradient?: any // нецелевое, нужно изавляться
    transparent?: any // нецелевое, нужно изавляться
    background: {
      0: string
      1: string
      root: string
      surface0: string
      surface1: string
    }
    text: {
      primary: string
      secondary: string
      warning: string
      critical: string
      disabled: string
      highlight: string
    }
    icon: {
      primary: string
      success: string
      disabled: string
      warning: string
      critical: string
      mpPrimary: string
    }
    divider: string
    divider2: string
    speedometer: {
      internet: string
      call: string
      sms: string
    }
    button: {
      primary: string
      secondary: string
      disabled: string
      contentPrimary: string
      contentBrand: string
      black: string
    }
    badge: {
      text: string
      background: string
      textSelected: string
      backgroundSelected: string
    }
    toggle: {
      on: string
      off: string
    }
    stroke: {
      dark: string
      color: string
    }
  }
}
