import styled from "styled-components"
import { H4 } from "components/Typography/Headline"
import { Body1 } from "components/Typography/Body"

export const SheetContainer = styled.div`
  padding: 64px 16px 32px 16px;
  background: ${(props) => props.theme.colors.background[1]};
  border-radius: 15px 15px 0px 0px;
`

export const TitleStyled = styled(H4)`
  padding: 16px 24px 8px;
  text-align: center;
`

export const SubtitleStyled = styled(Body1)`
  padding: 0px 24px 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const ImgStyled = styled.img`
  width: 240px;
  display: flex;
  margin: 0px auto;
`
