import { EThemeNames } from "./interfaces"

export const updateTheme = (
  themeName: EThemeNames,
  darkColor: string,
  lightColor: string
) => {
  let newDarkColor = ""
  let newLightColor = ""
  if (themeName === EThemeNames.DayTheme) {
    newDarkColor = lightColor
    newLightColor = lightColor
  } else if (themeName === EThemeNames.NightTheme) {
    newDarkColor = darkColor
    newLightColor = darkColor
  } else if (themeName === EThemeNames.SystemTheme) {
    newDarkColor = darkColor
    newLightColor = lightColor
  }
  document
    .querySelector('meta[media="(prefers-color-scheme: light)"]')
    ?.setAttribute("content", newLightColor)
  document
    .querySelector('meta[media="(prefers-color-scheme: dark)"]')
    ?.setAttribute("content", newDarkColor)
}
