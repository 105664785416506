import { IPhoneNumber } from "components"

export enum ENumberTypes {
  Normal = "Обычные",
  Bronze = "Бронза",
  Silver = "Серебро",
  Gold = "Золото",
}

export enum EMsisdnType {
  Normal = "NORMAL",
  Bronze = "BRONZE",
  Silver = "SILVER",
  Gold = "GOLD",
}

export interface IFormattedPhones {
  availablePhones: IPhoneNumber[]
  unavailablePhones: IPhoneNumber[]
}
