import React, { FC } from "react"
import {
  InputStyled,
  LabelWrapStyled,
  RootStyled,
  CheckboxIcon,
} from "./styles"

interface ICheckboxProps {
  label: string
  checked: boolean
  onChange?(value: boolean): void
}

export const Checkbox: FC<ICheckboxProps> = ({ label, checked, onChange }) => {
  const onClick = () => {
    onChange?.(!checked)
  }
  return (
    <RootStyled onClick={onClick}>
      <InputStyled type="checkbox" checked={checked} />
      <CheckboxIcon />
      <LabelWrapStyled>{label}</LabelWrapStyled>
    </RootStyled>
  )
}
