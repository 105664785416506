import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import commonReducer from "./slice/commonSlice"
import modalReducer from "./slice/modalSlice"
import loginReducer from "./slice/loginSlice"
import settingsReducer from "./slice/settingsSlice"
import formReducer from "./slice/formSlice"
import formValidationReducer from "./slice/formValidationSlice"
import numberTransferReducer from "./slice/numberTransferSlice"
import bannerReducer from "./slice/bannerSlice"
import { rootSaga } from "./sagas"
import { loadFromLocalStorage, saveToLocalStorage } from "./localStorage"
import { sberMobileApi } from "./api"
import { authInterceptor } from "./authInterceptor"
import beautifulNumbersReducer from "./slice/beautifulNumbersSlice"
import withQueryHocReducer from "./slice/withQueryHocSlice"
const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    login: loginReducer,
    common: commonReducer,
    modal: modalReducer,
    settings: settingsReducer,
    form: formReducer,
    formValidation: formValidationReducer,
    sberMobileApi: sberMobileApi.reducer,
    beautifulNumbers: beautifulNumbersReducer,
    numberTransfer: numberTransferReducer,
    withQueryHoc: withQueryHocReducer,
    banner: bannerReducer,
  },
  preloadedState: loadFromLocalStorage(),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ thunk: true })
      .concat(authInterceptor)
      .concat(sberMobileApi.middleware)
      .prepend(sagaMiddleware)
  },
})

store.subscribe(() => {
  saveToLocalStorage({
    login: store.getState().login,
    settings: store.getState().settings,
  })
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const getStore = () => store
