import { FC, ReactNode } from "react"
import { EButtonType } from "components/Sheet/Sheet"
import { Sheet } from "components"
import { Styled } from "./styles"
import phone from "./images/ServicesSheetAssistantPhone.png"
import rays from "./images/ServicesSheetAssistantRays.svg"

interface IServicesSheetAssistant {
  title: string
  subTitle?: string
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

export const ServicesSheetAssistant: FC<IServicesSheetAssistant> = (props) => {
  const { title, subTitle, children, isOpen, onClose } = props

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      closeButtonType={EButtonType.secondary}
    >
      <Styled.Container>
        <Styled.Title>{title}</Styled.Title>
        <Styled.SubTitle>{subTitle}</Styled.SubTitle>
        <Styled.ImageContainer>
          <Styled.ImagePhone src={phone} />
          <Styled.ImageRays src={rays} />
        </Styled.ImageContainer>
        <Styled.Children>{children}</Styled.Children>
      </Styled.Container>
    </Sheet>
  )
}
