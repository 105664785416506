import { useDispatch } from "react-redux"
import { bindActionCreators } from "@reduxjs/toolkit"
import { loginActions } from "store/slice/loginSlice"
import { modalActions } from "store/slice/modalSlice"
import { bannerActions } from "store/slice/bannerSlice"
import { commonActions } from "store/slice/commonSlice"
import { formActions } from "store/slice/formSlice"
import { beautifulNumbersActions } from "store/slice/beautifulNumbersSlice"
import { formValidationActions } from "../store/slice/formValidationSlice"
import { withQueryHocActions } from "../store/slice/withQueryHocSlice"

const actions = {
  ...loginActions,
  ...modalActions,
  ...commonActions,
  ...formActions,
  ...beautifulNumbersActions,
  ...formValidationActions,
  ...withQueryHocActions,
  ...bannerActions,
}

export const useActions = () => {
  const dispatch = useDispatch()
  return bindActionCreators(actions, dispatch)
}
