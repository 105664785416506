import { FC, ReactElement, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTheme } from "styled-components"
import { EHistoryCallsType, IHistoryCallsData } from "entities/historyCalls"
import { RootState } from "store"
import { useGetTranscriptionCallsQuery } from "store/api"
import { QueryStatus } from "@reduxjs/toolkit/query"
import { Icon, Loader, Sheet } from "components"
import { Styled } from "./styles"
import { BUTTON_CALL_TEXT, CALL_TYPE_TEXT } from "./constants"

export interface ITranscriptionSheet {
  phone: string
  date: string
  callId: IHistoryCallsData["callId"]
  category: EHistoryCallsType
  isOpen: boolean
  onClose: () => void
}

type TSheetHelper = {
  [key: string]: {
    icon: ReactElement
    callType: string
    callTypeText: string
  }
}

export const TranscriptionSheet: FC<ITranscriptionSheet> = (props) => {
  const { phone, date, callId, category, isOpen, onClose } = props
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const theme = useTheme()
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const audioRef = useRef<HTMLAudioElement>()

  const transcriptionCallsResponse = useGetTranscriptionCallsQuery(
    {
      phone: currentPhone,
      callId: callId,
    },
    { skip: !isOpen }
  )

  const transcriptionCallsData = transcriptionCallsResponse.data?.data

  useEffect(() => {
    if (
      isOpen &&
      transcriptionCallsResponse.isSuccess &&
      transcriptionCallsData?.recording
    ) {
      const base64data = `data:audio/mpeg;codecs=opus;base64,${transcriptionCallsData?.recording}`
      audioRef.current = new Audio(base64data)
      if (audioRef.current) {
        audioRef.current.addEventListener("ended", () => setIsPlaying(false))
        const refCopy = audioRef
        return () => {
          refCopy.current?.removeEventListener("ended", () =>
            setIsPlaying(false)
          )
        }
      }
    }
  }, [isOpen, transcriptionCallsData, transcriptionCallsResponse.isSuccess])

  const onPlayClick = () => {
    setIsPlaying((prev) => {
      if (!prev) {
        audioRef.current?.play()
      } else {
        audioRef.current?.pause()
      }
      return !prev
    })
  }

  const handlerOnCloe = () => {
    setIsPlaying(() => {
      audioRef.current?.pause()
      return false
    })
    onClose()
  }

  const sheetHelper: TSheetHelper = {
    [EHistoryCallsType.Normal]: {
      icon: <Icon.PhoneMissingCallInGrayCircle />,
      callType: theme.colors.text.critical,
      callTypeText: CALL_TYPE_TEXT[0],
    },
    [EHistoryCallsType.Spam]: {
      icon: <Icon.PhoneIncomingCallInGrayCircle />,
      callType: theme.colors.text.warning,
      callTypeText: CALL_TYPE_TEXT[1],
    },
  }

  return (
    <>
      {isOpen &&
        transcriptionCallsResponse.status !== QueryStatus.fulfilled && (
          <Loader />
        )}
      <Sheet isOpen={isOpen} onClose={handlerOnCloe}>
        <Styled.Container>
          <Styled.IconContainer>
            {sheetHelper[category].icon}
          </Styled.IconContainer>
          <Styled.CallType color={sheetHelper[category].callType}>
            {sheetHelper[category].callTypeText}
          </Styled.CallType>
          <Styled.Phone>{phone}</Styled.Phone>
          <Styled.Date>{date}</Styled.Date>
          <Styled.ButtonsContainer>
            <Styled.ButtonCall href={`tel:${phone}`}>
              <Styled.ButtonIcon>
                <Icon.PhoneDarkGrey />
              </Styled.ButtonIcon>
              <Styled.ButtonText>{BUTTON_CALL_TEXT}</Styled.ButtonText>
            </Styled.ButtonCall>
          </Styled.ButtonsContainer>

          {transcriptionCallsData?.recording && (
            <Styled.PlayerContainer>
              <Styled.ButtonPlayer onClick={() => onPlayClick()}>
                {!isPlaying ? <Styled.IconPlay /> : <Styled.IconPause />}{" "}
                Прослушать сообщение
              </Styled.ButtonPlayer>
            </Styled.PlayerContainer>
          )}

          {transcriptionCallsData &&
            transcriptionCallsData.transcriptionParts?.length > 0 &&
            transcriptionCallsData.transcriptionParts.map((item, index) => (
              <Styled.Transcription key={index}>{item}</Styled.Transcription>
            ))}
        </Styled.Container>
      </Sheet>
    </>
  )
}
