import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit"
import { deleteCookie } from "utils/cookie"
import { EStatusCode } from "../entities/common"
import { routes } from "../constants"
import { TOKEN } from "constants/common"
import { loginActions } from "./slice/loginSlice"
import { store } from "./index"

const TOKEN_LIFETIME = 30 * 60 * 1000

export const authInterceptor: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const lastUpdate = store.getState().login.lastUpdate || 0
    if (
      action.payload?.statusInfo?.statusCode === EStatusCode.MaintenanceWork
    ) {
      window.location.pathname = routes.engineeringWorks
    }
    if (action?.payload?.status === 401) {
      api.dispatch(loginActions.clear())
    }

    if (
      ![loginActions.setLastUpdate.type, loginActions.clear.type].includes(
        action.type
      )
    ) {
      if (Date.now() - lastUpdate > TOKEN_LIFETIME) {
        deleteCookie(TOKEN, "")
        store.dispatch(loginActions.clear())
      }
      if (Date.now() - lastUpdate > 3 * 1000) {
        store.dispatch(loginActions.setLastUpdate(Date.now()))
      }
    }
    return next(action)
  }
