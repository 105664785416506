import styled from "styled-components"
import { Typography } from "components"

const Container = styled.div`
  padding: 16px;
  background: linear-gradient(225deg, #ffba4a 0%, #e93563 65%, #ee7031 100%);
  overflow: hidden;
`

const Title = styled(Typography.Body1Bold)`
  position: relative;
  z-index: 1;
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.button.contentPrimary};
  text-align: center;
`

const SubTitle = styled(Typography.Body1)`
  position: relative;
  z-index: 1;
  margin-bottom: 12px;
  font-size: 17px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.button.contentPrimary};
  text-align: center;
`
const ImageContainer = styled.div`
  position: relative;
  max-width: 210px;
  margin: 0 auto 16px;
`
const ImagePhone = styled.img`
  width: 100%;
`

const ImageRays = styled.img`
  position: absolute;
  bottom: -136px;
  left: -121px;
  width: 919px;
  height: auto;
`
const Children = styled.div`
  position: relative;
  z-index: 1;
`

export const Styled = {
  Container,
  Title,
  SubTitle,
  ImageContainer,
  ImagePhone,
  ImageRays,
  Children,
}
