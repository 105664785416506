import styled from "styled-components"
import { Typography } from "components"

export const RootStyled = styled.div`
  background: ${(props) => props.theme.colors.background[1]};
  min-height: 100vh;
`

export const H1Styled = styled(Typography.H1)`
  margin: 74px 32px 17px;
`

export const WrapPhoneStyled = styled.div`
  margin: 0px 32px 27px;
`

export const Body1Styled = styled(Typography.Body1)`
  margin-right: 3px;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const PhoneStyled = styled(Typography.Body1)`
  color: ${(props) => props.theme.colors.text.primary};
  margin-right: 4px;
  white-space: nowrap;
`

export const WrapInputStyled = styled.div`
  margin: 0px 16px;
  width: calc(100% - 32px);
  border-radius: 8px;
`

export const Body2Styled = styled(Typography.Body2)`
  color: ${(props) => props.theme.colors.text.secondary};
`

export const LinkStyled = styled(Typography.Body1)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.warning};
`

export const FooterStyled = styled.div`
  margin: 0px 32px;
  width: calc(100% - 64px);
  max-width: 480px;
  margin-top: 32px;
`
