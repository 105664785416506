import { FC, memo } from "react"
import { v4 as uuidv4 } from "uuid"
import { IOptionElement } from "entities/connectedAvailable"
import { IServiceStatus } from "components/ServiceStatus/interfaces"
import { ShowOptions } from "pages/Services/ShowOptions"
import { Styled } from "./styles"

interface IEnabledPackages {
  packageServices: IOptionElement[]
  setServiceStatusContent: (state: IServiceStatus | null) => void
}

export const EnabledPackages: FC<IEnabledPackages> = memo(
  ({ packageServices, setServiceStatusContent }) => {
    const centerSlidePercentage = packageServices.length === 1 ? 100 : 89
    return (
      <Styled.EnabledPackagesWrap>
        <Styled.EnabledPackagesTitle>
          Подключенные пакеты
        </Styled.EnabledPackagesTitle>
        <Styled.Carousel
          emulateTouch
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          centerMode
          centerSlidePercentage={centerSlidePercentage}
        >
          {packageServices.map((packageService: IOptionElement) => (
            <ShowOptions
              key={uuidv4()}
              setServiceStatusContent={setServiceStatusContent}
              isRenderEmptyOptions={false}
              padding={0}
              options={[
                {
                  sectionName: "",
                  serviceData: {
                    options: [packageService],
                    roamingOptions: [],
                    contentOptions: [],
                    specials: [],
                  },
                },
              ]}
              onHomePage
            />
          ))}
        </Styled.Carousel>
      </Styled.EnabledPackagesWrap>
    )
  }
)
