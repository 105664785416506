import styled, { css } from "styled-components"
import { Typography } from "components"

export const RootStyled = styled.div``

const WithDesctiption = css`
  height: fit-content;
  align-items: flex-start;
`

const WithoutDescription = css`
  height: 100%;
  align-items: center;
`

export const PriceStyled = styled.div<{ description?: string }>`
  padding: 0 0 0 8px;
  text-align: end;
  display: grid;
  ${(props) =>
    !!props.description?.length ? WithDesctiption : WithoutDescription};
`

export const InternetStyled = styled.div`
  height: fit-content;
`

export const Body1BoldStyled = styled(Typography.Body1Bold)`
  margin-top: -2px;
  margin-bottom: 3px;
  justify-content: right;
  display: flex;
  white-space: nowrap;
`

export const PriceDescriptionStyled = styled(Typography.Body2)`
  justify-content: right;
  display: flex;
`
