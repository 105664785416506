import styled from "styled-components"
import { Secondary } from "components/Button/Secondary"
import { Primary } from "components/Button/Primary"
import { Typography } from "components"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  max-width: 480px;
  gap: 8px;
`

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const TextContent = styled(MainContent)`
  gap: 8px;
`

export const ImgStyled = styled.img`
  margin: 0 auto;
  max-width: 240px;
`

export const H4Styled = styled(Typography.H4)`
  text-align: center;
`

export const SubtitleStyled = styled(Typography.Body1)`
  text-align: center;
  color: ${(props) => props.theme.colors.text.secondary};
  margin: 0 24px;
`

export const FooterStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 96px;
  bottom: 0px;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.background[1]};
  max-width: 480px;
`

export const ButtonSecondaryStyled = styled(Secondary)`
  width: 100%;
`

export const ButtonPrimaryStyled = styled(Primary)`
  width: 100%;
`
