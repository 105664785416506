import { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import {
  findAdditionalOptionByContentType,
  getSearchObject,
} from "utils/search"
import { dayTheme } from "theme/dayTheme"
import { EContentType, EOptionStatus, ESpacerSize } from "entities/common"
import { useGetTariffQuery, usePostTariffOptionStatusMutation } from "store/api"
import { RootState } from "store"
import { ITariffOptionStatusRequest } from "entities/tariffOptionsStatus"
import { getCurrentExtendedData } from "pages/Home/utils"
import {
  Button,
  Container,
  Header,
  Icon,
  MPTypography,
  Spacer,
  Table,
  Typography,
} from "components"
import { routes } from "constants/routes"
import { Styled } from "./styles"
import {
  ACTIVATE_SERVICE,
  conditionAgreement,
  DEACTIVATE_SERVICE,
  DESCRIPTION,
  howItWorks,
  HOW_SERVICE_WORK,
  ITS_FREE,
  packageIncluded,
  questions,
  TITLE,
  WHAT_IS_GUARDIAN,
} from "./constants"

const TitleComponent = ({ children }: { children: any }) => (
  <Typography.H3>{children}</Typography.H3>
)

export const SecureAccountGuardian = () => {
  const { fromUrl, optionId: externalOptionId } = getSearchObject()
  const theme = useTheme()
  const navigate = useNavigate()
  const { currentPhone, phone } = useSelector((state: RootState) => state.login)
  const [isChangeBacground, setIsChangeBacground] = useState<boolean>(false)
  const [currentOptionStatus, setCurrentOptionStatus] =
    useState<EOptionStatus>()

  const heightFromContainerRef = useRef<HTMLDivElement>(null)
  const heightFromHeader = useRef<HTMLDivElement>(null)

  // получение данных
  const tariffResponse = useGetTariffQuery({
    numbers: [currentPhone],
  })

  // получение данных по текущему номеру
  const currentExtendedData = useMemo(
    () =>
      getCurrentExtendedData(
        tariffResponse.data?.data?.extendedData,
        currentPhone
      ),
    [tariffResponse.data, currentPhone]
  )

  // проверка что хранитель подключен
  const isSecureAccountActive =
    findAdditionalOptionByContentType(
      EContentType.SecureAccount,
      currentExtendedData?.connectedOptions?.additionalOptions
    )?.active === true

  /* получение optionId из разлчных объектов
   * в зависимости подключена услуга или нет */
  const getOptionId = (isSecureAccount: boolean) => {
    const targetObject = isSecureAccount
      ? currentExtendedData?.connectedOptions?.additionalOptions
      : currentExtendedData?.availableOptions?.additionalOptions

    return findAdditionalOptionByContentType(
      EContentType.SecureAccount,
      targetObject
    )?.optionId.toString()
  }

  const optionId = getOptionId(isSecureAccountActive) ?? externalOptionId

  // подготовка данных на подключение/отключение услуги
  const prepareRequestData = (optionStatus: EOptionStatus) => {
    const requestData: ITariffOptionStatusRequest = {
      status: optionStatus,
      optionId: `${optionId}`,
    }
    if (phone === currentPhone) {
      requestData.phone = phone
    } else {
      requestData.additionalNumbers = currentPhone
    }

    return requestData
  }

  // мутация отправки данных
  const [triggerTariffOptionStatus, status] =
    usePostTariffOptionStatusMutation()

  // непосредственно отправка данных подключение/отключения услуги
  const sendData = (optionStatus: EOptionStatus) => {
    setCurrentOptionStatus(optionStatus)
    triggerTariffOptionStatus(prepareRequestData(optionStatus))
  }

  useEffect(() => {
    // редирект на страницу "спасибо"
    if (status.isSuccess || status.isError) {
      switch (fromUrl) {
        case "showOptions":
          return navigate(
            `${routes.operationStatusCommon}?isSuccess=${
              status.isSuccess ? true : false
            }&oprationType=${currentOptionStatus}`
          )
        default:
          return navigate(
            `${routes.operationStatusCommon}?isSuccess=${
              status.isSuccess ? true : false
            }&oprationType=${currentOptionStatus}&redirectUrl=${routes.main}`
          )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, currentOptionStatus])

  useEffect(() => {
    const hideOrShowRow = () => {
      if (heightFromContainerRef.current && heightFromHeader.current) {
        if (
          window.scrollY >
          heightFromContainerRef.current.clientHeight -
            heightFromHeader.current.clientHeight
        ) {
          setIsChangeBacground(true)
        } else {
          setIsChangeBacground(false)
        }
      }
    }

    hideOrShowRow()

    window.addEventListener("scroll", hideOrShowRow)
    window.addEventListener("resize", hideOrShowRow)

    return () => {
      window.addEventListener("scroll", hideOrShowRow)
      window.removeEventListener("resize", hideOrShowRow)
    }
  }, [])

  // TODO: Сделать нормальную верстку
  const ButtonArea = () => (
    <>
      {isSecureAccountActive ? null : <Spacer height={12} />}
      <Styled.PaddingContainer>
        {isSecureAccountActive ? (
          <>
            <MPTypography typographyType="Body2" textAlign="center">
              <Styled.UnderlineSpan
                onClick={() => navigate(routes.guardianAgreement)}
              >
                Подробнее об условиях оказания услуги Хранитель
              </Styled.UnderlineSpan>
            </MPTypography>
            <Spacer height={12} />
            <Button.MNPPlain onClick={() => sendData(EOptionStatus.Disabled)}>
              <MPTypography
                typographyType="Body2Bold"
                color={theme.colors.text.critical}
              >
                {DEACTIVATE_SERVICE}
              </MPTypography>
            </Button.MNPPlain>
          </>
        ) : (
          <Button.MPPrimary onClick={() => sendData(EOptionStatus.Active)}>
            <MPTypography
              color={dayTheme.colors.background.surface1}
              typographyType="Body2Bold"
            >
              {ACTIVATE_SERVICE}
            </MPTypography>
          </Button.MPPrimary>
        )}
      </Styled.PaddingContainer>
      <Spacer height={16} />
      {!isSecureAccountActive ? (
        <Styled.PaddingContainer>
          <MPTypography typographyType="Body2" textAlign="center">
            <>
              {conditionAgreement[0]}
              <br />
              {conditionAgreement[1]}
              {" "}
              {
                <Styled.UnderlineSpan
                  onClick={() => navigate(routes.guardianAgreement)}
                >
                  {conditionAgreement[2]}
                </Styled.UnderlineSpan>
              }
              {" "}
              {conditionAgreement[3]}
            </>
          </MPTypography>
        </Styled.PaddingContainer>
      ) : null}
      <Spacer height={ESpacerSize.M} />
    </>
  )

  const onBackClick = () => {
    if (fromUrl === "showOptions") {
      navigate(routes.services)
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <Container
        position="relative"
        page
        backgroundColor={theme.colors.background.surface1}
      >
        <Styled.HeaderContainer ref={heightFromContainerRef}>
          <div ref={heightFromHeader}>
            <Header
              onBackClick={onBackClick}
              background={
                isChangeBacground
                  ? theme.colors.background.surface1
                  : theme.colors.transparent.pure
              }
            />
          </div>
          <Styled.ShieldContainer>
            <Icon.Guardian />
          </Styled.ShieldContainer>
        </Styled.HeaderContainer>

        <Styled.SecureContainer>
          <Typography.H2>{TITLE}</Typography.H2>
          <Spacer height={ESpacerSize.S} />
          <MPTypography>{DESCRIPTION}</MPTypography>
          <Spacer height={ESpacerSize.XL} />
          <Typography.Body1Bold
            fontSize={20}
            lineHeight={25}
            color={theme.colors.speedometer.internet}
          >
            {ITS_FREE}
          </Typography.Body1Bold>
        </Styled.SecureContainer>
        <Table.Section
          title={HOW_SERVICE_WORK}
          titleComponent={TitleComponent}
          titlePadding={{ paddingTop: ESpacerSize.XL }}
          cellContainerMargin={{
            marginTop: ESpacerSize.M,
            marginBottom: ESpacerSize.L,
          }}
          hasDivider={false}
          generalBackground={theme.colors.background.surface0}
        >
          {howItWorks.map(({ id, title, icon }) => (
            <Table.CommonCell
              key={id}
              title={title}
              titleSpacer={12}
              startIcon={
                <Styled.IconWrapper
                  hasBackground
                  svgColor={theme.colors.text.primary}
                >
                  {icon}
                </Styled.IconWrapper>
              }
            />
          ))}
        </Table.Section>
        <Table.Section
          title={WHAT_IS_GUARDIAN}
          titleComponent={TitleComponent}
          titlePadding={{ paddingTop: ESpacerSize.XL }}
          cellContainerMargin={{
            marginTop: ESpacerSize.M,
            marginBottom: ESpacerSize.L,
          }}
          hasDivider={false}
          generalBackground={theme.colors.background.surface1}
        >
          {packageIncluded.map(({ id, title, subtitle, icon }) => (
            <Table.CommonCell
              key={id}
              hasDivider
              title={
                <Typography.Body1Bold fontSize={17}>
                  {title}
                </Typography.Body1Bold>
              }
              subTitle={<MPTypography>{subtitle}</MPTypography>}
              titleSpacer={12}
              startIcon={
                <Styled.IconWrapper
                  svgColor={theme.colors.speedometer.internet}
                >
                  {icon}
                </Styled.IconWrapper>
              }
            />
          ))}
        </Table.Section>
        <Table.Section
          title="Ответы на вопросы"
          titleComponent={TitleComponent}
          titlePadding={{ paddingTop: ESpacerSize.XL }}
          cellContainerMargin={{
            marginTop: ESpacerSize.M,
            marginBottom: ESpacerSize.L,
          }}
          generalBackground={theme.colors.background.surface1}
          isDividerUnderline
        >
          {questions.map((element, index) => (
            <Table.CommonCell
              key={index}
              title={element.title}
              endIcon={<Icon.ArrowRight />}
              onClick={() => navigate(element.route)}
            />
          ))}
        </Table.Section>
        <Spacer height={ESpacerSize.S} />

        {isSecureAccountActive ? (
          <>
            <ButtonArea />
          </>
        ) : (
          <Styled.FixedFooter>
            <ButtonArea />
          </Styled.FixedFooter>
        )}
        {/* TODO: Сделать нормально */}
        {isSecureAccountActive ? null : <Spacer height={130} />}
      </Container>
    </>
  )
}
