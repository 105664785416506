import styled from "styled-components"

export const Black = styled.button`
  background: ${(props) => props.theme.colors.button.black};
  border-radius: 16px;
  font-family: "SBSansUI Regular", serif;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.button.contentPrimary};
  padding: 17px 0;
  font-weight: 700;
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
`
