import { useEffect, FC } from "react"
import { useNavigate } from "react-router-dom"
import { getSearchObject } from "utils/search"
import { sendMetric } from "utils/metrics"
import { OperationStatus, Spacer } from "components"
import { routes } from "constants/routes"

export const AuraOperationStatus: FC = () => {
  const navigate = useNavigate()
  const { isNotSuccess } = getSearchObject()
  const isSuccess = !isNotSuccess
  const title = isSuccess ? "Заявка принята" : "Заявка не отправлена"
  const buttonTitle = isSuccess ? "Отлично" : "Понятно"

  useEffect(() => {
    sendMetric({
      Услуги: {
        Доступные: {
          Аура: { "Отправка заявки_Показ": [isSuccess ? "Успех" : "Ошибка"] },
        },
      },
    })
  }, [isSuccess])

  const handleSubmit = () => {
    navigate(routes.services)
  }

  return (
    <OperationStatus
      title={title}
      isSuccess={isSuccess}
      continueHandler={handleSubmit}
      continueButtonTitle={buttonTitle}
    >
      {isSuccess ? (
        <span>Услуга подключится в течение 15 минут</span>
      ) : (
        <>
          <span>Произошла ошибка</span>
          <br />
          <Spacer height={4} />
          <span>Пожалуйста, повторите попытку</span>
        </>
      )}
    </OperationStatus>
  )
}
