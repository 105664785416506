import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { formatPhoneNumber } from "utils"
import { usePostSendConfirmationMutation } from "store/api"
import { ESendConfirmationType } from "entities/common"
import { RootState } from "store"
import { useTheme } from "styled-components"
import { sendMetric } from "utils/metrics"
import { Header, Signing, Typography, Container } from "components"
import { routes } from "constants/routes"

export const AuraSigning = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [triggerPostSendConfirmation] = usePostSendConfirmationMutation()
  const { currentPhone } = useSelector((state: RootState) => state.login)

  const onClickContinue = () => {
    sendMetric({ Услуги: { Доступные: { Аура: ["Подписать_Клик"] } } })
    navigate(`${routes.login}?aura=true`)
    triggerPostSendConfirmation({
      number: currentPhone,
      type: ESendConfirmationType.AuraActivation,
    })
  }

  return (
    <Container page>
      <Header title="Подписание" background={theme.colors.background[1]} />
      <Signing onContinue={onClickContinue}>
        <Typography.Body1 lineHeight={22}>
          {`Подключаем услугу "Аура" — защита от мошенничества на номер `}
          {formatPhoneNumber(currentPhone)}
        </Typography.Body1>
      </Signing>
    </Container>
  )
}
