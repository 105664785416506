import React, {
  FC,
  KeyboardEvent,
  MutableRefObject,
  useMemo,
  useEffect,
  useRef,
  useState,
} from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { formatPhoneNumber } from "utils"
import {
  useGetAdditionalNumberQuery,
  useGetContractQuery,
  useGetCustomerProfileQuery,
  // useGetSubQuery,
  useGetUserConfigQuery,
  usePostAdditionalNumberAliasMutation,
  // useSberSubUnbindMutation,
} from "store/api"
import { RootState } from "store"
import { EDocumentStatus } from "entities/contract"
import { sendMetric } from "utils/metrics"
import { prepareCurrentFeatureConfig } from "utils/userConfig"
import { Header, Icon, Loader, Table } from "components"
import { routes } from "constants/routes"
import {
  Body1Styled,
  DeleteButtonStyled,
  ESimActiveIconStyled,
  H4Styled,
  InputStyled,
  RootStyled,
  SimActiveIconStyled,
} from "./styles"
import { DeleteNumberSheet } from "./DeleteNumberSheet"
import { WaitInGoskey } from "./Banners/WaitInGoskey"
import { NotSigned } from "./Banners/NotSigned"
import { Signed } from "./Banners/Signed"
import { Skeleton } from "./Skeleton"
//!!! закомментил получение и отвязывание саба
const Phone: FC = () => {
  const {
    state: { phone, isMainNumber },
  } = useLocation()

  const navigate = useNavigate()
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenDeleteNumber, setOpenDeleteNumber] = useState(false)
  const [aliasValue, setAliasValue] = useState("")
  const { phone: phoneStore, currentPhone } = useSelector(
    (state: RootState) => state.login
  )

  // const [triggerUnbind, statusUnbind] = useSberSubUnbindMutation()
  // const {
  //   data: subData,
  //   refetch: refetchSub,
  //   isFetching: isSubFetching,
  // } = useGetSubQuery()
  const { data: contractData, isLoading: isContractLoading } =
    useGetContractQuery({ number: phone })

  const {
    data: numbersData,
    isLoading: isNumbersDataLoading,
    isFetching,
    refetch: numberDataRefetch,
    isSuccess,
  } = useGetAdditionalNumberQuery()

  const { data: customerData, isLoading: isCustomerLoading } =
    useGetCustomerProfileQuery({ number: phone })
  const [trigger, status] = usePostAdditionalNumberAliasMutation()
  const { data: userConfigData } = useGetUserConfigQuery()

  const currentFeatureConfig = useMemo(
    () =>
      prepareCurrentFeatureConfig(
        currentPhone,
        userConfigData?.data?.userConfigs
      ),
    [currentPhone, userConfigData?.data?.userConfigs]
  )

  // useEffect(() => {
  //   if (statusUnbind.isSuccess) {
  //     refetchSub()
  //     statusUnbind.reset()
  //   }
  // })

  useEffect(() => {
    if (isSuccess) {
      const rawAlias = isMainNumber
        ? numbersData?.data?.alias
        : numbersData?.data?.additionalNumbers.find(
            (element) => element.number === phone
          )?.alias
      const totalAlias = rawAlias !== phone ? rawAlias : ""
      setAliasValue(totalAlias ?? "")
    }
  }, [
    isSuccess,
    numbersData?.data?.additionalNumbers,
    phone,
    isMainNumber,
    numbersData?.data,
  ])

  useEffect(() => {
    if (status.isSuccess) {
      numberDataRefetch()
      setIsLoading(false)
    }
  }, [numberDataRefetch, status.isSuccess])

  const getStatus = () => {
    if (customerData?.data.simType === "ESIM") {
      return `eSIM • ${customerData?.data.profileStatusTitle || ""}`
    }
    if (customerData?.data.simType === "SIM") {
      return "Обычная сим-карта"
    }
    return ""
  }

  const renderGoskeyButton = () => {
    switch (customerData?.data?.documentStatus) {
      case EDocumentStatus.NotSigned:
        return <NotSigned isCommonCeil />
      case EDocumentStatus.WaitInGoskey:
        return <WaitInGoskey isCommonCeil />
      default:
        return (
          <Signed
            isShowPage={currentFeatureConfig?.isContractEnabled}
            isCommonCeil
            contractUrl={contractData?.data.contract.url}
          />
        )
    }
  }

  const onBlur = (newAlias?: string) => {
    const requestNumber = isMainNumber ? "" : phone
    if (!newAlias) {
      trigger({ alias: requestNumber, number: requestNumber })
    } else {
      trigger({ alias: newAlias, number: requestNumber })
    }

    setIsLoading(true)
  }

  const onKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      inputRef.current?.blur()
    }
  }

  const onPersonalDataClick = () => {
    sendMetric({ Профиль: ["Данные абонента_Клик"] })
    navigate(
      `${routes.personalInfo}${
        phone !== phoneStore ? `?additionalNumber=${phone}` : ""
      }`
    )
  }

  return (
    <>
      {(isLoading || (!isNumbersDataLoading && isFetching)) && <Loader />}
      {/* {(isLoading ||
        (!isNumbersDataLoading && isFetching) ||
        isSubFetching ||
        statusUnbind.isLoading) && <Loader />} */}
      <RootStyled>
        <Header onBackClick={() => navigate(routes.menu)} title="Профиль" />
        {isCustomerLoading || isNumbersDataLoading || isContractLoading ? (
          <Skeleton />
        ) : (
          <>
            {customerData?.data.simType === "SIM" && <SimActiveIconStyled />}
            {customerData?.data.simType === "ESIM" && <ESimActiveIconStyled />}
            <InputStyled
              ref={inputRef}
              value={aliasValue || ""}
              onChange={(e) => setAliasValue(e.target.value)}
              onBlur={(e) => onBlur(e.target.value)}
              onKeyUp={onKeyUp}
              placeholder="Добавить имя"
              maxLength={20}
              enterKeyHint="done"
              onClick={() => sendMetric({ Ещё: ["Ввод алиаса_Клик"] })}
              disabled={!currentFeatureConfig?.isAliasEnabled}
            />
            <H4Styled>{formatPhoneNumber(phone)}</H4Styled>
            <Body1Styled>{getStatus()}</Body1Styled>
            <Table.Body>
              <Table.Section>
                {renderGoskeyButton()}
                {currentFeatureConfig?.isPersonalInfoEnabled && (
                  <Table.CommonCell
                    startIcon={<Icon.PersonalData />}
                    title="Данные абонента"
                    endIcon={<Icon.ArrowRight />}
                    onClick={onPersonalDataClick}
                  />
                )}
              </Table.Section>
            </Table.Body>

            {/* {phoneStore === phone && subData?.data.existSub && (
              <DeleteButtonStyled onClick={() => triggerUnbind({})}>
                Выйти из Сбер ID
              </DeleteButtonStyled>
            )} */}

            {phoneStore !== phone && (
              <DeleteButtonStyled onClick={() => setOpenDeleteNumber(true)}>
                Удалить номер
              </DeleteButtonStyled>
            )}
            <DeleteNumberSheet
              isOpen={isOpenDeleteNumber}
              onClose={() => setOpenDeleteNumber(false)}
              phone={phone || ""}
            />
          </>
        )}
      </RootStyled>
    </>
  )
}

export { Phone }
