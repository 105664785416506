import React, { FC, ReactElement } from "react"
import { useNavigate } from "react-router-dom"
import { sendMetric } from "utils/metrics"
import { v4 as uuidv4 } from "uuid"
import { ITabBarItem } from "../TabBarItem"
import { Typography } from "../Typography"
import { StyledBar } from "./styles"

interface ITabBar {
  children: (ReactElement<ITabBarItem> | null)[]
  default: string
  path: string
}

type OnClickType = (
  innerClickHandler?: () => void,
  innerPath?: string,
  metric?: any
) => void

const TabBar: FC<ITabBar> = ({ children, path, default: defaultPath }) => {
  const navigate = useNavigate()
  const onClick: OnClickType = (innerClickHandler, innerPath, metric) => {
    if (innerClickHandler) {
      return innerClickHandler()
    } else {
      if (metric) {
        sendMetric(metric)
      }
      return navigate(innerPath ?? defaultPath)
    }
  }
  return (
    <>
      <StyledBar.Wrapper>
        {children.filter(
          (tab) =>
            tab && (tab.props.path === path || tab.props.alias?.includes(path))
        )}
      </StyledBar.Wrapper>
      <StyledBar.TabBar>
        <StyledBar.Container>
          {children.map((item) =>
            item ? (
              <StyledBar.Item
                active={
                  item.props.path === path || !!item.props.alias?.includes(path)
                }
                onClick={() =>
                  onClick(
                    item.props.onClick,
                    item.props.path,
                    item.props.metric
                  )
                }
                key={uuidv4()}
              >
                {item.props.path === path || item.props.alias?.includes(path)
                  ? item.props.iconActive
                  : item.props.icon}
                <Typography.Caption>{item.props.title}</Typography.Caption>
              </StyledBar.Item>
            ) : (
              <></>
            )
          )}
        </StyledBar.Container>
      </StyledBar.TabBar>
    </>
  )
}

export { TabBar }
