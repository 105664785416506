import React, { FC, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { useGetRoamingQuery } from "store/api"
import { RootState } from "store"
import { v4 as uuidv4 } from "uuid"
import { useActions } from "hooks/actions"
import { IContentOption, IRoamingOption } from "entities/roaming"
import { EContentContainer } from "entities/common"
import { useScrollToTop } from "hooks/useScrollToTop"
import { PullToRefresh } from "components/PullToRefresh"
import { Option } from "pages/Services/Option"
import {
  Container,
  Header,
  Icon,
  Typography,
  Table,
  Sheet,
  ServiceStatus,
  Loader,
} from "components"
import { Countries } from "../ChooseCountry/Countries"
import { routes } from "constants/routes"
import { Styled } from "./styles"
import { getFlagIcon, getPriceIcon } from "./utils"

const RoamingOptions: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { setRoamingCountry } = useActions()
  const [isShowCountries, setShowCountries] = useState(false)
  const [isShowPrices, setShowPrices] = useState(false)
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const [roamingOption, setRoamingOption] = useState<{
    roamingOption: IRoamingOption | IContentOption
    isContentOption: boolean
  } | null>(null)
  useScrollToTop()
  const { roamingCountry } = useSelector((state: RootState) => state.common)
  if (!roamingCountry) {
    navigate(routes.roamingChooseCountry)
  }
  const { data, isLoading, refetch, isFetching } = useGetRoamingQuery({
    code: roamingCountry?.code ?? "",
    phone: currentPhone,
  })

  return (
    <>
      {(isLoading || isFetching) && <Loader />}
      <Container page backgroundColor={theme.colors.background[0]}>
        <Header
          background={theme.colors.background[0]}
          onBackClick={() => navigate(routes.main)}
        />
        <PullToRefresh
          onPullEnd={refetch}
          isFetching={isFetching}
          iconTopOffset="-30px"
        >
          <Styled.Country>
            <Styled.Flag>{getFlagIcon(roamingCountry?.code ?? "")}</Styled.Flag>
            <Styled.Info>
              <Styled.Title
                onClick={() => navigate(routes.roamingChooseCountry)}
              >
                <Typography.H3>
                  {`Роуминг ${roamingCountry?.rusName}`}
                </Typography.H3>
                <Icon.ArrowRight />
              </Styled.Title>
              <Typography.Body2>
                Страна влияет на состав и стоимость услуг
              </Typography.Body2>
            </Styled.Info>
          </Styled.Country>
          <Table.Body noPadding>
            {!!data?.data.otherCountries?.length && (
              <Table.Section backgroundColor={theme.colors.background[0]}>
                <Table.CommonCell
                  title={`Условия и услуги действуют ещё для ${data.data.otherCountries.length} стран`}
                  startIcon={<Icon.Info />}
                  endIcon={<Icon.ArrowRight />}
                  onClick={() => setShowCountries(true)}
                />
              </Table.Section>
            )}
            {!!data?.data.prices.length && (
              <Table.Section
                backgroundColor={theme.colors.background[0]}
                title="Базовые условия"
                titleComponent={Typography.Body1Bold}
                endIcon={<Icon.ArrowRight />}
                onClick={() => setShowPrices(true)}
              >
                {data.data.prices
                  .filter((option) => option.visible)
                  .map((option) => (
                    <Table.CommonCell
                      key={uuidv4()}
                      title={option.label}
                      subTitle={option.subTitle}
                      titleInfo={`${option.cost} ${option.currPerItem}`}
                      startIcon={getPriceIcon(option.imageType)}
                    />
                  ))}
              </Table.Section>
            )}
          </Table.Body>
          <Table.Body>
            {(!!data?.data.roamingOptions?.length ||
              !!data?.data.contentOptions?.length) && (
              <Table.Section
                title="Дополнительные услуги"
                titleComponent={Typography.Body1Bold}
                spacedView
              >
                {!!data?.data.roamingOptions?.length &&
                  data.data.roamingOptions.map((option) => (
                    <div
                      onClick={() =>
                        setRoamingOption({
                          roamingOption: option,
                          isContentOption: false,
                        })
                      }
                    >
                      <Option
                        data={option}
                        isAvailableService={!option.active}
                        arrayName={EContentContainer.RoamingOptions}
                        isRoamingServices
                      />
                    </div>
                  ))}
                {!!data?.data.contentOptions?.length &&
                  data.data.contentOptions.map((option) => (
                    <div
                      onClick={() =>
                        setRoamingOption({
                          roamingOption: option,
                          isContentOption: true,
                        })
                      }
                    >
                      <Option
                        data={option}
                        isAvailableService={!option.active}
                        arrayName={EContentContainer.ContentOptions}
                        isRoamingServices
                      />
                    </div>
                  ))}
              </Table.Section>
            )}
          </Table.Body>
          {data?.data.otherCountries && (
            <Sheet
              onClose={() => setShowCountries(false)}
              isOpen={isShowCountries}
            >
              <Sheet.CommonContainer horizontalPadding={0}>
                <Sheet.CommonHeader>
                  <Typography.H4>
                    Условия действуют ещё для {data.data.otherCountries.length}
                     стран
                  </Typography.H4>
                </Sheet.CommonHeader>
                <Table.Body noPadding>
                  <Countries
                    countries={data.data.otherCountries}
                    onClick={(country) =>
                      setRoamingCountry(country) && setShowCountries(false)
                    }
                    backgroundColor={theme.colors.background[1]}
                  />
                </Table.Body>
              </Sheet.CommonContainer>
            </Sheet>
          )}
          {data?.data.prices && (
            <Sheet onClose={() => setShowPrices(false)} isOpen={isShowPrices}>
              <Sheet.CommonContainer horizontalPadding={0}>
                <Sheet.CommonHeader>
                  <Typography.H4>Базовые условия</Typography.H4>
                </Sheet.CommonHeader>
                <Table.Body noPadding>
                  <Table.Section>
                    {data.data.prices.map((option) => (
                      <Table.CommonCell
                        key={uuidv4()}
                        title={option.label}
                        subTitle={option.subTitle}
                        titleInfo={`${option.cost} ${option.currPerItem}`}
                        startIcon={getPriceIcon(option.imageType)}
                      />
                    ))}
                  </Table.Section>
                </Table.Body>
              </Sheet.CommonContainer>
            </Sheet>
          )}
          {roamingOption && (
            <Sheet
              onClose={() => setRoamingOption(null)}
              isOpen={!!roamingOption}
            >
              <ServiceStatus
                isAvailableOptions={!roamingOption.roamingOption.active}
                data={roamingOption.roamingOption}
                onClose={() => setRoamingOption(null)}
                isRoaming
                isContentOption={roamingOption.isContentOption}
              />
            </Sheet>
          )}
        </PullToRefresh>
      </Container>
    </>
  )
}

export { RoamingOptions }
