import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { MyTariffWidget } from "widgets"
import { RootState } from "store"
import {
  useGetAdditionalNumberQuery,
  useGetBannersQuery,
  useGetConnectedAvailableQuery,
  useGetNumberTransferOrderQuery,
  useGetTariffQuery,
  useGetUserConfigQuery,
} from "store/api"
import { useActions } from "hooks/actions"
import { EReceiveStatus } from "entities/tariff"
import { EClientType, EContentType } from "entities/common"
import { PullToRefresh } from "components/PullToRefresh"
import { sendMetric } from "utils/metrics"
import { EStatusType, IOptionElement } from "entities/connectedAvailable"
import { IServiceStatus } from "components/ServiceStatus/interfaces"
import { BannerGroup } from "components/BannerGroup/BannerGroup"
import { useScrollToTop } from "hooks/useScrollToTop"
import { SecureAccountBanner } from "processes"
import { findAdditionalOptionByContentType } from "utils/search"
import {
  Balance,
  Icon,
  PhoneSelect,
  Table,
  useModalConfirm,
  useModalDecline,
  Typography,
  Button,
  Spacer,
  Container,
  Sheet,
  ServiceStatus,
  Loader,
  NumberTransferBanner,
  EnabledPackages,
  CommonModal,
} from "components"
import { urls } from "constants/urls"
import {
  APP_DOWNLOAD_LINK,
  BLANK,
  MOVE_TO,
  OK,
  PRIME_MODAL_TITLE,
  SBER_PRIME,
} from "constants/common"
import { routes } from "constants/routes"
import {
  calculateAuraFee,
  getAmountNeedToPayTariff,
  getAvailableBanners,
  getBannerServices,
  getContentForNeedPayModal,
  getCurrentExtendedData,
  getNeedPayNumberList,
  getNumberData,
  getPackageServices,
  getPhonesFromData,
  getPhonesWithAdditionalData,
  hasNumberTransferBanner,
  prepareAmountPay,
} from "./utils"
import { Skeleton } from "./Skeleton"
import { Banners } from "./Banners"
import { PayButtonStyled, WrapBalance, PrimeIcon } from "./styles"
import { PrimeSheet } from "./PrimeSheet"
import { DetailsSection } from "./DetailsSection"

const Home: FC = () => {
  const navigate = useNavigate()
  const [isChangeOrderLoading, setIsChangeOrderLoading] = useState(false)
  const [serviceStatusContent, setServiceStatusContent] =
    useState<IServiceStatus | null>(null)
  const { phone, currentPhone } = useSelector((state: RootState) => state.login)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const {
    data: bannerData,
    isLoading: isGetBannersLoading,
    isFetching: isGetBannersFetching,
    refetch: getBannersRefetch,
  } = useGetBannersQuery({ phones: [currentPhone] })
  const currentBunners = getAvailableBanners(currentPhone, bannerData)

  const { roamingCountry } = useSelector((state: RootState) => state.common)
  const { showedMessageAboutInsufficientBalance } = useSelector(
    (state: RootState) => state.common
  )
  const {
    setCurrentPhone,
    openModal,
    closeModal,
    setShowedMessageAboutInsufficientBalance,
    setOptionStatusTitle,
  } = useActions()

  const {
    data: numbersData,
    refetch: numberDataRefetch,
    isFetching: isNumbersDataFetching,
    isLoading: isNumbersDataLoading,
  } = useGetAdditionalNumberQuery()
  const { data: numberTransferOrder, refetch: numberTransferOrderRefetch } =
    useGetNumberTransferOrderQuery()

  const {
    data: userConfigData,
    refetch: userConfigDataRefetch,
    isLoading: isUserConfigLoading,
    isFetching: isUserConfigDataFetching,
  } = useGetUserConfigQuery()

  const currentUserConfig = useMemo(
    () =>
      userConfigData?.data?.userConfigs?.find(
        (item) => item.number === currentPhone
      ),
    [userConfigData, currentPhone]
  )

  const { data: connectedAvailable, refetch: refetchGetConnectedAvailable } =
    useGetConnectedAvailableQuery({
      phone: currentPhone,
    })

  const packageServices = getPackageServices(
    connectedAvailable?.data?.enabledOptions
  ) as IOptionElement[]

  const bannerServices = getBannerServices(
    connectedAvailable?.data?.availableOptions
  ) as IOptionElement[]

  const phonesList = useMemo(
    () => getPhonesFromData(numbersData?.data.additionalNumbers, phone),
    [numbersData, phone]
  )

  const phones = useMemo(
    () => getPhonesWithAdditionalData(numbersData?.data),
    [numbersData]
  )

  useScrollToTop()

  useEffect(() => {
    if (currentPhone === "") {
      setCurrentPhone(phone)
    }
  }, [currentPhone, phone, setCurrentPhone])

  const {
    data,
    isLoading: isTariffDataLoading,
    isFetching: isDataFetching,
    refetch: dataRefetch,
  } = useGetTariffQuery(
    {
      numbers: phonesList,
    },
    { skip: !numbersData }
  )

  // tariffData для конкретного номера
  const currentExtendedData = useMemo(
    () => getCurrentExtendedData(data?.data?.extendedData, currentPhone),
    [data, currentPhone]
  )
  const needPayNumberList = useMemo(
    () => data && getNeedPayNumberList(data.data.extendedData),
    [data]
  )
  const onPhoneChange = (value: string) => {
    setCurrentPhone(value)
    setOptionStatusTitle(null)
  }
  const onClickSberPrime = () => window.open(APP_DOWNLOAD_LINK, BLANK)?.focus()

  const numberData = useMemo(
    () => getNumberData(numbersData?.data, phone, currentPhone),
    [numbersData, currentPhone, phone]
  )

  const isFetching =
    isDataFetching ||
    isNumbersDataFetching ||
    isUserConfigDataFetching ||
    isGetBannersFetching

  const refreshComponent = useCallback(() => {
    numberDataRefetch()
    userConfigDataRefetch()
    dataRefetch()
    refetchGetConnectedAvailable()
    numberTransferOrderRefetch()
    getBannersRefetch()
  }, [
    numberDataRefetch,
    userConfigDataRefetch,
    dataRefetch,
    refetchGetConnectedAvailable,
    numberTransferOrderRefetch,
    getBannersRefetch,
  ])

  const onClickPayButton = () => {
    // navigate(routes.topUp)
    window.open(
      `${urls.pay}&phone=${currentPhone}&amount=${prepareAmountPay(
        currentPhone,
        data
      )}`
    )
  }

  const isSecureAccountActive =
    findAdditionalOptionByContentType(
      EContentType.SecureAccount,
      currentExtendedData?.connectedOptions?.additionalOptions
    )?.statusType === EStatusType.Active

  const renderNumberData = () => {
    switch (numberData?.operatorType) {
      case 1:
        return (
          <Container>
            <PullToRefresh isFetching={isFetching} onPullEnd={refreshComponent}>
              <>
                <Typography.Body1Bold>
                  Вы пока не наш абонент
                </Typography.Body1Bold>
                <Spacer height={2} />
                <Typography.Body2>
                  Оформите SIM-карту или перейдите со своим номером, чтобы
                  пользоваться всеми преимуществами СберМобайл
                </Typography.Body2>
                <Spacer height={22} />
                <Button.Primary onClick={() => window.open(urls.orderSim)}>
                  Посмотреть тарифы
                </Button.Primary>
                <Spacer height={8} />
                <Button.Secondary onClick={() => window.open(urls.transfer)}>
                  Перейти со своим номером
                </Button.Secondary>
              </>
            </PullToRefresh>
          </Container>
        )
      case 0:
        if (
          numberData.clientType === EClientType.B2C ||
          numberData.clientType === EClientType.B2B2C
        ) {
          return (
            <>
              <BannerGroup
                key={currentPhone}
                bannerServices={bannerServices}
                featureConfig={currentUserConfig?.featureConfig}
                currentBunners={currentBunners}
                auraPrice={calculateAuraFee(
                  currentExtendedData?.availableOptions.additionalOptions
                )}
              />
              <WrapBalance
                isBalanceEnabled={
                  currentUserConfig?.featureConfig?.isBalanceEnabled
                }
              >
                {currentUserConfig?.featureConfig?.isBalanceEnabled && (
                  <Balance
                    date={data?.time ?? ""}
                    value={currentExtendedData?.balance.balanceValue ?? 0}
                  />
                )}
                {currentUserConfig?.featureConfig?.isButtonPaymentEnabled && (
                  <PayButtonStyled onClick={onClickPayButton}>
                    Пополнить
                  </PayButtonStyled>
                )}
              </WrapBalance>
              <PullToRefresh
                isFetching={isFetching}
                onPullEnd={refreshComponent}
              >
                <>
                  {currentExtendedData?.receiveStatus ===
                    EReceiveStatus.DeprecatedBaseTariff && (
                    <Container>
                      <Typography.Body1Bold>
                        Ваш тариф не поддерживается
                      </Typography.Body1Bold>
                      <Spacer height={2} />
                      <Typography.Body2>
                        Чтобы получить доступ к функциям приложения, смените
                        тариф самостоятельно в приложении или обратитесь в
                        поддержку
                      </Typography.Body2>
                      <Spacer height={32} />
                      <Button.Primary
                        onClick={() => window.open(urls.orderSim)}
                      >
                        Сменить тариф
                      </Button.Primary>
                    </Container>
                  )}
                  {currentExtendedData?.receiveStatus === EReceiveStatus.Ok && (
                    <Table.Body>
                      <Table.Section>
                        <Table.Cell>
                          <MyTariffWidget
                            setServiceStatusContent={setServiceStatusContent}
                            tariffData={currentExtendedData}
                            showTariffData={!currentUserConfig?.isInactiveEsim}
                          />
                        </Table.Cell>
                      </Table.Section>
                      <SecureAccountBanner isActive={isSecureAccountActive} />
                      <Banners />
                      {numberTransferOrder?.data &&
                        hasNumberTransferBanner(
                          numberTransferOrder.data,
                          currentPhone
                        ) && (
                          <NumberTransferBanner
                            data={numberTransferOrder.data.statuses[0]}
                          />
                        )}
                      {!currentUserConfig?.isInactiveEsim &&
                        currentUserConfig?.featureConfig
                          ?.isSberPrimeBannerEnabledV2 && (
                          <Table.Section>
                            <Table.CommonCell
                              title="СберПрайм"
                              subTitle="Управление подпиской"
                              startIcon={<PrimeIcon />}
                              endIcon={<Icon.ArrowRight />}
                              onClick={() => setIsOpenModal(true)}
                            />
                          </Table.Section>
                        )}
                      {currentUserConfig?.featureConfig
                        ?.isMainScreenRoamingButtonEnabled && (
                        <Table.Section>
                          <Table.CommonCell
                            title="Роуминг"
                            subTitle="Связь в поездках по миру"
                            startIcon={<Icon.Roaming />}
                            endIcon={<Icon.ArrowRight />}
                            onClick={() =>
                              navigate(
                                roamingCountry
                                  ? routes.roamingOptions
                                  : routes.roamingChooseCountry
                              )
                            }
                          />
                        </Table.Section>
                      )}
                      <DetailsSection currentPhone={currentPhone} />
                      {currentUserConfig?.featureConfig
                        ?.isAdditionalOptionsEnabled &&
                        packageServices?.length > 0 && (
                          <EnabledPackages
                            packageServices={packageServices}
                            setServiceStatusContent={setServiceStatusContent}
                          />
                        )}
                    </Table.Body>
                  )}
                  {currentExtendedData?.receiveStatus ===
                    EReceiveStatus.NoTariff && (
                    <Table.Body>
                      <Table.Section>
                        <Table.Cell>
                          <MyTariffWidget
                            setServiceStatusContent={setServiceStatusContent}
                            tariffData={currentExtendedData}
                            showTariffData={!currentUserConfig?.isInactiveEsim}
                          />
                        </Table.Cell>
                      </Table.Section>
                      <SecureAccountBanner isActive={isSecureAccountActive} />
                      <Banners />
                      {numberTransferOrder?.data &&
                        hasNumberTransferBanner(
                          numberTransferOrder.data,
                          currentPhone
                        ) && (
                          <NumberTransferBanner
                            data={numberTransferOrder.data.statuses[0]}
                          />
                        )}
                      {!currentUserConfig?.isInactiveEsim &&
                        currentUserConfig?.featureConfig
                          ?.isSberPrimeBannerEnabledV2 && (
                          <Table.Section>
                            <Table.CommonCell
                              title="СберПрайм"
                              subTitle="Управление подпиской"
                              startIcon={<PrimeIcon />}
                              endIcon={<Icon.ArrowRight />}
                              onClick={() => setIsOpenModal(true)}
                            />
                          </Table.Section>
                        )}
                      <DetailsSection currentPhone={currentPhone} />
                    </Table.Body>
                  )}
                </>
              </PullToRefresh>
            </>
          )
        } else {
          return (
            <Container>
              <PullToRefresh
                isFetching={isFetching}
                onPullEnd={refreshComponent}
              >
                <>
                  <Typography.Body1Bold>
                    Данные по тарифу пока недоступны
                  </Typography.Body1Bold>
                  <Spacer height={2} />
                  <Typography.Body2>
                    Мы добавим их в ближайшее время. Всю необходимую информацию
                    вы можете найти в разделе «Вопросы и ответы»
                  </Typography.Body2>
                  <Spacer height={22} />
                  <Button.Primary onClick={() => window.open(urls.faq)}>
                    Вопросы и ответы
                  </Button.Primary>
                </>
              </PullToRefresh>
            </Container>
          )
        }
      default:
        return <></>
    }
  }

  useModalConfirm(() => {
    sendMetric({
      ГлавныйЭкран: {
        МодальноеОкно: {
          "Скоро списание абонентской платы — пополните баланс":
            "ПополнитьБалансКлик",
        },
      },
    })
    const payedNumber = needPayNumberList?.[0] ?? currentPhone
    window.open(
      `${urls.pay}&phone=${payedNumber}&amount=${
        needPayNumberList?.length &&
        getAmountNeedToPayTariff(needPayNumberList[0], data?.data.extendedData)
      }`
    )
    closeModal()
  })

  useModalDecline(() => {
    sendMetric({
      "Главный экран": {
        "Модальный экран": {
          "Скоро списаниеАП": ["Не сейчас_Клик"],
        },
      },
    })
  })

  useEffect(() => {
    if (
      needPayNumberList &&
      data &&
      needPayNumberList.length > 0 &&
      !showedMessageAboutInsufficientBalance
    ) {
      setShowedMessageAboutInsufficientBalance(true)
      openModal(
        getContentForNeedPayModal(needPayNumberList, data.data.extendedData)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needPayNumberList])

  const isLoading =
    isTariffDataLoading || isUserConfigLoading || isGetBannersLoading

  if (isLoading && isFetching) {
    return <Skeleton />
  }

  return (
    <>
      <CommonModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        title={SBER_PRIME}
        subtitle={PRIME_MODAL_TITLE}
        declineHandler={() => setIsOpenModal(false)}
        confirmLabel={MOVE_TO}
        confirmHandler={onClickSberPrime}
        declineLabel={OK}
        isTitleCentered
      />
      {(isChangeOrderLoading ||
        (!isNumbersDataLoading && isNumbersDataFetching)) && <Loader />}
      <Sheet
        isOpen={!!serviceStatusContent}
        onClose={() => setServiceStatusContent(null)}
      >
        {!!serviceStatusContent && (
          <ServiceStatus
            isInHomePage
            onClose={() => setServiceStatusContent(null)}
            data={serviceStatusContent.data}
            isAvailableOptions={serviceStatusContent.isAvailableOptions}
            isContentOption={serviceStatusContent.isContentOption}
          />
        )}
      </Sheet>
      <PhoneSelect
        alias={numberData?.alias}
        current={currentPhone}
        onChange={onPhoneChange}
        phones={phones}
        setIsChangeOrderLoading={setIsChangeOrderLoading}
      />
      {renderNumberData()}
      {!isLoading && !isFetching && <PrimeSheet />}
    </>
  )
}

export { Home }
