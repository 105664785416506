import React, { FC, memo, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { RootState } from "store"
import { EThemeMetricNames, EThemeNames } from "theme/interfaces"
import { settingsActions } from "store/slice/settingsSlice"
import { useTheme } from "styled-components"
import { sendMetric } from "utils/metrics"
import { Header, Table } from "components"
import { CheckIconStyled, RootStyled } from "./styles"

export const Theme: FC = memo(() => {
  const dispatch = useDispatch()
  const { currentTheme } = useSelector((state: RootState) => state.settings)
  const theme = useTheme()
  const navigate = useNavigate()
  const prepareThemeMetricName = useCallback(() => {
    switch (currentTheme) {
      case EThemeNames.DayTheme:
        return EThemeMetricNames.DayTheme

      case EThemeNames.NightTheme:
        return EThemeMetricNames.NightTheme

      default:
        return EThemeMetricNames.SystemTheme
    }
  }, [currentTheme])

  useEffect(() => {
    sendMetric({
      Ещё: { "Тема приложения": { Открытие: [prepareThemeMetricName()] } },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeThemeMetric = (themeName: EThemeMetricNames) =>
    sendMetric({
      Ещё: { "Тема приложения": { Смена_Клик: [themeName] } },
    })

  const onBackClick = () => {
    sendMetric({
      Ещё: { "Тема приложения": { Назад_Клик: [prepareThemeMetricName()] } },
    })
    navigate(-1)
  }

  return (
    <RootStyled>
      <Header
        title="Тема приложения"
        onBackClick={onBackClick}
        background={theme.colors.background[0]}
      />
      <Table.Body noPadding={false} padding={16}>
        <Table.Section>
          <Table.CommonCell
            title={EThemeMetricNames.DayTheme}
            key={uuidv4()}
            endIcon={
              currentTheme === EThemeNames.DayTheme ? (
                <CheckIconStyled />
              ) : undefined
            }
            onClick={() => {
              dispatch(settingsActions.setTheme(EThemeNames.DayTheme))
              changeThemeMetric(EThemeMetricNames.DayTheme)
            }}
          />
          <Table.CommonCell
            title={EThemeMetricNames.NightTheme}
            key={uuidv4()}
            endIcon={
              currentTheme === EThemeNames.NightTheme ? (
                <CheckIconStyled />
              ) : undefined
            }
            onClick={() => {
              dispatch(settingsActions.setTheme(EThemeNames.NightTheme))
              changeThemeMetric(EThemeMetricNames.NightTheme)
            }}
          />
          <Table.CommonCell
            title={EThemeMetricNames.SystemTheme}
            key={uuidv4()}
            endIcon={
              currentTheme === EThemeNames.SystemTheme ? (
                <CheckIconStyled />
              ) : undefined
            }
            onClick={() => {
              dispatch(settingsActions.setTheme(EThemeNames.SystemTheme))
              changeThemeMetric(EThemeMetricNames.SystemTheme)
            }}
          />
        </Table.Section>
      </Table.Body>
    </RootStyled>
  )
})
