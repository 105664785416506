import React, { FC, TouchEvent } from "react"
import { IModal } from "./interfaces"
import { ModalStyle } from "./styles"

const Modal: FC<IModal> = ({ open, onClose, children }) => {
  const stopPropagation = (e: TouchEvent) => e.stopPropagation()

  return open ? (
    <ModalStyle.Container
      onTouchStart={stopPropagation}
      onTouchMove={stopPropagation}
      onTouchEnd={stopPropagation}
    >
      <ModalStyle.Backdrop onClick={() => onClose()} />
      <ModalStyle.Dialog>{children}</ModalStyle.Dialog>
    </ModalStyle.Container>
  ) : (
    <></>
  )
}

export { Modal }
