import React, { FC, useState } from "react"
import { useGetPersonalInfoQuery } from "store/api"
import { useNavigate } from "react-router"
import { sendMetric } from "utils/metrics"
import {
  Sheet,
  Typography,
  Spacer,
  Form,
  Button,
  CommonModal,
} from "components"
import { routes } from "constants/routes"
import { formPersonalData } from "./formData"

interface ICheckInfoSheetProps {
  isOpen: boolean
  onClose: () => void
  phoneNumber: string
}

const CheckInfoSheet: FC<ICheckInfoSheetProps> = ({
  isOpen,
  onClose,
  phoneNumber,
}) => {
  const navigate = useNavigate()
  const [isChangeModalOpen, setChangeModalOpen] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const { data } = useGetPersonalInfoQuery({
    additionalNumber: phoneNumber,
  })
  return (
    <>
      <CommonModal
        isOpen={isChangeModalOpen}
        onClose={() => setChangeModalOpen(false)}
        title="Изменить персональные данные в СберМобайл?"
        subtitle="Заявка на перенос номера не сохранится"
        declineLabel="Позже"
        declineHandler={() => {
          sendMetric({
            MNP: {
              "Данные абонента": {
                "Не совпадают в СберМобайл_Клик": ["Позже_Клик"],
              },
            },
          })
          navigate(routes.main)
        }}
        confirmLabel="Да, изменить"
        confirmHandler={() => {
          sendMetric({
            MNP: {
              "Данные абонента": {
                "Не совпадают в СберМобайл_Клик": ["Да, изменить_Клик"],
              },
            },
          })
          navigate(routes.personalInfo)
        }}
      />
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Измените персональные данные у вашего оператора"
        subtitle="Далее, вновь создайте заявку на перенос номера"
        declineHandler={() => navigate(routes.main)}
      />
      <Sheet isOpen={isOpen} onClose={onClose}>
        <Sheet.CommonContainer>
          <Sheet.CommonHeader>
            <Typography.H4>Проверьте данные</Typography.H4>
          </Sheet.CommonHeader>
          <Spacer height={32} />
          <Typography.Body1>
            Номер будет перенесён, если указанные ниже данные совпадают
            с данными у вашего оператора
          </Typography.Body1>
          <Spacer height={16} />
          {data && (
            <Form
              onSubmit={() => {
                sendMetric({
                  MNP: { "Данные абонента": ["Данные совпадают_Клик"] },
                })
                navigate(routes.numberTransferSigning)
              }}
              modules={formPersonalData(data.data)}
              padding="0"
            >
              <Spacer height={32} />
              <Button.Primary>Данные совпадают</Button.Primary>
              <Spacer height={8} />
              <Button.Secondary as="a" onClick={() => setChangeModalOpen(true)}>
                Не совпадают в СберМобайл
              </Button.Secondary>
              <Spacer height={8} />
              <Button.Secondary
                as="a"
                onClick={() => {
                  sendMetric({
                    MNP: {
                      "Данные абонента": [
                        "Не совпадают у вашего оператора_Клик",
                      ],
                    },
                  })
                  setModalOpen(true)
                }}
              >
                Не совпадают у вашего оператора
              </Button.Secondary>
            </Form>
          )}
        </Sheet.CommonContainer>
      </Sheet>
    </>
  )
}

export { CheckInfoSheet }
