import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IFormValidationState {
  isValid?: boolean
}

const initialState: IFormValidationState = {
  isValid: undefined,
}

export const formValidationSlice = createSlice({
  name: "formValidation",
  initialState,
  reducers: {
    setValid: (state, { payload }: PayloadAction<IFormValidationState>) => {
      state.isValid = payload.isValid
    },
  },
})

export const formValidationActions = formValidationSlice.actions

export default formValidationSlice.reducer
