import { ReactComponent as SberMobile } from "./SberMobile.svg"
import { ReactComponent as DeleteCircle } from "./DeleteCircle.svg"
import { ReactComponent as SberPay } from "./SberPay.svg"
import { ReactComponent as ArrowLeft } from "./ArrowLeft.svg"
import { ReactComponent as Home } from "./Home.svg"
import { ReactComponent as TopUp } from "./Topup.svg"
import { ReactComponent as Menu } from "./Menu.svg"
import { ReactComponent as HomeActive } from "./HomeActive.svg"
import { ReactComponent as TopUpActive } from "./TopUpActive.svg"
import { ReactComponent as MenuActive } from "./MenuActive.svg"
import { ReactComponent as Loader } from "./Loader.svg"
import { ReactComponent as ArrowRight } from "./ArrowRight.svg"
import { ReactComponent as OrderSim } from "./OrderSim.svg"
import { ReactComponent as Arc } from "./Arc.svg"
import { ReactComponent as WebOrange } from "./WebOrange.svg"
import { ReactComponent as Prime } from "./Prime.svg"
import { ReactComponent as Plus } from "./Plus.svg"
import { ReactComponent as PhoneOrange } from "./PhoneOrange.svg"
import { ReactComponent as EmailRed } from "./EmailRed.svg"
import { ReactComponent as ESim } from "./ESim.svg"
import { ReactComponent as Map } from "./Map.svg"
import { ReactComponent as Question } from "./Question.svg"
import { ReactComponent as Sim } from "./Sim.svg"
import { ReactComponent as Support } from "./Support.svg"
import { ReactComponent as Like } from "./Like.svg"
import { ReactComponent as Message } from "./Message.svg"
import { ReactComponent as Music } from "./Music.svg"
import { ReactComponent as Play } from "./Play.svg"
import { ReactComponent as Discount40 } from "./Discount40.svg"
import { ReactComponent as Web } from "./Web.svg"
import { ReactComponent as Phone } from "./Phone.svg"
import { ReactComponent as Pdf } from "./Pdf.svg"
import { ReactComponent as Email } from "./Email.svg"
import { ReactComponent as Percent } from "./Percent.svg"
import { ReactComponent as Secure } from "./Secure.svg"
import { ReactComponent as NoRefresh } from "./NoRefresh.svg"
import { ReactComponent as Play2 } from "./Play2.svg"
import { ReactComponent as Pencil } from "./Pencil.svg"
import { ReactComponent as Exit } from "./Exit.svg"
import { ReactComponent as SocialService } from "./SocialService.svg"
import { ReactComponent as MusicService } from "./MusicService.svg"
import { ReactComponent as VideoService } from "./VideoService.svg"
import { ReactComponent as MessengerService } from "./MessengerService.svg"
import { ReactComponent as Cross } from "./Cross.svg"
import { ReactComponent as ArrowDown } from "./ArrowDown.svg"
import { ReactComponent as CirclePlus } from "./CirclePlus.svg"
import { ReactComponent as ESimActive } from "./ESimActive.svg"
import { ReactComponent as SimActive } from "./SIMActive.svg"
import { ReactComponent as Sms } from "./Sms.svg"
import { ReactComponent as Shield } from "./Shield.svg"
import { ReactComponent as CarryOver } from "./CarryOver.svg"
import { ReactComponent as Social } from "./Social.svg"
import { ReactComponent as Messager } from "./Messager.svg"
import { ReactComponent as Video } from "./Video.svg"
import { ReactComponent as Music2 } from "./Music2.svg"
import { ReactComponent as AutorenewalBlock } from "./AutorenewalBlock.svg"
import { ReactComponent as Tethering } from "./Tethering.svg"
import { ReactComponent as Sber } from "./Sber.svg"
import { ReactComponent as Umbrella } from "./Umbrella.svg"
import { ReactComponent as Salut } from "./Salut.svg"
import { ReactComponent as Check } from "./Check.svg"
import { ReactComponent as AgreementNotSigned } from "./AgreementNotSigned.svg"
import { ReactComponent as Bank } from "./Bank.svg"
import { ReactComponent as Home2 } from "./Home2.svg"
import { ReactComponent as Mobile } from "./Mobile.svg"
import { ReactComponent as CoatOfArms } from "./CoatOfArms.svg"
import { ReactComponent as Key } from "./Key.svg"
import { ReactComponent as Document } from "./Document.svg"
import { ReactComponent as AgreementSigned } from "./AgreementSigned.svg"
import { ReactComponent as Office } from "./Office.svg"
import { ReactComponent as House } from "./House.svg"
import { ReactComponent as InfoIcon } from "./InfoIcon.svg"
import { ReactComponent as ClearIcon } from "./ClearIcon.svg"
import { ReactComponent as Checkbox } from "./Checkbox.svg"
import { ReactComponent as Services } from "./Services.svg"
import { ReactComponent as ServicesActive } from "./ServicesActive.svg"
import { ReactComponent as Autorenewal } from "./Autorenewal.svg"
import { ReactComponent as AuraPerson } from "./AuraPerson.svg"
import { ReactComponent as Pause } from "./Pause.svg"
import { ReactComponent as Signature } from "./Signature.svg"
import { ReactComponent as Lock } from "./Lock.svg"
import { ReactComponent as Moon } from "./Moon.svg"
import { ReactComponent as AuraRobot } from "./AuraRobot.svg"
import { ReactComponent as Present } from "./Present.svg"
import { ReactComponent as Zvooq } from "./Zvooq.svg"
import { ReactComponent as SberId } from "./SberId.svg"
import { ReactComponent as SberPrime } from "./SberPrime.svg"
import { ReactComponent as Phone2 } from "./Phone2.svg"
import { ReactComponent as SubmitArrowRight } from "./SubmitArrowRight.svg"
import { ReactComponent as Diamond } from "./Diamond.svg"
import { ReactComponent as Hamburger } from "./Hamburger.svg"
import { ReactComponent as PersonalData } from "./PersonalData.svg"
import { ReactComponent as UploadFile } from "./UploadFile.svg"
import { ReactComponent as PaymentAdjustment } from "./PaymentAdjustment.svg"
import { ReactComponent as AdjustmentNumber } from "./AdjustmentNumber.svg"
import { ReactComponent as AdjustmentAmount } from "./AdjustmentAmount.svg"
import { ReactComponent as NotSuccess } from "./NotSuccess.svg"
import { ReactComponent as Info } from "./Info.svg"
import { ReactComponent as Signing } from "./Signing.svg"
import { ReactComponent as Telegram } from "./Telegram.svg"
import { ReactComponent as EngineeringWorks } from "./EngineeringWorks.svg"
import { ReactComponent as Pie } from "./Pie.svg"
import { ReactComponent as MoveNumber } from "./MoveNumber.svg"
import { ReactComponent as NumberTransferDecline } from "./NumberTransferDecline.svg"
import { ReactComponent as NumberTransferSuccess } from "./NumberTransferSuccess.svg"
import { ReactComponent as NumberTransferProgress } from "./NumberTransferProgress.svg"
import { ReactComponent as FieldCross } from "./FieldCross.svg"
import { ReactComponent as FieldDate } from "./FieldDate.svg"
import { ReactComponent as FieldTime } from "./FieldTime.svg"
import { ReactComponent as Bin } from "./Bin.svg"
import { ReactComponent as RoamingBanner } from "./RoamingBanner.svg"
import { ReactComponent as SupportChat } from "./SupportChat.svg"
import { ReactComponent as SupportChatActive } from "./SupportChatActive.svg"
import { ReactComponent as Internet24 } from "./Internet24.svg"
import { ReactComponent as IncomingCall } from "./IncomingCall.svg"
import { ReactComponent as OutgoingCall } from "./OutgoingCall.svg"
import { Flag } from "./flags"
import { ReactComponent as EsimHand } from "./EsimHand.svg"
import { ReactComponent as YAPhone } from "./YAPhone.svg"
import { ReactComponent as InfinityBanner } from "./InfinityBanner.svg"
import { ReactComponent as AuraBanner } from "./AuraBanner.svg"
import { ReactComponent as DocTimer } from "./DocTimer.svg"
import { ReactComponent as DocGoskluch } from "./DocGoskluch.svg"
import { ReactComponent as Roaming } from "./Roaming.svg"
import { ReactComponent as Blocked } from "./Blocked.svg"
import { ReactComponent as BlockedBig } from "./BlockedBig.svg"
import { ReactComponent as AccountShield } from "./AccountShield.svg"
import { ReactComponent as Ruble } from "./Ruble.svg"
import { ReactComponent as RublePlus } from "./RublePlus.svg"
import { ReactComponent as PhoneFilled } from "./PhoneFilled.svg"
import { ReactComponent as WarningFilled } from "./WarningFilled.svg"
import { ReactComponent as ChangeNumberFAQ1 } from "./ChangeNumberFAQ1.svg"
import { ReactComponent as ChangeNumberFAQ2 } from "./ChangeNumberFAQ2.svg"
import { ReactComponent as ChangeNumberFAQ3 } from "./ChangeNumberFAQ3.svg"
import { ReactComponent as ChangeNumberFAQ4 } from "./ChangeNumberFAQ4.svg"
import { ReactComponent as ChangeNumberFAQ5 } from "./ChangeNumberFAQ5.svg"
import { ReactComponent as ChangeNumberFAQ6 } from "./ChangeNumberFAQ6.svg"
import { ReactComponent as Guardian } from "./Guardian.svg"
import { ReactComponent as ShieldPlain } from "./ShieldPlain.svg"
import { ReactComponent as SecureAccountShield } from "./SecureAccountShield.svg"
import { ReactComponent as SecureAccountDisabled } from "./SecureAccountDisabled.svg"
import { ReactComponent as SecureAccountAcctive } from "./SecureAccountAcctive.svg"
import { ReactComponent as SecureAccountServicesAura } from "./SecureAccountServicesAura.svg"
import { ReactComponent as SecureAccountServicesAssistant } from "./SecureAccountServicesAssistant.svg"
import { ReactComponent as PhoneInGrayCircle } from "./PhoneInGrayCircle.svg"
import { ReactComponent as RubleInGrayCircle } from "./RubleInGrayCircle.svg"
import { ReactComponent as ExclamationMarkInGrayCircle } from "./ExclamationMarkInGrayCircle.svg"
import { ReactComponent as PhoneIncomingCall } from "./PhoneIncomingCall.svg"
import { ReactComponent as PhoneMissingCall } from "./PhoneMissingCall.svg"
import { ReactComponent as RecordingCall } from "./RecordingCall.svg"
import { ReactComponent as PhoneIncomingCallInGrayCircle } from "./PhoneIncomingCallInGrayCircle.svg"
import { ReactComponent as PhoneMissingCallInGrayCircle } from "./PhoneMissingCallInGrayCircle.svg"
import { ReactComponent as PhoneDarkGrey } from "./PhoneDarkGrey.svg"
import { ReactComponent as OpenLockDarkGrey } from "./OpenLockDarkGrey.svg"
import { ReactComponent as Heart } from "./Heart.svg"
import { ReactComponent as Spasibo } from "./Spasibo.svg"
import { ReactComponent as SberGradient } from "./SberGradient.svg"
import { ReactComponent as TwoGis } from "./TwoGis.svg"
import { ReactComponent as PauseCircle } from "./PauseCircle.svg"

export const Icon = {
  ChangeNumberFAQ1,
  ChangeNumberFAQ2,
  ChangeNumberFAQ3,
  ChangeNumberFAQ4,
  ChangeNumberFAQ5,
  ChangeNumberFAQ6,
  Ruble,
  RublePlus,
  PhoneFilled,
  WarningFilled,
  Flag,
  Blocked,
  BlockedBig,
  Roaming,
  IncomingCall,
  OutgoingCall,
  Internet24,
  SupportChat,
  SupportChatActive,
  Bin,
  NumberTransferDecline,
  NumberTransferSuccess,
  NumberTransferProgress,
  FieldCross,
  FieldDate,
  FieldTime,
  Info,
  EngineeringWorks,
  Telegram,
  Signing,
  NotSuccess,
  AdjustmentNumber,
  AdjustmentAmount,
  PaymentAdjustment,
  UploadFile,
  Present,
  Zvooq,
  Office,
  House,
  SberMobile,
  DeleteCircle,
  SberPay,
  ArrowLeft,
  Home,
  TopUp,
  Menu,
  HomeActive,
  TopUpActive,
  MenuActive,
  Loader,
  ArrowRight,
  OrderSim,
  Arc,
  WebOrange,
  Prime,
  Plus,
  PhoneOrange,
  EmailRed,
  ESim,
  Map,
  Question,
  Sim,
  Support,
  Like,
  Message,
  Music,
  Play,
  Discount40,
  Web,
  Phone,
  Pdf,
  Email,
  Percent,
  Secure,
  NoRefresh,
  Play2,
  Pencil,
  Exit,
  SocialService,
  MusicService,
  VideoService,
  MessengerService,
  Cross,
  ArrowDown,
  CirclePlus,
  ESimActive,
  SimActive,
  Sms,
  Shield,
  CarryOver,
  Social,
  Messager,
  Video,
  Music2,
  AutorenewalBlock,
  Tethering,
  Sber,
  Umbrella,
  Salut,
  Check,
  AgreementNotSigned,
  Bank,
  Home2,
  Mobile,
  CoatOfArms,
  Key,
  Document,
  AgreementSigned,
  InfoIcon,
  ClearIcon,
  Checkbox,
  Services,
  ServicesActive,
  Autorenewal,
  AuraPerson,
  Pause,
  Signature,
  Lock,
  Moon,
  AuraRobot,
  SberId,
  SberPrime,
  Phone2,
  SubmitArrowRight,
  Diamond,
  Hamburger,
  PersonalData,
  Pie,
  MoveNumber,
  RoamingBanner,
  EsimHand,
  YAPhone,
  InfinityBanner,
  AuraBanner,
  DocTimer,
  DocGoskluch,
  AccountShield,
  Guardian,
  ShieldPlain,
  SecureAccountShield,
  SecureAccountDisabled,
  SecureAccountAcctive,
  SecureAccountServicesAura,
  SecureAccountServicesAssistant,
  PhoneInGrayCircle,
  RubleInGrayCircle,
  ExclamationMarkInGrayCircle,
  PhoneIncomingCall,
  PhoneMissingCall,
  RecordingCall,
  PhoneIncomingCallInGrayCircle,
  PhoneMissingCallInGrayCircle,
  PhoneDarkGrey,
  OpenLockDarkGrey,
  Heart,
  Spasibo,
  SberGradient,
  TwoGis,
  PauseCircle,
}
