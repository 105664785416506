import styled from "styled-components"
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel"

const Carousel = styled(ResponsiveCarousel)`
  .slide {
    display: inline-flex;
    padding: 0 8px 0 0;
    text-align: left;
    max-width: 192px;
    min-width: 192px !important;
    box-sizing: content-box;
  }
  .slide:first-child {
    padding-left: 8px;
  }
`

export const Styled = { Carousel }
