import styled from "styled-components"
import { Typography } from "components"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.background[0]};
  flex-direction: column;
`

export const H4Styled = styled(Typography.H4)``

export const H1Styled = styled(Typography.H1)``

export const WrapPriceStyled = styled.div`
  display: flex;
  align-items: baseline;
`

export const Body2Styled = styled(Typography.Body2)``

export const PriceStyled = styled(Typography.Body1)`
  color: ${(props) => props.theme.colors.text.secondary};
  margin-left: 3px;
`

export const OldPriceStyled = styled(Typography.Body1)`
  color: ${(props) => props.theme.colors.text.secondary};
  margin-left: 3px;
  text-decoration: line-through;
`

export const WrapFooterStyled = styled.div`
  display: flex;
  justify-content: space-between;
`
