import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useActions } from "hooks/actions"
import { v4 as uuidv4 } from "uuid"
import {
  useGetAdditionalNumberQuery,
  useGetLinksQuery,
  useGetUserConfigQuery,
} from "store/api"
import { formatPhoneNumber } from "utils"
import { loginActions } from "store/slice/loginSlice"
import { EESimState, normalizeUserConfig } from "entities/userConfig"
import { ELinksCode } from "entities/common"
import { sendMetric } from "utils/metrics"
import { useTheme } from "styled-components"
import { isAnyB2COrB2B2C, prepareCurrentFeatureConfig } from "utils/userConfig"
import { RootState } from "store"
import { deleteCookie } from "utils/cookie"
import { Button, Icon, Sheet, Spacer, Table, Typography } from "components"
import { routes } from "constants/routes"
import { BLANK, SELF, TOKEN } from "constants/common"
import { About, Container, Section, SupportSheetContainer } from "./styles"
import { ConfirmLogout } from "./ConfirmLogout"
import { EFeatureConfigs } from "./interfaces"

export const Menu: FC = () => {
  const pathname = window.location.pathname
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { clear } = useActions()
  const [confirmLogoutShow, setConfirmLogoutShow] = useState(false)
  const [isOpenSupport, setOpenSupport] = useState(false)
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data } = useGetAdditionalNumberQuery()
  const { data: links } = useGetLinksQuery()
  const { data: userConfigData } = useGetUserConfigQuery()

  useEffect(() => {
    sendMetric({ Еще: { Открытие: [] } })
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const prepareTitle = (alias?: string, phone?: string) =>
    alias === phone ? (formatPhoneNumber(phone, true) as string) : alias

  const userConfigNormalized = useMemo(() => {
    return normalizeUserConfig(userConfigData)
  }, [userConfigData])

  const currentFeatureConfig = useMemo(
    () =>
      prepareCurrentFeatureConfig(
        currentPhone,
        userConfigData?.data?.userConfigs
      ),
    [currentPhone, userConfigData?.data?.userConfigs]
  )

  const logOutHandler = useCallback(() => {
    sendMetric({ Еще: { ВыходИзПриложенияКлик: [] } })
    deleteCookie(TOKEN, "")
    clear()
  }, [clear])
  const handleAddPhone = () => {
    sendMetric({ Еще: { ДобавитьНомерКлик: [] } })
    dispatch(loginActions.setShowSmsCode(false))
    navigate(`${routes.login}?additional=true`)
  }

  const additionalNumbers = useMemo(
    () =>
      [...(data?.data.additionalNumbers || [])].sort((a, b) => {
        return a.order - b.order
      }),
    [data]
  )

  const onClickNumber = (phone?: string) => {
    if (phone) {
      sendMetric({ Ещё: ["Номер_Клик"] })
      const isMainNumber = phone === data?.data.number
      sendMetric({ Еще: { НомерКлик: [] } })
      navigate(routes.phone, {
        state: {
          phone,
          isMainNumber,
        },
      })
    }
  }

  const onClickFAQ = () => {
    sendMetric({ Еще: { ВопросыКлик: [] } })
    if (links && links[ELinksCode.voprosy]) {
      navigate(routes.showExternalLink, {
        state: { link: links[ELinksCode.voprosy], pathname },
      })
    }
  }

  const onClickMoveNumber = () => {
    navigate(routes.numberTransferEntry)
  }

  const onClickCoverageMap = () => {
    sendMetric({ Еще: { КартаПокрытияКлик: [] } })
    if (links && links[ELinksCode.coverageMap]) {
      navigate(routes.showExternalLink, {
        state: { link: links[ELinksCode.coverageMap], pathname },
      })
    }
  }

  const onClickOrder = () => {
    sendMetric({ Еще: { ЗаказатьСимКартуКлик: [] } })
    if (links && links[ELinksCode.sberTariffs]) {
      window.open(links[ELinksCode.sberTariffs], BLANK)?.focus()
    }
  }

  const getStatusIcon = (number?: string) => {
    if (!number) {
      return <></>
    }
    if (
      [EESimState.ESIM_IDENTIFIED, EESimState.ESIM_NOT_IDENTIFIED].includes(
        userConfigNormalized?.[number]?.eSIMState
      )
    ) {
      return <Icon.ESim />
    }
    return <Icon.Sim />
  }

  const onThemeClick = () => {
    navigate(routes.theme)
  }

  const onBeautifulNumbersClick = () => {
    sendMetric({ Ещё: { "Красивые номера": ["Открытие"] } })
    navigate(routes.beautifulNumbers)
  }

  return (
    <Container>
      <Table.Body>
        <Table.Section title="Ваши номера">
          <Table.CommonCell
            title={prepareTitle(data?.data.alias, data?.data.number)}
            subTitle={
              data?.data.alias !== data?.data.number
                ? (formatPhoneNumber(data?.data.number, true) as string)
                : ""
            }
            startIcon={getStatusIcon(data?.data.number)}
            endIcon={<Icon.ArrowRight />}
            onClick={() => {
              onClickNumber(data?.data.number)
            }}
            isPhoneNumber
          />
          {additionalNumbers.map((item) => (
            <Table.CommonCell
              key={uuidv4()}
              title={prepareTitle(item.alias, item.number)}
              subTitle={
                item.alias !== item.number
                  ? (formatPhoneNumber(item.number, true) as string)
                  : ""
              }
              startIcon={getStatusIcon(item.number)}
              endIcon={<Icon.ArrowRight />}
              onClick={() => {
                onClickNumber(item.number)
              }}
              isPhoneNumber
            />
          ))}
          {currentFeatureConfig?.canBindPhoneNumber && (
            <Table.CommonCell
              title="Добавить номер"
              key={uuidv4()}
              onClick={handleAddPhone}
              startIcon={<Icon.CirclePlus />}
              color={theme.colors.text.warning}
            />
          )}
        </Table.Section>
        <Table.Section title="Услуги и сервисы">
          <Table.CommonCell
            title="Заказать SIM-карту"
            startIcon={<Icon.OrderSim />}
            endIcon={<Icon.ArrowRight />}
            onClick={onClickOrder}
          />
          {isAnyB2COrB2B2C(
            EFeatureConfigs.IsBeautifulNumbersEnabled,
            userConfigData?.data?.userConfigs
          ) && (
            <Table.CommonCell
              startIcon={<Icon.Diamond />}
              title="Красивые номера"
              endIcon={<Icon.ArrowRight />}
              onClick={onBeautifulNumbersClick}
            />
          )}
          {isAnyB2COrB2B2C(
            EFeatureConfigs.IsPaymentAdjustmentEnabled,
            userConfigData?.data?.userConfigs
          ) &&
            currentFeatureConfig?.isPaymentAdjustmentEnabled && (
              <Table.CommonCell
                startIcon={<Icon.PaymentAdjustment />}
                title="Корректировка платежа"
                endIcon={<Icon.ArrowRight />}
                onClick={() => navigate(routes.paymentAdjustment)}
              />
            )}
          {currentFeatureConfig?.isMnpEnabled && (
            <Table.CommonCell
              startIcon={<Icon.MoveNumber />}
              title="Перенести номер"
              endIcon={<Icon.ArrowRight />}
              onClick={onClickMoveNumber}
            />
          )}
          {currentFeatureConfig?.isMapEnabled && (
            <Table.CommonCell
              startIcon={<Icon.Map />}
              title="Карта покрытия"
              endIcon={<Icon.ArrowRight />}
              onClick={onClickCoverageMap}
            />
          )}
        </Table.Section>
        <Table.Section title="Помощь">
          {currentFeatureConfig?.isFAQEnabled && (
            <Table.CommonCell
              title="Вопросы и ответы"
              startIcon={<Icon.Question />}
              endIcon={<Icon.ArrowRight />}
              onClick={onClickFAQ}
            />
          )}
          {currentFeatureConfig?.isSupportCallEnabled && (
            <Table.CommonCell
              startIcon={<Icon.Support />}
              title="Позвонить в поддержку"
              endIcon={<Icon.ArrowRight />}
              onClick={() => {
                sendMetric({ Еще: { ПозвонитьВПоддержкуКлик: [] } })
                setOpenSupport(true)
              }}
            />
          )}
        </Table.Section>
        <Table.Section title="Настройки">
          {/*<Table.CommonCell*/}
          {/*  title="Настройки входа"*/}
          {/*  startIcon={<Icon.Lock />}*/}
          {/*  endIcon={<Icon.ArrowRight />}*/}
          {/*/>*/}
          <Table.CommonCell
            startIcon={<Icon.Moon />}
            title="Тема приложения"
            endIcon={<Icon.ArrowRight />}
            onClick={onThemeClick}
          />
        </Table.Section>
      </Table.Body>
      <Section showAboutSection={currentFeatureConfig?.isAboutAppEnabled}>
        {currentFeatureConfig?.isAboutAppEnabled && (
          <About>
            <Typography.Body2>Приложение СберМобайл</Typography.Body2>
            <Typography.Caption>
              Версия {process.env.REACT_APP_VERSION}
            </Typography.Caption>
          </About>
        )}
        <Button.Bordered
          onClick={() => {
            sendMetric({ Еще: ["ВыходИзПриложенияКлик"] })
            setConfirmLogoutShow(true)
          }}
        >
          <Icon.Exit />
          Выйти
        </Button.Bordered>
      </Section>
      <ConfirmLogout
        isOpen={confirmLogoutShow}
        onClose={() => setConfirmLogoutShow(false)}
        onConfirm={logOutHandler}
      />
      <Sheet isOpen={isOpenSupport} onClose={() => setOpenSupport(false)}>
        <SupportSheetContainer>
          <Typography.H4>Позвонить в поддержку</Typography.H4>
          <Spacer height={16} />
          <Table.Body noPadding>
            <Table.Section>
              <Table.CommonCell
                title="+7 (499) 651-44-44"
                subTitle="Бесплатно по России для абонентов СберМобайл. По тарифу оператора для звонков с других операторов"
                startIcon={<Icon.Phone />}
                endIcon={<Icon.ArrowRight />}
                onClick={() => window.open("tel:+74996514444", SELF)}
              />
              <Table.CommonCell
                title="901"
                subTitle="Бесплатный короткий номер для абонента СберМобайл"
                startIcon={<Icon.Phone />}
                endIcon={<Icon.ArrowRight />}
                onClick={() => window.open("tel:901", "_self")}
              />
            </Table.Section>
          </Table.Body>
        </SupportSheetContainer>
      </Sheet>
    </Container>
  )
}
