import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { RootState } from "store"
import { TabBar } from "components/TabBar"
import { TabBarItem } from "components/TabBarItem"
import { Icon } from "components/Icons"
import { Home, Menu, SupportSetPhone } from "pages"
import { prepareCurrentFeatureConfig } from "utils/userConfig"
import { useGetUserConfigQuery } from "store/api"
import { Services } from "pages/Services"
import { routes } from "constants/routes"

const TabBarRouting: FC = () => {
  const location = useLocation()
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data: userConfigData } = useGetUserConfigQuery()

  const currentFeatureConfig = useMemo(
    () =>
      prepareCurrentFeatureConfig(
        currentPhone,
        userConfigData?.data?.userConfigs
      ),
    [currentPhone, userConfigData?.data?.userConfigs]
  )

  return (
    <TabBar default={routes.main} path={location.pathname}>
      <TabBarItem
        title="Главный"
        icon={<Icon.Home />}
        iconActive={<Icon.HomeActive />}
        component={<Home />}
        path={routes.main}
      />
      {!currentFeatureConfig ||
      currentFeatureConfig?.isMainScreenServicesButtonEnabled ? (
        <TabBarItem
          title="Услуги"
          icon={<Icon.Services />}
          iconActive={<Icon.ServicesActive />}
          component={<Services />}
          path={routes.services}
          alias={[routes.servicesConnected, routes.servicesAvailable]}
        />
      ) : null}
      {/* чаты */}
      {!currentFeatureConfig ||
      currentFeatureConfig?.isTabBarChatsEnabled_v2 ? (
        <TabBarItem
          title="Поддержка"
          icon={<Icon.SupportChat />}
          iconActive={<Icon.SupportChatActive />}
          component={<SupportSetPhone />}
          path={routes.supportSetPhone}
          metric={{
            Поддержка: ["Открытие"],
          }}
        />
      ) : null}
      <TabBarItem
        title="Еще"
        icon={<Icon.Menu />}
        iconActive={<Icon.MenuActive />}
        component={<Menu />}
        path={routes.menu}
      />
    </TabBar>
  )
}

export { TabBarRouting }
