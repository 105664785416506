import React, { FC, memo, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { loginActions } from "store/slice/loginSlice"
import { RootState } from "store"
import { formatPhoneNumber } from "utils/phone"
import {
  useGetLinksQuery,
  usePostGoskeySendMutation,
  usePostSendConfirmationMutation,
  usePostTariffAuraMutation,
} from "store/api"
import { getSearchObject } from "utils/search"
import { ELinksCode, ESendConfirmationType } from "entities/common"
import { useTheme } from "styled-components"
import { useActions } from "hooks/actions"
import { Header, Input } from "components"
import {
  FootnoteLinkStyled,
  FootnoteStyled,
  ServiceRulesWrap,
} from "../../styles"
import { BLANK } from "constants/common"
import {
  Body1Styled,
  Body2Styled,
  FooterStyled,
  H1Styled,
  LinkStyled,
  PhoneStyled,
  RootStyled,
  WrapInputStyled,
  WrapPhoneStyled,
} from "./styles"
import { SMS_CODE_LENGTH, SMS_TIMEOUT } from "./constants"
import { NoSmsSheet } from "./components/NoSmsSheet"

export const EnterSmsCode: FC<{ onBack: () => void }> = memo(({ onBack }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { goskey, aura } = getSearchObject()
  const {
    phone,
    currentPhone,
    displayPhone,
    error,
    isLoading,
    contract,
    signature,
  } = useSelector((state: RootState) => state.login)
  const { setError } = useActions()
  const [readOnly, setReadOnly] = useState(true)
  const { data: links } = useGetLinksQuery()
  const [timeLeft, setTimeLeft] = useState(SMS_TIMEOUT)
  const [trigger, goskeyStatus] = usePostGoskeySendMutation()
  const [triggerTariffAura, auraStatus] = usePostTariffAuraMutation()
  const [postSendConfirmationTrigger, confirmationStatus] =
    usePostSendConfirmationMutation()
  const minutes = ("00" + Math.floor(timeLeft / 60)).slice(-2)
  const seconds = ("00" + (timeLeft % 60)).slice(-2)

  const isLoadingData =
    goskeyStatus.isLoading ||
    auraStatus.isLoading ||
    confirmationStatus.isLoading ||
    isLoading

  const loginPhone = useMemo(() => {
    const { additional } = getSearchObject()
    return additional === "true" ? currentPhone : phone
  }, [currentPhone, phone])

  useEffect(() => {
    setTimeout(
      function run(value, setValue) {
        if (value > 0) {
          setTimeout(run, 1000)
          setValue(timeLeft - 1)
        }
      },
      1000,
      timeLeft,
      setTimeLeft
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft])

  useEffect(() => {
    setReadOnly(false)
  }, [])

  const onCodeChange = (value: string) => {
    if (error) {
      setError("")
    }

    if (value?.length === SMS_CODE_LENGTH) {
      if (goskey === "true") {
        if (currentPhone === phone) {
          trigger({ code: value })
        } else {
          trigger({ code: value, additionalNumber: currentPhone })
        }
      } else if (aura === "true") {
        const body: any = {
          signatureImage: signature,
          smsCode: value,
        }
        if (currentPhone !== phone) {
          body.additionalNumber = currentPhone
        }

        triggerTariffAura(body)
      } else {
        dispatch(loginActions.sendSmsStart(value))
      }
    }
  }

  const onCodeRefresh = () => {
    if (goskey === "true") {
      postSendConfirmationTrigger({
        number: currentPhone,
        type: ESendConfirmationType.SendDocumentToGoskey,
      })
    } else if (aura === "true") {
      postSendConfirmationTrigger({
        number: currentPhone,
        type: ESendConfirmationType.AuraActivation,
      })
    } else {
      dispatch(loginActions.loginStart(loginPhone))
    }
    setTimeLeft(SMS_TIMEOUT)
  }

  const onClickRules = () => {
    if (goskey === "true") {
      const linkSource = `data:application/pdf;base64,${contract}`
      const downloadLink = document.createElement("a")
      const fileName = "Договор.pdf"
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    } else {
      if (links && links[ELinksCode.Rules]) {
        window.open(links[ELinksCode.Rules], BLANK)?.focus()
      }
    }
  }

  const getServiceRules = () => {
    if (goskey === "true") {
      return (
        <>
          <FootnoteStyled as="span">
            Вводя код, вы подтверждаете правильность данных и отправку
          </FootnoteStyled>
          <FootnoteLinkStyled as="span" onClick={onClickRules}>
            договора
          </FootnoteLinkStyled>
          <FootnoteStyled as="span">на Госуслуги</FootnoteStyled>
        </>
      )
    } else if (aura === "true") {
      return (
        <FootnoteStyled>
          Вводя код из СМС, вы подтверждаете подключение услуги «Аура — защита
          от мошенничества»
        </FootnoteStyled>
      )
    }
    return (
      <>
        <FootnoteStyled>Вводя код из СМС, вы соглашаетесь</FootnoteStyled>
        <FootnoteLinkStyled onClick={onClickRules}>
          с Правилами сервиса
        </FootnoteLinkStyled>
      </>
    )
  }

  return (
    <RootStyled>
      <Header
        title="Подтверждение"
        onBackClick={onBack}
        background={theme.colors.background[1]}
      />
      <H1Styled>Введите код</H1Styled>
      <WrapPhoneStyled>
        <Body1Styled as="span">
          Отправили СМС с секретным кодом на&nbsp;номер
        </Body1Styled>
        <PhoneStyled as="span">
          {formatPhoneNumber(displayPhone || currentPhone || phone)}
        </PhoneStyled>
        {/*<Icon.Pencil onClick={onBack} />*/}
      </WrapPhoneStyled>
      <WrapInputStyled>
        <Input
          placeholder="Код из СМС"
          mask="999999"
          maskChar=""
          maskPlaceholder=""
          type="tel"
          onChange={onCodeChange}
          description={error}
          hasError={error?.length > 0}
          isLoading={isLoadingData}
          autoComplete="one-time-code"
          onFocus={() => window.scrollTo(0, 0)}
          readOnly={readOnly}
        />
      </WrapInputStyled>
      <ServiceRulesWrap>{getServiceRules()}</ServiceRulesWrap>
      <FooterStyled>
        {displayPhone && <NoSmsSheet />}
        {timeLeft > 0 && (
          <Body2Styled>
            До повторной отправки кода осталось {minutes}:{seconds}
          </Body2Styled>
        )}
        {timeLeft <= 0 && (
          <LinkStyled onClick={onCodeRefresh}>
            Повторить отправку кода
          </LinkStyled>
        )}
      </FooterStyled>
    </RootStyled>
  )
})
