const lookup: { [key: string]: string } = {
  "0": "0000",
  "1": "0001",
  "2": "0010",
  "3": "0011",
  "4": "0100",
  "5": "0101",
  "6": "0110",
  "7": "0111",
  "8": "1000",
  "9": "1001",
  a: "1010",
  b: "1011",
  c: "1100",
  d: "1101",
  e: "1110",
  f: "1111",
  A: "1010",
  B: "1011",
  C: "1100",
  D: "1101",
  E: "1110",
  F: "1111",
}

export const hexToBinary = (s: string) =>
  s.split("").reduce((acc, el) => acc + lookup[el], "")

export const getBitsFromHex = (
  value: string, // строка в HEX
  startWith: number, // номер первого бита
  count: number // сколько битов нужно взять
) =>
  hexToBinary(value)
    .split("")
    .reverse()
    .splice(startWith, count)
    .reverse()
    .join("")
