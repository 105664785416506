import { ICommonResponse } from "./common"

export interface IBannersRequest {
  phones: string[]
}

export interface IBannersResponse extends ICommonResponse {
  data: IData
}

interface IData {
  bannersData: IBannersDatum[]
}

interface IBannersDatum {
  number: string
  banners: IBanner[]
}

export interface IBanner {
  bannerType: EBannerType
}

export enum EBannerType {
  EsimIdentify = "ESIM_IDENTIFY",
  SignedByUser = "SIGNED_BY_USER",
  WaitInGoskey = "WAIT_IN_GOSKEY",
  Signed = "SIGNED",
  IPass = "IPASS",
  Aura = "AURA",
  Bezlimit20 = "BEZLIMIT20",
  YA = "YA",
  EsimEnabled = "ESIM_ENABLED",
  Spasibo = "SPASIBO",
  Roaming = "ROAMING",
}
