import { FAQWithButton } from "pages"
import { getSearchObject } from "utils/search"
import { ANDROID, IOS } from "constants/common"
import { BUTTON_TEXT, SUBTITLE, TITLE } from "./constants"

export const NoLimitSberKids = () => {
  const { theme: externalTheme, system, appName } = getSearchObject()
  const buttonLink = () => {
    switch ((system ?? "").toLowerCase()) {
      case IOS:
      case ANDROID:
        return `${appName}://open_external?source=https%3A%2F%2Fsberbank.com%2Fsms%2Faboutkids%3Futm_source%3Dsber_mobile_app%26utm_term%3Dsber_mobile_tariff_kids`
      // ссылка для PWA
      default:
        return ""
    }
  }

  return (
    <FAQWithButton
      buttonText={BUTTON_TEXT}
      buttonLink={buttonLink()}
      subtitle={SUBTITLE}
      externalTheme={externalTheme}
      title={TITLE}
      system={system}
    />
  )
}
