import { FAQWithButton } from "pages"
import { getSearchObject } from "utils/search"
import { sectionContent, SECTION_TITLE, SUBTITLE, TITLE } from "./constants"

export const BonusPay = () => {
  const { theme: externalTheme } = getSearchObject()

  return (
    <FAQWithButton
      subtitle={SUBTITLE}
      externalTheme={externalTheme}
      title={TITLE}
      sectionContent={sectionContent}
      sectionTitle={SECTION_TITLE}
    />
  )
}
