import React from "react"
import { ERoamingImageType } from "entities/roaming"
import { Icon } from "components"

export const getFlagIcon = (code: string) => {
  if (code in Icon.Flag) {
    const Flag = Icon.Flag[code]
    return <Flag viewBox="0 0 36 36" width="64" height="64" />
  }
  return <></>
}

export const getPriceIcon = (code: ERoamingImageType) => {
  switch (code) {
    case ERoamingImageType.CalCenter:
      return <Icon.Support />
    case ERoamingImageType.Internet:
      return <Icon.Internet24 />
    case ERoamingImageType.IncomingCall:
      return <Icon.IncomingCall />
    case ERoamingImageType.OutgoingCall:
      return <Icon.OutgoingCall />
    case ERoamingImageType.Sms:
      return <Icon.Messager />
    default:
      return <></>
  }
}
