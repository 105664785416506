import styled from "styled-components"
import { Icon } from "components"

export const RootStyled = styled.div<{ background?: string }>`
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 480px;
  padding: 12px 16px;
  background: ${(props) =>
    props.background || props.theme.colors.background[0]};
  z-index: 3;
`

export const HeightStyled = styled.div`
  height: 64px;
`

export const LeftStyled = styled.div`
  min-width: 24px;
`

export const ArrowLeftIconStyled = styled(Icon.ArrowLeft)`
  cursor: pointer;
`

export const RightStyled = styled.div`
  min-width: 24px;
`

export const TitleWrapStyled = styled.div`
  p {
    text-align: center;
  }
`
