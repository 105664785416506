import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { CommonModal } from "components/CommonModal"
import { routes } from "constants/routes"

interface IServiceFailModalProps {
  isOpen: boolean
  onClose: () => void
  declineHandler?: () => void
}

export const ServiceFailModal: FC<IServiceFailModalProps> = ({
  isOpen,
  onClose,
  declineHandler,
}: {
  isOpen: boolean
  onClose: () => void
  declineHandler?: () => void
}) => {
  const navigate = useNavigate()
  const onDecline = declineHandler ?? (() => navigate(routes.menu))
  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      title="Сервис временно недоступен"
      subtitle="Попробуйте позже"
      declineLabel="Понятно"
      declineHandler={onDecline}
      preventCloseOnOutsideClick
    />
  )
}
