import { FunctionComponent, SVGProps } from "react"
import { ReactComponent as AB } from "./AB.svg"
import { ReactComponent as AD } from "./AD.svg"
import { ReactComponent as AE } from "./AE.svg"
import { ReactComponent as AF } from "./AF.svg"
import { ReactComponent as AL } from "./AL.svg"
import { ReactComponent as AM } from "./AM.svg"
import { ReactComponent as AO } from "./AO.svg"
import { ReactComponent as AR } from "./AR.svg"
import { ReactComponent as AT } from "./AT.svg"
import { ReactComponent as AU } from "./AU.svg"
import { ReactComponent as AW } from "./AW.svg"
import { ReactComponent as AZ } from "./AZ.svg"
import { ReactComponent as BA } from "./BA.svg"
import { ReactComponent as BB } from "./BB.svg"
import { ReactComponent as BD } from "./BD.svg"
import { ReactComponent as BE } from "./BE.svg"
import { ReactComponent as BG } from "./BG.svg"
import { ReactComponent as BI } from "./BI.svg"
import { ReactComponent as BM } from "./BM.svg"
import { ReactComponent as BR } from "./BR.svg"
import { ReactComponent as BS } from "./BS.svg"
import { ReactComponent as BY } from "./BY.svg"
import { ReactComponent as CA } from "./CA.svg"
import { ReactComponent as CD } from "./CD.svg"
import { ReactComponent as CF } from "./CF.svg"
import { ReactComponent as CH } from "./CH.svg"
import { ReactComponent as CI } from "./CI.svg"
import { ReactComponent as CL } from "./CL.svg"
import { ReactComponent as CM } from "./CM.svg"
import { ReactComponent as CN } from "./CN.svg"
import { ReactComponent as CR } from "./CR.svg"
import { ReactComponent as CU } from "./CU.svg"
import { ReactComponent as CV } from "./CV.svg"
import { ReactComponent as CW } from "./CW.svg"
import { ReactComponent as CY } from "./CY.svg"
import { ReactComponent as CZ } from "./CZ.svg"
import { ReactComponent as DE } from "./DE.svg"
import { ReactComponent as DK } from "./DK.svg"
import { ReactComponent as DM } from "./DM.svg"
import { ReactComponent as DZ } from "./DZ.svg"
import { ReactComponent as EE } from "./EE.svg"
import { ReactComponent as EG } from "./EG.svg"
import { ReactComponent as ES } from "./ES.svg"
import { ReactComponent as ET } from "./ET.svg"
import { ReactComponent as FI } from "./FI.svg"
import { ReactComponent as FR } from "./FR.svg"
import { ReactComponent as GB } from "./GB.svg"
import { ReactComponent as GD } from "./GD.svg"
import { ReactComponent as GE } from "./GE.svg"
import { ReactComponent as GH } from "./GH.svg"
import { ReactComponent as GI } from "./GI.svg"
import { ReactComponent as GM } from "./GM.svg"
import { ReactComponent as GP } from "./GP.svg"
import { ReactComponent as GQ } from "./GQ.svg"
import { ReactComponent as GR } from "./GR.svg"
import { ReactComponent as HK } from "./HK.svg"
import { ReactComponent as HR } from "./HR.svg"
import { ReactComponent as HT } from "./HT.svg"
import { ReactComponent as HU } from "./HU.svg"
import { ReactComponent as ID } from "./ID.svg"
import { ReactComponent as IE } from "./IE.svg"
import { ReactComponent as IL } from "./IL.svg"
import { ReactComponent as IM } from "./IM.svg"
import { ReactComponent as IN } from "./IN.svg"
import { ReactComponent as IQ } from "./IQ.svg"
import { ReactComponent as IR } from "./IR.svg"
import { ReactComponent as IS } from "./IS.svg"
import { ReactComponent as IT } from "./IT.svg"
import { ReactComponent as JE } from "./JE.svg"
import { ReactComponent as JM } from "./JM.svg"
import { ReactComponent as JO } from "./JO.svg"
import { ReactComponent as JP } from "./JP.svg"
import { ReactComponent as KE } from "./KE.svg"
import { ReactComponent as KG } from "./KG.svg"
import { ReactComponent as KH } from "./KH.svg"
import { ReactComponent as KN } from "./KN.svg"
import { ReactComponent as KR } from "./KR.svg"
import { ReactComponent as KZ } from "./KZ.svg"
import { ReactComponent as Ky } from "./Ky.svg"
import { ReactComponent as LA } from "./LA.svg"
import { ReactComponent as LB } from "./LB.svg"
import { ReactComponent as LC } from "./LC.svg"
import { ReactComponent as LI } from "./LI.svg"
import { ReactComponent as LK } from "./LK.svg"
import { ReactComponent as LR } from "./LR.svg"
import { ReactComponent as LT } from "./LT.svg"
import { ReactComponent as LU } from "./LU.svg"
import { ReactComponent as LV } from "./LV.svg"
import { ReactComponent as MA } from "./MA.svg"
import { ReactComponent as MC } from "./MC.svg"
import { ReactComponent as MD } from "./MD.svg"
import { ReactComponent as ME } from "./ME.svg"
import { ReactComponent as MG } from "./MG.svg"
import { ReactComponent as MK } from "./MK.svg"
import { ReactComponent as ML } from "./ML.svg"
import { ReactComponent as MM } from "./MM.svg"
import { ReactComponent as MN } from "./MN.svg"
import { ReactComponent as MQ } from "./MQ.svg"
import { ReactComponent as MR } from "./MR.svg"
import { ReactComponent as MS } from "./MS.svg"
import { ReactComponent as MT } from "./MT.svg"
import { ReactComponent as MU } from "./MU.svg"
import { ReactComponent as MV } from "./MV.svg"
import { ReactComponent as MX } from "./MX.svg"
import { ReactComponent as MY } from "./MY.svg"
import { ReactComponent as MZ } from "./MZ.svg"
import { ReactComponent as NE } from "./NE.svg"
import { ReactComponent as NG } from "./NG.svg"
import { ReactComponent as NL } from "./NL.svg"
import { ReactComponent as NO } from "./NO.svg"
import { ReactComponent as NP } from "./NP.svg"
import { ReactComponent as NZ } from "./NZ.svg"
import { ReactComponent as PA } from "./PA.svg"
import { ReactComponent as PE } from "./PE.svg"
import { ReactComponent as PF } from "./PF.svg"
import { ReactComponent as PH } from "./PH.svg"
import { ReactComponent as PK } from "./PK.svg"
import { ReactComponent as PL } from "./PL.svg"
import { ReactComponent as PT } from "./PT.svg"
import { ReactComponent as PY } from "./PY.svg"
import { ReactComponent as QA } from "./QA.svg"
import { ReactComponent as RE } from "./RE.svg"
import { ReactComponent as RS } from "./RS.svg"
import { ReactComponent as RW } from "./RW.svg"
import { ReactComponent as SA } from "./SA.svg"
import { ReactComponent as SD } from "./SD.svg"
import { ReactComponent as SE } from "./SE.svg"
import { ReactComponent as SG } from "./SG.svg"
import { ReactComponent as SI } from "./SI.svg"
import { ReactComponent as SK } from "./SK.svg"
import { ReactComponent as SL } from "./SL.svg"
import { ReactComponent as SN } from "./SN.svg"
import { ReactComponent as SR } from "./SR.svg"
import { ReactComponent as TC } from "./TC.svg"
import { ReactComponent as TD } from "./TD.svg"
import { ReactComponent as TG } from "./TG.svg"
import { ReactComponent as TH } from "./TH.svg"
import { ReactComponent as TJ } from "./TJ.svg"
import { ReactComponent as TN } from "./TN.svg"
import { ReactComponent as TO } from "./TO.svg"
import { ReactComponent as TR } from "./TR.svg"
import { ReactComponent as TT } from "./TT.svg"
import { ReactComponent as TW } from "./TW.svg"
import { ReactComponent as TZ } from "./TZ.svg"
import { ReactComponent as UA } from "./UA.svg"
import { ReactComponent as UY } from "./UY.svg"
import { ReactComponent as UZ } from "./UZ.svg"
import { ReactComponent as VC } from "./VC.svg"
import { ReactComponent as VI } from "./VI.svg"
import { ReactComponent as VN } from "./VN.svg"
import { ReactComponent as VU } from "./VU.svg"
import { ReactComponent as XK } from "./XK.svg"
import { ReactComponent as YE } from "./YE.svg"
import { ReactComponent as ZA } from "./ZA.svg"
import { ReactComponent as ZM } from "./ZM.svg"
import { ReactComponent as ZW } from "./ZW.svg"
import { ReactComponent as RO } from "./RO.svg"
import { ReactComponent as SY } from "./SY.svg"
import { ReactComponent as DO } from "./DO.svg"
import { ReactComponent as NC } from "./NC.svg"
import { ReactComponent as AG } from "./AG.svg"
import { ReactComponent as AI } from "./AI.svg"

export const Flag: {
  [key: string]: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >
} = {
  AI,
  AG,
  NC,
  DO,
  SY,
  RO,
  AB,
  AD,
  AE,
  AF,
  AL,
  AM,
  AO,
  AR,
  AT,
  AU,
  AW,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BG,
  BI,
  BM,
  BR,
  BS,
  BY,
  CA,
  CD,
  CF,
  CH,
  CI,
  CL,
  CM,
  CN,
  CR,
  CU,
  CV,
  CW,
  CY,
  CZ,
  DE,
  DK,
  DM,
  DZ,
  EE,
  EG,
  ES,
  ET,
  FI,
  FR,
  GB,
  GD,
  GE,
  GH,
  GI,
  GM,
  GP,
  GQ,
  GR,
  HK,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KN,
  KR,
  KZ,
  Ky,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LT,
  LU,
  LV,
  MA,
  MC,
  MD,
  ME,
  MG,
  MK,
  ML,
  MM,
  MN,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MX,
  MY,
  MZ,
  NE,
  NG,
  NL,
  NO,
  NP,
  NZ,
  PA,
  PE,
  PF,
  PH,
  PK,
  PL,
  PT,
  PY,
  QA,
  RE,
  RS,
  RW,
  SA,
  SD,
  SE,
  SG,
  SI,
  SK,
  SL,
  SN,
  SR,
  TC,
  TD,
  TG,
  TH,
  TJ,
  TN,
  TO,
  TR,
  TT,
  TW,
  TZ,
  UA,
  UY,
  UZ,
  VC,
  VI,
  VN,
  VU,
  XK,
  YE,
  ZA,
  ZM,
  ZW,
}
