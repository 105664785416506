import { ICommonResponse } from "./common"

export interface IContractRequest {
  number?: string
}

export interface IContractResponse extends ICommonResponse {
  data: {
    contract: {
      url: string
    }
  }
}

export enum EDocumentStatus {
  SignedByUser = "SIGNED_BY_USER",
  WaitInGoskey = "WAIT_IN_GOSKEY",
  NotSigned = "NOT_SIGNED",
}
