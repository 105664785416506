import React, { FC, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { useGetContractQuery } from "store/api"
import { RootState } from "store"
import { sendMetric } from "utils/metrics"
import { Icon, Table } from "components"
import { routes } from "constants/routes"

export const Signed: FC = () => {
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data } = useGetContractQuery({ number: currentPhone })
  const navigate = useNavigate()

  useEffect(() => {
    sendMetric({ ГлавныйЭкран: { ВиджетДоговораЕСимПоказ: ["Подписан"] } })
  }, [])

  const onClick = () => {
    sendMetric({ ГлавныйЭкран: { ВиджетДоговораЕСимКлик: ["Подписан"] } })
    if (data?.data.contract.url) {
      navigate(routes.showExternalLink, {
        state: {
          link: data?.data.contract.url,
          pathname: window.location.pathname,
        },
      })
    }
  }
  return (
    <Table.Section key={uuidv4()}>
      <Table.CommonCell
        title="Договор подписан"
        subTitle="Он хранится в вашем профиле"
        startIcon={<Icon.AgreementSigned />}
        endIcon={<Icon.ArrowRight />}
        onClick={onClick}
      />
    </Table.Section>
  )
}
