import React, { FC, useMemo, useState } from "react"
import { normalizeUserConfig } from "entities/userConfig"
import { useGetAdditionalNumberQuery, useGetUserConfigQuery } from "store/api"
import { formatPhoneNumber, prepareSelectorNumbers } from "utils"
import { getPhonesWithAdditionalData } from "pages/Home/utils"
import {
  Icon,
  Sheet,
  Table,
  Typography,
  Spacer,
  FormInput,
  RenderPhones,
} from "components"
import { ICommonField, TFieldValue, TFormField } from "../Form/interfaces"

interface IPhoneSelectField
  extends ICommonField,
    Required<Pick<TFormField, "sheet">> {
  value: string
  onSelect?: (value: TFieldValue) => void
  unavailableTitle?: string
  unavailableSubtitle?: string
}

const PhoneSelectField: FC<IPhoneSelectField> = ({
  value,
  name,
  placeholder,
  sheet,
  fieldRef,
  unavailableTitle,
  unavailableSubtitle,
  onChange,
  onSelect,
  onBlur,
  description,
  startIcon,
  onClick,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)
  const { data: numbersData } = useGetAdditionalNumberQuery()
  const { data: userConfigData } = useGetUserConfigQuery()
  const userConfigNormalized = useMemo(() => {
    return normalizeUserConfig(userConfigData)
  }, [userConfigData])
  const phones = useMemo(
    () => getPhonesWithAdditionalData(numbersData?.data),
    [numbersData]
  )

  const selectorNumbers = unavailableTitle
    ? prepareSelectorNumbers(phones)
    : phones

  const handlePhoneSelect = (phone: string) => {
    setCurrentValue(phone)
    onChange(phone)
    onSelect?.(phone)
    setOpen(false)
  }
  return (
    <>
      <FormInput
        placeholder={placeholder}
        name={name}
        value={formatPhoneNumber(currentValue, true) as string}
        initialValue={formatPhoneNumber(currentValue, true) as string}
        onFocus={(event) => {
          onClick?.()
          setOpen(true)
          event.target.blur()
        }}
        onEndIconClick={() => setOpen(true)}
        endIcon={<Icon.ArrowDown />}
        fieldRef={fieldRef}
        onBlur={(val) => onBlur(val)}
        onChange={(val) => onChange(val)}
        description={description}
        startIcon={startIcon}
      />
      <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
        <Sheet.CommonContainer horizontalPadding={0}>
          <Sheet.CommonHeader>
            <Typography.H4>{sheet.title}</Typography.H4>
            <Spacer height={4} />
            {sheet.description && (
              <Typography.Body2>{sheet.description}</Typography.Body2>
            )}
          </Sheet.CommonHeader>
          {selectorNumbers && (
            <Table.Body noPadding>
              <Table.Section>
                <RenderPhones
                  phones={selectorNumbers}
                  handlePhoneSelect={handlePhoneSelect}
                  current={currentValue}
                  unavailableTitle={unavailableTitle}
                  unavailableSubtitle={unavailableSubtitle}
                  userConfigNormalized={userConfigNormalized}
                />
              </Table.Section>
            </Table.Body>
          )}
        </Sheet.CommonContainer>
      </Sheet>
    </>
  )
}

export { PhoneSelectField }
