export const getCookie = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}(\\)[\]/+^])/g, "\\$1")}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export const deleteCookie = (name: any, path?: any, domain?: any) => {
  if (getCookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT"
  }
}

export const setCookie = (name: string, value: string, options: any = {}) => {
  const newOptions = {
    path: "/",
    ...options,
  }
  if (newOptions.expires instanceof Date) {
    newOptions.expires = newOptions.expires.toUTCString()
  }
  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const optionKey in newOptions) {
    updatedCookie += `; ${optionKey}`
    const optionValue = newOptions[optionKey]
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`
    }
  }
  document.cookie = updatedCookie
}
