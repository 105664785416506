import { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { RootState } from "store"
import { useGetPackageServicesQuery, useGetTariffQuery } from "store/api"
import { EContentType } from "entities/common"
import {
  findAdditionalOptionByContentType,
  findOptionByContentType,
} from "utils/search"
import { getCurrentExtendedData } from "pages/Home/utils"
import {
  Container,
  Header,
  Icon,
  Loader,
  Spacer,
  Table,
  Typography,
  Wrapper,
} from "components"
import { routes } from "constants/routes"
import { GuardianBanner } from "./components/GuardianBanner"
import { INTERTNAL_SERVICES, questions, SUBTITLE, TITLE } from "./constants"
import { InternalServices } from "./components/InternalServices"
import { ServicesCardCarousel } from "./components/ServicesCardCarousel/ServicesCardCarousel"
import { Styled } from "./styles"

export const SecureAccountMain: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { currentPhone } = useSelector((state: RootState) => state.login)

  const tariffResponse = useGetTariffQuery({
    numbers: [currentPhone],
  })

  const currentExtendedData = useMemo(
    () =>
      getCurrentExtendedData(
        tariffResponse.data?.data?.extendedData,
        currentPhone
      ),
    [tariffResponse.data, currentPhone]
  )

  const isSecureAccountActive =
    findAdditionalOptionByContentType(
      EContentType.SecureAccount,
      currentExtendedData?.connectedOptions?.additionalOptions
    )?.active === true

  const getOptionId = (isSecureAccount: boolean) => {
    const targetObject = isSecureAccount
      ? currentExtendedData?.connectedOptions?.additionalOptions
      : currentExtendedData?.availableOptions?.additionalOptions

    return findAdditionalOptionByContentType(
      EContentType.SecureAccount,
      targetObject
    )?.optionId.toString()
  }

  const optionId = getOptionId(isSecureAccountActive)

  const servicesResponse = useGetPackageServicesQuery(
    {
      optionId: optionId ?? "",
      phone: currentPhone,
    },
    { skip: !optionId }
  )

  const servicesData = servicesResponse.data?.data

  const isYaActive =
    findOptionByContentType(
      EContentType.Ya,
      servicesData?.additionalServices?.options
    )?.active === true

  return (
    <>
      {!servicesData && <Loader />}
      <Container page backgroundColor={theme.colors.background.surface0}>
        <Header
          onBackClick={() => navigate(routes.main)}
          background={theme.colors.background.surface0}
        />
        <Styled.HeadIcon />
        <Wrapper padding={24}>
          <Typography.H2 textAlign="center">{TITLE}</Typography.H2>
          <Spacer height={8} />
          <Typography.Body1 textAlign="center" fontSize={15} lineHeight={20}>
            {SUBTITLE[0]}
            <br />
            {SUBTITLE[1]}
          </Typography.Body1>
        </Wrapper>
        <Spacer height={24} />
        {servicesData && (
          <GuardianBanner
            title={servicesData.mainService.title}
            subTitle={servicesData.mainService.subTitle}
            isActive={servicesData.mainService.active}
          />
        )}

        {(isSecureAccountActive || isYaActive) && (
          <Table.Body>
            <Table.Section
              spacedView
              cellContainerMargin={{ marginTop: 0, marginBottom: 8 }}
            >
              <InternalServices
                icon={<Icon.PhoneInGrayCircle />}
                title={INTERTNAL_SERVICES[0]}
                onClick={() => navigate(routes.historyCalls)}
              />

              {/* TODO: входит во 2 релиз */}
              {isSecureAccountActive && (
                <InternalServices
                  icon={<Icon.RubleInGrayCircle />}
                  title={INTERTNAL_SERVICES[1]}
                  onClick={() => navigate(routes.moneyBack)}
                />
              )}
              {/* 
              <InternalServices
                icon={<Icon.ExclamationMarkInGrayCircle />}
                title={INTERTNAL_SERVICES[2]}
                onClick={() => (window.location.href = "#")}
              /> */}
            </Table.Section>
          </Table.Body>
        )}

        <Spacer height={24} />
        <Wrapper padding={8}>
          <Typography.H3>Дополнительные услуги</Typography.H3>
        </Wrapper>
        <Spacer height={16} />
        <ServicesCardCarousel
          slides={servicesData?.additionalServices.options ?? []}
        />
        <Spacer height={32} />
        <Wrapper padding={16}>
          <Typography.H3>Ответы на вопросы</Typography.H3>
        </Wrapper>
        <Table.Section
          cellContainerMargin={{ marginTop: 0, marginBottom: 64 }}
          generalBackground={theme.colors.background.surface0}
        >
          {questions.map((element, index) => (
            <Table.CommonCell
              key={index}
              title={element.title}
              endIcon={<Icon.ArrowRight />}
              onClick={() => navigate(element.route)}
            />
          ))}
        </Table.Section>
      </Container>
    </>
  )
}
