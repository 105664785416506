import {
  Body1,
  Body2,
  Body1Bold,
  Body2Link,
  Body2Label,
  Body2Bold,
  Numbered,
} from "./Body"
import { Caption } from "./Caption"
import { H1, H2, H3, H4, H6 } from "./Headline"

export const Typography = {
  Body1,
  Body2,
  Body1Bold,
  Body2Link,
  Body2Label,
  Body2Bold,
  Numbered,
  H1,
  H2,
  H3,
  H4,
  H6,
  Caption,
}
