import React, { FC, memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Header } from "components/Header"
import { useGetUserConfigQuery } from "store/api"
import { RootState } from "store"
import { getBitsFromHex } from "utils/converters"
import { Icon, Table } from "components"
import { routes } from "constants/routes"
import { urls } from "constants/urls"
import { TitleStyled, RootStyled } from "./styles"

export const Signature: FC = memo(() => {
  const navigate = useNavigate()
  const { currentPhone, summary } = useSelector(
    (state: RootState) => state.login
  )
  const eSimBit = useMemo(() => getBitsFromHex(summary, 19, 1), [summary])
  const servicesBit = useMemo(() => getBitsFromHex(summary, 21, 1), [summary])

  const { data: userConfigData } = useGetUserConfigQuery()
  const onClickOnline = () => {
    navigate(routes.signatureOnline)
  }

  const currentUserConfig = useMemo(() => {
    return userConfigData?.data?.userConfigs?.find(
      (item) => item.number === currentPhone
    )
  }, [userConfigData?.data?.userConfigs, currentPhone])

  const onClickOffice = () => {
    navigate(routes.showExternalLink, {
      state: { link: urls.officeMap },
    })
  }

  const onClickCourier = () => {
    navigate(routes.showExternalLink, {
      state: {
        link: `${urls.identificationEsim}${currentPhone}`,
      },
    })
  }

  return (
    <RootStyled>
      <Header />
      {eSimBit === "0" && servicesBit === "0" ? (
        <TitleStyled>Подпишите договор, чтобы пользоваться eSIM</TitleStyled>
      ) : (
        <TitleStyled>Нужно подписать договор</TitleStyled>
      )}
      <Table.Body>
        <Table.Section title="Выберите способ">
          {currentUserConfig?.featureConfig?.isGoskeySignEnabled && (
            <Table.CommonCell
              title="Онлайн"
              subTitle={`Нужно будет авторизоваться через Госуслуги и **установить приложение Госключ** для создания электронной подписи`}
              startIcon={<Icon.Mobile />}
              endIcon={<Icon.ArrowRight />}
              onClick={onClickOnline}
            />
          )}
          {currentUserConfig?.featureConfig?.isOfficeSignEnabled && (
            <Table.CommonCell
              title="В офисе Сбера"
              subTitle="Понадобится только паспорт"
              startIcon={<Icon.Bank />}
              endIcon={<Icon.ArrowRight />}
              onClick={onClickOffice}
            />
          )}
          {currentUserConfig?.featureConfig?.isCourSignEnabled && (
            <Table.CommonCell
              title="Вызвать курьера"
              subTitle="В Москве, Санкт-Петербурге и других городах — на встрече с курьером нужно иметь при себе паспорт"
              startIcon={<Icon.Home2 />}
              endIcon={<Icon.ArrowRight />}
              onClick={onClickCourier}
            />
          )}
        </Table.Section>
      </Table.Body>
    </RootStyled>
  )
})
