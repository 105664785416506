import React, { FC, memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Header } from "components/Header"
import { useGetLinksQuery } from "store/api"
import { ELinksCode } from "entities/common"
import { RootState } from "store"
import { useTheme } from "styled-components"
import { loginActions } from "store/slice/loginSlice"
import { getBitsFromHex } from "utils/converters"
import { routes } from "constants/routes"
import { BLANK } from "constants/common"
import {
  Body1Styled,
  ButtonPrimaryStyled,
  FooterStyled,
  H4Styled,
  ImageStyled,
  IndentStyled,
  RootStyled,
} from "./styles"

export const SignatureGoskey: FC = memo(() => {
  const theme = useTheme()
  const { data: links } = useGetLinksQuery()
  const { summary } = useSelector((state: RootState) => state.login)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onConfirm = () => {
    const bits = getBitsFromHex(summary, 21, 1)
    if (bits === "0") {
      dispatch(loginActions.clear())
    } else {
      navigate(routes.main)
    }
    return (
      links &&
      links[ELinksCode.SignInGoskey] &&
      window.open(links[ELinksCode.SignInGoskey], BLANK)?.focus()
    )
  }

  return (
    <RootStyled>
      <Header background={theme.colors.background[1]} />
      <ImageStyled />
      <H4Styled>Теперь нужно подписать договор в приложении Госключ</H4Styled>
      <Body1Styled>Это безопасно и надёжно</Body1Styled>
      <IndentStyled />
      {links && (
        <FooterStyled>
          <ButtonPrimaryStyled onClick={onConfirm}>
            Перейти в Госключ
          </ButtonPrimaryStyled>
        </FooterStyled>
      )}
    </RootStyled>
  )
})
