import styled from "styled-components"
import { Typography, Icon } from "../../../../components"

export const RootStyled = styled.div`
  background: ${(props) => props.theme.colors.background[1]};
  min-height: 100vh;
`

export const SberMobileIconStyled = styled(Icon.SberMobile)`
  width: 100%;
  margin-top: 200px;
`

export const FootnoteStyled = styled(Typography.Caption)`
  display: flex;
  justify-content: center;
`

export const FootnoteLinkStyled = styled(Typography.Caption)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;
`

export const InputWrapStyled = styled.div`
  padding: 24px 32px 16px;
  display: flex;
`
