import { Icon } from "components"

export const CHANGE_SBER_MAIN_NUMBER =
  "Как сделать номер СберМобайла основным в СберБанке Онлайн"
export const BUTTON_TEXT = "открыть Сбербанк онлайн"
export const sectionContent = [
  {
    id: 1,
    content: "Перейдите в любое приложение СберБанк Онлайн",
    icon: <Icon.ChangeNumberFAQ1 />,
  },
  {
    id: 2,
    content: "Нажмите на профиль в левом верхнем углу экрана",
    icon: <Icon.ChangeNumberFAQ2 />,
  },
  {
    id: 3,
    content: "В разделе «Настройки» выберите «Уведомления»",
    icon: <Icon.ChangeNumberFAQ3 />,
  },
  {
    id: 4,
    content:
      "Нажмите на номер для уведомлений и в открывшемся окне на «Изменить номер»",
    icon: <Icon.ChangeNumberFAQ4 />,
  },
  {
    id: 5,
    content:
      "Далее введите оформленный на вас номер СберМобайл и подтвердите его по СМС",
    icon: <Icon.ChangeNumberFAQ5 />,
  },
  {
    id: 6,
    content: "Готово!",
    icon: <Icon.ChangeNumberFAQ6 />,
  },
]
