import React, { FC, memo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { PhoneInputMask } from "components/PhoneInputMask"
import { RootState } from "store"
import { loginActions } from "store/slice/loginSlice"
import { getSearchObject } from "utils/search"
import { useGetLinksQuery, useGetUserDetailAuthParamsQuery } from "store/api"
import { sendMetric } from "utils/metrics"
import { useTheme } from "styled-components"
import { NotificationPosition, SberidSDK, SberidSDKProps } from "@sberid/js-sdk"
import { useNavigate } from "react-router"
import { Header } from "components"
import {
  H1Styled,
  InputWrapStyled,
  // OrStyled,
  // SberIdContainer,
  SberMobileIconStyled,
  WrapHeaderStyled,
} from "../../styles"
import { routes } from "constants/routes"
import { RootStyled } from "./styles"
import { CLIENT_ID, CLIENT_SECRET } from "./constants"

export const EnterPhoneNumber: FC = memo(() => {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch()
  // Заранее получаем список линков
  useGetLinksQuery()
  const { isLoading, error } = useSelector((state: RootState) => state.login)
  const { additional } = getSearchObject()

  useEffect(() => {
    sendMetric({ Авторизация: { ВводНомера: ["Показ"] } })
    dispatch(loginActions.setDisplayPhone(""))
    dispatch(loginActions.resetError())
  }, [dispatch])

  const { data, isLoading: isAuthParamsLoading } =
    useGetUserDetailAuthParamsQuery()

  useEffect(() => {
    if (data?.data?.nonce) {
      dispatch(loginActions.setNonce(data?.data?.nonce))
    }
  }, [data, dispatch])

  useEffect(() => {
    // Выключение СберID
    if (1) {
      return
    }
    if (!isAuthParamsLoading && data) {
      const oidcParams = {
        response_type: "code",
        client_type: "PRIVATE",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        redirect_uri: data.data.redirect_uri,
        state: data.data.state,
        scope: data.data.scope,
        nonce: data.data.nonce,
      }

      const params: SberidSDKProps = {
        oidc: oidcParams,
        container: "#sber-id-button",
        display: "page",
        mweb2app: false,
        generateState: false,
        notification: {
          enable: true,
          position: NotificationPosition.topRight,
        },
        utmProxyDisabled: true,
        buttonProps: {
          type: "default",
          custom: {
            anonymous: "Вход",
            personal: "Вход как {{userName}}",
          },
        },
      }

      new SberidSDK(params)
    }
  }, [isAuthParamsLoading, data])

  const onPhoneChange = (value: string) => {
    dispatch(loginActions.setError(""))
    if (!isLoading && value && value.length === 10) {
      dispatch(loginActions.loginStart(value))
    }
  }

  const onPhoneInputBlur = (value: string) => {
    if (!isLoading && value && value.length === 10) {
      dispatch(loginActions.loginStart(value))
    }
  }

  const onClearIconHandler = () => dispatch(loginActions.resetError())

  return (
    <RootStyled>
      {additional === "true" && (
        <WrapHeaderStyled>
          <Header
            onBackClick={() => navigate(routes.main)}
            title="Добавить номер"
            background={theme.colors.background[1]}
          />
        </WrapHeaderStyled>
      )}
      {additional !== "true" && <SberMobileIconStyled />}
      <H1Styled>Войти в личный кабинет</H1Styled>
      <InputWrapStyled>
        <PhoneInputMask
          placeholder="Введите номер телефона"
          onChange={onPhoneChange}
          description={error}
          hasError={error?.length > 0}
          onBlur={onPhoneInputBlur}
          isLoading={isLoading}
          type="tel"
          hasClearIcon
          onClearIconHandler={onClearIconHandler}
        />
      </InputWrapStyled>
      {/*Кнопка СберID*/}
      {/* <OrStyled>или</OrStyled>
      <SberIdContainer id="sber-id-button" /> */}
    </RootStyled>
  )
})
