import { EContentType } from "entities/common"
import { IOptionElement } from "entities/connectedAvailable"
import { IAdditionalOption } from "entities/tariff"

export const getSearchObject = (search?: string) => {
  const query = search || window.location.search.substring(1)
  const result: any = {}
  const vars = query.split("&")
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=")
    result[pair[0]] = pair[1]
  }
  return result
}

export const findAdditionalOptionByContentType = (
  element: EContentType,
  array?: IAdditionalOption[]
) => array && array.find((x) => x.contentType === element)

// TODO: сделать нормальные интерфейсы для IAdditionalOption и EContentType,
// что бы был contentType: EContentType
export const findOptionByContentType = (
  element: EContentType,
  array?: IOptionElement[]
) => array && array.find((x) => x.contentType === element)
