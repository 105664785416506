import React, {
  FC,
  HTMLAttributes,
  HTMLInputTypeAttribute,
  memo,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { ICommonField } from "../Form/interfaces"
import {
  formatInputValue,
  formatPhoneNumber,
  onPhoneInput,
} from "../PhoneInputMask/utils"
import { Styled } from "./styles"

interface IFormInput
  extends ICommonField,
    Pick<HTMLAttributes<HTMLInputElement>, "inputMode"> {
  type?: HTMLInputTypeAttribute
  value: string
  endIcon?: ReactElement
  onEndIconClick?: () => void
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void
  maxLength?: number
  initialValue?: string
  postfix?: string
  pattern?: RegExp
  autoFocus?: boolean
}

const FormInput: FC<IFormInput> = memo(
  ({
    name,
    value,
    onChange,
    description,
    hasError,
    onBlur,
    placeholder,
    type,
    disabled,
    endIcon,
    onEndIconClick,
    onFocus,
    maxLength,
    initialValue,
    inputMode,
    postfix,
    pattern,
    autoFocus,
    startIcon,
    onClick,
  }) => {
    const [readOnly, setReadOnly] = useState(true)
    const ref = useRef() as MutableRefObject<HTMLInputElement>
    const hasPhoneMask = type === "tel"
    const getInitialValue = useCallback(
      (innerValue: string) =>
        hasPhoneMask ? formatPhoneNumber(innerValue) : innerValue,
      [hasPhoneMask]
    )
    const isPlaceholderTop = !!value && value.trim().length !== 0
    const [inputValue, setInputValue] = useState<string | null>(null)
    const updateInputValue = (newValue: string) => {
      setInputValue(newValue)
      onChange(newValue)
    }

    useEffect(() => {
      if (initialValue) {
        setInputValue(getInitialValue(initialValue))
      }
    }, [initialValue, hasPhoneMask, getInitialValue])

    const [currentSelectionStart, setCurrentSelectionStart] = useState<
      number | null
    >(0)

    const handleOnPhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const [selectionStart, totalValue] = onPhoneInput(
        event,
        inputValue || value || ""
      )
      setCurrentSelectionStart(selectionStart as number)
      updateInputValue(totalValue as string)
    }

    useEffect(() => {
      setReadOnly(false)
    }, [])

    useEffect(() => {
      const current = ref.current
      if (current && (hasPhoneMask || postfix)) {
        current.selectionStart = currentSelectionStart
        current.selectionEnd = currentSelectionStart
      }
    }, [inputValue, currentSelectionStart, hasPhoneMask, postfix])

    const onKeyDownHandler = (
      e: React.ChangeEvent<HTMLInputElement> &
        React.KeyboardEvent<HTMLDivElement>
    ) => {
      const isKeyBackspace = e.key === "Backspace"
      const trimmedInputValue = e.target.value.trim()
      if (isKeyBackspace && trimmedInputValue.length <= 2 && hasPhoneMask) {
        updateInputValue("")
      }
    }

    const onPostfixValueInput = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const resultValue = formatInputValue(event.target.value)
      updateInputValue(resultValue)
      setCurrentSelectionStart(resultValue.length)
    }

    const declineByFilter = (current: string) =>
      (maxLength && maxLength < current.length) ||
      (pattern && !pattern.test(current) && current !== "")

    const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) =>
      onBlur(event.target.value)

    const handleOnInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (declineByFilter(event.target.value)) {
        return
      }
      if (hasPhoneMask) {
        handleOnPhoneInput(event)
        return
      }
      if (postfix) {
        onPostfixValueInput(event)
        return
      }

      updateInputValue(event.target.value)
    }

    return (
      <Styled.Root>
        {!!postfix && !!inputValue?.length && (
          <Styled.Postfix valueLength={inputValue.length} postfix={postfix} />
        )}
        {startIcon && (
          <Styled.StartIconWrapper>{startIcon}</Styled.StartIconWrapper>
        )}
        {isPlaceholderTop && (
          <Styled.TopPlaceholder hasStartIcon={!!startIcon}>
            {placeholder}
          </Styled.TopPlaceholder>
        )}
        <Styled.Input
          autoComplete="off"
          ref={ref}
          placeholder={placeholder}
          placeholderTop={isPlaceholderTop}
          value={inputValue ?? value}
          onBlur={handleOnBlur}
          type={type}
          hasError={hasError}
          disabled={disabled}
          name={name}
          onFocus={(event) => {
            onFocus?.(event)
            onClick?.()
          }}
          hasEndIcon={!!endIcon}
          onInput={handleOnInput}
          onKeyDown={hasPhoneMask || !!postfix ? onKeyDownHandler : undefined}
          inputMode={inputMode}
          autoFocus={autoFocus}
          readOnly={readOnly}
          hasStartIcon={!!startIcon}
        />
        {endIcon && (
          <Styled.EndIconWrapper onClick={onEndIconClick}>
            {endIcon}
          </Styled.EndIconWrapper>
        )}
        <Styled.Description hasError={hasError}>
          {description}
        </Styled.Description>
      </Styled.Root>
    )
  }
)

export { FormInput }
