import React, { FC, memo, useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { RootState } from "store"
import { PhoneInputMask } from "components/PhoneInputMask"
import { loginActions } from "store/slice/loginSlice"
import {
  useGetLinksQuery,
  useGetUserDetailServiceCheckMutation,
} from "store/api"
import { useActions } from "hooks/actions"
import { ENumberState } from "entities/checkNumber"
import { CommonModal, Header } from "components"
import { routes } from "constants/routes"
import { YES_CONTINUE, DECLINE, OK } from "constants/common"
import {
  InputWrapStyled,
  RootStyled,
  SubtitleStyled,
  TitleStyled,
} from "./styles"
import { ShowInfoSheet } from "./components"

export const LoginSberIdBind: FC = memo(() => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const navigate = useNavigate()
  const { isLoading, error, sberId, phone } = useSelector(
    (state: RootState) => state.login
  )
  const defaultModalState = {
    isOpen: false,
    title: "",
    subtitle: "",
    declineLabel: "",
    confirmLabel: "",
  }
  const [modalState, setModalState] = useState(defaultModalState)
  const { setError, loginStart, setSberIdShowInfoSheet, resetPhone } =
    useActions()
  const [innerPhone, setInnerPhone] = useState<string>("")
  useGetLinksQuery()
  const [triggerCheckData, checkData] = useGetUserDetailServiceCheckMutation()
  const onAvailable = useCallback(() => {
    setError("")
    loginStart(innerPhone)
    return navigate(`${routes.login}?state=sberId`)
  }, [loginStart, setError, navigate, innerPhone])
  const onBackClick = () => {
    resetPhone()
    navigate(routes.loginSberId)
  }

  useEffect(() => {
    if (checkData.isSuccess && (!phone || phone !== innerPhone)) {
      checkData.reset()
      const { data } = checkData
      switch (data.data.numberState) {
        case ENumberState.NotAvailable:
          setSberIdShowInfoSheet(true)
          break
        case ENumberState.Available:
          return onAvailable()
        case ENumberState.BoundToSber:
          setModalState({
            isOpen: true,
            title: data.data.userMessage.title,
            subtitle: data.data.userMessage.subtitle,
            confirmLabel: YES_CONTINUE,
            declineLabel: DECLINE,
          })
          break
        default:
          setModalState({
            isOpen: true,
            title: data.data.userMessage.title,
            subtitle: data.data.userMessage.subtitle,
            declineLabel: OK,
            confirmLabel: "",
          })
      }
    }
  }, [
    checkData,
    setError,
    loginStart,
    innerPhone,
    navigate,
    setSberIdShowInfoSheet,
    onAvailable,
    phone,
  ])

  const onPhoneChange = (inputPhone: string) => {
    if (checkData.isLoading) {
      return
    }
    setInnerPhone(inputPhone)
    dispatch(loginActions.setError(""))
    if (!isLoading && inputPhone && inputPhone.length === 10) {
      triggerCheckData({ phone: inputPhone })
    }
  }

  const onConfirmLabel = () => {
    loginStart(innerPhone)
    navigate(`${routes.login}?state=sberId`)
  }

  const onClearIconHandler = () => dispatch(loginActions.resetError())

  return (
    <>
      <CommonModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState(defaultModalState)}
        title={modalState.title}
        subtitle={modalState.subtitle}
        declineHandler={() => setModalState(defaultModalState)}
        confirmLabel={modalState.confirmLabel}
        confirmHandler={onConfirmLabel}
        declineLabel={modalState.declineLabel}
        isTitleCentered
      />
      <RootStyled>
        <Header
          background={theme.colors.background[1]}
          onBackClick={onBackClick}
        />
        <TitleStyled>Привязать номер</TitleStyled>
        <SubtitleStyled>
          Укажите номер СберМобайл, для которого будет выполнен вход по Сбер ID
        </SubtitleStyled>
        <InputWrapStyled>
          <PhoneInputMask
            placeholder="Введите номер телефона"
            onChange={onPhoneChange}
            description={error}
            hasError={error?.length > 0}
            // onBlur={onPhoneInputBlur}
            isLoading={isLoading || checkData.isLoading}
            type="tel"
            hasClearIcon
            onClearIconHandler={onClearIconHandler}
          />
        </InputWrapStyled>
        <ShowInfoSheet
          title={checkData?.data?.data?.userMessage?.title || ""}
          subtitle={checkData?.data?.data?.userMessage?.subtitle || ""}
          isOpen={sberId.showInfoSheet}
          phone={innerPhone}
        />
      </RootStyled>
    </>
  )
})
