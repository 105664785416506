import styled, { css } from "styled-components"

const Body1Css = css<{
  color?: string
  fontSize?: number
  lineHeight?: number
  textDecoration?: string
}>`
  font-family: "SBSansUI Regular", serif;
  font-size: ${(props) => props.fontSize ?? 16}px;
  line-height: ${(props) => props.lineHeight ?? 18}px;
  color: ${(props) => props.color ?? props.theme.colors.text.primary};
`

export const Body1 = styled.p<{
  color?: string
  fontSize?: number
  lineHeight?: number
  textAlign?: string
  textDecoration?: string
  startPadding?: number
}>`
  ${Body1Css};
  ${(props) => props.startPadding && `padding-left: ${props.startPadding}px;`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign}}`}
`

export const Body1Bold = styled.p<{
  color?: string
  fontSize?: number
  lineHeight?: number
}>`
  font-family: "SBSansUI Bold", serif;
  font-size: ${(props) => props.fontSize ?? 16}px;
  line-height: ${(props) => props.lineHeight ?? 22}px;
  color: ${(props) => props.color ?? props.theme.colors.text.primary};
`

export const Body2Bold = styled.p<{
  color?: string
  fontSize?: number
  lineHeight?: number
  textTransform?: string
}>`
  font-family: "SBSansUI Bold", serif;
  font-size: ${(props) => props.fontSize ?? 14}px;
  line-height: ${(props) => props.lineHeight ?? 16}px;
  color: ${(props) => props.color ?? props.theme.colors.text.primary};
  ${(props) => props.textTransform && `text-transform: ${props.textTransform}}`}
`

export const Body2 = styled.p<{
  color?: string
  fontSize?: number
  lineHeight?: number
  textAlign?: string
  textDecoration?: string
  padding?: number
}>`
  font-family: "SBSansUI Regular", serif;
  font-size: ${(props) => props.fontSize ?? 14}px;
  line-height: ${(props) => props.lineHeight ?? 18}px;
  color: ${(props) => props.color ?? props.theme.colors.text.secondary};
  text-align: ${(props) => props.textAlign ?? "start"};
  ${(props) =>
    props.textDecoration && `text-decoration: ${props.textDecoration}}`}
  ${(props) => (props.padding ? `padding 0 ${props.padding}px;` : undefined)}
`
export const Body2Label = styled.label`
  font-family: "SBSansUI Regular", serif;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const Body2Link = styled.a`
  font-family: "SBSansUI Regular", serif;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.text.warning};
`

export const Numbered = styled.li<{
  color?: string
  fontSize?: number
  lineHeight?: number
}>`
  ::marker {
    ${Body1Css};
  }
`
