import { ICommonResponse } from "./common"

export interface ICheckNumberRequest {
  phone: string
}

export interface ICheckNumberResponse extends ICommonResponse {
  data: {
    userMessage: {
      title: string
      subtitle: string
    }
    numberState: ENumberState
  }
}

export enum ENumberState {
  NotAvailable = "NOT_AVAILABLE",
  BoundToSber = "BOUND_TO_SBERID", // номер уже привязан
  Available = "AVAILABLE",
}
