import styled from "styled-components"
import { Typography } from "../Typography"

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.background[0]};
  padding-top: 12px;
  flex-direction: column;
  padding-bottom: 90px;
`

const TabBar = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 90px;
  z-index: 5;
  background-color: ${(props) => props.theme.colors.background[1]};
  max-width: 480px;
  left: 50%;
  transform: translate(-50%, 0);
`

const Container = styled.div`
  display: flex;
  padding: 8px 8px 25px 8px;
  gap: 16px;
  max-width: 480px;
  margin: 0 auto;
  box-shadow: ${(props) => `0 4px 12px ${props.theme.colors.background[1]}`};
`

const Item = styled.div<{ active: boolean }>`
  flex-grow: 1;
  height: 100%;
  display: flex;
  padding: 8px 4px;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  path {
    fill: ${(props) =>
      props.active
        ? props.theme.colors.button.primary
        : props.theme.colors.icon.primary};
  }
  ${Typography.Caption} {
    color: ${(props) =>
      props.active
        ? props.theme.colors.text.primary
        : props.theme.colors.text.secondary};
  }
`

export const StyledBar = { Wrapper, Container, Item, TabBar }
