import styled from "styled-components"
import { Typography } from "components"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.background[0]};
  flex-direction: column;
`

export const TitleStyled = styled(Typography.H1)`
  margin: 40px 32px 16px;
`
