import React, { FC, memo, useState, useMemo, useCallback } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import { useTheme } from "styled-components"
import { RootState } from "store"
import { useActions } from "hooks/actions"
import { TFieldValue, TFormDataValues } from "components/Form/interfaces"
import { useGetTimeslotQuery } from "store/api"
import { TimeSlot } from "entities/numberTransfer"
import { getInitialValues } from "components/Form/utils"
import { SheetNumberTransferInstructions } from "pages/NumberTransferEntry/SheetNumberTransferInstructions"
import {
  CommonModal,
  Container,
  Form,
  Header,
  Icon,
  PhoneNumber,
  Typography,
} from "components"
import { formModules } from "./formData"
import { CheckInfoSheet } from "./CheckInfoSheet"
import { Styled } from "./styles"

const NumberTransferDate: FC = memo(() => {
  const theme = useTheme()
  const [isOpenInfo, setOpenInfo] = useState(false)
  const [warningTransferModal, setWarningTransferModal] = useState<{
    isOpen: boolean
    temporaryNumber: TFieldValue
  }>({
    isOpen: false,
    temporaryNumber: "",
  })
  const [isOpen, setOpen] = useState(false)
  const { values } = useSelector((state: RootState) => state.form)
  const { numberTransfer } = useSelector((state: RootState) => state.common)
  const { data: slots } = useGetTimeslotQuery({ number: numberTransfer })
  const { setFormValues } = useActions()
  const handleFormSubmit = (data: TFormDataValues) => {
    setFormValues({ values: data })
    setOpen(true)
  }
  const queryParametersMapper = useCallback(
    (value: unknown) => ({
      number: numberTransfer,
      date: moment(value as string, "DD.MM.YY").format(),
    }),
    [numberTransfer]
  )
  const modules = useMemo(
    () =>
      formModules(
        queryParametersMapper,
        setWarningTransferModal,
        slots as TimeSlot[] | undefined
      ),
    [queryParametersMapper, slots]
  )
  return (
    <Container page backgroundColor={theme.colors.background[0]}>
      <Header title="Перенос номера" background={theme.colors.background[0]}>
        <Icon.InfoIcon onClick={() => setOpenInfo(true)} />
      </Header>
      <Styled.HeaderTitle>
        Выберите номер, который хотите заменить на{" "}
        <PhoneNumber phone={numberTransfer} />
      </Styled.HeaderTitle>
      <Form
        onSubmit={handleFormSubmit}
        modules={modules}
        initialValues={values ?? getInitialValues(modules)}
      />
      <SheetNumberTransferInstructions
        isOpen={isOpenInfo}
        onClose={() => setOpenInfo(false)}
      />
      <CheckInfoSheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        phoneNumber={values?.temporaryNumber as string}
      />
      <CommonModal
        isOpen={warningTransferModal.isOpen}
        onClose={() =>
          setWarningTransferModal((prev) => ({ ...prev, isOpen: false }))
        }
        subtitle={
          <Typography.Body1 color={theme.colors.text.secondary}>
            После завершения переноса оказание услуг связи по выбранному номеру{" "}
            <PhoneNumber
              phone={warningTransferModal.temporaryNumber as string}
            />
             будет прекращено
          </Typography.Body1>
        }
        declineLabel="Понятно"
        declineHandler={() =>
          setWarningTransferModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
    </Container>
  )
})

export { NumberTransferDate }
