import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react"
import {
  FieldFormat,
  ICommonField,
  TFormField,
  TCustomValidator,
  TFieldValue,
  TFormFieldCommon,
} from "components/Form/interfaces"
import { useFormContext } from "react-hook-form"
import { useActions } from "hooks/actions"
import { Sheet } from "components"

interface ICustomValidatorState {
  sheetContent?: ReactNode
  message?: string
  isOpen: boolean
  isValid?: boolean
  fieldProps?: Partial<TFormFieldCommon>
}

const withCustomValidation = (
  Field: ComponentType<TFormField & ICommonField>
): ((
  props: TFormField & ICommonField & { customValidator: TCustomValidator }
) => JSX.Element) => {
  return (props) => {
    const {
      onChange,
      customValidator,
      name,
      onBlur,
      format,
      watchField,
      value: outerValue,
      ...other
    } = props
    const { setError, clearErrors, watch } = useFormContext()
    const { setValid } = useActions()
    const [validatorData, setValidatorData] = useState<ICustomValidatorState>({
      isOpen: false,
    })
    const watchFieldValue = watch(watchField ?? "")
    const hasPhoneMask = format === FieldFormat.phone
    useEffect(() => {
      if (!validatorData.isValid && validatorData.message) {
        setError(name, { type: "validate", message: validatorData.message })
      }
      if (validatorData.isValid !== undefined && !validatorData.isOpen) {
        setValid({ isValid: validatorData.isValid })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatorData])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const doValidate = useCallback(
      (() => {
        let currentValue: TFieldValue
        return async (value: TFieldValue, force?: boolean) => {
          if (currentValue !== value || force) {
            currentValue = value
            const { message, sheetContent, isValid, fieldProps } =
              await customValidator(currentValue, {
                watchField: watchFieldValue,
              })
            setValidatorData({
              isOpen: !isValid && !!sheetContent,
              sheetContent,
              message,
              isValid,
              fieldProps,
            })
            if (isValid) {
              clearErrors(name)
            }
          }
        }
      })(),
      []
    )
    const handleChange = (value: TFieldValue) => {
      if (hasPhoneMask && (value as string).length === 18) {
        doValidate(value)
      }
      onChange(value)
    }
    const handleBlur = (value: TFieldValue) => {
      doValidate(value)
    }
    useEffect(() => {
      if (outerValue) {
        doValidate(outerValue, true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchFieldValue])

    return (
      <>
        <Field
          {...other}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          customValidator={customValidator}
          format={format}
          value={outerValue}
          watchField={watchField ?? ""}
          {...validatorData.fieldProps}
        />
        {validatorData.sheetContent && (
          <Sheet
            isOpen={validatorData.isOpen}
            onClose={() =>
              setValidatorData({ ...validatorData, isOpen: false })
            }
          >
            {validatorData.sheetContent}
          </Sheet>
        )}
      </>
    )
  }
}

export { withCustomValidation }
