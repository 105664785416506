import { FC, Fragment } from "react"
import { v4 as uuidv4 } from "uuid"
import { Button, Sheet, Spacer, Typography } from "components"
import { NumberTransferEntryStyled } from "./styles"

export const SheetNumberTransferInstructions: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const instructions = [
    "Номер не заблокирован и на нём нет задолженности",
    "Вы не меняли оператора 60 и более дней",
    "Регионы переносимого и заменяемого номера совпадают",
    "Договор с прежним оператором заключён на ваше имя, и в нём указаны актуальные паспортные данные",
    "После завершения переноса оказание услуг связи по заменяемому номеру будет прекращено",
    "Смена оператора связи возможна только по федеральному номеру, который соответствует формату +7 9ХХ ХХХ ХХ ХХ",
  ]

  return (
    <Sheet isOpen={isOpen} onClose={() => onClose()}>
      <Sheet.CommonContainer>
        <Sheet.CommonHeader>
          <Typography.H4>Условия переноса номера</Typography.H4>
        </Sheet.CommonHeader>
        <Spacer height={30} />
        <NumberTransferEntryStyled.List>
          {instructions.map((condition) => (
            <Fragment key={uuidv4()}>
              <Typography.Numbered>
                <NumberTransferEntryStyled.MNPCondition>
                  {condition}
                </NumberTransferEntryStyled.MNPCondition>
              </Typography.Numbered>
              <Spacer height={28} />
            </Fragment>
          ))}
        </NumberTransferEntryStyled.List>
        <Spacer height={2} />
        <Button.Secondary onClick={() => onClose()}>Понятно</Button.Secondary>
      </Sheet.CommonContainer>
    </Sheet>
  )
}
