// import { sendEvent } from "@sbol/clickstream-agent"

// const getPath = (obj: any): any =>
//   Object.keys(obj as object).reduce((acc, el) => {
//     const value = obj[el]
//     if (Array.isArray(value)) {
//       return `${acc}${el}/${value.pop()}`
//     }
//     if (value instanceof Object) {
//       return `${acc}${el}/${getPath(value)}`
//     }
//     return `${acc}${el}/${value}`
//   }, "")

export const sendMetric = (obj: object) => obj
// {
//   void sendEvent({
//     eventCategory: "SBERMOBILE_PWA",
//     eventAction: getPath(obj),
//     value: "",
//   })
//   if (window.ym) {
//     // TODO:: убрать test. Нужно чисто чтоб пока разрабатываем не заспамить метрики
//     window.ym(93711432, "params", obj || {})
//   }
// }
