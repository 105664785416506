import React, { FC, useEffect, useState, useCallback } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  ESendConfirmationType,
  EStatusCode,
  IResponseErrorData,
} from "entities/common"
import { RootState } from "store"
import { useTheme } from "styled-components"
import { getSearchObject } from "utils/search"
import {
  usePostAdjustmentAmountMutation,
  usePostAdjustmentNumberMutation,
} from "store/api"
import {
  IPaymentAdjustmentAmountRequest,
  IPaymentAdjustmentNumberRequest,
} from "entities/paymentAdjustment"
import { sendMetric } from "utils/metrics"
import { normalizeFileData } from "components/UploadFile/utils"
import { IFile } from "components/UploadFile/UploadFile"
import { useActions } from "hooks/actions"
import { Container, Header, OperationStatus, SmsConfirmation } from "components"
import { routes } from "constants/routes"
import { paymentAdjustmentMetric } from "./constants"

const PaymentAdjustmentConfirmation: FC = () => {
  const [openOperationStatus, setOpenOperationStatus] = useState(false)
  const { setError, openModal } = useActions()
  const theme = useTheme()
  const navigate = useNavigate()
  const { values } = useSelector((state: RootState) => state.form)
  if (!values) {
    navigate(routes.paymentAdjustment)
  }
  const { type } = getSearchObject()
  const { phone, signature } = useSelector((state: RootState) => state.login)
  const [
    postAdjustmentAmount,
    {
      isLoading: isLoadingAmount,
      isSuccess: isSuccessAmount,
      error: errorAmount,
      reset: resetAmount,
    },
  ] = usePostAdjustmentAmountMutation()
  const [
    postAdjustmentNumber,
    {
      isLoading: isLoadingNumber,
      isSuccess: isSuccessNumber,
      error: errorNumber,
      reset: resetNumber,
    },
  ] = usePostAdjustmentNumberMutation()

  const handleRequestError = useCallback(
    (errorData: IResponseErrorData, metricTitle: string) => {
      if (typeof errorData === "string") {
        sendMetric({
          [metricTitle]: {
            "Отправка заявки": {
              Ошибка: [errorData],
            },
          },
        })
        return setOpenOperationStatus(true)
      }

      switch (errorData?.statusInfo.statusCode) {
        case EStatusCode.BadCredentials:
          sendMetric({
            [metricTitle]: {
              "Ввод СМС-кода": {
                Ошибка: [errorData?.statusInfo.statusMessage],
              },
            },
          })
          setError("Неверный код")
          break
        case EStatusCode.PasswordExpired:
          sendMetric({
            [metricTitle]: {
              "Ввод СМС-кода": {
                Ошибка: [errorData?.statusInfo.statusMessage],
              },
            },
          })
          openModal({
            isOpen: true,
            title: "Информация",
            subtitle: errorData?.statusInfo.statusMessage,
            declineLabel: "Понятно",
          })
          return navigate(-1)
        default:
          sendMetric({
            [metricTitle]: {
              "Отправка заявки": {
                Ошибка: [errorData?.statusInfo.statusMessage],
              },
            },
          })
          return setOpenOperationStatus(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (isSuccessNumber) {
      sendMetric({
        "Корректировка платежа по номеру": { "Ввод СМС-кода": ["Успех"] },
      })
      sendMetric({
        "Корректировка платежа по номеру": {
          "Отправка заявки": ["Успех_Показ"],
        },
      })
    }

    if (isSuccessAmount) {
      sendMetric({
        "Корректировка платежа по сумме": { "Ввод СМС-кода": ["Успех"] },
      })
      sendMetric({
        "Корректировка платежа по сумме": {
          "Отправка заявки": ["Успех_Показ"],
        },
      })
    }
  }, [isSuccessAmount, isSuccessNumber])

  useEffect(() => {
    if (errorNumber && "data" in errorNumber) {
      handleRequestError(
        errorNumber.data as IResponseErrorData,
        paymentAdjustmentMetric.number
      )
      resetNumber()
    }
    if (errorAmount && "data" in errorAmount) {
      handleRequestError(
        errorAmount.data as IResponseErrorData,
        paymentAdjustmentMetric.amount
      )
      resetAmount()
    }
  }, [errorAmount, errorNumber, handleRequestError, resetAmount, resetNumber])

  const onSuccessHandle = (code: string) => {
    const additionalValues = {
      smsCode: code,
      signatureImage: signature,
      contactNumber: phone,
    }
    if (type === "amount") {
      const {
        paymentAmount,
        documents = [],
        ...other
      } = values as unknown as IPaymentAdjustmentAmountRequest
      postAdjustmentAmount({
        paymentAmount: paymentAmount.replace(/\D/g, ""),
        documents: normalizeFileData(documents as IFile[]),
        ...other,
        ...additionalValues,
        cardHolder: values?.payerFio as string,
      })
    }

    if (type === "number") {
      const {
        incorrectNumber = "",
        documents = [],
        ...other
      } = values as unknown as IPaymentAdjustmentNumberRequest
      postAdjustmentNumber({
        incorrectNumber: incorrectNumber.replace(/\D/g, "").slice(1),
        documents: normalizeFileData(documents as IFile[]),
        ...other,
        ...additionalValues,
      })
    }
  }
  return (
    <Container page>
      {(isSuccessNumber || isSuccessAmount) && (
        <OperationStatus continueHandler={() => navigate(routes.main)}>
          Срок обработки заявки 5 дней
        </OperationStatus>
      )}

      {openOperationStatus && (
        <OperationStatus
          continueHandler={() => navigate(routes.main)}
          title="Заявка не отправлена"
          isSuccess={false}
          continueButtonTitle="Понятно"
        >
          Произошла ошибка. Пожалуйста, создайте заявку повторно
        </OperationStatus>
      )}
      {!openOperationStatus && !isSuccessNumber && !isSuccessAmount && (
        <>
          <Header
            title="Подтверждение"
            background={theme.colors.background[1]}
          />
          <SmsConfirmation
            number={
              ((type === "number"
                ? values?.refillingNumber
                : values?.clientNumber) as string) ?? ""
            }
            onSuccess={onSuccessHandle}
            type={
              type === "number"
                ? ESendConfirmationType.AdjustmentNumber
                : ESendConfirmationType.AdjustmentAmount
            }
            isLoading={isLoadingAmount || isLoadingNumber}
          />
        </>
      )}
    </Container>
  )
}

export { PaymentAdjustmentConfirmation }
