import { Icon } from "components/Icons"
import { Typography } from "components/Typography"
import styled from "styled-components"
import { colors } from "theme/nightTheme/colors"

const Container = styled.div<{ gradient: string }>`
  width: 100%;
  height: 128px;
  position: relative;
  display: flex;
  background: ${(props) => props.gradient};
  flex-direction: column;
  padding: 0 16px 16px;
  border-radius: 16px;
`

const Title = styled(Typography.Body1Bold)`
  position: relative;
  z-index: 1;
  text-align: start;
`

const Subtitle = styled(Typography.Body2)`
  position: relative;
  z-index: 1;
  color: ${colors.text.primary};
`

const ConfirmationButton = styled.button`
  margin-top: auto;
  position: relative;
  background: ${(props) => props.theme.colors.button.disabled};
  border-radius: 8px;
  width: fit-content;
  padding: 4px 12px 6px 12px;
  border: none;
  text-align: center;
  cursor: pointer;
  z-index: 1;
`

const BannerIcon = styled.div`
  position: absolute;
  right: 0;
`

const CloseIcon = styled(Icon.ClearIcon)`
  opacity: 0.3;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  cursor: pointer;
`

export const Styled = {
  Container,
  Title,
  Subtitle,
  ConfirmationButton,
  BannerIcon,
  CloseIcon,
}
