import { FAQCommon } from "pages"
import { getSearchObject } from "utils/search"
import { MPTypography, Spacer } from "components"
import { SecureAccountStyled } from "../SecureAccount/styles"
import { SELF } from "constants/common"
import {
  DESCRIPTION,
  fullConditions,
  insuranceRulesLink,
  list,
  MONEY_REFUND_CONDITIONS,
  REFUND_CASES,
  TITLE,
} from "./constants"
import { ListStyled, MarkerStyled } from "./styles"
const Footer = () => (
  <SecureAccountStyled.PaddingContainer>
    <Spacer height={16} />

    <MPTypography typographyType="Body1Bold">
      {MONEY_REFUND_CONDITIONS}
    </MPTypography>
    <Spacer height={24} />
    <MPTypography typographyType="Body1">{REFUND_CASES}</MPTypography>
    <Spacer height={24} />

    <ListStyled>
      {list.map((element, index) => (
        <>
          {index !== 0 && <Spacer height={24} />}
          <MarkerStyled key={index}>
            <MPTypography typographyType="Body1">{element}</MPTypography>
          </MarkerStyled>
        </>
      ))}
    </ListStyled>
    <Spacer height={32} />
    <MPTypography typographyType="Body2">
      <>
        {fullConditions[0]}
        {" "}
        {
          <SecureAccountStyled.UnderlineSpan
            onClick={() => window.open(insuranceRulesLink, SELF)}
          >
            {fullConditions[1]}
          </SecureAccountStyled.UnderlineSpan>
        }
      </>
    </MPTypography>
    <Spacer height={32} />
  </SecureAccountStyled.PaddingContainer>
)
export const AmountToRefunded = () => {
  const { theme: externalTheme } = getSearchObject()
  return (
    <FAQCommon
      externalTheme={externalTheme}
      title={TITLE}
      description={DESCRIPTION}
      children={<Footer />}
    />
  )
}
