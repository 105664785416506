import { Primary } from "./Primary"
import { Secondary } from "./Secondary"
import { Plain } from "./Plain"
import { Bordered } from "./Bordered"
import { MPPrimary } from "./MPPrimary"
import { MNPPlain } from "./MNPPlain"
import { Black } from "./Black"

export const Button = {
  Primary,
  Secondary,
  Plain,
  Bordered,
  MPPrimary,
  MNPPlain,
  Black,
}
