import React, { FC, memo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getSearchObject } from "utils/search"
import { RootState } from "store"
import { loginActions } from "store/slice/loginSlice"
import { useActions } from "hooks/actions"
import { deleteCookie } from "utils/cookie"
import { routes } from "constants/routes"
import { TOKEN } from "constants/common"
import { RootStyled } from "./styles"
import { EnterPhoneNumber } from "./components"
import { EnterSmsCode } from "./components/EnterSmsCode"

export const Login: FC = memo(() => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showSmsCode, sberId } = useSelector((state: RootState) => state.login)
  const { goskey, aura, additional, state } = getSearchObject()
  const { clear, setShowSmsCode, resetPhone } = useActions()
  useEffect(() => {
    if (
      goskey !== "true" &&
      aura !== "true" &&
      additional !== "true" &&
      state === undefined
    ) {
      deleteCookie(TOKEN, "")
      clear()
    }

    if (typeof state === "string") {
      setShowSmsCode(true)
    }

    return () => {
      if (goskey !== "true" && aura !== "true" && additional !== "true") {
        dispatch(loginActions.setShowSmsCode(false))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBack = () => {
    if (goskey === "true" || aura === "true") {
      navigate(-1)
    } else if (additional === "true") {
      setShowSmsCode(false)
      navigate(`${routes.login}?additional=true`)
    } else if (typeof state === "string") {
      // TODO попробовать вынести в отдельный компонент
      if (sberId.number) {
        navigate(routes.loginSberId)
      } else {
        resetPhone()
        navigate(routes.loginSberIdBind)
      }
    } else {
      dispatch(loginActions.clear())
    }
  }

  return (
    <RootStyled>
      {!showSmsCode && <EnterPhoneNumber />}
      {showSmsCode && <EnterSmsCode onBack={onBack} />}
    </RootStyled>
  )
})
