import React, { FC, memo } from "react"
import { ICountry } from "entities/roaming"
import { useTheme } from "styled-components"
import { Table, Icon } from "components"

interface ICountries {
  countries: ICountry[]
  title?: string
  onClick: (country: ICountry) => void
  backgroundColor?: string
}

const Countries: FC<ICountries> = memo(
  ({ countries, title, onClick, backgroundColor }) => {
    const theme = useTheme()
    const getIcon = (code: string) => {
      if (code in Icon.Flag) {
        const Flag = Icon.Flag[code]
        return <Flag />
      }
      return <></>
    }
    return (
      <Table.Section
        title={title}
        backgroundColor={backgroundColor ?? theme.colors.background[0]}
      >
        {countries.map((country) => (
          <Table.CommonCell
            title={country.rusName}
            key={country.code}
            startIcon={getIcon(country.code)}
            onClick={() => onClick(country)}
            endIcon={<Icon.ArrowRight />}
          />
        ))}
      </Table.Section>
    )
  }
)

export { Countries }
