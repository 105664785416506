import { ICommonResponse } from "./common"

export enum EPrimeType {
  PrimePlus = "PRIME_PLUS",
  Prime = "PRIME",
}

export enum EPrimeStatus {
  Active = "ACTIVE",
}

export interface IPrimeProfileV2Response extends ICommonResponse {
  data: {
    primeType: EPrimeType
    primeStatus: string
    primeExpirationDate: string
    primeNumber: string
  }
}
