import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { useMotionValue, useDragControls } from "framer-motion"
import { v4 as uuidv4 } from "uuid"
import { useGetUserConfigQuery } from "store/api"
import { normalizeUserConfig } from "entities/userConfig"
import { RootState } from "store"
import { CellDivider } from "components/Table/Section"
import { Table } from "components"
import { endIcon, prepareSubtitle, prepareTitle, renderIcon } from "../utils"
import { HamburgerIconStyled, ReaorderItem } from "./styles"
import { ISortableItemsProps } from "./interface"

export const SortablePhone: FC<ISortableItemsProps> = ({
  phone,
  handlePhoneSelect,
  phonesCount,
}) => {
  const { data: userConfigData } = useGetUserConfigQuery()
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const userConfigNormalized = useMemo(
    () => normalizeUserConfig(userConfigData),
    [userConfigData]
  )

  const y = useMotionValue(0)
  const dragControls = useDragControls()
  return (
    <ReaorderItem
      value={phone}
      id={phone.number}
      style={{ y }}
      dragListener={false}
      dragControls={dragControls}
    >
      <Table.CommonCell
        title={prepareTitle(phone.alias, phone.number)}
        subTitle={prepareSubtitle(phone.alias, phone.number)}
        key={uuidv4()}
        onClick={() => handlePhoneSelect(phone.number)}
        startIcon={renderIcon(userConfigNormalized, phone.number)}
        endIcon={endIcon(phone, currentPhone)}
        isPhoneNumber
      />
      <CellDivider />
      {phonesCount > 2 && (
        <HamburgerIconStyled
          onPointerDown={(event) => dragControls.start(event)}
        />
      )}
    </ReaorderItem>
  )
}
