import React, { FC, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { RefCallBack } from "react-hook-form/dist/types/form"
import Compressor from "compressorjs"
import { nightTheme } from "theme/nightTheme"
import { Header, Icon, Spacer } from "components"
import { ICommonField } from "../Form/interfaces"
import { Styled } from "./styles"

interface IUploadFile extends ICommonField {
  placeholder: string
  info?: string
  max?: number
  value: IFile[]
  fieldRef: RefCallBack
}

export interface IFile {
  contentType: string
  fileBody: string
  fileName: string
  size: number
  url?: string
}

const UploadFile: FC<IUploadFile> = ({
  placeholder,
  name,
  info,
  max = 1,
  value,
  fieldRef,
  onChange,
  onBlur,
  hasError,
  onClick,
}) => {
  const [files, setFiles] = useState<IFile[]>(value ?? [])
  const [selected, setSelected] = useState<number | null>(null)
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)

  const isImage = (fileType: string) => !!fileType.match(/^image\/+/)
  const toMb = (size: number) => Math.round(size / (1024 * 1024))
  const toKb = (size: number) => Math.round(size / 1024)
  const toSizeString = (size: number) =>
    toMb(size) > 0 ? `${toMb(size)} Мб` : `${toKb(size)} Кб`

  const handleClick = () => {
    onClick?.()
    inputRef?.click()
  }

  const readFile = (
    file: File,
    resolve: (value: unknown) => void,
    reject: (reason?: any) => void
  ) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () =>
      resolve({
        fileBody: reader.result,
        contentType: file.type,
        size: file.size,
        fileName: file.name.split(".")[0],
        url: URL.createObjectURL(file),
      })
    reader.onerror = reject
  }

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      if (isImage(file.type)) {
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 1920,
          convertTypes: ["image/png", "image/heic", "image/heif"],
          convertSize: 0,
          success(result: File) {
            readFile(result, resolve, reject)
          },
        })
      } else {
        readFile(file, resolve, reject)
      }
    })
  const saveFiles = (uploadFiles: File[]) =>
    uploadFiles.map(async (file) => (await toBase64(file)) as IFile)

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = await Promise.all(
      saveFiles(Array.from(event.target.files ?? []))
    )
    const newFiles = [...files, ...uploadedFiles].slice(0, max)
    event.target.value = ""
    setFiles(newFiles)
    onChange(newFiles)
    onBlur(newFiles)
  }

  const handleDelete = (index: number) => {
    if (selected !== null) {
      setSelected(null)
    }
    const newFiles = [...files.filter((_, i) => i !== index)]
    setFiles(newFiles)
    onChange(newFiles)
    onBlur(newFiles)
  }

  return (
    <Styled.Root>
      <Styled.Field hasError={hasError}>
        {files.length > 0 ? (
          <>
            <Styled.TopPlaceholder>{placeholder}</Styled.TopPlaceholder>
            <Spacer height={4} />
            <Styled.Files>
              {files.map((file, index) => (
                <Styled.ImageWrapper key={uuidv4()}>
                  {isImage(file.contentType) ? (
                    <Styled.Image
                      src={file.fileBody}
                      onClick={() => setSelected(index)}
                    />
                  ) : (
                    <Styled.ButtonWrapper
                      onClick={() => setSelected(index)}
                      border="solid"
                    >
                      <Icon.Pdf />
                    </Styled.ButtonWrapper>
                  )}
                  <Styled.CloseButton onClick={() => handleDelete(index)} />
                  {hasError && (
                    <Styled.SizeBadge>
                      {toSizeString(file.size)}
                    </Styled.SizeBadge>
                  )}
                </Styled.ImageWrapper>
              ))}
              {files.length < max && (
                <Styled.ButtonWrapper onClick={handleClick} border="dashed">
                  <Icon.UploadFile />
                </Styled.ButtonWrapper>
              )}
            </Styled.Files>
          </>
        ) : (
          <Styled.EmptyField onClick={handleClick}>
            <Styled.Placeholder>{placeholder}</Styled.Placeholder>
            <Styled.UploadFileWrapper>
              <Icon.UploadFile />
            </Styled.UploadFileWrapper>
          </Styled.EmptyField>
        )}
      </Styled.Field>
      <Styled.Info hasError={hasError}>{info}</Styled.Info>
      <Styled.Hidden>
        <input
          id="file_upload"
          type="file"
          multiple
          ref={(input) => {
            fieldRef(input)
            setInputRef(input)
          }}
          accept="image/*,.pdf"
          onChange={handleChange}
          name={name}
        />
      </Styled.Hidden>
      {selected !== null && files.length > 0 && (
        <Styled.ModalWrapper>
          <Styled.Modal>
            <Styled.ModalHeader>
              <Header
                title={`${selected + 1} из ${files.length}`}
                background={nightTheme.colors.background[0]}
                onBackClick={() => setSelected(null)}
              >
                <Icon.Bin onClick={() => handleDelete(selected)} />
              </Header>
            </Styled.ModalHeader>
            {isImage(files[selected].contentType) ? (
              <Styled.ModalImage src={files[selected].fileBody} />
            ) : (
              <Styled.ModalFrame
                title="pdf"
                src={`${files[selected].url}#toolbar=0`}
              />
            )}
          </Styled.Modal>
        </Styled.ModalWrapper>
      )}
    </Styled.Root>
  )
}

export { UploadFile }
