import { FC } from "react"
import { Player } from "@lottiefiles/react-lottie-player"
import successfulFeedback from "animations/successfulFeedback.json"
import { Secondary } from "components/Button/Secondary"
import { Icon } from "components/Icons"
import { Spacer } from "components/Spacer"
import { Container } from "components/Container"
import { OperationStatusStyles } from "./styles"
import { IOperationStatusProps } from "./interfaces"

export const OperationStatus: FC<IOperationStatusProps> = ({
  title = "Заявка принята",
  children,
  continueHandler,
  continueButtonTitle = "Отлично",
  isSuccess = true,
}) => (
  <>
    <OperationStatusStyles.Root>
      <OperationStatusStyles.MainContent>
        <OperationStatusStyles.WrapImage>
          {isSuccess ? (
            <Player autoplay loop src={successfulFeedback} />
          ) : (
            <Container>
              <Spacer height={32} />
              <Icon.NotSuccess />
            </Container>
          )}
        </OperationStatusStyles.WrapImage>
        <OperationStatusStyles.TextContent>
          <OperationStatusStyles.H4>{title}</OperationStatusStyles.H4>
          {children && (
            <OperationStatusStyles.Subtitle>
              {children}
            </OperationStatusStyles.Subtitle>
          )}
        </OperationStatusStyles.TextContent>
      </OperationStatusStyles.MainContent>
      <OperationStatusStyles.Footer>
        <Secondary onClick={continueHandler} children={continueButtonTitle} />
      </OperationStatusStyles.Footer>
    </OperationStatusStyles.Root>
  </>
)
