import styled from "styled-components"
import { Typography, Icon } from "components"

export const RootStyled = styled.div`
  width: 100%;
`

export const SberMobileIconStyled = styled(Icon.SberMobile)`
  margin: 16px 0 80px 16px;
`

export const WrapHeaderStyled = styled.div`
  margin-bottom: 74px;
`

export const H1Styled = styled(Typography.H1)`
  margin: 0 32px;
`

export const ServiceRulesWrap = styled.div`
  margin: 16px 32px 0px;
`

export const FootnoteStyled = styled(Typography.Body2)`
  color: ${(props) => props.theme.colors.text.secondary};
`

export const FootnoteLinkStyled = styled(Typography.Body2Link)`
  cursor: pointer;
  text-decoration: underline;
  margin: 0px 3px;
`

export const InputWrapStyled = styled.div`
  user-select: initial;
  padding: 32px 16px 24px;
  display: flex;
  border-radius: 8px;
`

export const OrStyled = styled(Typography.Body2)`
  text-align: center;
  margin-bottom: 24px;
`

export const SberIdContainer = styled.div`
  text-align: center;
  padding: 0px 16px;
`
