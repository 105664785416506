import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { usePostNumberTransferOrderMutation } from "store/api"
import { IModalState, IResponseErrorData } from "entities/common"
import { RootState } from "store"
import { useTheme } from "styled-components"
import { CommonModal } from "components/CommonModal"
import moment from "moment"
import { sendMetric } from "utils/metrics"
import {
  Header,
  Signing,
  Typography,
  Container,
  Loader,
  PhoneNumber,
} from "components"
import { routes } from "constants/routes"

export const NumberTransferSigning = () => {
  const navigate = useNavigate()
  const [modalState, setModalState] = useState<IModalState>({
    isOpen: false,
    title: "",
  })
  const { values } = useSelector((state: RootState) => state.form)
  if (!values) {
    navigate(routes.menu)
  }
  const theme = useTheme()
  const [numberTransferOrderTrigger, { error, isLoading, isSuccess }] =
    usePostNumberTransferOrderMutation()
  const { numberTransfer } = useSelector((state: RootState) => state.common)
  const { smsCode } = useSelector((state: RootState) => state.login)

  const declineHandler = () => {
    navigate(routes.menu)
  }

  const onClickContinue = (signature?: string) => {
    if (!values) {
      return
    }
    sendMetric({ MNP: { Подписание: ["Ввод подписи_Клик"] } })
    const date = moment(
      `${values.transferDate as string} ${values.transferHour as string}`,
      "DD.MM.YY h"
    ).format()
    numberTransferOrderTrigger({
      number: numberTransfer,
      temporaryNumber: values.temporaryNumber as string,
      transferDate: date,
      notificationNumber: numberTransfer,
      agreementSign: signature ?? "",
      code: smsCode,
    })
  }

  useEffect(() => {
    if (modalState.isOpen) {
      return
    }

    if (isSuccess) {
      sendMetric({ MNP: { "Отправка заявки": ["Успех_Показ"] } })
      navigate(routes.numberTransferStatus)
    }

    if (error && "data" in error) {
      const errorData = error.data as IResponseErrorData
      const errorStatusCode = errorData.statusInfo.statusMessage
      sendMetric({ MNP: { "Отправка заявки": { Ошибка: [errorStatusCode] } } })
      setModalState({
        title: errorStatusCode,
        declineLabel: "Понятно",
        isOpen: true,
      })
    }
  }, [error, isSuccess, modalState.isOpen, navigate, setModalState])

  return (
    <>
      {isLoading && <Loader />}
      <CommonModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false, title: "" })}
        title={modalState.title}
        subtitle={modalState?.subtitle}
        declineLabel={modalState?.declineLabel}
        preventCloseOnOutsideClick
        declineHandler={declineHandler}
      />
      <Container page>
        <Header title="Подписание" background={theme.colors.background[1]} />
        <Signing onContinue={onClickContinue}>
          {values && (
            <Typography.Body1 lineHeight={22}>
              Переносим в сеть СберМобайл
              <br />
              номер <PhoneNumber phone={numberTransfer} />
              <br />
              вместо 
              <PhoneNumber phone={values.temporaryNumber as string} />{" "}
            </Typography.Body1>
          )}
        </Signing>
      </Container>
    </>
  )
}
