import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux"
import { RootState } from "store"

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
export const formSelector = (store: RootState) => store.form
export const loginSelector = (store: RootState) => store.login
export const modalSelector = (store: RootState) => store.modal
