import { routes } from "constants/routes"

export const REQUIRED_CONDITIONS_TITLE =
  "Условия возврата денег, если мошенники всё же обманут меня"

export const FOOTER_INFO =
  "Я соглашаюсь с тем, что при несоблюдении условий не могу претендовать на возврат денег"

export const requiredConditions = [
  {
    id: 1,
    title:
      "Ваш номер СберМобайл и счёт в Сбере оформлены на одни и те же паспортные данные",
  },
  {
    id: 2,
    buttonText: "Как это сделать?",
    title:
      "Ваш номер СберМобайл указан в приложении СберБанк Онлайн как основной",
    route: `${routes.pwaChangeSberMainNumber}`,
  },
  {
    id: 3,
    title:
      "Вы стали жертвой мошенников во время телефонного разговора с ними в сети СберМобайл",
  },
  {
    id: 4,
    title:
      "Деньги списали с вашего счёта в Сбере — другие банки не попадают под страхование",
  },
  {
    id: 5,
    title: "У вас был оплачен тариф на момент разговора с мошенниками ",
  },
]
