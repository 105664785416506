export const formatPrice = (value: string | number) => {
  const valueInt = Number(value) < 0 ? 0 : Number(value)
  const rubles = Math.trunc(valueInt / 100) || 0
  const rublesStr = rubles.toLocaleString("ru")
  const kopecks = valueInt % 100
  const kopecksStr = ("0" + kopecks).slice(-2)
  const formated =
    kopecks === 0 ? `${rublesStr} ₽` : `${rublesStr},${kopecksStr} ₽`
  return {
    rubles,
    rublesStr,
    kopecks,
    kopecksStr,
    value: formated,
  }
}
