import styled from "styled-components"
import { Primary } from "components/Button/Primary"
import { Plain } from "components/Button/Plain"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background[1]};
`

export const FooterStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 160px;
  max-width: 480px;
  bottom: 0;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.background[1]};
`

export const ButtonPrimaryStyled = styled(Primary)`
  width: 100%;
  margin-bottom: 8px;
`

export const ButtonPlainStyled = styled(Plain)`
  width: 100%;
`

export const WrapItemsStyled = styled.div`
  padding: 0 16px;
`
