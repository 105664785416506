import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { Badge, Spacer, Table, Typography } from "components"
import { routes } from "constants/routes"
import { Styled } from "./styles"

type Props = {
  title: string
  subTitle: string
  isActive: boolean
}

export const GuardianBanner: FC<Props> = (props) => {
  const { title, subTitle, isActive } = props
  const navigate = useNavigate()

  return (
    <Table.Body>
      <Table.Section cellContainerMargin={{ marginTop: 0, marginBottom: 8 }}>
        <Table.CellTwoColumn
          leftColumn={
            <>
              <Typography.Body1Bold fontSize={17} lineHeight={22}>
                {title}
              </Typography.Body1Bold>
              <Spacer height={4} />
              <Typography.Body2 fontSize={13} lineHeight={18}>
                {subTitle}
              </Typography.Body2>
              <Spacer height={16} />
              <Badge
                onClick={() => navigate(routes.secureAccountGuardian)}
                text={isActive ? "Подробнее" : "Подключить"}
                active={isActive}
              />
            </>
          }
          rightColumn={
            <>
              <Styled.GuardianVolume />
              <Styled.GuardianIcon />
            </>
          }
        />
      </Table.Section>
    </Table.Body>
  )
}
