import React, { FC, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { formatPhoneNumber } from "utils"
import { RootState } from "store"
import { sendMetric } from "utils/metrics"
import { useGetUserConfigQuery } from "store/api"
import { prepareCurrentFeatureConfig } from "utils/userConfig"
import { Icon, Table } from "components"
import { routes } from "constants/routes"

interface IEsimIdentifyProps {
  isInactiveEsim?: boolean
}

export const EsimIdentify: FC<IEsimIdentifyProps> = ({ isInactiveEsim }) => {
  const navigate = useNavigate()
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data: userConfigData } = useGetUserConfigQuery()

  const currentFeatureConfig = useMemo(
    () =>
      prepareCurrentFeatureConfig(
        currentPhone,
        userConfigData?.data?.userConfigs
      ),
    [currentPhone, userConfigData?.data?.userConfigs]
  )

  useEffect(() => {
    sendMetric({
      ГлавныйЭкран: { ВиджетДоговораЕСимПоказ: ["НужноПодписать"] },
    })
  }, [])

  const onSignClick = () => {
    sendMetric({
      ГлавныйЭкран: { ВиджетДоговораЕСимКлик: ["НужноПодписать"] },
    })
    navigate(routes.signature)
  }

  if (isInactiveEsim) {
    return (
      <Table.Section key={uuidv4()}>
        <Table.CommonCell
          titleInfo="Чтобы пользоваться eSIM — подпишите договор"
          startIcon={<Icon.AgreementNotSigned />}
          endIcon={<Icon.ArrowRight />}
          onClick={onSignClick}
        />
      </Table.Section>
    )
  }

  return currentFeatureConfig?.isEsimIdentifyBannerV2Enabled ? (
    <Table.Section key={uuidv4()}>
      <Table.CommonCell
        title="Нужно подписать договор"
        subTitle={`для eSIM ${formatPhoneNumber(currentPhone, true)}`}
        startIcon={<Icon.AgreementNotSigned />}
        endIcon={<Icon.ArrowRight />}
        onClick={onSignClick}
        isPhoneNumber
      />
    </Table.Section>
  ) : (
    <></>
  )
}
