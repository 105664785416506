import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Page } from "react-pdf"
import { pdfjs } from "react-pdf"
import { Checkbox } from "components/Checkbox"
import { useTheme } from "styled-components"
import { sendMetric } from "utils/metrics"
import { Header } from "components"
import { routes } from "constants/routes"
import aura from "./aura.pdf"
import {
  DocumentWrap,
  CheckboxWrap,
  RootStyled,
  PrimaryButton,
  HeaderWrap,
  DocumentStyled,
} from "./styles"
import { PdfSkeleton } from "./PdfSkeleton"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/legacy/build/pdf.worker.min.js",
  import.meta.url
).toString()
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
export const AuraAgreement = () => {
  const theme = useTheme()
  const [width, setWidth] = useState(0)
  const [isChecked, setIsChecked] = useState(false)
  const navigate = useNavigate()
  const onPdfLoad = () => {
    setWidth(
      document.getElementsByTagName("canvas")[0]?.offsetParent?.clientWidth ?? 0
    )
  }

  const handleAgreement = () => {
    sendMetric({ Услуги: { Доступные: { Аура: ["Принять условия_Клик"] } } })
    navigate(routes.auraSigning)
  }

  return (
    <RootStyled padding={16}>
      <HeaderWrap>
        <Header title="Услуга Аура" background={theme.colors.background["1"]} />
      </HeaderWrap>
      <DocumentWrap>
        <DocumentStyled file={aura} loading={<PdfSkeleton />}>
          <Page
            pageNumber={1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            onLoadSuccess={onPdfLoad}
            width={width}
          />
        </DocumentStyled>
      </DocumentWrap>
      <CheckboxWrap>
        <Checkbox
          label="Я соглашаюсь с условиями и подписываю документы по операции"
          checked={isChecked}
          onChange={() => setIsChecked((prev) => !prev)}
        />
      </CheckboxWrap>
      <PrimaryButton
        isDisabled={!isChecked}
        disabled={!isChecked}
        onClick={handleAgreement}
      >
        Подписать
      </PrimaryButton>
    </RootStyled>
  )
}
