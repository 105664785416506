import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  FC,
  ReactElement,
} from "react"
import { IBonusOption } from "entities/tariff"
import { EContentType } from "entities/common"
import {
  CaptionStyled,
  ContainerStyled,
  H4Styled,
  PathStyled,
  WrapIconStyled,
  WrapTextStyled,
  CarryOverIcon,
} from "./styles"

export interface IRadialChart {
  color: string
  percent: number
  footer: string
  body: string
  icon: ReactElement
  carryOverResidues?: IBonusOption
  contentType?: EContentType
  onClick?: (contentType?: EContentType) => void
  footerColor?: string
}

export const RadialChart: FC<IRadialChart> = ({
  percent,
  color,
  footer,
  body,
  icon,
  carryOverResidues,
  onClick,
  contentType,
  footerColor,
}) => {
  const [totalLength, setTotalLength] = useState(1000)
  const pathRef = useRef<SVGPathElement>(null)

  useEffect(() => {
    const pathLen = pathRef.current?.getTotalLength() ?? 0
    setTotalLength(Math.round(pathLen) + 1)
  }, [])

  const dashOffset = useMemo(() => {
    const percentLength = (totalLength / 100) * percent
    return totalLength + percentLength
  }, [totalLength, percent])

  const onClickInner = () => {
    onClick?.(contentType)
  }

  return (
    <ContainerStyled color={color} onClick={onClickInner}>
      {carryOverResidues && (
        <CarryOverIcon color={color} isActive={carryOverResidues.active} />
      )}
      <WrapIconStyled>{icon}</WrapIconStyled>
      <WrapTextStyled>
        <H4Styled>{body}</H4Styled>
        <CaptionStyled color={footerColor}>{footer}</CaptionStyled>
      </WrapTextStyled>
      <svg
        width="96"
        height="75"
        viewBox="0 0 96 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref={pathRef}
          d="M86.5 71.3098C90.625
          64.5112 93 56.533 93
          48C93 23.1472 72.8528
          3 48 3C23.1472 3 3 23.1472
          3 48C3 56.533 5.37499
          64.5112 9.5 71.3098"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray={totalLength}
          strokeDashoffset={dashOffset}
          color={color}
        />
        <PathStyled
          ref={pathRef}
          d="
          M86.5 71.3098C90.625
          64.5112 93 56.533 93
          48C93 23.1472 72.8528
          3 48 3C23.1472 3 3 23.1472
          3 48C3 56.533 5.37499
          64.5112 9.5 71.3098"
          stroke="currentColor"
          stroke-width="6"
          stroke-linecap="round"
          strokeMiterlimit={0}
          color={color}
        />
      </svg>
    </ContainerStyled>
  )
}
