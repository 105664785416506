import React, { FC } from "react"
import ContentLoader from "react-content-loader"
import { useTheme } from "styled-components"
import { Table } from "components"
import { ServiceSkeletonContainer } from "./styles"

const SPEED = 1.2
const INTERVAL = 0.25

const StartIcon = () => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={SPEED}
      height={56}
      interval={INTERVAL}
      backgroundColor={theme.colors.stroke.color}
      foregroundColor={theme.colors.background[1]}
      width="80%"
    >
      <rect rx="33" ry="33" width="33px" height="33px" />
      <rect x="44px" y="2px" rx="8" ry="8" width="109px" height="16px" />
      <rect x="44px" y="25px" rx="6" ry="6" width="75%" height="13px" />
    </ContentLoader>
  )
}

const EndIcon = () => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={SPEED}
      height={16}
      interval={INTERVAL}
      backgroundColor={theme.colors.stroke.color}
      foregroundColor={theme.colors.background[1]}
      width="42px"
    >
      <rect rx="8" ry="8" width="42px" height="16px" />
    </ContentLoader>
  )
}

const ServiceItem = () => (
  <ServiceSkeletonContainer>
    <StartIcon />
    <EndIcon />
  </ServiceSkeletonContainer>
)

const Skeleton: FC = () => {
  const theme = useTheme()
  return (
    <Table.Body padding={16}>
      <ContentLoader
        speed={SPEED}
        interval={INTERVAL}
        height={74}
        backgroundColor={theme.colors.transparent.black["10"]}
        foregroundColor={theme.colors.background[1]}
      >
        <rect rx="19" ry="19" width="100%" height="35" />
        <rect y="51" rx="8" ry="8" width="117" height="16" />
      </ContentLoader>
      <Table.Section spacedView>
        <ServiceItem />
        <ServiceItem />
        <ServiceItem />
        <ServiceItem />
        <ServiceItem />
        <ServiceItem />
      </Table.Section>
    </Table.Body>
  )
}

export { Skeleton }
