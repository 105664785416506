import React, { useRef, useState, useCallback, useEffect, FC } from "react"
import { Loader } from "components/Loader"
import { IPullToRefreshProps } from "./interfaces"
import {
  LoaderIcon,
  LoaderIconAnimation,
  RenderDataWrapper,
  WrapLoaderIconStyled,
} from "./styles"

const pullToRefreshClasses = {
  loading: "loading",
  showLoader: "showLoader",
}

export const PullToRefresh: FC<IPullToRefreshProps> = ({
  isFetching,
  onPullEnd,
  children,
  iconTopOffset,
}) => {
  const [startPoint, setStartPoint] = useState(0)
  const [, setIsVisibleChanged] = useState(false)
  const refreshContainer = useRef<HTMLDivElement>(null)
  const pullChangeRef = useRef(0)

  const onChangeVisibility = useCallback(() => {
    if (document.visibilityState === "visible") {
      setIsVisibleChanged((prev) => !prev)
    }
  }, [])

  useEffect(() => {
    if (!isFetching && refreshContainer?.current) {
      refreshContainer.current?.classList.remove(
        pullToRefreshClasses.showLoader
      )
      refreshContainer?.current.style.setProperty("z-index", "0")
    }
  }, [isFetching])

  const onTouchStart = useCallback(
    (e: TouchEvent) => {
      if (!isFetching && window.scrollY === 0) {
        const { screenY } = e.targetTouches[0]
        setStartPoint(screenY)
      }
    },
    [isFetching]
  )

  const onTouchMove = useCallback(
    (e: TouchEvent) => {
      if (!isFetching && window.scrollY === 0) {
        const refreshContainerRef = refreshContainer?.current
        const touch = e.targetTouches[0]
        const { screenY } = touch
        const pullLength =
          startPoint < screenY ? Math.abs(screenY - startPoint) : 0
        pullChangeRef.current = pullLength

        if (!refreshContainerRef) {
          return
        }

        if (screenY - startPoint > 0) {
          refreshContainerRef.classList.add(pullToRefreshClasses.loading)
        }

        if (pullLength > 90) {
          refreshContainerRef.classList.remove(pullToRefreshClasses.loading)
          refreshContainerRef.classList.add(pullToRefreshClasses.showLoader)
          refreshContainer?.current.style.setProperty("z-index", "4")
        }

        refreshContainer.current.style.setProperty(
          "--pull-length",
          pullLength + ""
        )
      }
    },
    [isFetching, startPoint]
  )

  const onTouchEnd = useCallback(() => {
    const el = refreshContainer?.current
    if (!el) {
      return
    }

    const pullLength = pullChangeRef?.current
    if (pullLength) {
      setStartPoint(0)
    }

    el.classList.remove(pullToRefreshClasses.loading)
    if (el.classList.value.includes("showLoader")) {
      onPullEnd()
      el.style.setProperty("--pull-length", 0 + "")
      pullChangeRef.current = 0
    }
  }, [onPullEnd])

  useEffect(() => {
    document.addEventListener("touchstart", onTouchStart)
    document.addEventListener("touchmove", onTouchMove)
    document.addEventListener("touchend", onTouchEnd)
    document.addEventListener("visibilitychange", onChangeVisibility)
    return () => {
      document.removeEventListener("touchstart", onTouchStart)
      document.removeEventListener("touchmove", onTouchMove)
      document.removeEventListener("touchend", onTouchEnd)
      document.removeEventListener("visibilitychange", onChangeVisibility)
    }
  }, [onTouchStart, onTouchMove, onTouchEnd, onChangeVisibility])

  return (
    <>
      {isFetching && <Loader />}
      <RenderDataWrapper ref={refreshContainer}>
        <WrapLoaderIconStyled icontopoffset={iconTopOffset}>
          <LoaderIconAnimation />
        </WrapLoaderIconStyled>
        <LoaderIcon icontopoffset={iconTopOffset} />
        {children}
      </RenderDataWrapper>
    </>
  )
}
