import styled from "styled-components"
import { Typography } from "components"

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 192px;
  width: 100%;
  min-height: 250px;
  padding: 16px 12px 12px;
  border-radius: 16px;
  background: ${(props) => props.theme.colors.background[1]};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const IconContainer = styled.div`
  display: inline-flex;
  margin-bottom: 16px;

  svg {
    width: 36px;
    height: 36px;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const Price = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 65px;
  word-wrap: break-word;
`

const SheetButtonPrimary = styled(Typography.Body1Bold)`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.button.contentPrimary};
  letter-spacing: 1.5px;
  text-transform: uppercase;
`

const SheetButtonSecondary = styled(Typography.Body1Bold)<{
  color: string
}>`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.color};
  letter-spacing: 1.5px;
  text-transform: uppercase;
`

const SheetAssistantText = styled(Typography.Body1)`
  padding-top: 16px;
  font-size: 13px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.button.contentPrimary};
  text-align: center;
`

const SheetAssistantLink = styled(Typography.Body2Link)`
  font-size: 13px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.button.contentPrimary};
  text-decoration: underline;
  cursor: pointer;
`

export const Styled = {
  Card,
  Content,
  IconContainer,
  Footer,
  Price,
  SheetButtonPrimary,
  SheetButtonSecondary,
  SheetAssistantText,
  SheetAssistantLink,
}
