import styled, { css } from "styled-components"

const Flex = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SberKidsContainer = styled.div`
  ${Flex}
  position: relative;
  /* margin-top: -16px; */
  top: -16px;
  align-items: center;
  padding: 24px 0;
  border-radius: 16px 16px 0 0;
  z-index: 2;
  background: ${(props) => props.theme.colors.background.surface1};
  > p {
    text-align: center;
  }
`
const IconWrapper = styled.div`
  ${Flex}
  align-items: center;
  width: 48px;
  height: 36px;
`

export const ImgStyled = styled.img`
  width: 100%;
  display: flex;
  margin: 0px auto;
`

export const SberKidsStyled = {
  SberKidsContainer,
  IconWrapper,
  ImgStyled,
}
