import { useState } from "react"
import { Page, pdfjs } from "react-pdf"
import { getSearchObject } from "utils/search"
import { useSetTheme } from "hooks/useSetTheme"
import { PdfSkeleton } from "pages/AuraAgreement/PdfSkeleton"
import insurance from "./insurance.pdf"
import { DocumentWrap, RootStyled, DocumentStyled } from "./styles"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/legacy/build/pdf.worker.min.js",
  import.meta.url
).toString()
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
export const InsuranceRules = () => {
  const { theme: externalTheme } = getSearchObject()
  useSetTheme(externalTheme)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(100)
  const onPdfLoad = () => {
    setHeight(0)
    setWidth(
      document.getElementsByTagName("canvas")[0]?.offsetParent?.clientWidth ?? 0
    )
  }

  return (
    <RootStyled padding={16}>
      <DocumentWrap>
        <DocumentStyled
          height={height}
          file={insurance}
          loading={<PdfSkeleton />}
        >
          {Array(33)
            .fill(0)
            .map((_, i) => (
              <Page
                pageNumber={i + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                onLoadSuccess={onPdfLoad}
                width={width}
              />
            ))}
        </DocumentStyled>
      </DocumentWrap>
    </RootStyled>
  )
}
