import React, { FC, ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { Typography } from "../"
import {
  ArrowLeftIconStyled,
  HeightStyled,
  LeftStyled,
  RightStyled,
  RootStyled,
  TitleWrapStyled,
} from "./styles"

interface IHeaderProps {
  back?: boolean // Отображать или нет кнопку назад
  title?: string // Заголовок
  subtitle?: string // Подзаголовок
  onBackClick?(): void // Обработчик клика назад
  children?: ReactNode
  background?: string
}

export const Header: FC<IHeaderProps> = ({
  back = true,
  onBackClick,
  children,
  subtitle,
  title,
  background,
}) => {
  const navigate = useNavigate()
  const onBack = () => {
    if (onBackClick) {
      onBackClick()
    } else {
      navigate(-1)
    }
  }

  return (
    <div>
      <RootStyled background={background}>
        <LeftStyled>
          {back && (
            <ArrowLeftIconStyled onClick={onBack} data-testid="backButton" />
          )}
        </LeftStyled>
        <TitleWrapStyled>
          <Typography.Body1>{title}</Typography.Body1>
          <Typography.Body2>{subtitle}</Typography.Body2>
        </TitleWrapStyled>
        <RightStyled>{children}</RightStyled>
      </RootStyled>
      <HeightStyled />
    </div>
  )
}
