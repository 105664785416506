import React, { FC } from "react"
import { EDiscountType } from "entities/tariff"
import { Typography } from "components"
import { Container } from "./styles"

const DiscountFlag: FC<{ discount: string; type?: EDiscountType }> = ({
  discount,
  type,
}) => {
  return (
    <Container>
      <svg
        width="75"
        height="39"
        viewBox="0 0 75 39"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 39L75 34.1165L70.0751 17.485L75 0L0 4.88353V39Z"
          fill={`url(#${
            type === EDiscountType.subsFeeDiscount
              ? "linearGradient-subsFeeDiscount"
              : "linearGradient-prime"
          })`}
        />
        {type === EDiscountType.subsFeeDiscount && (
          <defs>
            <linearGradient
              id="linearGradient-subsFeeDiscount"
              x1="-5.58793e-07"
              y1="19.5"
              x2="75"
              y2="19.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F8426E" />
              <stop offset="1" stopColor="#FF8934" />
            </linearGradient>
          </defs>
        )}
        {type === EDiscountType.prime && (
          <defs>
            <linearGradient
              id="linearGradient-prime"
              x1="5.30973"
              y1="38.9977"
              x2="75"
              y2="38.9977"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A2E538" />
              <stop offset="0.48" stopColor="#21D726" />
              <stop offset="0.53" stopColor="#21D531" />
              <stop offset="0.63" stopColor="#20CE4F" />
              <stop offset="0.76" stopColor="#1FC480" />
              <stop offset="0.91" stopColor="#1EB6C3" />
              <stop offset="1" stopColor="#1DAEEB" />
            </linearGradient>
          </defs>
        )}
      </svg>
      <Typography.H4>{discount}</Typography.H4>
    </Container>
  )
}

export { DiscountFlag }
