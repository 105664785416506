import { FC, memo, useCallback, useEffect, useState, UIEvent } from "react"
import { useSelector } from "react-redux"
import { Player } from "@lottiefiles/react-lottie-player"
import empty from "animations/empty.json"
import { formatPhoneNumber } from "utils"
import { formatPrice } from "utils/price"
import { RootState } from "store"
import { useActions } from "hooks/actions"
import { sendMetric } from "utils/metrics"
import { useNavigate } from "react-router"
import { usePostBeautifulNumberReserveMutation } from "store/api"
import { Icon, Loader, Table, Typography, useModalConfirm } from "components"
import { urls } from "constants/urls"
import { routes } from "constants/routes"
import { BLANK } from "constants/common"
import { INumberListProps } from "./interfaces"
import { Container, EndIcon, HighLight, TypographyPrice } from "./styles"

export const NumbersList: FC<INumberListProps> = memo(
  ({
    trigger,
    setPageNumber,
    isLoading,
    pageNumber,
    balance,
    currentNumber,
    setScrollToTop,
    numberSectionRef,
    beautifulPhones,
    isDataEmpty,
  }) => {
    const { openModal, closeModal, setNewMsisdn, setReplacedPhone } =
      useActions()
    const [isLocalLoading, setLocalLoading] = useState(false)
    const { isLoaded, mask, currentCategory } = useSelector(
      (state: RootState) => state.beautifulNumbers
    )
    const { phone } = useSelector((state: RootState) => state.login)
    const [selectedNumber, setSelectedNumber] = useState("")
    const additionalNumber = phone !== currentNumber ? currentNumber : ""
    const navigate = useNavigate()
    const [beautifulNumberReserve, { isSuccess, isLoading: isReserveLoading }] =
      usePostBeautifulNumberReserveMutation()

    const renderEndIcon = (price: number) => (
      <EndIcon>
        <TypographyPrice>{formatPrice(price).value}</TypographyPrice>
        <Icon.ArrowRight />
      </EndIcon>
    )

    const onScroll = useCallback(
      (event: UIEvent<HTMLElement>) => {
        const isBottom =
          (event.target as HTMLElement).scrollHeight -
            (event.target as HTMLElement).scrollTop -
            (event.target as HTMLElement).clientHeight <
          1

        if (
          isBottom &&
          !isLoading &&
          !isLocalLoading &&
          beautifulPhones?.hasNextPage
        ) {
          setLocalLoading(true)
        }
      },
      [beautifulPhones, isLoading, isLocalLoading]
    )

    useEffect(() => {
      if (isSuccess) {
        navigate(routes.beautifulNumbersChange, {
          state: {
            currentNumber,
            newNumber: selectedNumber,
            price: beautifulPhones?.price,
          },
        })
      }
    }, [
      beautifulPhones?.price,
      currentNumber,
      isSuccess,
      navigate,
      selectedNumber,
    ])

    useEffect(() => {
      if (isLocalLoading && beautifulPhones?.hasNextPage) {
        trigger({
          pageNumber: pageNumber + 1,
          additionalNumber,
        })

        setLocalLoading(false)
        setPageNumber(pageNumber + 1)
      }
    }, [
      beautifulPhones?.hasNextPage,
      isLocalLoading,
      pageNumber,
      setPageNumber,
      trigger,
      additionalNumber,
    ])

    useEffect(() => {
      const section = numberSectionRef.current
      setScrollToTop((isScrollTop: boolean) => {
        if (isScrollTop) {
          section?.scrollTo(0, 0)
        }
        return false
      })
      section?.addEventListener(
        "scroll",
        onScroll as (event: UIEvent<HTMLElement> | Event) => void
      )
      return () => {
        section?.removeEventListener(
          "scroll",
          onScroll as (event: UIEvent<HTMLElement> | Event) => void
        )
      }
    }, [numberSectionRef, onScroll, setScrollToTop])

    useModalConfirm(() => {
      sendMetric({
        КрасивыеНомера: {
          МодальноеОкно: {
            "Недостаточно средств для смены номера": "ПополнитьБалансКлик",
          },
        },
      })
      window.open(`${urls.pay}&phone=${currentNumber}`, BLANK)?.focus()
      closeModal()
    })

    const onClickNumber = (newNumber: string) => {
      if (balance === undefined) {
        return
      }

      if (beautifulPhones && beautifulPhones.price > balance) {
        openModal({
          isOpen: true,
          title: "Недостаточно средств для смены номера",
          subtitle: "Пополнить баланс?",
          confirmLabel: "Пополнить",
          declineLabel: "Не сейчас",
          isPhoneNumber: true,
        })
      } else {
        sendMetric({
          Ещё: {
            "Красивые номер": { "Выбор номера_Клик": [currentCategory] },
          },
        })

        if (currentNumber !== phone) {
          beautifulNumberReserve({
            msisdn: newNumber,
            additionalNumber: currentNumber,
          })
        } else {
          beautifulNumberReserve({ msisdn: newNumber })
        }
        setNewMsisdn(newNumber)
        setSelectedNumber(newNumber)
        setReplacedPhone(currentNumber)
      }
    }

    const highlightText = (number: string) => {
      const phoneParts = (formatPhoneNumber(number, true) as string).split(
        new RegExp(`(${mask.split("").join("-?")})`, "gi")
      )

      return (
        <p>
          {phoneParts.map((part) =>
            part.replace(/-/gi, "") === mask.replace(/-/gi, "") ? (
              <HighLight>{part}</HighLight>
            ) : (
              <span>{part}</span>
            )
          )}
        </p>
      )
    }

    return (
      <>
        {(isLocalLoading || isLoading || isLoaded || isReserveLoading) && (
          <Loader title="Загружаем красивые номера" />
        )}
        {!isLocalLoading &&
          !isLoading &&
          !isDataEmpty &&
          !beautifulPhones?.msisdns.length && (
            <Table.Body padding={48}>
              <Player autoplay loop src={empty} />
              <Typography.Body2 textAlign="center">
                К сожалению, у нас сейчас нет номеров этой категории
              </Typography.Body2>
            </Table.Body>
          )}
        {beautifulPhones && (
          <Container noPadding ref={numberSectionRef}>
            <Table.Section>
              {beautifulPhones.msisdns.map((number: string) => (
                <Table.CommonCell
                  onClick={() => onClickNumber(number)}
                  title={
                    mask
                      ? highlightText(number)
                      : formatPhoneNumber(number, true)
                  }
                  endIcon={renderEndIcon(beautifulPhones.price)}
                />
              ))}
            </Table.Section>
          </Container>
        )}
      </>
    )
  }
)
