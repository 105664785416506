import React, { FC, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { useTheme } from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { RootState } from "store"
import { formatPhoneNumber } from "utils"
import {
  useGetLinksQuery,
  useGetPrimeProfileV2Mutation,
  useGetUserDetailAuthParamsQuery,
  usePostUserDetailServiceAuthLoginMutation,
} from "store/api"
import { EPrimeType } from "entities/prime"
import { ELinksCode } from "entities/common"
import { getSearchObject } from "utils/search"
import { loginActions } from "store/slice/loginSlice"
import { useActions } from "hooks/actions"
import { Button, Header, Icon, Table } from "components"
import { routes } from "constants/routes"
import { BLANK } from "constants/common"
import {
  ButtonContainerStyled,
  FooterLabelStyled,
  RootStyled,
  TitleStyled,
  WrapContent,
} from "./styles"
import { BindAnotherNumberSheet } from "./components"

export const LoginSberId: FC = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const navigate = useNavigate()
  const { sberId, token } = useSelector((state: RootState) => state.login)
  const { code } = getSearchObject()
  const [triggerGetPrimeProfileV2, primeData] = useGetPrimeProfileV2Mutation()
  const { data: links } = useGetLinksQuery()
  const [triggerDetailServiceAuthLogin, authLoginStatus] =
    usePostUserDetailServiceAuthLoginMutation()
  const { data: userDetailAuthParamsData } = useGetUserDetailAuthParamsQuery()

  const { setToken, setSberIdNumber, setSberIdClientFullName } = useActions()
  useEffect(() => {
    if (
      code &&
      userDetailAuthParamsData?.data?.state &&
      userDetailAuthParamsData?.data?.nonce &&
      userDetailAuthParamsData?.data?.redirect_uri &&
      !token
    ) {
      triggerDetailServiceAuthLogin({
        returnUri: userDetailAuthParamsData.data.redirect_uri,
        nonce: userDetailAuthParamsData.data.nonce,
        state: userDetailAuthParamsData.data.state,
        authCode: code,
      })
    }
  }, [
    code,
    userDetailAuthParamsData?.data?.state,
    triggerDetailServiceAuthLogin,
    userDetailAuthParamsData?.data?.redirect_uri,
    userDetailAuthParamsData?.data?.nonce,
    token,
  ])

  useEffect(() => {
    if (token) {
      triggerGetPrimeProfileV2()
    }
  }, [token, triggerGetPrimeProfileV2])

  const getPrimeTitle = () => {
    switch (primeData?.data?.data?.primeType) {
      case EPrimeType.Prime:
        return "СберПрайм"
      case EPrimeType.PrimePlus:
        return "СберПрайм+"
      default:
        return ""
    }
  }

  useEffect(() => {
    if (authLoginStatus.isSuccess) {
      const { data } = authLoginStatus
      setToken(data?.data?.token)
      setSberIdNumber(data?.data?.number)
      setSberIdClientFullName(data?.data?.clientFullName)
    }
  }, [authLoginStatus, setToken, setSberIdNumber, setSberIdClientFullName])

  const onBackClick = () => {
    navigate(routes.login)
  }

  const onClickOrderSimCard = () => {
    if (links?.[ELinksCode.sberTariffs]) {
      window.open(links[ELinksCode.sberTariffs], BLANK)?.focus()
    }
  }
  const onClickNext = () => {
    dispatch(loginActions.setError(""))
    dispatch(loginActions.loginStart(sberId.number))
  }

  const onClickBindAnotherNumber = () => {
    dispatch(loginActions.setSberIdBindAnotherNumberSheet(true))
  }

  return (
    <RootStyled>
      <Header
        background={theme.colors.background[1]}
        onBackClick={onBackClick}
      />
      <TitleStyled>Вход по Сбер ID</TitleStyled>
      <WrapContent>
        <Table.Section>
          {sberId?.clientFullName && (
            <Table.CommonCell
              title={sberId.clientFullName}
              startIcon={<Icon.SberId />}
              key={uuidv4()}
            />
          )}
          <Table.CommonCell
            title={getPrimeTitle()}
            subTitle={`Подписка до ${moment(
              primeData?.data?.data?.primeExpirationDate
            ).format("DD.MM.YY")}`}
            startIcon={<Icon.SberPrime />}
            key={uuidv4()}
          />
          {sberId?.number ? (
            <Table.CommonCell
              title={formatPhoneNumber(sberId.number) as string}
              subTitle="Привязанный номер СберМобайл"
              startIcon={<Icon.Phone2 />}
              key={uuidv4()}
            />
          ) : (
            <Table.CommonCell
              title="Привязать номер СберМобайл"
              startIcon={<Icon.Phone2 />}
              endIcon={<Icon.ArrowRight />}
              key={uuidv4()}
              onClick={() => {
                navigate(routes.loginSberIdBind)
              }}
            />
          )}
          {/*TODO:: чтоб рисовался divider :)*/}
          <></>
        </Table.Section>
        <ButtonContainerStyled>
          {sberId?.number && (
            <Button.Primary onClick={onClickNext}>Продолжить</Button.Primary>
          )}
          {!sberId?.number && (
            <Button.Primary onClick={onClickOrderSimCard}>
              Заказать сим-карту
            </Button.Primary>
          )}
          {sberId?.number && (
            <Button.Plain onClick={onClickBindAnotherNumber}>
              Привязать другой номер
            </Button.Plain>
          )}
          {!sberId?.number && (
            <FooterLabelStyled>
              Со скидкой для подписчиков СберПрайм
            </FooterLabelStyled>
          )}
        </ButtonContainerStyled>
      </WrapContent>
      <BindAnotherNumberSheet
        isOpen={sberId?.showBindAnotherNumberSheet}
        phone={sberId?.number}
      />
    </RootStyled>
  )
}
