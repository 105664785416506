import { useState, memo } from "react"
import { useLocation } from "react-router-dom"
import { useTheme } from "styled-components"
import { Header } from "components"
import { IFrame, RootStyled } from "./styles"
import { ExternalLinkSkeleton } from "./Skeleton"

export const ShowExternalLink = memo(() => {
  const {
    state: { link },
  } = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const theme = useTheme()

  return (
    <RootStyled>
      <Header background={theme.colors.background[0]} />
      {isLoading && <ExternalLinkSkeleton />}
      <IFrame
        isLoading={isLoading}
        onLoad={() => setIsLoading(false)}
        src={link}
      />
    </RootStyled>
  )
})
