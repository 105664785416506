import React, { FC } from "react"
import ContentLoader from "react-content-loader"
import { useTheme } from "styled-components"
import { Table } from "components"
import { ServiceSkeletonContainer, SimIcomContainer } from "./styles"

const SPEED = 1.2
const INTERVAL = 0.25

const StartIcon = () => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={SPEED}
      height={36}
      interval={INTERVAL}
      backgroundColor={theme.colors.stroke.color}
      foregroundColor={theme.colors.background[1]}
      width="100%"
    >
      <rect rx="33" ry="33" width="33px" height="33px" />
      <rect x="44px" rx="8" ry="8" width="70%" height="12px" />
      <rect x="44px" y="21px" rx="8" ry="8" width="70%" height="12px" />
    </ContentLoader>
  )
}

const SimIcon = () => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={SPEED}
      height={96}
      interval={INTERVAL}
      backgroundColor={theme.colors.stroke.color}
      foregroundColor={theme.colors.background[1]}
      width="96px"
    >
      <rect rx="96" ry="96" width="96px" height="96px" />
    </ContentLoader>
  )
}

const SimContent = () => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={SPEED}
      height={100}
      interval={INTERVAL}
      backgroundColor={theme.colors.stroke.color}
      foregroundColor={theme.colors.background[1]}
      width="190px"
    >
      <rect x="17px" rx="8" ry="8" y="18px" width="156px" height="20px" />
      <rect rx="8" ry="8" y="56px" width="190px" height="18px" />
      <rect x="20px" y="84px" rx="8" ry="8" width="150px" height="16px" />
    </ContentLoader>
  )
}

const EndIcon = () => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={SPEED}
      height={16}
      interval={INTERVAL}
      backgroundColor={theme.colors.stroke.color}
      foregroundColor={theme.colors.background[1]}
      width="18px"
    >
      <rect rx="8" ry="8" width="16px" height="16px" />
    </ContentLoader>
  )
}

const ServiceItem = () => (
  <ServiceSkeletonContainer>
    <StartIcon />
    <EndIcon />
  </ServiceSkeletonContainer>
)

const Skeleton: FC = () => {
  return (
    <Table.Body padding={8}>
      <SimIcomContainer>
        <SimIcon />
        <SimContent />
      </SimIcomContainer>
      <Table.Section>
        <ServiceItem />
        <ServiceItem />
      </Table.Section>
    </Table.Body>
  )
}

export { Skeleton }
