import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IModalState {
  isOpen: boolean
  title: string
  subtitle?: string
  content?: string
  confirmLabel?: string
  isConfirmClick?: boolean
  isDeclineClick?: boolean
  declineLabel: string
  isPhoneNumber?: boolean
}

const initialState: IModalState = {
  isOpen: false,
  title: "",
  confirmLabel: undefined,
  isConfirmClick: false,
  isDeclineClick: false,
  declineLabel: "",
}

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, { payload }: PayloadAction<IModalState>) => {
      state.isOpen = payload.isOpen
      state.title = payload.title
      state.subtitle = payload?.subtitle
      state.content = payload?.content
      state.confirmLabel = payload?.confirmLabel
      state.declineLabel = payload.declineLabel
      state.isPhoneNumber = payload?.isPhoneNumber
    },
    closeModal: () => {
      return initialState
    },
    setConfirm: (state, { payload }: PayloadAction<boolean>) => {
      state.isConfirmClick = payload
    },
    setDecline: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeclineClick = payload
    },
  },
})

export const modalActions = modalSlice.actions

export default modalSlice.reducer
