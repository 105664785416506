import styled from "styled-components"
import { Typography } from "components"

export const RootStyled = styled.div`
  margin-top: 24px;
  display: initial;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const WrapRadialChartsStyled = styled.div<{ hasAllOptions: boolean }>`
  display: flex;
  margin: 20px auto 16px auto;
  justify-content: ${(props) =>
    props.hasAllOptions ? "space-between" : "center"};
  gap: ${(props) => (props.hasAllOptions ? "0" : "20px")};
  max-width: 394px;
  @media (min-width: 351px) {
    width: 100%;
  }
  @media (max-width: 350px) {
    ${(props) =>
      props.hasAllOptions &&
      `
      margin-left: -10px;
      margin-right: -10px; 
    `}
  }
  @media (max-width: 320px) {
    ${(props) =>
      props.hasAllOptions &&
      `
      transform: scale(0.85);
      gap: 5px;
      margin-left: -34px;
      margin-right: -34px; 
    `}
  }
`

export const WrapHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TariffInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FooterItemStyled = styled.div<{ disabled?: boolean }>`
  width: 25%;
  display: block;
  text-align: center;
  padding: 8px 4px 2px;
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
`

export const IconWrapper = styled.div`
  position: relative;
  width: fit-content;
  display: inline-block;
`

export const SubIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`

export const FooterStyled = styled.div`
  display: flex;
  padding-top: 8px;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
`

export const CaptionStyled = styled(Typography.Caption)`
  display: flex;
  padding-top: 4px;
  justify-content: center;
`

export const MyTariffHeaderColored = styled.span<{ color?: string }>`
  color: ${(props) => props.color ?? props.theme.colors.text.warning};
`
