import moment from "moment"

export const getSlotFromHour = (hour: number) =>
  `${moment(`${hour}`, "h").format("HH:mm")} - ${moment(`${hour}`, "h")
    .add(1, "h")
    .format("HH:mm")}`

export const prepareMonthValue = (month: number) =>
  // индекс 9 будет соответствовать уже двузначному месяцу, октябрю (10), поэтому условие "< 9"
  // +1 так как мы индекс приводим к календарному обозначению месяца, а базово у нас индекс месяца (для января - 0) на единицу меньше месяца по календарю
  month < 9 ? `0${month + 1}` : month + 1

export const getDate = {
  dateAndMonth: (dateUTCString: string) => {
    const date = new Date(dateUTCString)
    return date.toLocaleString("ru", { day: "numeric", month: "long" })
  },
  time: (dateUTCString: string) => {
    const date: Date = new Date(dateUTCString)
    return date.toLocaleString("ru", {
      hour: "numeric",
      minute: "numeric",
    })
  },
}
