import styled from "styled-components"
import { nightTheme } from "theme/nightTheme"
import { Typography, Icon } from "components"

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Info = styled(Typography.Body2)<{
  hasError?: boolean
}>`
  padding: 0 16px;
  color: ${(props) =>
    props.hasError
      ? props.theme.colors.text.warning
      : props.theme.colors.text.secondary};
`

const Field = styled.div<{
  hasError?: boolean
}>`
  min-height: 56px;
  padding: 8px 16px;
  background: ${(props) => props.theme.colors.background[1]};
  border: ${(props) =>
    `1px solid ${
      props.hasError
        ? props.theme.colors.text.warning
        : props.theme.colors.transparent.black[10]
    }`};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

const EmptyField = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  flex-grow: 1;
`

const Files = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

const Image = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 12px;
  cursor: pointer;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 90px;
  height: 90px;
`

const CloseButton = styled(Icon.DeleteCircle)`
  position: absolute;
  top: -4px;
  right: -4px;
  cursor: pointer;
`

const SizeBadge = styled(Typography.Caption)`
  display: block;
  position: absolute;
  left: 4px;
  bottom: 5px;
  background: ${(props) => props.theme.colors.background[1]};
  border-radius: 8px;
  padding: 2px 6px;
  color: ${(props) => props.theme.colors.text.warning};
`

const ButtonWrapper = styled.div<{ border: string }>`
  width: 90px;
  height: 90px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: ${(props) =>
    `1px ${props.border} ${props.theme.colors.transparent.black[10]}`};
`

const Placeholder = styled(Typography.Body1)`
  flex-grow: 1;
  color: ${(props) => props.theme.colors.text.secondary};
`

const TopPlaceholder = styled(Typography.Caption)`
  color: ${(props) => props.theme.colors.text.secondary};
`

const Hidden = styled.div`
  display: none;
`

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${nightTheme.colors.background[0]};
  max-width: 480px;
`

const ModalHeader = styled.div`
  p {
    color: ${nightTheme.colors.text.primary};
  }
`

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`

const ModalFrame = styled.iframe`
  max-width: 480px;
  width: 100%;
  height: calc(100% - 64px);
  align-self: flex-end;
`

const UploadFileWrapper = styled.div`
  width: 24px;
  height: 24px;
`

export const Styled = {
  Root,
  Field,
  Placeholder,
  Info,
  Hidden,
  TopPlaceholder,
  Files,
  Image,
  ButtonWrapper,
  EmptyField,
  ImageWrapper,
  CloseButton,
  Modal,
  ModalImage,
  ModalHeader,
  ModalFrame,
  SizeBadge,
  ModalWrapper,
  UploadFileWrapper,
}
