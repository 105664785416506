import styled from "styled-components"
import { Table } from "components"

export const TableBody = styled(Table.Body)`
  position: relative;
  gap: 24px;
  height: 100vh;
  align-items: center;
  background: ${(props) => props.theme.colors.background[1]};
  overflow: hidden;
`

export const HeaderWrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  div {
    width: fit-content;
    padding-right: 0;
  }
`
