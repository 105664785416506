import styled from "styled-components"
import { Icon } from "components"

const GuardianVolume = styled.div`
  width: 150px;
  height: 100%;
`

const GuardianIcon = styled(Icon.Guardian)`
  position: absolute;
  right: -22px;
  bottom: -44px;
  width: 150px;
  height: 150px;
`

export const Styled = {
  GuardianVolume,
  GuardianIcon,
}
