import { FC, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { Body1Bold } from "components/Typography/Body"
import { Table } from "components"
import { TabBarWrapper, TabBarItem } from "./style"
import { ITabs } from "./interfaces"

export const Tabs: FC<ITabs> = ({ children, currentTab, onChangeTab }) => {
  const [activeTab, setActiveTab] = useState(
    currentTab || children[0].props.title
  )

  const [activeContent] = children.filter(
    (tab) => activeTab === tab.props.title
  )

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab)
    onChangeTab?.(tab)
  }

  return (
    <>
      <Table.Body padding={16}>
        <TabBarWrapper>
          {children.map((tab) => (
            <TabBarItem
              key={uuidv4()}
              isActive={activeTab === tab.props.title}
              onClick={() => handleChangeTab(tab.props.title)}
            >
              <Body1Bold>{tab.props.title}</Body1Bold>
            </TabBarItem>
          ))}
        </TabBarWrapper>
      </Table.Body>
      {activeContent.props.component}
    </>
  )
}
