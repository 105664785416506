import styled from "styled-components"
import RMSheet from "react-modal-sheet"
import { Icon } from "components"

export const CloseIconPrimary = styled(Icon.Cross)`
  position: absolute;
  top: 24px;
  right: 16px;
  cursor: pointer;
  z-index: 10;
  padding: 4px;
  height: 24px;
  width: 24px;
  background-color: ${(props) => props.theme.colors.background[1]};
`

export const CloseIconSecondary = styled(Icon.Cross)`
  position: absolute;
  top: 24px;
  right: 16px;
  cursor: pointer;
  z-index: 10;
  padding: 4px;
  height: 24px;
  width: 24px;
`

export const CommonHeader = styled.div.attrs({
  className: "CommonHeader",
})`
  padding: 0 40px 0 8px;
  align-self: flex-start;
  width: 100%;
`

export const CommonContainer = styled.div<{
  paddingBottom?: number
  horizontalPadding?: number
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(props) =>
    `24px ${props?.horizontalPadding ?? 16}px ${props?.paddingBottom ?? 32}px`};
  align-items: center;
  background-color: ${(props) => props.theme.colors.background[1]};
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  .CommonHeader {
    ${(props) =>
      props.horizontalPadding !== undefined
        ? `padding-left: ${24 - props.horizontalPadding}px;`
        : null}
  }
`

const Backdrop = styled(RMSheet.Backdrop)`
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
`

const Sheet = styled(RMSheet)`
  max-width: 480px;
  margin: auto;
`

const Container = styled(RMSheet.Container)`
  background-color: ${(props) => props.theme.colors.background[1]} !important;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
`

const Content = styled(RMSheet.Content)`
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const Styled = {
  CloseIconPrimary,
  CloseIconSecondary,
  Backdrop,
  Sheet,
  Container,
  Content,
  CommonContainer,
  CommonHeader,
}
