import styled from "styled-components"
import { Plain } from "components/Button/Plain"
import { colors } from "theme/dayTheme/colors"
import { Icon, Table } from "components"

export const SigningStyled = {
  Root: styled(Table.Body)`
    height: 100%;
    background: ${(props) => props.theme.colors.background[1]};
    padding: 16px 16px 32px 16px;
  `,
  Container: styled.div`
    padding: 16px 8px 0 8px;
  `,
  SignatureIcon: styled(Icon.Signature)`
    color: ${colors.stroke.dark};
  `,
  ClearButton: styled(Plain)`
    position: absolute;
    width: initial;
    height: initial;
    padding: initial;
    color: ${colors.text.primary};
    right: 15px;
    top: 15px;
    z-index: 2;
  `,
  SignatureCanvasWrap: styled.div`
    position: relative;
    border: 1px ${colors.transparent.black[10]} solid;
    background-color: ${colors.background[1]};
    border-radius: 16px;
    canvas {
      border-radius: 16px;
    }
  `,
  Signature: styled.div`
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
}
