import React, { memo, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  ESendConfirmationType,
  EStatusCode,
  IResponseErrorData,
} from "entities/common"
import { useTheme } from "styled-components"
import { useActions } from "hooks/actions"
import { usePostCheckConfirmationMutation } from "store/api"
import { loginSelector, useSelector } from "hooks/useSelector"
import { NoWrap } from "utils"
import { sendMetric } from "utils/metrics"
import {
  Container,
  Header,
  ServiceFailModal,
  SmsConfirmation,
  Typography,
} from "components"
import { routes } from "constants/routes"

export const EmailConfirmation = memo(() => {
  const [isOpen, setIsOpen] = useState(false)
  const [trigger, { isSuccess, isLoading, error, reset }] =
    usePostCheckConfirmationMutation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { setSmsCode, setError, openModal } = useActions()
  const { selectedPhone, phone } = useSelector(loginSelector)
  const onBackClick = () =>
    navigate(
      `${routes.personalInfo}${
        phone !== selectedPhone ? `?additionalNumber=${phone}` : ""
      }`
    )

  const isMainPhone = phone === selectedPhone

  const onSuccess = (code: string) => {
    setSmsCode(code)
    trigger({
      code,
      type: ESendConfirmationType.UpdateEmailProfile,
      additionalNumber: isMainPhone ? undefined : selectedPhone,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      sendMetric({ "Корректировка email": { "Ввод СМС-кода": ["Успех"] } })
      navigate(routes.emailAdjustment)
    }

    if (error && "data" in error) {
      const errorData = error.data as IResponseErrorData
      const sendErrorCodeMetric = (errorMessage: string) =>
        sendMetric({
          "Корректировка email": {
            "Ввод СМС-кода": { Ошибка: [errorMessage] },
          },
        })

      if (typeof errorData === "string") {
        sendErrorCodeMetric(errorData)
        return setIsOpen(true)
      }

      sendErrorCodeMetric(errorData?.statusInfo.statusMessage)
      switch (errorData?.statusInfo.statusCode) {
        case EStatusCode.BadCredentials:
          setError("Неверный код")
          reset()
          break
        case EStatusCode.PasswordExpired:
          openModal({
            isOpen: true,
            title: "Информация",
            subtitle: errorData?.statusInfo.statusMessage,
            declineLabel: "Понятно",
          })
          return navigate(-1)
        default:
          return setIsOpen(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isSuccess, navigate, reset])

  return (
    <>
      <ServiceFailModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Container page>
        <Header
          onBackClick={onBackClick}
          title="Подтверждение"
          background={theme.colors.background[1]}
        />
        <SmsConfirmation
          number={selectedPhone}
          onSuccess={onSuccess}
          type={ESendConfirmationType.UpdateEmailProfile}
          isLoading={isLoading}
        >
          <Typography.Body2>
            Вводя код из СМС, вы соглашаетесь{" "}
            <NoWrap>на изменение данных</NoWrap>
          </Typography.Body2>
        </SmsConfirmation>
      </Container>
    </>
  )
})
