import axios from "axios"
import { getUserInfoHeader } from "utils/system"
import { store } from "../store"

export const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOP_BASE_URL
      : process.env.REACT_APP_PRODUCTION_BASE_URL,
})

axiosInstance.interceptors.request.use(function (config) {
  const token = store.getState().login.token
  config.headers.token = token
  // TODO:: расхардкодить
  config.headers["X-User-info"] = getUserInfoHeader()

  return config
})
