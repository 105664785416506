import { createGlobalStyle } from "styled-components"
import SBSansUIRegularTtf from "./fonts/SBSansTextInterface/SBSansTextInterfaceRegular/SBSansUI-Regular.ttf"
import SBSansUIRegularWoff from "./fonts/SBSansTextInterface/SBSansTextInterfaceRegular/SBSansUI-Regular.woff"
import SBSansUIRegularWoff2 from "./fonts/SBSansTextInterface/SBSansTextInterfaceRegular/SBSansUI-Regular.woff2"
import SBSansUISemiBoldTtf from "./fonts/SBSansTextInterface/SBSansTextInterfaceSemiBold/SBSansUI-Semibold.ttf"
import SBSansUISemiBoldWoff from "./fonts/SBSansTextInterface/SBSansTextInterfaceSemiBold/SBSansUI-Semibold.woff"
import SBSansUISemiBoldWoff2 from "./fonts/SBSansTextInterface/SBSansTextInterfaceSemiBold/SBSansUI-Semibold.woff2"
import SBSansUIBoldTtf from "./fonts/SBSansTextInterface/SBSansUIBold/SBSansUI-Bold.ttf"
import SBSansUIBoldWoff from "./fonts/SBSansTextInterface/SBSansUIBold/SBSansUI-Bold.woff"
import SBSansUIBoldWoff2 from "./fonts/SBSansTextInterface/SBSansUIBold/SBSansUI-Bold.woff2"

export default createGlobalStyle`
    @font-face {
        font-family: 'SBSansUI Regular';
        src: url(${SBSansUIRegularTtf}) format('truetype'),
        url(${SBSansUIRegularWoff2}) format('woff2'),
        url(${SBSansUIRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
      font-family: 'SBSansUI Bold';
      src: url(${SBSansUISemiBoldTtf}) format('truetype'),
      url(${SBSansUISemiBoldWoff2}) format('woff2'),
      url(${SBSansUISemiBoldWoff}) format('woff');
      font-weight: 700;
      font-style: normal;
    }
    @font-face {
      font-family: 'SBSansUI Bold';
      src: url(${SBSansUIBoldTtf}) format('truetype'),
      url(${SBSansUIBoldWoff2}) format('woff2'),
      url(${SBSansUIBoldWoff}) format('woff');
      font-weight: 700;
      font-style: normal;
    }
`
