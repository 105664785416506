import { IDefaultVBanners } from "components/BannerGroup/interfaces"
import { Icon } from "components/Icons"
import { EBannerType } from "entities/banners"
import { EFeatureConfigs } from "pages/Menu/interfaces"

export const defaultBanner: IDefaultVBanners[] = [
  {
    title: "Умный автоответчик",
    subtitle: (
      <>
        Робот ответит на звонки
        <br />и запишет разговор
      </>
    ),
    confirmationText: "Подключить",
    gradient: "linear-gradient(52deg, #E93563 20.69%, #FAF752 89.94%);",
    type: EBannerType.YA,
    image: <Icon.YAPhone />,
    featureFlag: EFeatureConfigs.isYABannerEnabled,
  },
  {
    title: "Защита от мошенничества",
    subtitle: (
      <>
        Распознаем подозрительные
        <br />
        фразы и сразу предупредим
      </>
    ),
    confirmationText: "Узнать больше",
    gradient:
      "linear-gradient(272deg, #FFF -32.27%, #69FCEA 17.91%, #03A8DC 102.13%);",
    type: EBannerType.Aura,
    image: <Icon.AuraBanner />,
    featureFlag: EFeatureConfigs.isAuraBannerEnabled,
  },
  {
    title: "Хорошей поездки!",
    subtitle: (
      <>
        Будьте на связи
        <br />в любой точке мира!
      </>
    ),
    confirmationText: "Услуги в роуминге",
    gradient:
      "linear-gradient(272deg, #8FFFF8 -23.54%, #43D6EA 48.22%, #369FFF 94.55%);",
    type: EBannerType.Roaming,
    image: <Icon.RoamingBanner />,
    featureFlag: EFeatureConfigs.isRoamingBannerEnabled,
  },
  // баннеры внизу пока не добавляем
  // {
  //   title: "Тариф «Безлимит»",
  //   subtitle: (
  //     <>
  //       Скидка 20% на 6 месяцев
  //       <br />
  //       по промокоду UNLIM20
  //     </>
  //   ),
  //   confirmationText: "Перейти на тариф",
  //   gradient:
  //     "linear-gradient(280deg, #48ECBD 5.93%, #2AD1CB 34.97%, #00A9DF 73.89%, #1EACD9 109.94%);",
  //   type: EBannerType.Bezlimit20,
  //   image: <Icon.InfinityBanner />,
  //   featureFlag: EFeatureConfigs.isUnlimetedBannerEnabled,
  // },
  // {
  //   title: "Вам доступна eSIM",
  //   subtitle: (
  //     <>
  //       Всё та же сим-карта,
  //       <br />
  //       только цифровая
  //     </>
  //   ),
  //   confirmationText: "Подробнее",
  //   gradient:
  //     "linear-gradient(180deg, #F90 0%, #FF7426 54.16%, #FF3566 119.53%);",
  //   type: EBannerType.EsimEnabled,
  //   image: <Icon.EsimHand />,
  // },
]
