import styled from "styled-components"
import { Typography } from "components"

const Container = styled.div`
  padding: 16px;
`

const Title = styled(Typography.Body1Bold)`
  margin-bottom: 40px;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
`

const IconContainer = styled.div`
  width: 72px;
  height: 72px;
  margin: 0 auto 40px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`

const SubTitle = styled(Typography.Body1)`
  margin-bottom: 40px;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
`

const Text = styled(Typography.Body1)`
  margin-bottom: 40px;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.text.secondary};
  text-align: center;
`

export const Styled = {
  Container,
  Title,
  IconContainer,
  SubTitle,
  Text,
}
