import styled from "styled-components"
import { Icon, Typography } from "components"

const Container = styled.div`
  padding: 55px 16px 16px;
`
const IconContainer = styled.div`
  width: 72px;
  height: 72px;
  margin: 0 auto 24px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`
const CallType = styled(Typography.Body1)<{
  color: string
}>`
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: ${(props) => props.color};
`

const Phone = styled(Typography.Body1Bold)`
  margin-bottom: 8px;
  font-size: 28px;
  line-height: 34px;
  color: ${(props) => props.color};
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Date = styled(Typography.Body1)`
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.text.secondary};
  text-align: center;
`

const ButtonsContainer = styled.div`
  width: 100;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 32px;

  & > * {
    width: 49%;
  }
`

const ButtonCall = styled.a`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 78px;
  padding: 8px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.background[0]};
  text-decoration: none;
`
const ButtonIcon = styled.div`
  width: 36px;
  height: 36px;
  margin-bottom: 4px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`
const ButtonText = styled(Typography.Body1)`
  font-size: 13px;
  line-height: 28px;
`

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 28px;
  margin-bottom: 32px;
`

const ButtonPlayer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  background-color: transparent;
  gap: 8px;
  font-family: "SBSansUI Regular", serif;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.text.warning};
  text-align: center;
  cursor: pointer;

  svg {
    color: ${(props) => props.theme.colors.text.warning};
  }
`

const IconPlay = styled(Icon.Play)`
  width: 18px;
`

const IconPause = styled(Icon.Pause)`
  width: 18px;
`

const Transcription = styled(Typography.Body1)`
  padding-left: 35px;
  margin-bottom: 8px;
  font-size: 17px;
  text-indent: -35px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const Styled = {
  Container,
  IconContainer,
  CallType,
  Phone,
  Date,
  ButtonsContainer,
  ButtonCall,
  ButtonIcon,
  ButtonText,
  PlayerContainer,
  ButtonPlayer,
  IconPlay,
  IconPause,
  Transcription,
}
