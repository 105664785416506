import { useState } from "react"
import { Page } from "react-pdf"
import { pdfjs } from "react-pdf"
import { useTheme } from "styled-components"
import { Header } from "components"
import guardianPDF from "./guardian.pdf"
import { Styled } from "./styles"
import { PdfSkeleton } from "./PdfSkeleton"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/legacy/build/pdf.worker.min.js",
  import.meta.url
).toString()
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export const GuardianAgreement = () => {
  const theme = useTheme()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(100)

  const onPdfLoad = () => {
    setHeight(0)
    setWidth(
      document.getElementsByTagName("canvas")[0]?.offsetParent?.clientWidth ?? 0
    )
  }

  return (
    <Styled.RootStyled padding={16}>
      <Styled.HeaderWrap>
        <Header
          title="Услуга Хранитель"
          background={theme.colors.background.surface1}
        />
      </Styled.HeaderWrap>
      <Styled.DocumentWrap>
        <Styled.DocumentStyled
          height={height}
          file={guardianPDF}
          loading={<PdfSkeleton />}
        >
          {Array(1)
            .fill(0)
            .map((_, i) => (
              <Page
                key={i}
                pageNumber={i + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                onLoadSuccess={onPdfLoad}
                width={width}
              />
            ))}
        </Styled.DocumentStyled>
      </Styled.DocumentWrap>
    </Styled.RootStyled>
  )
}
