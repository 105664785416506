import React, { FC, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { TExtendedData, IContentOption } from "entities/tariff"
import { v4 as uuidv4 } from "uuid"
import { sendMetric } from "utils/metrics"
import {
  EStatusType,
  IOptionElement,
  IRoaming,
  ISpecial,
} from "entities/connectedAvailable"
import { IServiceStatus } from "components/ServiceStatus/interfaces"
import { useGetUserConfigQuery } from "store/api"
import { prepareCurrentFeatureConfig } from "utils/userConfig"
import { RootState } from "store"
import { EContentType } from "entities/common"
import { Icon, RadialChart, Typography } from "components"
import { routes } from "constants/routes"
import {
  CaptionStyled,
  FooterItemStyled,
  FooterStyled,
  RootStyled,
  WrapHeaderStyled,
  WrapRadialChartsStyled,
  TariffInfoWrapper,
  IconWrapper,
  SubIcon,
} from "./styles"
import {
  getContentOptionIcon,
  getTariffHeader,
  getTariffSubHeader,
  mapPackagesData,
} from "./utils"

export const MyTariffWidget: FC<{
  setServiceStatusContent: (serviceContent: IServiceStatus) => void
  tariffData?: TExtendedData
  showTariffData?: boolean
}> = ({ tariffData, showTariffData = true, setServiceStatusContent }) => {
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data: userConfigData } = useGetUserConfigQuery()

  const currentFeatureConfig = useMemo(
    () =>
      prepareCurrentFeatureConfig(
        currentPhone,
        userConfigData?.data?.userConfigs
      ),
    [currentPhone, userConfigData?.data?.userConfigs]
  )

  useEffect(() => {
    if (!showTariffData) {
      sendMetric({
        ГлавныйЭкран: {
          СтатусТарифа: ["ДанныеНеДоступны"],
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const serviceData = tariffData?.currentTariff.contentOptions
  const tariffOptions = useMemo(
    () =>
      mapPackagesData(
        tariffData?.connectedOptions.defaultOptions,
        tariffData?.currentTariff,
        tariffData?.balance.needPay
      ),
    [tariffData]
  )
  const navigate = useNavigate()
  const handleServiceClick = (
    serviceItem: ISpecial | IContentOption | IRoaming | IOptionElement
  ) => {
    if (serviceItem.active) {
      if (serviceItem.availability === "FREE") {
        sendMetric({
          "Главный экран": {
            "Шторка безлимитов": { ВключеноВТариф: [serviceItem.contentType] },
          },
        })
      } else {
        sendMetric({
          "Главный экран": {
            "Шторка безлимитов": {
              Активна: { Показ: [serviceItem.contentType] },
            },
          },
        })
      }
    } else {
      sendMetric({
        "Главный экран": {
          "Шторка безлимитов": {
            Неактивна: { Показ: [serviceItem.contentType] },
          },
        },
      })
    }

    setServiceStatusContent({
      data: serviceItem,
      isAvailableOptions: !serviceItem?.active,
      isContentOption: true,
    })
  }
  const onRedirectToMyTariff = () => {
    navigate(routes.myTariff)
  }

  const prepareTariffHeader = useMemo(
    () => (showTariffData ? getTariffHeader(tariffData) : "_"),
    [tariffData, showTariffData]
  )

  const prepareTariffSubHeader = useMemo(
    () => (showTariffData ? getTariffSubHeader(tariffData) : "_"),
    [tariffData, showTariffData]
  )

  const onRadialChartClick = (type?: EContentType) => {
    switch (type) {
      case EContentType.Sms:
        navigate(routes.servicesSms)
        break
      case EContentType.Internet:
        navigate(routes.servicesInternet)
        break
      case EContentType.Calls:
        navigate(routes.servicesCall)
        break
      default:
      // no default
    }
  }

  return (
    <RootStyled>
      <WrapHeaderStyled
        onClick={
          showTariffData && currentFeatureConfig?.isTariffPickerEnabled
            ? onRedirectToMyTariff
            : undefined
        }
      >
        <TariffInfoWrapper>
          {currentFeatureConfig?.isTariffPickerEnabled && (
            <Typography.Body1Bold>{prepareTariffHeader}</Typography.Body1Bold>
          )}
          {currentFeatureConfig?.isTariffPaymentInfoEnabled && (
            <Typography.Body2>{prepareTariffSubHeader}</Typography.Body2>
          )}
        </TariffInfoWrapper>
        {showTariffData && currentFeatureConfig?.isTariffPickerEnabled && (
          <Icon.ArrowRight />
        )}
      </WrapHeaderStyled>
      {tariffOptions.length > 0 && (
        <WrapRadialChartsStyled hasAllOptions={tariffOptions.length === 3}>
          {tariffOptions.map((option) => (
            <RadialChart
              key={uuidv4()}
              color={option.color}
              percent={showTariffData ? option.percent : 0}
              footer={option.footer}
              body={showTariffData ? option.body : "-"}
              icon={option.icon}
              carryOverResidues={
                showTariffData ? option.carryOverResidues : undefined
              }
              contentType={option.contentType}
              onClick={onRadialChartClick}
            />
          ))}
        </WrapRadialChartsStyled>
      )}
      <FooterStyled>
        {currentFeatureConfig?.isContentOptionsEnabled &&
          serviceData &&
          serviceData.map((serviceItem) => (
            <FooterItemStyled
              key={serviceItem.optionId}
              onClick={() => handleServiceClick(serviceItem)}
              disabled={!serviceItem?.active}
            >
              <IconWrapper>
                {getContentOptionIcon(serviceItem.contentType)}
                {serviceItem.statusType === EStatusType.Blocked && (
                  <SubIcon>
                    <Icon.Blocked viewBox="0 0 18 18" width="12" height="12" />
                  </SubIcon>
                )}
              </IconWrapper>
              <CaptionStyled>{serviceItem.title}</CaptionStyled>
            </FooterItemStyled>
          ))}
      </FooterStyled>
    </RootStyled>
  )
}
