import styled from "styled-components"

export const ListStyled = styled.ul`
  padding-left: 24px;
`
export const MarkerStyled = styled.li`
  ::marker {
    color: ${(props) => props.theme.colors.text.primary};
  }
`
