import React, { FC, memo, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getSearchObject } from "utils/search"
import { RootState } from "store"
import { usePostGoskeyAuthQuery } from "store/api"
import { EStatusCode, IResponseErrorData } from "entities/common"
import { SignatureCheckUserData } from "pages/SignatureCheckUserData"
import { SignatureNotSamePerson } from "pages/SignatureNotSamePerson"
import { SignatureNotVerifiedData } from "pages/SignatureNotVerifiedData"
import { SignatureUserNotIndividual } from "pages/SignatureUserNotIndividual"

export const SignatureCheck: FC = memo(() => {
  const [errorStatusCode, serErrorStatusCode] = useState("")
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { code, state } = getSearchObject()
  const { isLoading, data, error } = usePostGoskeyAuthQuery({
    additionalNumber: currentPhone,
    code,
    state,
  })

  useEffect(() => {
    if (error && "data" in error) {
      const errorData = error.data as IResponseErrorData
      const statusCode = errorData.statusInfo.statusCode
      serErrorStatusCode(statusCode)
    }
  }, [error])

  if (isLoading) {
    return <></>
  }

  switch (errorStatusCode) {
    case EStatusCode.NotSamePerson:
      return <SignatureNotSamePerson />
    case EStatusCode.NotVerifiedData:
      return <SignatureNotVerifiedData />
    case EStatusCode.UserIsNotIndividual:
      return <SignatureUserNotIndividual />
    default:
      return <SignatureCheckUserData data={data} />
  }
})
