// Данный слайс не нужно синхроинизировать с localStorage

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EServicesTabs } from "entities/common"
import { ICountry } from "entities/roaming"

interface IState {
  showedMessageAboutInsufficientBalance: boolean // Показывать или нет сообщение о скором списании
  currentServicesTab: EServicesTabs
  numberTransfer: string
  roamingCountry?: ICountry
  optionStatusTitle?: string | null
}

const initialState: IState = {
  showedMessageAboutInsufficientBalance: false,
  currentServicesTab: EServicesTabs.Available,
  numberTransfer: "",
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setShowedMessageAboutInsufficientBalance: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showedMessageAboutInsufficientBalance = payload
    },
    setCurrentServicesTab: (
      state,
      { payload }: PayloadAction<EServicesTabs>
    ) => {
      state.currentServicesTab = payload
    },
    setNumberTransfer: (state, { payload }: PayloadAction<string>) => {
      state.numberTransfer = payload
    },
    setRoamingCountry: (state, { payload }: PayloadAction<ICountry>) => {
      state.roamingCountry = payload
    },
    setOptionStatusTitle: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.optionStatusTitle = payload
    },
  },
})

export const commonActions = commonSlice.actions

export default commonSlice.reducer
