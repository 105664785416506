import styled from "styled-components"
import { Document } from "react-pdf"
import { Body } from "components/Table/Body"
import { Typography } from "components"

export const RootStyled = styled(Body)`
  height: 100vh;
  padding-bottom: 32px;
  padding-top: 32px;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.background.surface1};
`

export const Title = styled(Typography.Body1)`
  text-align: center;
`

export const DocumentWrap = styled.div`
  min-height: 0;
  height: 100%;
  padding-bottom: 16px;
  div {
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    div {
      min-width: initial !important;
    }
  }
`

export const DocumentStyled = styled(Document)<{ height: number }>`
  height: 100%;
  ${(props) =>
    props.height &&
    `div {
    height: ${props.height}%;
  }`}
`
