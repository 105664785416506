import React, { FC, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { useGetAdditionalNumberQuery, useGetUserConfigQuery } from "store/api"
import { normalizeUserConfig } from "entities/userConfig"
import { prepareSelectorNumbers } from "utils"
import { loginSelector, useSelector } from "hooks/useSelector"
import { sendMetric } from "utils/metrics"
import { useScrollToTop } from "hooks/useScrollToTop"
import { getPhonesWithAdditionalData } from "pages/Home/utils"
import {
  Header,
  Container,
  Icon,
  IPhoneNumber,
  RenderChatPhones,
} from "components"
import { routes } from "constants/routes"
import { Styled } from "./styles"
import { ADDITIONAL, MAIN } from "./constants"

const SupportSetPhone: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { phone: mainPhone } = useSelector(loginSelector)
  const { data: numbersData } = useGetAdditionalNumberQuery()
  const { data: userConfigData } = useGetUserConfigQuery()
  useScrollToTop()
  const userConfigNormalized = useMemo(() => {
    return normalizeUserConfig(userConfigData)
  }, [userConfigData])

  const phones = useMemo(
    () => getPhonesWithAdditionalData(numbersData?.data),
    [numbersData]
  )

  const selectorNumbers = prepareSelectorNumbers(phones)

  const availablePhones = Array.isArray(selectorNumbers)
    ? (selectorNumbers as IPhoneNumber[])
    : selectorNumbers?.availablePhones
  const unavailablePhones = !Array.isArray(selectorNumbers)
    ? selectorNumbers?.unavailablePhones
    : []

  const handlePhoneSelect = (phone: string) => {
    const isMain = mainPhone === phone
    const metricDescription = isMain ? MAIN : ADDITIONAL
    sendMetric({
      Поддержка: { Номер_Клик: [`${metricDescription}`] },
    })
    navigate(routes.supportChat, {
      state: {
        phone,
        isMain,
        backToMainScreen: availablePhones?.length === 1,
      },
    })
  }

  return (
    <Container page backgroundColor={theme.colors.background[0]}>
      <Header
        title="Поддержка"
        background={theme.colors.background[0]}
        back={false}
      />
      <Styled.Title>Выберите номер для общения с оператором</Styled.Title>
      <RenderChatPhones
        availablePhones={availablePhones}
        unavailablePhones={unavailablePhones}
        handlePhoneSelect={handlePhoneSelect}
        unavailableTitle="Чат недоступен"
        unavailableSubtitle="Чат недоступен для номеров юридических лиц,
                    других операторов и разных регионов"
        userConfigNormalized={userConfigNormalized}
        current=""
        backgroundColor={theme.colors.background[0]}
        endIcon={<Icon.ArrowRight />}
      />
    </Container>
  )
}

export { SupportSetPhone }
