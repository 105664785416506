import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EThemeNames } from "theme/interfaces"

interface IState {
  currentTheme: EThemeNames
}

const initialState: IState = {
  currentTheme: EThemeNames.SystemTheme,
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setTheme: (state, { payload }: PayloadAction<EThemeNames>) => {
      state.currentTheme = payload
    },
  },
})

export const settingsActions = settingsSlice.actions

export default settingsSlice.reducer
