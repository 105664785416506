import styled from "styled-components"
import { Plain } from "components/Button/Plain"
import { Icon, Typography } from "components"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.background[0]};
  flex-direction: column;
`

export const ESimActiveIconStyled = styled(Icon.ESimActive)`
  margin-top: 36px;
  margin-bottom: 56px;
  display: flex;
  align-self: center;
`

export const SimActiveIconStyled = styled(Icon.SimActive)`
  margin-top: 36px;
  margin-bottom: 56px;
  display: flex;
  align-self: center;
`

export const H4Styled = styled(Typography.H4)`
  text-align: center;
  margin-bottom: 8px;
`

export const Body1Styled = styled(Typography.Body1)`
  text-align: center;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const DeleteButtonStyled = styled(Plain)`
  position: absolute;
  bottom: 32px;
  max-width: 480px;
`

export const ServiceSkeletonContainer = styled.div`
  display: flex;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
`
export const SimIcomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  margin-top: 36px;
  margin-bottom: 16px;
`

export const InputStyled = styled.input<{ value: string }>`
  appearance: none;
  outline: none;
  height: 56px;
  box-sizing: border-box;
  background: unset;
  border: unset;
  text-align: ${(props) => (!props.value ? "left" : "center")};
  width: ${(props) => (!props.value ? "155px" : "100%")};
  padding: 0 8px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  caret-color: ${(props) => props.theme.colors.text.warning};
  color: ${(props) => props.theme.colors.text.secondary};
  ::placeholder {
    font-family: SB Sans Interface, sans-serif;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.text.disabled};
    opacity: 0.8;
  }
`
