import React, { FC, memo } from "react"
import { v4 as uuidv4 } from "uuid"
import { useFormContext, Controller } from "react-hook-form"
import { withCustomValidation } from "hocs/withCustomValidation"
import { withQuery } from "hocs/withQuery"
import { Spacer, Typography } from "components"
import { FieldExtensionType, IFormModule } from "./interfaces"
import { Styled } from "./styles"
import { Field } from "./Field"

const Module: FC<IFormModule> = memo(({ title, subtitle, fields }) => {
  const { control } = useFormContext()
  const FieldWithCustomValidation = withCustomValidation(Field)
  const FieldWithQuery = withQuery(Field)
  return (
    <div>
      {title && (
        <>
          <Spacer height={24} />
          <Typography.Body1Bold>{title}</Typography.Body1Bold>
          {!subtitle && <Spacer height={9} />}
        </>
      )}
      {subtitle && (
        <>
          <Typography.Body2>{subtitle}</Typography.Body2>
          <Spacer height={16} />
        </>
      )}
      <Styled.Fields>
        {fields.map((field) => (
          <Controller
            key={uuidv4()}
            name={field.name}
            control={control}
            rules={field.validators}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, error },
            }) => {
              switch (field.extensionType) {
                case FieldExtensionType.WithCustomValidation:
                  return (
                    <FieldWithCustomValidation
                      {...field}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || field.initialValue}
                      name={name}
                      fieldRef={ref}
                      hasError={invalid}
                      description={error?.message || field.info}
                      options={field?.options ?? []}
                      customValidator={field.customValidator}
                      type={field.type}
                    />
                  )
                case FieldExtensionType.WithQuery:
                  return (
                    <FieldWithQuery
                      {...field}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || field.initialValue}
                      name={name}
                      fieldRef={ref}
                      hasError={invalid}
                      description={error?.message || field.info}
                      options={field?.options ?? []}
                      query={field.query}
                      type={field.type}
                    />
                  )
                default:
                  return (
                    <Field
                      {...field}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || field.initialValue}
                      name={name}
                      fieldRef={ref}
                      hasError={invalid}
                      description={error?.message || field.info}
                      options={field?.options ?? []}
                      type={field.type}
                    />
                  )
              }
            }}
          />
        ))}
      </Styled.Fields>
    </div>
  )
})

export { Module }
