import styled from "styled-components"
import { Typography } from "../Typography"

const Root = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
`

const Input = styled.input<{
  placeholderTop: boolean
  hasError?: boolean
  hasEndIcon: boolean
  hasStartIcon?: boolean
}>`
  font-family: SB Sans Interface, sans-serif;
  appearance: none;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.hasStartIcon ? "52px" : "16px")};
  padding-right: ${(props) => (props.hasEndIcon ? "52px" : "16px")};
  padding-top: ${(props) => (props.placeholderTop ? "12px" : "0")};
  gap: 12px;
  height: 56px;
  left: 0;
  background: ${(props) => props.theme.colors.background[1]};
  border: ${(props) =>
    `1px ${props.disabled ? "dashed" : "solid"} ${
      props.hasError
        ? props.theme.colors.text.warning
        : props.theme.colors.transparent.black[10]
    }`};
  border-color: ${(props) =>
    props.hasError
      ? `${props.theme.colors.text.warning}`
      : `${props.theme.colors.transparent.black[10]}`};
  border-radius: 8px;
  font-size: 16px;
  caret-color: ${(props) => props.theme.colors.text.warning};
  color: ${(props) => props.theme.colors.text.primary};

  :focus {
    padding-left: ${(props) => (props.hasStartIcon ? "51px" : "15px")};
    border: ${(props) =>
      props.hasError
        ? `2px solid ${props.theme.colors.text.warning}`
        : `2px solid ${props.theme.colors.text.primary}`};
  }

  :autofill {
    box-shadow: inset 0 0 0 50px ${(props) => props.theme.colors.background[1]};
    -webkit-text-fill-color: ${(props) => props.theme.colors.text.primary};
  }

  ::placeholder {
    font-family: SB Sans Interface, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.text.secondary};
    text-align: left;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) => props.type === "number" && `-moz-appearance: textfield;`}
`

const TopPlaceholder = styled.span<{ hasStartIcon: boolean }>`
  font-family: SB Sans Interface, sans-serif;
  font-weight: 400;
  position: absolute;
  font-size: 11px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.text.secondary};
  top: 9px;
  left: ${(props) => (props.hasStartIcon ? "52px" : "16px")};
`

const Postfix = styled.div<{ valueLength: number; postfix: string }>`
  ::after {
    font-family: "SBSansUI Regular", sans-serif;
    pointer-events: none;
    position: absolute;
    left: ${(props) => Math.ceil(((props.valueLength - 1) / 1.8) * 16) + 30}px;
    top: 24px;
    content: "${(props) => props.postfix}";
    color: ${(props) => props.theme.colors.text.primary};
  }
`

const Description = styled(Typography.Body2)<{
  hasError?: boolean
}>`
  margin-top: 4px;
  margin-left: 16px;
  color: ${(props) =>
    props.hasError
      ? props.theme.colors.text.warning
      : props.theme.colors.text.secondary};
`

const StartIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  left: 16px;
  top: 28px;
  transform: translate(0, -50%);
`

const EndIconWrapper = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 28px;
  padding: 0;
  transform: translate(0, -50%);
  background-size: contain;
  text-align: center;
  right: 18px;
  width: 23px;
  height: 23px;
`

export const Styled = {
  Root,
  Input,
  TopPlaceholder,
  Description,
  EndIconWrapper,
  StartIconWrapper,
  Postfix,
}
