import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { settingsActions } from "store/slice/settingsSlice"
import { EThemeNames, EThemeType } from "theme/interfaces"

export const useSetTheme = (externalTheme: EThemeType) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (externalTheme === EThemeType.Light) {
      dispatch(settingsActions.setTheme(EThemeNames.DayTheme))
    } else {
      dispatch(settingsActions.setTheme(EThemeNames.NightTheme))
    }
  }, [externalTheme, dispatch])
}
