import styled from "styled-components"
import { Table, Typography } from "components"

export const Container = styled(Table.Body)`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const EndIcon = styled.div`
  display: flex;
  gap 12px;
  justyfi-content: center;
`

export const TypographyPrice = styled(Typography.Body1Bold)`
  white-space: nowrap;
  align-self: center;
`

export const HighLight = styled.span`
  background-color: ${(props) => props.theme.colors.text.highlight};
`
