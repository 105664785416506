export enum EHistoryCallsType {
  Spam = "SPAM",
  Normal = "NORMAL",
}

export interface IHistoryCallsData {
  callDate: string
  callId: string
  callingNumber: string
  category: EHistoryCallsType
  categoryTitle: string
  isNew: boolean
  transcriptionPreview: string
}

export interface IHistoryCalls {
  date: string
  calls: IHistoryCallsData[]
}

export interface IHistoryCallsGrouped {
  data: IHistoryCalls[]
}

export interface IHistoryCallsResponse {
  data: {
    calls: IHistoryCallsData[]
  }
}

export interface IHistoryCallsRequest {
  phone: string
}

export interface ITranscriptionCalls {
  recording: string
  transcriptionParts: string[]
}

export interface ITranscriptionCallsResponse {
  data: ITranscriptionCalls
}

export interface ITranscriptionCallsRequest {
  phone: string
  callId: IHistoryCallsData["callId"]
}
