import React, { FC, isValidElement } from "react"
import ReactMarkdown from "react-markdown"
import { useTheme } from "styled-components"
import { Spacer, Typography, Button } from "components"
import { Modal } from "../Modal"
import { ICommonModalState } from "./interfaces"

export const CommonModal: FC<ICommonModalState> = ({
  children,
  isOpen,
  onClose,
  title,
  subtitle,
  content,
  confirmLabel,
  declineLabel,
  isPhoneNumber,
  confirmHandler,
  declineHandler,
  preventCloseOnOutsideClick,
  isTitleCentered = false,
}) => {
  const theme = useTheme()
  const onClickOutside = () => (preventCloseOnOutsideClick ? {} : onClose())

  return (
    <>
      <Modal open={isOpen} onClose={onClickOutside}>
        {title && (
          <Typography.H4 textAlign={isTitleCentered ? "center" : undefined}>
            {title}
          </Typography.H4>
        )}
        {subtitle && (
          <>
            <Spacer height={8} />
            {isValidElement(subtitle) ? (
              subtitle
            ) : (
              <Typography.Body1 color={theme.colors.text.secondary}>
                {subtitle}
              </Typography.Body1>
            )}
          </>
        )}
        {content && (
          <>
            <Spacer height={16} />
            <Typography.Body1
              as="div"
              lineHeight={22}
              className={isPhoneNumber ? "ym-hide-content" : ""}
            >
              <ReactMarkdown>{content}</ReactMarkdown>
            </Typography.Body1>
          </>
        )}
        <Spacer height={28} />
        {confirmLabel && (
          <>
            <Button.Primary
              onClick={() => {
                onClose()
                confirmHandler?.()
              }}
            >
              {confirmLabel}
            </Button.Primary>
            <Spacer height={8} />
          </>
        )}
        <Button.Plain
          onClick={() => {
            onClose()
            declineHandler()
          }}
        >
          {declineLabel ?? "Понятно"}
        </Button.Plain>
      </Modal>
      {children}
    </>
  )
}
