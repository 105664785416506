import styled from "styled-components"
import { Plain } from "components/Button/Plain"
import { Player } from "@lottiefiles/react-lottie-player"
import { Typography } from "components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 252px;
  padding: 16px 16px 34px 16px;
  align-items: center;
  background: ${(props) => props.theme.colors.background[1]};
  border-radius: 15px 15px 0 0;
`

export const WrapLoaderIconStyled = styled.div`
  height: 56px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
`

export const PlayerStyled = styled(Player)`
  height: 24px;
  width: 24px;
`

export const Title = styled(Typography.H4)`
  text-align: center;
  padding: 0 30px;
  margin-bottom: 2px;
`
export const Message = styled(Typography.Body1)`
  text-align: center;
`

export const Price = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;
`

export const Icons = styled.div<{ isAvailableOptions: boolean }>`
  display: flex;
  margin-top: 24px;
  margin-bottom: ${(props) => (props.isAvailableOptions ? 32 : 28)}px;
  img {
    border-radius: 50%;
    height: 48px;
    border: 3px solid white;
    margin: auto -4px;
  }
`

export const WrapRenderStatus = styled.div<{ isAvailableOptions: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.isAvailableOptions ? 28 : 20)}px;
`

export const DescriptionStyled = styled(Typography.Body1)<{
  isEnabledPackegeService: boolean
  marginTop?: number
}>`
  text-align: center;
  ${(props) => !!props.marginTop && `margin-top: ${props.marginTop}px;`}
  ${(props) => props.isEnabledPackegeService && "margin-bottom: 8px;"}
`

export const SubtitleText = styled(Typography.Body1)<{
  canBeDisabled: boolean
}>`
  text-align: center;
  ${(props) => props.canBeDisabled && "margin-bottom: 20px;"}
`

export const ButtonPlainStyled = styled(Plain)<{ isAvailableOptions: boolean }>`
  margin-top: ${(props) => (props.isAvailableOptions ? 8 : 24)}px;
`

export const FeeValue = styled(Typography.Body1)`
  white-space: nowrap;
`

export const SignatureUnderPrice = styled(Typography.Body2)`
  max-width: 210px;
`
