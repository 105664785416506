import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TFormDataValues } from "components/Form/interfaces"

export interface IFormState {
  values?: TFormDataValues
}

const initialState: IFormState = {
  values: undefined,
}

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormValues: (state, { payload }: PayloadAction<IFormState>) => {
      state.values = payload.values
    },
    addFormValues: (state, { payload }: PayloadAction<IFormState>) => {
      state.values = { ...state.values, ...payload.values }
    },
    clearFormValues: (state) => {
      state.values = undefined
    },
  },
})

export const formActions = formSlice.actions

export default formSlice.reducer
