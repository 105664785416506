import styled from "styled-components"
import { H4 } from "components/Typography/Headline"
import { Body1 } from "components/Typography/Body"
import { Primary } from "components/Button/Primary"
import { Plain } from "components/Button/Plain"

export const SheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  p {
    text-align: center;
  }
  background-color: ${(props) => props.theme.colors.background[1]};
`

export const ImageStyled = styled.img`
  margin: 104px auto 56px auto;
  display: flex;
  width: 50%;
`

export const H4Styled = styled(H4)`
  margin-bottom: 8px;
`

export const Body1Styled = styled(Body1)`
  margin: 0px 24px 24px;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const ButtonPrimaryStyled = styled(Primary)`
  width: calc(100% - 32px);
`

export const ButtonSecondaryStyled = styled(Plain)`
  margin-top: 8px;
  margin-bottom: 28px;
`

export const WrapImageStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  margin: 104px auto 56px auto;
`
