import ContentLoader from "react-content-loader"
import { useTheme } from "styled-components"

const SPEED = 1.2
const INTERVAL = 0.25

export const ExternalLinkSkeleton = () => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={SPEED}
      interval={INTERVAL}
      height="100%"
      width="100%"
      backgroundColor={theme.colors.transparent.black["10"]}
      foregroundColor={theme.colors.text.secondary}
      fill="none"
    >
      <rect width="100%" height="100%" />
    </ContentLoader>
  )
}
