export const colors = {
  // !Нецелевые цвета - начало
  transparent: {
    pure: "transparent",
    black: {
      10: "rgba(255, 255, 255, 0.1)",
    },
  },
  // !Нецелевые цвета - конец

  background: {
    0: "#0D0D1B",
    1: "#2A2E34",
    root: "#333740",
    surface0: "#10152F",
    surface1: "#282C44",
  },
  text: {
    primary: "#F4F4F4", // +
    secondary: "#8995A1",
    warning: "#FF8439",
    critical: "#F24141",
    disabled: "rgba(137, 149, 161, 0.8)",
    highlight: "rgba(223, 99, 8, 0.5)",
  },
  icon: {
    primary: "#8995A1", // +
    success: "#21A038",
    disabled: "rgba(255, 255, 255, 0.1)",
    warning: "#FF8439",
    critical: "#F24141",
    mpPrimary: "#F4F8F9",
  },
  divider: "rgba(255, 255, 255, 0.1)", // +
  divider2: "rgba(255, 255, 255, 0.1)",
  speedometer: {
    internet: "#FF8000",
    call: "#FF541A",
    sms: "#FF4E72",
  },
  button: {
    primary: "linear-gradient(90deg, #F8426E 0%, #FF8934 100%)",
    secondary: "#FFF3E5",
    disabled: "rgba(255, 255, 255, 0.2)",
    contentPrimary: "#FFFFFF", // +
    contentBrand: "#DF6308",
    black: "#000000",
  },
  toggle: {
    on: "#0D0D1B",
    off: "rgba(255, 255, 255, 0.1)",
  },
  stroke: {
    dark: "#FFFFFF",
    color: "rgba(255, 255, 255, 0.1)",
  },
  badge: {
    text: "#F4F8F9",
    background: "rgba(255, 255, 255, 0.1)",
    textSelected: "#FFF",
    backgroundSelected: "#677888",
  },
}
