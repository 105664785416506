import React, { FC } from "react"
import styled from "styled-components"

const NoWrap = styled.span`
  white-space: nowrap;
`

const format = (phone: string) => {
  const match = (phone[0] === "7" ? phone.substring(1) : phone).match(
    /^(\d{3})(\d{3})(\d{2})(\d{2})$/
  )
  if (match) {
    return `+7 (${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
  }
  return ""
}

const PhoneNumber: FC<{ phone: string }> & {
  format: (phone: string) => string
} = ({ phone }) => <NoWrap className="ym-hide-content">{format(phone)}</NoWrap>

PhoneNumber.format = format

export { PhoneNumber }
