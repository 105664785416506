import { ESpacerSize } from "entities/common"
import { NoWrap } from "utils"
import { MPTypography, Spacer } from "components"
import { Styled } from "./styles"

export const TITLE = "Как отключить услугу Хранитель"

export const Description = () => (
  <Styled.PaddingContainer>
    <Spacer height={ESpacerSize.XL} />
    <MPTypography typographyType="Body1">
      Вы можете подключить или отключить Хранителя в любое время — просто
      нажмите соответствующую кнопку в разделе «Безопасность» в своём приложении
      СберМобайл или позвонив по номеру
      <NoWrap>
        <MPTypography typographyType="Body1BoldSpan">
          +7 (499) 651-44-44
        </MPTypography>
      </NoWrap>
      , а так же на короткий номер{" "}
      <MPTypography typographyType="Body1BoldSpan">901</MPTypography> (для
      абонентов СберМобайл).
    </MPTypography>
    <Spacer height={ESpacerSize.L} />
    <MPTypography typographyType="Body1">
      Помните, что при отключении Хранителя не будут доступны его возможности,
      например, возмещение денег по страховке — будьте осторожны.
    </MPTypography>
  </Styled.PaddingContainer>
)
