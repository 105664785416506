import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import {
  EContentType,
  ELinksCode,
  EOptionErrorCode,
  EOptionStatus,
  IResponseErrorData,
} from "entities/common"
import { useGetLinksQuery, usePostTariffOptionStatusMutation } from "store/api"
import { formatPrice } from "utils/price"
import { RootState } from "store"
import { ITariffOptionStatusRequest } from "entities/tariffOptionsStatus"
import moment from "moment"
import { useTheme } from "styled-components"
import { sendMetric } from "utils/metrics"
import { useActions } from "hooks/actions"
import { EOptionAvailability } from "entities/tariff"
import { EStatusType } from "entities/connectedAvailable"
import { prepareGb, prepareInternetUnit } from "pages/Services/Option/utils"
import {
  Typography,
  Button,
  Table,
  Spacer,
  Loader,
  CommonModal,
} from "components"
import { routes } from "constants/routes"
import { urls } from "constants/urls"
import { BLANK } from "constants/common"
import { IServiceStatus } from "./interfaces"
import {
  Container,
  Icons,
  Price,
  WrapRenderStatus,
  DescriptionStyled,
  ButtonPlainStyled,
  Title,
  SubtitleText,
  FeeValue,
  SignatureUnderPrice,
  Message,
} from "./styles"
import { prepareConnectionFee, preparePlugButtonText } from "./utils"

const ServiceStatus: FC<IServiceStatus> = ({
  data,
  isAvailableOptions = false,
  onClose,
  isContentOption = false,
  isInHomePage = false,
  isRoaming = false,
}) => {
  const isContentOptionIncludedInTariff =
    isContentOption && data?.availability === EOptionAvailability.Free

  const isRoamingMarker = data.contentType === EContentType.RoamingMarker
  const isServiceCanBeConnected =
    (isAvailableOptions || !(data.active ?? data.status === "enabled")) &&
    !isContentOptionIncludedInTariff
  const pathname = window.location.pathname
  const canBeDisabled =
    data?.canBeDisabled ?? data?.availability === EOptionAvailability.Paid
  const { currentPhone, phone } = useSelector((state: RootState) => state.login)
  const { openModal } = useActions()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: links } = useGetLinksQuery()
  const [serviceStatus, setServiceStatus] = useState<
    EOptionStatus | undefined
  >()
  const [modalState, setModalState] = useState<{
    errorMessage: string
    isOpen: boolean
    confirmLabel?: string
    confirmHandler?: () => void
    declineLabel?: string
  }>({ errorMessage: "", isOpen: false })
  const [triggerTariffOptionStatus, status] =
    usePostTariffOptionStatusMutation()

  const onCloseModal = () => {
    setModalState({
      errorMessage: "",
      isOpen: false,
    })
    onClose?.()
  }

  const isDetailedButton = [
    EContentType.Aura,
    EContentType.Ya,
    EContentType.RoamingMarker,
  ].includes(data.contentType)

  const isCallsOrInternet = [
    EContentType.Calls,
    EContentType.Internet,
  ].includes(data.contentType)

  const isPackageService = [
    EContentType.Calls,
    EContentType.Internet,
    EContentType.WorryLessSms,
    EContentType.CompSms,
    EContentType.Sms,
  ].includes(data.contentType)

  const rawMonthlyFee = data?.monthlyFee < 0 ? 0 : data?.monthlyFee
  const rawConnectionFee = data?.connectionFee < 0 ? 0 : data?.connectionFee

  const connectionPeriod = (contentType: EContentType) => {
    switch (contentType) {
      case EContentType.Tethering:
      case EContentType.RoamingMarker:
        return data?.connectDescription

      case EContentType.AutorenewalBlock:
      case EContentType.Ya:
        return ""

      case EContentType.Calls:
      case EContentType.Internet:
        return data?.subTitle

      case EContentType.Messengers:
      case EContentType.Socials:
      case EContentType.Music:
      case EContentType.Video:
        return isServiceCanBeConnected && !rawMonthlyFee
          ? ""
          : data?.connectDescription ?? data?.description

      default:
        return data?.connectDescription ?? data?.description
    }
  }

  const isEnabledPackage = isPackageService && !isServiceCanBeConnected
  const totalPrice = prepareConnectionFee(
    rawMonthlyFee,
    rawConnectionFee,
    data?.periodType,
    isContentOption,
    isServiceCanBeConnected
  )

  const monthlyFee = formatPrice(rawMonthlyFee).value
  const connectionFee = formatPrice(rawConnectionFee).value
  const prepareDescription =
    data?.fullDescription ??
    (isCallsOrInternet || data.contentType === EContentType.Ya
      ? data.connectDescription
      : data.description)

  const isShowConnectMonthlyFee =
    isContentOption &&
    isServiceCanBeConnected &&
    rawConnectionFee !== 0 &&
    !isRoamingMarker

  const prepareSubTitle = () => {
    const hasSubtitle = [
      EContentType.Internet,
      EContentType.Calls,
      EContentType.Video,
      EContentType.Messengers,
      EContentType.Music,
      EContentType.Socials,
      EContentType.RoamingMarker,
    ].includes(data.contentType)
    const subTitle = isCallsOrInternet ? data.description : data.subTitle

    return hasSubtitle ? subTitle : ""
  }

  const subTitle = prepareSubTitle()

  useEffect(() => {
    if (isServiceCanBeConnected) {
      sendMetric({
        Услуги: { Доступные: { "Шторка услуги_Показ": [data.title] } },
      })
    } else {
      sendMetric({
        Услуги: { Подключенные: { "Шторка услуги_Показ": [data.title] } },
      })
    }
  }, [isServiceCanBeConnected, data.title])

  const enabledPackageSubtitle = () => {
    const currentValue =
      data.contentType === EContentType.Internet
        ? prepareGb(data.currentValue)
        : data.currentValue
    const unit =
      data.contentType === EContentType.Internet
        ? prepareInternetUnit(data.currentValue)
        : data.unit.toUpperCase()
    return (
      <SubtitleText
        canBeDisabled={canBeDisabled && data.statusType !== EStatusType.Blocked}
      >
        {`Осталось ${currentValue} ${unit} до ${moment(data?.expDate).format(
          "DD.MM.YY"
        )}`}
      </SubtitleText>
    )
  }

  useEffect(() => {
    if (status.isSuccess) {
      navigate(`${routes.serviceConnectionStatus}?status=${serviceStatus}`)
    }
    if (status.error && "data" in status.error) {
      const errorData = status.error.data as IResponseErrorData
      setModalState({
        errorMessage: errorData.statusInfo.statusMessage,
        isOpen: true,
        ...(errorData.statusInfo.statusCode === EOptionErrorCode.NoMoney
          ? {
              confirmLabel: "Пополнить",
              declineLabel: "Не сейчас",
              confirmHandler: () =>
                window.open(`${urls.pay}&phone=${currentPhone}`),
            }
          : {}),
      })
      status.reset()
    }
  }, [
    navigate,
    openModal,
    serviceStatus,
    status.error,
    status.isSuccess,
    setModalState,
    status,
    currentPhone,
  ])

  const prepareTitle = () =>
    data.contentType === EContentType.Ya ? data.description : data.title

  const prepareExtraTitle = () => {
    const extraPackage = isServiceCanBeConnected
      ? data?.volume
      : data?.totalValue
    if (!extraPackage) {
      return
    }
    const formattedUnit =
      data.contentType === EContentType.Calls
        ? data.unit.toLowerCase()
        : data.unit.toUpperCase()

    if (data.contentType === EContentType.Internet) {
      const formatGb = Math.floor(
        extraPackage >= 1024 ? extraPackage / 1024 : extraPackage
      )
      return ` +${formatGb} ${formattedUnit}`
    }
    // пока временно уберу, когда на всех услугах проверим и все будет ок, удалю, TODO
    // return ` +${extraPackage} ${formattedUnit}`
  }

  const onDetailsClick = () => {
    sendMetric({ Услуги: { Доступные: { Подробнее_Клик: [data.title] } } })

    switch (data.contentType) {
      case EContentType.Aura:
        return navigate(`${routes.auraDetails}?price=${totalPrice}`)

      case EContentType.Ya:
        if (links && links[ELinksCode.SmartAnswerMore]) {
          return navigate(routes.showExternalLink, {
            state: { link: links[ELinksCode.SmartAnswerMore], pathname },
          })
        }
        break

      case EContentType.RoamingMarker:
        if (links && links[ELinksCode.RoamingMarkerUrl]) {
          return window.open(links[ELinksCode.RoamingMarkerUrl], BLANK)
        }
        break
    }
  }

  const renderIcon = (
    enabledApps: Array<{ appCode: string; iconUrl: string }>
  ) => {
    return (
      <Icons isAvailableOptions={isServiceCanBeConnected}>
        {enabledApps?.map(({ appCode, iconUrl }) => (
          <img key={uuidv4()} src={iconUrl} alt={appCode} />
        ))}
      </Icons>
    )
  }

  const prepareRequestData = (optionStatus: EOptionStatus) => {
    const requestData: ITariffOptionStatusRequest = {
      status: optionStatus,
      optionId: data.optionId,
    }
    if (phone === currentPhone) {
      requestData.phone = phone
    } else {
      requestData.additionalNumbers = currentPhone
    }

    return requestData
  }

  const onToPlug = () => {
    if (isInHomePage) {
      sendMetric({
        ГлавныйЭкран: {
          ШторкаБезлимитов: {
            НеАктивна: { ПодключитьКлик: [data.contentType] },
          },
        },
      })
    } else {
      sendMetric({ Услуги: { Доступные: { Подключить_Клик: [data.title] } } })
    }

    if (data.contentType === EContentType.Aura) {
      navigate(routes.auraAgreement)
    } else if (
      data.contentType === EContentType.Ya &&
      links &&
      links[ELinksCode.SmartAnswerDownload]
    ) {
      window.open(links[ELinksCode.SmartAnswerDownload], BLANK)
    } else if (data.contentType === EContentType.SberSound && links) {
      window.open(links[ELinksCode.SberSound], BLANK)
    } else {
      const requestData = prepareRequestData(EOptionStatus.Active)
      setServiceStatus(EOptionStatus.Active)
      triggerTariffOptionStatus(requestData)
    }
  }

  const onConnectionButtonClick = () => {
    if (!canBeDisabled) {
      return onClose?.()
    }

    if (
      data.contentType === EContentType.Ya &&
      links &&
      links[ELinksCode.SmartAnswerDownload]
    ) {
      window.open(links[ELinksCode.SmartAnswerDownload], BLANK)
    } else {
      const requestData = prepareRequestData(EOptionStatus.Disabled)
      if (isInHomePage) {
        sendMetric({
          ГлавныйЭкран: {
            ШторкаБезлимитов: {
              Активна: { ОтключитьКлик: [data.contentType] },
            },
          },
        })
      } else {
        sendMetric({
          Услуги: { Подключенные: { Отключить_Клик: ["<Title>"] } },
        })
      }
      setServiceStatus(EOptionStatus.Disabled)
      triggerTariffOptionStatus(requestData)
    }
  }

  const ConnectionButton = () =>
    isServiceCanBeConnected || data.contentType === EContentType.SberSound ? (
      <>
        {data.contentType === EContentType.SberSound && <Spacer height={20} />}
        <Button.Primary onClick={onToPlug}>
          {preparePlugButtonText(data.contentType)}
        </Button.Primary>
      </>
    ) : (
      <ButtonPlainStyled
        isAvailableOptions={isServiceCanBeConnected || isRoamingMarker}
        onClick={onConnectionButtonClick}
      >
        {canBeDisabled ? "Отключить" : "Понятно"}
      </ButtonPlainStyled>
    )

  const renderStatus = () => (
    <>
      {!isServiceCanBeConnected &&
        data.contentType !== EContentType.RoamingMarker && (
          <Typography.Body2>
            {isContentOptionIncludedInTariff
              ? "Включено в тариф"
              : "Услуга подключена"}
          </Typography.Body2>
        )}
      {isRoamingMarker && (
        <>
          <Typography.Body1>{data.coverageArea}</Typography.Body1>
          <Spacer height={6} />
        </>
      )}
      <Price>
        <Typography.H1>{formatPrice(totalPrice).rubles}</Typography.H1>
        <Typography.Body2>₽</Typography.Body2>
      </Price>
      {/* условие, чтобы не подставлять подпись периода оплаты, когда стоимость подключения !== 0*/}
      {canBeDisabled && !isShowConnectMonthlyFee && (
        <SignatureUnderPrice textAlign="center">
          {connectionPeriod(data?.contentType)}
        </SignatureUnderPrice>
      )}
    </>
  )

  const renderDescriptionContent = () =>
    data.enabledApps ? (
      <>
        {renderIcon(data.enabledApps)}
        {data?.fullDescription && (
          <DescriptionStyled
            lineHeight={22}
            isEnabledPackegeService={
              isEnabledPackage || data.statusType === EStatusType.Blocked
            }
          >
            {data.fullDescription}
          </DescriptionStyled>
        )}
      </>
    ) : (
      <>
        <DescriptionStyled
          marginTop={32}
          lineHeight={22}
          isEnabledPackegeService={
            isEnabledPackage || data.statusType === EStatusType.Blocked
          }
        >
          {prepareDescription}
        </DescriptionStyled>
        {isEnabledPackage && enabledPackageSubtitle()}
      </>
    )

  return (
    <>
      {status.isLoading && <Loader />}
      <CommonModal
        isOpen={modalState.isOpen}
        onClose={onCloseModal}
        title={modalState.errorMessage}
        declineHandler={onCloseModal}
        confirmLabel={modalState.confirmLabel}
        confirmHandler={modalState.confirmHandler}
        declineLabel={modalState.declineLabel}
      />
      <Container>
        <Title>
          {prepareTitle()}
          {prepareExtraTitle()}
        </Title>
        <Spacer height={4} />
        {subTitle && <Typography.Body2>{subTitle}</Typography.Body2>}
        {renderDescriptionContent()}
        {data.statusType === EStatusType.Blocked && (
          <>
            <Spacer height={8} />
            <Message lineHeight={22}>
              Недостаточно средств, чтобы активировать услуги
            </Message>
            <Spacer height={12} />
          </>
        )}
        {(!isRoamingMarker || isRoaming) && (
          <WrapRenderStatus isAvailableOptions={isServiceCanBeConnected}>
            {renderStatus()}
          </WrapRenderStatus>
        )}
        {isServiceCanBeConnected && <Spacer height={28} />}
        {isShowConnectMonthlyFee && (
          <Table.Body padding={0}>
            <Table.Section>
              <Table.CommonCell
                title="Абонентская плата"
                color={theme.colors.text.secondary}
                endIcon={<FeeValue>{`${monthlyFee}/мес`}</FeeValue>}
              />
              <Table.CommonCell
                title="Подключение услуги"
                color={theme.colors.text.secondary}
                endIcon={
                  <Typography.Body1 color={theme.colors.text.secondary}>
                    {connectionFee}
                  </Typography.Body1>
                }
              />
            </Table.Section>
          </Table.Body>
        )}
        {isRoamingMarker && data.active && (
          <>
            <Spacer height={28} />
            <Button.Primary onClick={onDetailsClick}>Подробнее</Button.Primary>
          </>
        )}
        <ConnectionButton />
        {isDetailedButton &&
          isServiceCanBeConnected &&
          !(isRoamingMarker && data.active) && (
            <ButtonPlainStyled
              onClick={onDetailsClick}
              isAvailableOptions={isServiceCanBeConnected}
            >
              Подробнее
            </ButtonPlainStyled>
          )}
      </Container>
    </>
  )
}

export { ServiceStatus }
