import styled from "styled-components"
import { Button } from "components/Button"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: ${(props) => props.theme.colors.background[0]};
  flex-direction: column;
`

export const EmptyAvailableOptionsWrap = styled.div`
  position: fixed;
  padding: 16px;
  text-align: center;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`

export const ServiceSkeletonContainer = styled.div`
  display: flex;
  padding: 17px 20px;
  justify-content: space-between;
`

export const PlainButton = styled(Button.Plain)`
  align-self: flex-start;
  width: fit-content;
  padding: 0;
`
