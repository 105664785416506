import React, { FC, memo } from "react"
import { useSelector } from "react-redux"
import { useFormState } from "react-hook-form"
import { RootState } from "store"
import { Button } from "components"

const FormButton: FC<{ submitButton?: string }> = memo(({ submitButton }) => {
  const { isValid } = useFormState()
  const { isValid: isCustomValid } = useSelector(
    (state: RootState) => state.formValidation
  )
  return (
    <Button.Primary
      type="submit"
      disabled={!isValid || (!isCustomValid && isCustomValid !== undefined)}
    >
      {submitButton ?? "Продолжить"}
    </Button.Primary>
  )
})

export { FormButton }
