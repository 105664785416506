import styled from "styled-components"
import { Typography } from "components"

export const SmsConfirmationStyles = {
  H1: styled(Typography.H1)`
    padding-left: 16px;
    margin-top: 74px;
    margin-bottom: 17px;
  `,
  WrapPhone: styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 27px;
    padding-left: 16px;
  `,
  Body1: styled(Typography.Body1)`
    color: ${(props) => props.theme.colors.text.secondary};
  `,
  Phone: styled(Typography.Body1)`
    color: ${(props) => props.theme.colors.text.primary};
    white-space: nowrap;
  `,
  WrapInput: styled.div`
    width: 100%;
    border-radius: 8px;
  `,
  Body2: styled(Typography.Body2)`
    color: ${(props) => props.theme.colors.text.secondary};
  `,
  Footer: styled.div`
    width: 100%;
    max-width: 480px;
    margin-top: 32px;
    padding-left: 16px;
  `,
  Link: styled(Typography.Body1)`
    cursor: pointer;
    color: ${(props) => props.theme.colors.text.warning};
  `,
  ServiceRulesWrap: styled.div`
    margin-top: 16px;
    padding-left: 16px;
  `,
  Footnote: styled(Typography.Body2)`
    color: ${(props) => props.theme.colors.text.secondary};
  `,
  FootnoteLink: styled(Typography.Body2Link)`
    cursor: pointer;
    text-decoration: underline;
    margin: 0 3px;
  `,
  Wrapper: styled.div`
    display: flex;
    padding: 0 16px;
    flex-direction: column;
  `,
}
