import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { usePostPersonalAdjustmentMutation } from "store/api"
import { RootState } from "store"
import { useActions } from "hooks/actions"
import { useTheme } from "styled-components"
import { useNavigate } from "react-router"
import { EStatusCode, IResponseErrorData } from "entities/common"
import { CommonModal } from "components/CommonModal"
import { IFile } from "components/UploadFile/UploadFile"
import { normalizeFileData } from "components/UploadFile/utils"
import { sendMetric } from "utils/metrics"
import { Container, Header, Loader, Signing } from "components"
import { routes } from "constants/routes"
import { IPersonalFormValues, IModalState } from "./interfaces"

export const PersonalInfoAdjustmentSigning = () => {
  const [modalState, setModalState] = useState<IModalState>({
    isOpen: false,
    title: "",
  })
  const { setLoading } = useActions()
  const [errorStatusCode, setErrorStatusCode] = useState("")
  const navigate = useNavigate()
  const [trigger, { isSuccess, error, isLoading }] =
    usePostPersonalAdjustmentMutation()
  const { selectedPhone, smsCode, phone } = useSelector(
    (state: RootState) => state.login
  )
  const isMainNumber = phone === selectedPhone
  const theme = useTheme()
  const isAdditionalNumber = selectedPhone !== phone
  const { values } = useSelector((state: RootState) => state.form)
  const { email, contactNumber, firstPage, registrationPage, previousDocPage } =
    values as unknown as IPersonalFormValues
  const onClickContinue = (signatureImage?: string) => {
    if (!signatureImage) {
      return
    }

    sendMetric({
      "Корректировка рег.данных": { Подписание: ["Ввод подписи_Клик"] },
    })
    setLoading(true)
    trigger({
      contactNumber,
      email,
      signatureImage,
      documents: normalizeFileData([
        ...firstPage,
        ...(registrationPage ?? {}),
        ...(previousDocPage ?? {}),
      ]) as IFile[],
      smsCode,
      additionalNumber: isAdditionalNumber ? selectedPhone : undefined,
    })
  }

  useEffect(() => {
    if (modalState.isOpen) {
      return
    }
    if (isSuccess) {
      navigate(routes.personalInfoAdjustmentStatus, {
        state: {
          isSuccess: true,
          statusMessage: "",
        },
      })
    }

    if (error && "data" in error) {
      const errorData = error.data as IResponseErrorData
      const statusCode = errorData?.statusInfo.statusCode

      switch (statusCode) {
        case EStatusCode.OperationTimeExpired:
          setErrorStatusCode(statusCode)
          setModalState({
            title: "Время сессии истекло.",
            subtitle: "Попробуйте внести изменения снова",
            isOpen: true,
          })
          break

        case EStatusCode.UnknownServerError:
          setErrorStatusCode(statusCode)
          setModalState({
            title: "Заявление не отправлено.",
            subtitle: "Пожалуйста, создайте заявку повторно",
            isOpen: true,
          })
          break

        case EStatusCode.OtrsError:
          setErrorStatusCode(statusCode)
          setModalState({
            title: "Не удалось отправить заявку.",
            subtitle: "Введите, пожалуйста, корректный email",
            isOpen: true,
          })
          break

        default:
          navigate(routes.personalInfoAdjustmentStatus, {
            state: {
              isSuccess: false,
              statusMessage: errorData?.statusInfo.statusMessage,
            },
          })
      }
    }

    setLoading(false)
  }, [error, isSuccess, modalState.isOpen, navigate, setLoading, setModalState])

  const declineHandler = () => {
    switch (errorStatusCode) {
      case EStatusCode.OperationTimeExpired:
      case EStatusCode.UnknownServerError:
        navigate(routes.phone, {
          state: {
            phone: selectedPhone,
            isMainNumber,
          },
        })
        break

      case EStatusCode.OtrsError:
        navigate(`${routes.personalInfoAdjustment}?phone=${selectedPhone}`)
        break
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <CommonModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false, title: "" })}
        title={modalState.title}
        subtitle={modalState?.subtitle}
        declineLabel={modalState?.declineLabel}
        preventCloseOnOutsideClick
        declineHandler={declineHandler}
      />
      <Container page>
        <Header title="Подписание" background={theme.colors.background[1]} />
        <Signing onContinue={onClickContinue} phone={selectedPhone} />
      </Container>
    </>
  )
}
