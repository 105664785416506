import React, { FC, memo, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { BrowserRouter, Route, Routes } from "react-router-dom"
// import { setConfig, setMeta } from "@sbol/clickstream-agent"
import {
  Login,
  PaymentAdjustment,
  PaymentAdjustmentAmount,
  PaymentAdjustmentNumber,
  PaymentAdjustmentSigning,
  PaymentAdjustmentConfirmation,
  PaymentAdjustmentAmountContacts,
  NumberTransferDate,
  SupportChat,
} from "pages"
import {
  ChooseCountry,
  RequiredSecureConditions,
  RoamingOptions,
  SecureAccount,
  ChangeSberMainNumber,
  Offer,
  GuardianPrice,
  HowItWorks,
  IfIWasDeceived,
  SbermobileNumberAsMain,
  UnwantedCalls,
  DeactivateDuardianService,
  HowAuraWork,
  VoiceAssistantWork,
  CallRecordingStorage,
  AmountToRefunded,
  MessengerCalls,
  InsuranceRules,
  SecureAccountMain,
  HistoryCalls,
  SberKids,
  TwoGisGeolocation,
  BonusPay,
  ContentRestriction,
  FavoriteNumber,
  NoLimitSberKids,
  ZeroConnection,
} from "processes"
import { ThemeProvider } from "styled-components"
import GlobalFonts from "theme/fonts"
import GlobalStyles from "theme/global"
import { RootState } from "store"
import { getTheme } from "theme"
import { updateTheme } from "theme/utils"
import { colors as dayColors } from "theme/dayTheme/colors"
import { colors as nightColors } from "theme/nightTheme/colors"
import { STORAGE_VERSION } from "store/localStorage"
import { SecureAccountGuardian } from "processes/secureAccount/PWA/SecureAccountGuardian"
import { GuardianAgreement } from "processes/secureAccount/PWA/GuardianAgreement"
import { PWARequiredSecureConditions } from "processes/secureAccount/PWA/answers/PWARequiredSecureConditions"
import { PWAChangeSberMainNumber } from "processes/secureAccount/PWA/answers/PWAChangeSberMainNumber"
import { PWAAmountToRefunded } from "processes/secureAccount/PWA/answers/PWAAmountToRefunded"
import { PWAMessengerCalls } from "processes/secureAccount/PWA/answers/PWAMessengerCalls"
import { PWAInsuranceRules } from "processes/secureAccount/PWA/answers/PWAInsuranceRules"
import { PWASbermobileNumberAsMain } from "processes/secureAccount/PWA/answers/PWASbermobileNumberAsMain"
import { PWAUnwantedCalls } from "processes/secureAccount/PWA/answers/PWAUnwantedCalls"
import { PWADeactivateDuardianService } from "processes/secureAccount/PWA/answers/PWADeactivateDuardianService"
import { PWAGuardianPrice } from "processes/secureAccount/PWA/answers/PWAGuardianPrice"
import { PWAHowItWorks } from "processes/secureAccount/PWA/answers/PWAHowItWorks"
import { PWAIfIWasDeceived } from "processes/secureAccount/PWA/answers/PWAIfIWasDeceived"
import { PWAHowAuraWork } from "processes/secureAccount/PWA/answers/PWAHowAuraWork"
import { PWAVoiceAssistantWork } from "processes/secureAccount/PWA/answers/PWAVoiceAssistantWork"
import { PWACallRecordingStorage } from "processes/secureAccount/PWA/answers/PWACallRecordingStorage"
import { MoneyBack } from "processes/secureAccount/PWA/MoneyBack"
import { MyTariff } from "pages/MyTariff"
import { Phone } from "pages/Phone"
import { EngineeringWorks } from "pages/EngineeringWorks"
import { Signature } from "pages/Signature"
import { SignatureOnline } from "pages/SignatureOnline"
import { SignatureCheck } from "pages/SignatureCheck"
import { SignatureCondition } from "pages/SignatureCondition"
import { SignatureGoskey } from "pages/SignatureGoskey"
import { SignatureGoskeyWait } from "pages/SignatureGoskeyWait"
import { ServicesCall } from "pages/ServicesCall"
import { ServicesSms } from "pages/ServicesSms"
import { ServicesInternet } from "pages/ServicesInternet"
import { AuraDetails } from "pages/AuraDetails"
import { AuraSigning } from "pages/AuraSigning"
import { AuraAgreement } from "pages/AuraAgreement"
import { ServiceConnectionStatus } from "pages/ServiceConnectionStatus"
import { ShowExternalLink } from "pages/ShowExternalLink/ShowExternalLink"
import { Theme as ThemePage } from "pages/Theme"
import { LoginSberId } from "pages/LoginSberId"
import { LoginSberIdBind } from "pages/LoginSberIdBind"
import { BeautifulNumbers } from "pages/BeautifulNumbers"
import { ChangeNumber } from "pages/ChangeNumber/ChangeNumber"
import { ChangeNumberStatus } from "pages/ChangeNumber/ChangeNumberStatus"
import { BeautifulNumbersConfirmation } from "pages/BeautifulNumbersConfirmation/BeautifulNumbersConfirmation"
import { PersonalInfo } from "pages/PersonalInfo/PersonalInfo"
import { PersonalInfoConfirmation } from "pages/PersonalInfo/PersonalInfoConfirmation"
import { PersonalInfoAdjustment } from "pages/PersonalInfoAdjustment"
import { PersonalInfoAdjustmentSigning } from "pages/PersonalInfoAdjustmentSigning"
import { PersonalInfoAdjustmentStatus } from "pages/PersonalInfoAdjustmentStatus"
import { EmailConfirmation } from "pages/EmailConfirmation"
import { EmailAdjustment } from "pages/EmailAdjustment/EmailAdjustment"
import { EmailAjustmentConfirmation } from "pages/EmailAjustmentConfirmation"
import { EmailAdjustmentStatus } from "pages/EmailAdjustmentStatus"
import { AuraOperationStatus } from "pages/AuraOperationStatus"
import { OrderDetails } from "pages/OrderDetails/OrderDetails"
import { OrderDetailsConfirmation } from "pages/OrderDetailsConfirmation"
import { OrderDetailsStatus } from "pages/OrderDetailsStatus"
import { NumberTransferConfirmation } from "pages/NumberTransferConfirmation/NumberTransferConfirmation"
import { NumberTransferEntry } from "pages/NumberTransferEntry/NumberTransferEntry"
import { NumberTransferSigning } from "pages/NumberTransferSigning"
import { NumberTransferStatus } from "pages/NumberTransferStatus"
import { AssistantAgreement } from "pages/AssistantAgreement"
import { OperationStatusCommon } from "pages/OperationStatusCommon"
import { ModalProvider, PrivateRoute, TabBarRouting } from "../"
import { routes } from "constants/routes"
// import { CLICK_STREAM_KEY, CLICK_STREAM_URL } from "constants/metrics"
import { RootStyled } from "./styles"

export const App: FC = memo(() => {
  const [innerTheme, setInnerTheme] = useState<"dark" | "light">()
  const { currentTheme } = useSelector((state: RootState) => state.settings)

  const theme = useMemo(() => {
    return getTheme()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme, innerTheme])

  useEffect(() => {
    updateTheme(
      currentTheme,
      nightColors.background[0],
      dayColors.background[0]
    )
  }, [currentTheme])

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        if (event.matches) {
          setInnerTheme("dark")
        } else {
          setInnerTheme("light")
        }
      })

    if (!localStorage.getItem("storageVersion")) {
      localStorage.clear()
      localStorage.setItem("storageVersion", STORAGE_VERSION)
      window.location.reload()
    }
    // setConfig({
    //   url: CLICK_STREAM_URL,
    //   buffer: 1,
    //   credentials: false,
    // })
    // setMeta({
    //   apiKey: CLICK_STREAM_KEY,
    // })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <RootStyled>
          <BrowserRouter>
            <Routes>
              <Route path={routes.sberKids} element={<SberKids />} />
              <Route
                path={routes.twoGisGeolocation}
                element={<TwoGisGeolocation />}
              />
              <Route path={routes.bonusPay} element={<BonusPay />} />
              <Route
                path={routes.contentRestriction}
                element={<ContentRestriction />}
              />
              <Route
                path={routes.favoriteNumber}
                element={<FavoriteNumber />}
              />
              <Route
                path={routes.noLimitSberKids}
                element={<NoLimitSberKids />}
              />
              <Route
                path={routes.zeroConnection}
                element={<ZeroConnection />}
              />

              <Route path={routes.secureAccount} element={<SecureAccount />} />
              <Route
                path={routes.requiredSecureConditions}
                element={<RequiredSecureConditions />}
              />
              <Route path={routes.offer} element={<Offer />} />
              <Route
                path={routes.insuranceRules}
                element={<InsuranceRules />}
              />
              <Route
                path={routes.changeSberMainNumber}
                element={<ChangeSberMainNumber />}
              />
              <Route path={routes.guardianPrice} element={<GuardianPrice />} />
              <Route path={routes.howItWorks} element={<HowItWorks />} />
              <Route
                path={routes.ifIWasDeceived}
                element={<IfIWasDeceived />}
              />
              <Route
                path={routes.sbermobileNumberAsMain}
                element={<SbermobileNumberAsMain />}
              />
              <Route path={routes.unwantedCalls} element={<UnwantedCalls />} />
              <Route
                path={routes.deactivateDuardianService}
                element={<DeactivateDuardianService />}
              />
              <Route
                path={routes.voiceAssistantWork}
                element={<VoiceAssistantWork />}
              />
              <Route
                path={routes.messengerCalls}
                element={<MessengerCalls />}
              />
              <Route
                path={routes.callRecordingStorage}
                element={<CallRecordingStorage />}
              />
              <Route path={routes.howAuraWork} element={<HowAuraWork />} />
              <Route
                path={routes.amountToRefunded}
                element={<AmountToRefunded />}
              />
              <Route path={routes.login} element={<Login />} />
              <Route path={routes.loginSberId} element={<LoginSberId />} />
              <Route
                path={routes.engineeringWorks}
                element={<EngineeringWorks />}
              />
              <Route
                path={routes.signatureGoskey}
                element={<SignatureGoskey />}
              />
              <Route
                path={routes.signatureGoskeyWait}
                element={<SignatureGoskeyWait />}
              />
              <Route path={routes.main} element={<PrivateRoute />}>
                <Route path={routes.historyCalls} element={<HistoryCalls />} />
                <Route
                  path={routes.secureAccountMain}
                  element={<SecureAccountMain />}
                />
                <Route
                  path={routes.secureAccountGuardian}
                  element={<SecureAccountGuardian />}
                />
                <Route
                  path={routes.guardianAgreement}
                  element={<GuardianAgreement />}
                />

                <Route
                  path={routes.assistantAgreement}
                  element={<AssistantAgreement />}
                />
                {/*#region guardian answers */}
                <Route path={routes.moneyBack} element={<MoneyBack />} />
                <Route
                  path={routes.pwaGuardianPrice}
                  element={<PWAGuardianPrice />}
                />
                <Route
                  path={routes.pwaHowItWorks}
                  element={<PWAHowItWorks />}
                />
                <Route
                  path={routes.pwaIfIWasDeceived}
                  element={<PWAIfIWasDeceived />}
                />
                <Route
                  path={routes.pwaHowAuraWork}
                  element={<PWAHowAuraWork />}
                />
                <Route
                  path={routes.pwaVoiceAssistantWork}
                  element={<PWAVoiceAssistantWork />}
                />
                <Route
                  path={routes.pwaCallRecordingStorage}
                  element={<PWACallRecordingStorage />}
                />
                <Route
                  path={routes.pwaAmountToRefunded}
                  element={<PWAAmountToRefunded />}
                />
                <Route
                  path={routes.pwaInsuranceRules}
                  element={<PWAInsuranceRules />}
                />
                <Route
                  path={routes.pwaMessengerCalls}
                  element={<PWAMessengerCalls />}
                />
                <Route
                  path={routes.pwaSbermobileNumberAsMain}
                  element={<PWASbermobileNumberAsMain />}
                />
                <Route
                  path={routes.pwaUnwantedCalls}
                  element={<PWAUnwantedCalls />}
                />
                <Route
                  path={routes.pwaDeactivateDuardianService}
                  element={<PWADeactivateDuardianService />}
                />
                <Route
                  path={routes.pwaRequiredSecureConditions}
                  element={<PWARequiredSecureConditions />}
                />
                <Route
                  path={routes.pwaChangeSberMainNumber}
                  element={<PWAChangeSberMainNumber />}
                />
                {/*#endregion */}
                <Route
                  path={routes.loginSberIdBind}
                  element={<LoginSberIdBind />}
                />
                <Route path={routes.main} element={<TabBarRouting />} />
                <Route path={routes.menu} element={<TabBarRouting />} />
                <Route path={routes.topUp} element={<TabBarRouting />} />
                <Route path={routes.myTariff} element={<MyTariff />} />
                <Route path={routes.services} element={<TabBarRouting />} />
                <Route
                  path={routes.servicesConnected}
                  element={<TabBarRouting />}
                />
                <Route
                  path={routes.servicesAvailable}
                  element={<TabBarRouting />}
                />
                <Route path={routes.auraDetails} element={<AuraDetails />} />
                <Route path={routes.auraSigning} element={<AuraSigning />} />
                <Route
                  path={routes.auraAgreement}
                  element={<AuraAgreement />}
                />
                <Route
                  path={routes.auraOperationStatus}
                  element={<AuraOperationStatus />}
                />
                <Route
                  path={routes.serviceConnectionStatus}
                  element={<ServiceConnectionStatus />}
                />
                <Route
                  path={routes.operationStatusCommon}
                  element={<OperationStatusCommon />}
                />
                <Route
                  path={routes.servicesInternet}
                  element={<ServicesInternet />}
                />
                <Route path={routes.servicesCall} element={<ServicesCall />} />
                <Route path={routes.servicesSms} element={<ServicesSms />} />
                <Route path={routes.phone} element={<Phone />} />
                <Route path={routes.signature} element={<Signature />} />
                <Route
                  path={routes.signatureOnline}
                  element={<SignatureOnline />}
                />
                <Route
                  path={routes.signatureCheck}
                  element={<SignatureCheck />}
                />
                <Route
                  path={routes.signatureCondition}
                  element={<SignatureCondition />}
                />
                <Route path={routes.theme} element={<ThemePage />} />
                <Route
                  path={routes.showExternalLink}
                  element={<ShowExternalLink />}
                />
                <Route
                  path={routes.paymentAdjustment}
                  element={<PaymentAdjustment />}
                />
                <Route
                  path={routes.paymentAdjustmentAmount}
                  element={<PaymentAdjustmentAmount />}
                />
                <Route
                  path={routes.paymentAdjustmentAmountContacts}
                  element={<PaymentAdjustmentAmountContacts />}
                />
                <Route
                  path={routes.paymentAdjustmentNumber}
                  element={<PaymentAdjustmentNumber />}
                />
                <Route
                  path={routes.paymentAdjustmentSinging}
                  element={<PaymentAdjustmentSigning />}
                />
                <Route
                  path={routes.paymentAdjustmentConfirmation}
                  element={<PaymentAdjustmentConfirmation />}
                />
                <Route
                  path={routes.beautifulNumbers}
                  element={<BeautifulNumbers />}
                />
                <Route
                  path={routes.beautifulNumbersChange}
                  element={<ChangeNumber />}
                />
                <Route
                  path={routes.beautifulNumbersChangeStatus}
                  element={<ChangeNumberStatus />}
                />
                <Route
                  path={routes.beautifulNumbersConfirmation}
                  element={<BeautifulNumbersConfirmation />}
                />
                <Route path={routes.personalInfo} element={<PersonalInfo />} />
                <Route
                  path={routes.personalInfoConfirmation}
                  element={<PersonalInfoConfirmation />}
                />
                <Route
                  path={routes.personalInfoAdjustment}
                  element={<PersonalInfoAdjustment />}
                />
                <Route
                  path={routes.personalInfoAdjustmentSigning}
                  element={<PersonalInfoAdjustmentSigning />}
                />
                <Route
                  path={routes.personalInfoAdjustmentStatus}
                  element={<PersonalInfoAdjustmentStatus />}
                />
                <Route
                  path={routes.emailAdjustment}
                  element={<EmailAdjustment />}
                />
                <Route
                  path={routes.emailConfirmation}
                  element={<EmailConfirmation />}
                />
                <Route
                  path={routes.emailAdjustmentConfirmation}
                  element={<EmailAjustmentConfirmation />}
                />
                <Route
                  path={routes.emailAdjustmentStatus}
                  element={<EmailAdjustmentStatus />}
                />
                <Route path={routes.orderDetails} element={<OrderDetails />} />
                <Route
                  path={routes.orderDetailsConfirmation}
                  element={<OrderDetailsConfirmation />}
                />
                <Route
                  path={routes.orderDetailsStatus}
                  element={<OrderDetailsStatus />}
                />
                <Route
                  path={routes.numberTransferDate}
                  element={<NumberTransferDate />}
                />
                <Route
                  path={routes.numberTransferEntry}
                  element={<NumberTransferEntry />}
                />
                <Route
                  path={routes.numberTransferConfirmation}
                  element={<NumberTransferConfirmation />}
                />
                <Route
                  path={routes.numberTransferSigning}
                  element={<NumberTransferSigning />}
                />
                <Route
                  path={routes.numberTransferStatus}
                  element={<NumberTransferStatus />}
                />
                <Route
                  path={routes.roamingChooseCountry}
                  element={<ChooseCountry />}
                />
                <Route
                  path={routes.roamingOptions}
                  element={<RoamingOptions />}
                />
                <Route path={routes.supportChat} element={<SupportChat />} />
                <Route
                  path={routes.supportSetPhone}
                  element={<TabBarRouting />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </RootStyled>
      </ModalProvider>
      <GlobalFonts />
      <GlobalStyles />
    </ThemeProvider>
  )
})
