import { FC } from "react"
import { useSetTheme } from "hooks/useSetTheme"
import { EThemeType } from "theme/interfaces"
import { useTheme } from "styled-components"
import { dayTheme } from "theme/dayTheme"
import { ESpacerSize } from "entities/common"
import { FAQStyled } from "pages/FAQ/styles"
import {
  Container,
  MPTypography,
  Spacer,
  Table,
  Typography,
  Wrapper,
} from "components"
import { BLANK, SELF } from "constants/common"
import { FAQButtonStyled } from "./styles"

interface IFAQDetailsProps {
  title: string
  subtitle: string
  sectionContent?: {
    id: number
    content: string
  }[]
  sectionTitle?: string
  buttonText?: string
  externalTheme: EThemeType
  buttonLink?: string
  system?: string
}
export const FAQWithButton: FC<IFAQDetailsProps> = ({
  title,
  subtitle,
  sectionContent,
  sectionTitle,
  buttonText,
  externalTheme,
  buttonLink,
  system,
}) => {
  useSetTheme(externalTheme)

  // если system есть, значит это андройд, или ios, иначе PWA
  const onButtonClick = () =>
    system ? window.open(buttonLink, SELF) : window.open(buttonLink, BLANK)
  const theme = useTheme()
  return (
    <Container
      position="relative"
      backgroundColor={theme.colors.background.surface1}
      page
    >
      <Spacer height={40} />
      <Wrapper>
        <Typography.H2>{title}</Typography.H2>
      </Wrapper>
      <Spacer height={40} />
      <Wrapper>
        <MPTypography>{subtitle}</MPTypography>
      </Wrapper>
      <Spacer height={ESpacerSize.L} />
      {sectionContent && (
        <Table.Section
          title={sectionTitle}
          titlePadding={{ paddingBottom: 6 }}
          titleComponent={({ children }: any) => (
            <MPTypography typographyType="Body1Bold">{children}</MPTypography>
          )}
          hasDivider={false}
        >
          {sectionContent.map((content) => (
            <Table.CommonCell
              backgroundColor={theme.colors.background.surface1}
              key={content.id}
              title={content.content}
              titleComponent={({ children }: any) => (
                <MPTypography startPadding={16}>{children}</MPTypography>
              )}
              startIcon={
                <FAQStyled.IconWrapper hasBackground>
                  <Typography.Body1Bold>{content.id}</Typography.Body1Bold>
                </FAQStyled.IconWrapper>
              }
              startIconWidth="auto"
              titleSpacer={16}
            />
          ))}
        </Table.Section>
      )}
      <Spacer height={24} />
      <Wrapper>
        <Spacer height={ESpacerSize.XL} />
        {!!buttonText && (
          <FAQButtonStyled onClick={onButtonClick}>
            <Typography.Body2Bold
              color={dayTheme.colors.background.surface0}
              textTransform="uppercase"
            >
              {buttonText}
            </Typography.Body2Bold>
          </FAQButtonStyled>
        )}
      </Wrapper>
      <Spacer height={24} />
    </Container>
  )
}
