import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { RootState } from "store"
import { getSlotFromHour } from "utils/date"
import { OperationStatus, PhoneNumber, Typography } from "components"
import { routes } from "constants/routes"
import { NumberTransferStatusStyled } from "./styles"

export const NumberTransferStatus = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { values } = useSelector((state: RootState) => state.form)
  const { numberTransfer } = useSelector((state: RootState) => state.common)
  if (!values) {
    navigate(routes.menu)
  }
  const title = "Заявка принята"
  const buttonTitle = "Отлично"

  const handleSubmit = () => {
    navigate(routes.main)
  }

  return (
    <OperationStatus
      title={title}
      isSuccess={true}
      continueHandler={handleSubmit}
      continueButtonTitle={buttonTitle}
    >
      {values && (
        <Typography.Body1 color={theme.colors.text.secondary} lineHeight={22}>
          Перенос номера <PhoneNumber phone={numberTransfer} /> вместо{" "}
          <PhoneNumber phone={values.temporaryNumber as string} /> запланирован{" "}
          <NumberTransferStatusStyled.NoWrap>
            {`на ${values.transferDate} с ${getSlotFromHour(
              Number(values.transferHour)
            )}`}
          </NumberTransferStatusStyled.NoWrap>
        </Typography.Body1>
      )}
    </OperationStatus>
  )
}
