import { useMemo } from "react"
import { useSelector } from "react-redux"
import {
  useGetCustomerProfileQuery,
  useGetPersonalInfoQuery,
  useGetUserConfigQuery,
} from "store/api"
import { getSearchObject } from "utils/search"
import { RootState } from "store"
import { useNavigate } from "react-router"
import { useActions } from "hooks/actions"
import { useTheme } from "styled-components"
import { ERoutes } from "entities/common"
import { sendMetric } from "utils/metrics"
import { prepareCurrentFeatureConfig } from "utils/userConfig"
import {
  Button,
  Container,
  Form,
  Header,
  Loader,
  Spacer,
  Table,
} from "components"
import { routes } from "constants/routes"
import { formContactData, formPersonalData } from "./formData"
import { PersonalInfoStyled } from "./styles"

export const PersonalInfo = () => {
  const { additionalNumber: currentPhone } = getSearchObject()
  const { setSelectedPhone, clearFormValues } = useActions()
  const { phone } = useSelector((state: RootState) => state.login)
  const navigate = useNavigate()
  const currentNumber = currentPhone ?? phone
  const { data, isLoading } = useGetPersonalInfoQuery({
    additionalNumber: currentPhone,
  })
  const onBackClick = () => {
    const isMainNumber = currentPhone === phone
    return navigate(routes.phone, {
      state: {
        phone: currentNumber,
        isMainNumber,
      },
    })
  }

  const { data: userConfigData } = useGetUserConfigQuery()

  const currentFeatureConfig = useMemo(
    () =>
      prepareCurrentFeatureConfig(
        currentNumber,
        userConfigData?.data?.userConfigs
      ),
    [currentNumber, userConfigData?.data?.userConfigs]
  )

  const { data: customerData, isFetching } = useGetCustomerProfileQuery(
    {
      number: currentPhone,
    },
    { refetchOnMountOrArgChange: true }
  )
  //TODO подумать, как сделать иначе

  const theme = useTheme()
  const onSumbit = (path: ERoutes) => {
    clearFormValues()
    if (path === ERoutes.PersonalInfoConfirmation) {
      sendMetric({ Профиль: { "Данные абонента": ["Внести изменения_Клик"] } })
    } else {
      if (customerData && customerData.data?.email) {
        sendMetric({
          Профиль: { "Данные абонента": ["Изменить email_Клик"] },
        })
      } else {
        sendMetric({
          Профиль: { "Данные абонента": ["Добавить email_Клик"] },
        })
      }
    }
    setSelectedPhone(currentPhone ?? phone)
    navigate(routes[path])
  }

  return (
    <>
      {(isLoading || isFetching) && <Loader />}
      <Container page backgroundColor={theme.colors.background[0]}>
        <Header onBackClick={onBackClick} title="Данные абонента" />
        {data && customerData && (
          <>
            {currentFeatureConfig?.isPersonalInfoAdjustmentEnabled && (
              <Table.Body>
                <Table.Section>
                  <Table.Cell>
                    <Spacer height={8} />
                    <PersonalInfoStyled.SectionTitle>
                      Персональные данные
                    </PersonalInfoStyled.SectionTitle>
                    <Spacer height={16} />
                    <Form
                      onSubmit={() =>
                        onSumbit(ERoutes.PersonalInfoConfirmation)
                      }
                      modules={formPersonalData(data.data)}
                      padding="0"
                    >
                      <Button.Plain>Внести изменения</Button.Plain>
                    </Form>
                  </Table.Cell>
                </Table.Section>
              </Table.Body>
            )}
            {currentFeatureConfig?.isEmailAdjustmentEnabled && (
              <Table.Body>
                <Table.Section>
                  <Table.Cell>
                    <Spacer height={8} />
                    <PersonalInfoStyled.SectionTitle>
                      Контактные данные
                    </PersonalInfoStyled.SectionTitle>
                    <Spacer height={16} />
                    <Form
                      onSubmit={() => onSumbit(ERoutes.EmailConfirmation)}
                      modules={formContactData(customerData.data.email ?? "")}
                      padding="0"
                    >
                      <Button.Plain>
                        {customerData.data?.email
                          ? "Изменить email"
                          : "Добавить email"}
                      </Button.Plain>
                    </Form>
                  </Table.Cell>
                </Table.Section>
              </Table.Body>
            )}
          </>
        )}
      </Container>
    </>
  )
}
