import { all, takeLatest } from "redux-saga/effects"
import { loginActions } from "../slice/loginSlice"
import { loginStart, sendSmsStart } from "./loginSaga"

export function* rootSaga() {
  yield all([
    takeLatest(loginActions.loginStart, loginStart),
    takeLatest(loginActions.sendSmsStart, sendSmsStart),
  ])
}
