import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Section = styled.div<{ showAboutSection?: boolean }>`
  padding: 16px;
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: ${(props) =>
    props.showAboutSection ? "space-between" : "flex-end"};
  align-items: center;
`

export const About = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 8px;
`

export const SupportSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0 32px;
  align-items: center;
  h4,
  & > p {
    padding: 0 24px;
    text-align: left;
    width: 100%;
  }
  background: ${(props) => props.theme.colors.background[1]};
  border-radius: 15px 15px 0px 0px;
`
