import React, {
  ChangeEvent,
  FC,
  forwardRef,
  memo,
  ReactNode,
  useState,
} from "react"
import DataPicker, { registerLocale } from "react-datepicker"
import moment from "moment"
import ru from "date-fns/locale/ru"
import "react-datepicker/dist/react-datepicker.css"
import "./styles.css"
import { ICommonField, TFormField } from "../Form/interfaces"
import { FormInput } from "../FormInput"
import { Calendar } from "./styles"

registerLocale("ru", ru)

interface IDateField
  extends ICommonField,
    Pick<TFormField, "maxDate" | "minDate"> {
  value: string
}

const DateField: FC<IDateField> = memo(
  ({
    value: fieldValue,
    name,
    placeholder,
    fieldRef,
    onChange,
    onBlur,
    description,
    hasError,
    maxDate,
    minDate,
    startIcon,
    onClick: onInputClick,
  }) => {
    const [innerValue, setInnerValue] = useState(fieldValue)
    const handleChange = (value: Date) => {
      setInnerValue(moment(value).format("DD.MM.YY"))
      onChange(moment(value).format("DD.MM.YY"))
      onBlur(moment(value).format("DD.MM.YY"))
    }
    const Container = ({ children }: { children: ReactNode }) => (
      <Calendar>{children}</Calendar>
    )
    const CustomInput = forwardRef(
      ({
        onClick,
      }: {
        onClick?: (event: ChangeEvent<HTMLInputElement>) => void
      }) => (
        <FormInput
          placeholder={placeholder}
          name={name}
          value={innerValue}
          onFocus={(event) => {
            onClick?.(event)
            event.preventDefault()
            event.target.blur()
          }}
          fieldRef={fieldRef}
          onBlur={() => {}}
          onChange={(v) => onChange(v)}
          description={description}
          hasError={hasError}
          startIcon={startIcon}
        />
      )
    )

    return (
      <DataPicker
        onInputClick={onInputClick}
        selected={innerValue ? moment(innerValue, "DD.MM.YY").toDate() : null}
        onChange={handleChange}
        customInput={<CustomInput />}
        locale="ru"
        showPopperArrow={false}
        calendarContainer={Container}
        className="data-picker"
        maxDate={maxDate}
        minDate={minDate}
        onCalendarClose={() => onBlur(innerValue)}
      />
    )
  }
)

export { DateField }
