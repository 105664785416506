import { EClientType } from "entities/common"
import { IPhoneNumber } from "components"
import { IFormattedPhones } from "./interfaces"
//todo разобраться с типами actualPhones.availablePhones[0]?.number
export const prepareAvailableCurrentPhone = (
  currentPhone: string,
  phones: IPhoneNumber[],
  actualPhones?: IFormattedPhones
) => {
  if (!actualPhones) {
    return ""
  }
  const isAvailable = [EClientType.B2C, EClientType.B2B2C].includes(
    phones.find((item: IPhoneNumber) => item.number === currentPhone)
      ?.clientType as EClientType
  )
  return isAvailable
    ? currentPhone
    : (actualPhones.availablePhones[0]?.number as unknown as string)
}
