import styled from "styled-components"
import { Icon } from "components"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.background[0]};
  flex-direction: column;
`

export const CheckIconStyled = styled(Icon.Check)`
  color: ${(props) => props.theme.colors.text.warning};
  height: 100%;
`
