import styled from "styled-components"
import { Plain } from "components/Button/Plain"
import { Primary } from "components/Button/Primary"
import { Typography } from "components"

export const RootStyled = styled.div``

export const ImageStyled = styled.img`
  margin: 112px auto 48px auto;
  display: flex;
  width: 50%;
  height: 50%;
`

export const H4Styled = styled(Typography.H4)`
  justify-content: center;
  display: flex;
  margin-bottom: 8px;
`

export const Body1Styled = styled(Typography.Body1)`
  justify-content: center;
  display: flex;
  color: ${(props) => props.theme.colors.text.secondary};
  margin-bottom: 42px;
`

export const ConfirmButtonStyled = styled(Primary)`
  margin: 0px 16px 8px 16px;
  width: calc(100% - 32px);
`

export const CancelButtonStyled = styled(Plain)`
  margin: 0px 16px 32px 16px;
  width: calc(100% - 32px);
`
