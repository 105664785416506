import styled from "styled-components"

const IconWrapper = styled.div<{
  hasBackground?: boolean
  svgColor?: string
}>`
  ${(props) =>
    props.hasBackground
      ? `background: ${props.theme.colors.icon.disabled};`
      : undefined};
  svg {
    color: ${(props) => props.theme.colors.icon.mpPrimary};
  }
`

const PaddingContainer = styled.div<{ padding?: number }>`
    padding 0 ${(props) => props.padding ?? 16}px;
`

export const HowItWorksStyled = { IconWrapper, PaddingContainer }
