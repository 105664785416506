import styled from "styled-components"

const Icon = (color: string) =>
  window.btoa(`
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 13C11 9.68629 13.6863 7 17 7H19C22.3137 7 25 9.68629 25 13V23.7352C25 24.789 24.4471 25.7655 23.5435 26.3077L21.8769 27.3077C21.5952 26.5443 20.8612 26 20 26H18C16.3431 26 15 27.3431 15 29C15 30.6569 16.3431 32 18 32H20C21.1046 32 22 31.1046 22 30V29.5662L24.5725 28.0227C26.0025 27.1647 26.9055 25.6539 26.993 24H28C30.2091 24 32 22.2091 32 20V17C32 14.7909 30.2091 13 28 13H27C27 8.58172 23.4183 5 19 5H17C12.5817 5 9 8.58172 9 13H8C5.79086 13 4 14.7909 4 17V20C4 22.2091 5.79086 24 8 24H10C10.2652 24 10.5196 23.8946 10.7071 23.7071C10.8946 23.5196 11 23.2652 11 23V13ZM27 15V22H28C29.1046 22 30 21.1046 30 20V17C30 15.8954 29.1046 15 28 15H27ZM8 15H9V22H8C6.89543 22 6 21.1046 6 20V17C6 15.8954 6.89543 15 8 15ZM20 28.9836C19.9998 28.9951 19.9998 29.0067 20 29.0183V30H18C17.4477 30 17 29.5523 17 29C17 28.4477 17.4477 28 18 28H20V28.9836Z" fill="${color}"/>
</svg>
`)

export const Chat = styled.div`
  width: 100%;
  height: 100%;
  .sber-care-chat-block .sber-care-chat-bubble__text-wrapper p,
  .sber-care-chat-block .sber-care-chat-bubble__text-wrapper p > a {
    color: ${(props) => props.theme.colors.text.primary};
    font-family: SB Sans Interface, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
  }
  div.sber-care-chat-messages,
  .sber-care-chat-block div.sber-care-chat-suggestions {
    background-color: ${(props) => props.theme.colors.background[0]};
  }
  button.sber-care-chat-suggestions__item {
    background-color: transparent;
    color: ${(props) => props.theme.colors.text.primary};
  }
  .sber-care-chat-bubble_type_client div.sber-care-chat-bubble__text-wrapper {
    background-color: ${(props) => props.theme.colors.badge.background};
    box-shadow: none;
  }
  .sber-care-chat-bubble_type_operator div.sber-care-chat-bubble__text-wrapper {
    background-color: ${(props) => props.theme.colors.background[1]};
    box-shadow: none;
  }
  div.sber-care-chat-status__time {
    color: ${(props) => props.theme.colors.text.secondary};
  }
  .sber-care-chat-ui-icon path {
    fill: ${(props) => props.theme.colors.text.secondary};
  }
  div.sber-care-chat-bubble__icon {
    background-color: ${(props) => props.theme.colors.background[1]};
    border-radius: 50%;
  }
  div.sber-care-chat-logo {
    background-size: 80%;
    background-image: url("data:image/svg+xml;base64,${(props) =>
      Icon(props.theme.colors.text.secondary)}") !important;
  }
  div.sber-care-chat-footer {
    background-color: ${(props) => props.theme.colors.background[1]};
    border-top: none;
  }
  div.sber-care-chat-footer__border {
    display: none;
  }
  .sber-care-chat-ui-field__text textarea.sber-care-chat-ui-textarea__input {
    border: 1px solid ${(props) => props.theme.colors.divider2};
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.background[1]};
    color: ${(props) => props.theme.colors.text.primary};
  }
`
