import { FieldFormat, IFormModule } from "components/Form"
import { FieldExtensionType } from "components/Form/interfaces"
import { sendMetric } from "utils/metrics"
import { emailPattern } from "constants/common"

export const formEmail: IFormModule[] = [
  {
    title: "",
    fields: [
      {
        extensionType: FieldExtensionType.common,
        name: "email",
        placeholder: "Введите email",
        format: FieldFormat.text,
        info: "На этот адрес будет приходить детализация и сообщения службы поддержки",
        onClick: () =>
          sendMetric({ "Корректировка email": { Email: ["Ввод email_Клик"] } }),
        validators: {
          required: "Укажите email",
          pattern: { value: emailPattern, message: "Укажите корректный email" },
        },
        initialValue: "",
      },
    ],
  },
]
