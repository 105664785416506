import React, { FC, Fragment } from "react"
import { useSelector } from "react-redux"
import { useGetBannersQuery, useGetUserConfigQuery } from "store/api"
import { RootState } from "store"
import { v4 as uuidv4 } from "uuid"
import { EBannerType, IBanner } from "entities/banners"
import { RootStyled } from "./styles"
import { Signed } from "./components/Signed"
import { EsimIdentify } from "./components/EsimIdentify"
import { SignedByUser } from "./components/SignedByUser"
import { WaitInGoskey } from "./components/WaitInGoskey"

export const Banners: FC = () => {
  const { currentPhone } = useSelector((state: RootState) => state.login)
  const { data, isLoading } = useGetBannersQuery({ phones: [currentPhone] })
  const { data: userConfigData } = useGetUserConfigQuery()

  if (isLoading) {
    return <></>
  }
  const currentBunners = data?.data.bannersData.find(
    (item) => item.number === currentPhone
  )

  const currentUserConfig = userConfigData?.data?.userConfigs?.find(
    (item) => item.number === currentPhone
  )

  const renderBanner = (banner: IBanner) => {
    switch (banner.bannerType) {
      case EBannerType.Signed:
        return <Signed />
      case EBannerType.EsimIdentify:
        return (
          <EsimIdentify isInactiveEsim={currentUserConfig?.isInactiveEsim} />
        )
      case EBannerType.SignedByUser:
        return <SignedByUser />
      case EBannerType.WaitInGoskey:
        return <WaitInGoskey />
      default:
        return <Fragment key={uuidv4()} />
    }
  }

  return (
    <RootStyled>{(currentBunners?.banners || []).map(renderBanner)}</RootStyled>
  )
}
