import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { Table, Icon } from "components"
import { routes } from "constants/routes"
import { TITLE, SUBTITLE } from "./constants"

type Props = {
  isActive: boolean
}

export const SecureAccountBanner: FC<Props> = (props) => {
  const { isActive } = props
  const navigate = useNavigate()

  return (
    <Table.Section>
      <Table.CommonCell
        title={TITLE}
        subTitle={`${SUBTITLE[0]} ${!isActive ? SUBTITLE[1] : ""} ${
          SUBTITLE[2]
        }`}
        startIcon={<Icon.SecureAccountShield />}
        startSubIcon={
          isActive ? (
            <Icon.SecureAccountAcctive />
          ) : (
            <Icon.SecureAccountDisabled />
          )
        }
        startSubIconRight={60}
        startSubIconBottom={57}
        endIcon={<Icon.ArrowRight />}
        onClick={() => navigate(routes.secureAccountMain)}
      />
    </Table.Section>
  )
}
