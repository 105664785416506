import styled, { css } from "styled-components"
import { Plain } from "components/Button/Plain"
import { Carousel } from "react-responsive-carousel"
import { Icon, Table, Typography } from "components"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const IconCss = css`
  align-self: center;
  width: 240px;
  margin-bottom: 66px;

  @media (max-height: 650px) {
    margin-bottom: 48px;
  }
`

export const RootStyled = styled(Table.Body)`
  position: relative;
  padding-top: 100px;
  padding-bottom: 50px;
  justify-content: space-between;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.background[1]};

  @media (max-height: 755px) {
    padding-bottom: 24px;
    padding-top: 44px;
  }
  @media (max-height: 660px) {
    padding-bottom: 8px;
  }
`

export const Title = styled(Typography.H4)`
  text-align: start;
`

export const CarouselStyled = styled(Carousel)`
  .control-dots {
    top: 250px;
    height: 6px;
    .dot {
      height: 6px;
      width: 6px;
      transition: width 0.2s linear;
      box-shadow: none;
      background: ${(props) => props.theme.colors.icon.disabled};
      opacity: 1;
      margin: 0 3px;
    }
    .dot.selected {
      width: 17px;
      border-radius: 10px;
      transition: width 0.2s linear;
      box-shadow: none;
      background: ${(props) => props.theme.colors.icon.primary};
    }
  }
`

export const AuraRobotIcon = styled(Icon.AuraRobot)`
  ${IconCss}
`

export const AuraIcon = styled(Icon.AuraPerson)`
  ${IconCss}
`

export const SubtitleStyled = styled(Typography.Body1)`
  color: ${(props) => props.theme.colors.text.secondary};
  text-align: start;
  margin-top: 8px;
`

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const IconPlay = styled(Icon.Play)`
  width: 18px;
`

export const IconPause = styled(Icon.Pause)`
  width: 18px;
`

export const ButtonPlain = styled(Plain)`
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  svg {
    color: ${(props) => props.theme.colors.text.warning};
  }
`

export const PlainButtonChildren = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
