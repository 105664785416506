import React, { memo, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import { usePostCostDetailIntervalMutation } from "store/api"
import {
  ESendConfirmationType,
  EStatusCode,
  IResponseErrorData,
} from "entities/common"
import { useTheme } from "styled-components"
import { SmsConfirmationStyles } from "components/SmsConfirmation/styles"
import { useActions } from "hooks/actions"
import { sendMetric } from "utils/metrics"
import { Container, Header, SmsConfirmation } from "components"
import { routes } from "constants/routes"

export const OrderDetailsConfirmation = memo(() => {
  const theme = useTheme()
  const { setError, openModal } = useActions()
  const navigate = useNavigate()
  const [trigger, { isLoading, isSuccess, error, reset }] =
    usePostCostDetailIntervalMutation()
  const { values } = useSelector((state: RootState) => state.form)
  if (!values) {
    navigate(routes.orderDetails)
  }
  const { phone } = useSelector((state: RootState) => state.login)

  const onSuccess = (code: string) => {
    if (!values) {
      return
    }

    trigger({
      email: values.email as string,
      additionalNumber:
        values?.clientNumber === phone
          ? undefined
          : (values.clientNumber as string),
      dateFrom: values.period as string,
      code,
    })
  }

  useEffect(() => {
    if (!values) {
      return
    }

    if (isSuccess) {
      sendMetric({ "Детализация на email": { "Ввод СМС-кода": ["Успех"] } })
      navigate(routes.orderDetailsStatus, {
        state: {
          number: values.clientNumber,
          email: values.email,
        },
      })
    }

    if (error && "data" in error) {
      const errorData = error.data as IResponseErrorData
      const showErrorStatusPage = () =>
        navigate(routes.orderDetailsStatus, {
          state: {
            isNotSuccess: true,
            number: values.clientNumber,
            email: values.email,
          },
        })

      if (typeof errorData === "string") {
        sendMetric({
          "Детализация на email": {
            "Создание заявки_Показ": {
              Ошибка: [errorData],
            },
          },
        })
        return showErrorStatusPage()
      }

      switch (errorData?.statusInfo.statusCode) {
        case EStatusCode.BadCredentials:
          sendMetric({
            "Детализация на email": {
              "Ввод СМС-кода": {
                Ошибка: [errorData?.statusInfo.statusMessage],
              },
            },
          })
          setError("Неверный код")
          reset()
          break
        case EStatusCode.PasswordExpired:
          sendMetric({
            "Детализация на email": {
              "Ввод СМС-кода": {
                Ошибка: [errorData?.statusInfo.statusMessage],
              },
            },
          })
          openModal({
            isOpen: true,
            title: "Информация",
            subtitle: errorData?.statusInfo.statusMessage,
            declineLabel: "Понятно",
          })
          return navigate(-1)
        default:
          sendMetric({
            "Детализация на email": {
              "Создание заявки_Показ": {
                Ошибка: [errorData?.statusInfo.statusMessage],
              },
            },
          })
          return showErrorStatusPage()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    isSuccess,
    navigate,
    reset,
    values,
    values?.clientNumber,
    values?.email,
  ])

  return (
    <>
      {values && (
        <Container page>
          <Header
            title="Подтверждение"
            background={theme.colors.background[1]}
          />
          <SmsConfirmation
            number={values.clientNumber as unknown as string}
            onSuccess={onSuccess}
            type={ESendConfirmationType.CostDetail}
            isLoading={isLoading}
          >
            <SmsConfirmationStyles.Body1>
              {`Вводя код из СМС, вы подтверждаете отправку детализации на
          ${values.email}`}
            </SmsConfirmationStyles.Body1>
          </SmsConfirmation>
        </Container>
      )}
    </>
  )
})
