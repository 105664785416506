import React, { FC } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"
import { getSearchObject } from "utils/search"
import { sendMetric } from "utils/metrics"
import { RootState } from "store"
import { Container, Header, Signing } from "components"
import { routes } from "constants/routes"

const PaymentAdjustmentSigning: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { type } = getSearchObject()
  const { values } = useSelector((state: RootState) => state.form)
  const phone =
    type === "amount" ? values?.clientNumber : values?.refillingNumber

  const handleContinue = () => {
    if (type === "number") {
      sendMetric({
        "Корректировка платежа по номеру": {
          Подписание: ["Ввод подписи_Клик"],
        },
      })
    } else {
      sendMetric({
        "Корректировка платежа по сумме": { Подписание: ["Ввод подписи_Клик"] },
      })
    }

    navigate(`${routes.paymentAdjustmentConfirmation}?type=${type}`)
  }
  return (
    <Container page>
      <Header title="Подписание" background={theme.colors.background[1]} />
      <Signing onContinue={handleContinue} phone={phone as string} />
    </Container>
  )
}

export { PaymentAdjustmentSigning }
