import { FC, ReactElement, useState } from "react"
import { useTheme } from "styled-components"
import { EHistoryCallsType, IHistoryCallsGrouped } from "entities/historyCalls"
import { getDate } from "utils/date"
import { Icon, Typography } from "components"
import { TranscriptionSheet } from "../TranscriptionSheet"
import { ITranscriptionSheet } from "../TranscriptionSheet/TranscriptionSheet"
import { Styled } from "./styles"
import { CALL_TYPE_TEXT } from "./constants"

type TCardHelper = {
  [key: string]: {
    icon: ReactElement
    headerPhone: string
    callType: string
    callTypeText: string
  }
}

export const HistoryCallsItem: FC<IHistoryCallsGrouped> = (props) => {
  const { data } = props
  const theme = useTheme()
  const [dataSheet, setDataSheet] = useState<
    Omit<ITranscriptionSheet, "onClose">
  >({
    phone: "",
    date: "",
    callId: "",
    category: EHistoryCallsType.Normal,
    isOpen: false,
  })

  const cardHelper: TCardHelper = {
    [EHistoryCallsType.Normal]: {
      icon: <Icon.PhoneMissingCall />,
      headerPhone: theme.colors.text.critical,
      callType: theme.colors.text.secondary,
      callTypeText: CALL_TYPE_TEXT[0],
    },
    [EHistoryCallsType.Spam]: {
      icon: <Icon.PhoneIncomingCall />,
      headerPhone: theme.colors.text.primary,
      callType: theme.colors.text.warning,
      callTypeText: CALL_TYPE_TEXT[1],
    },
  }

  return (
    <>
      <TranscriptionSheet
        phone={dataSheet.phone}
        date={dataSheet.date}
        callId={dataSheet.callId}
        isOpen={dataSheet.isOpen}
        category={dataSheet.category}
        onClose={() =>
          setDataSheet({ ...dataSheet, isOpen: !dataSheet.isOpen })
        }
      />
      {data.length > 0 &&
        data.map((element, i) => (
          <Styled.Element key={i}>
            <Styled.TitleContainer>
              <Typography.Body1
                fontSize={16}
                lineHeight={21}
                color={theme.colors.text.secondary}
              >
                {getDate.dateAndMonth(element.date)}
              </Typography.Body1>
            </Styled.TitleContainer>
            {element.calls.map((content, j) => (
              <Styled.Container
                key={j}
                onClick={() =>
                  setDataSheet({
                    phone: content.callingNumber,
                    date: `${getDate.time(
                      content.callDate
                    )}, ${getDate.dateAndMonth(element.date)}`,
                    callId: content.callId,
                    category: content.category,
                    isOpen: content.transcriptionPreview ? true : false,
                  })
                }
              >
                <Styled.Content>
                  <Styled.IconContainer>
                    {cardHelper[content.category].icon}
                  </Styled.IconContainer>
                  <Styled.ContentContainer>
                    <Styled.ContentHeader>
                      <Styled.ContentHeaderPhone
                        color={cardHelper[content.category].headerPhone}
                      >
                        {content.callingNumber}
                      </Styled.ContentHeaderPhone>
                      <Styled.ContentHeaderTime>
                        {getDate.time(content.callDate)}
                      </Styled.ContentHeaderTime>
                    </Styled.ContentHeader>
                    <Styled.CallType>
                      <Typography.Body1
                        fontSize={13}
                        lineHeight={18}
                        color={cardHelper[content.category].callType}
                      >
                        {cardHelper[content.category].callTypeText}
                      </Typography.Body1>
                    </Styled.CallType>

                    {content.transcriptionPreview && (
                      <Styled.VoiceMessage isNewMessage={content.isNew}>
                        <Styled.VoiceMessageText>
                          <Styled.IconRecordingCall />
                          <Typography.Body1 fontSize={13} lineHeight={18}>
                            {content.transcriptionPreview}
                          </Typography.Body1>
                          {content.isNew && <Styled.ListenMarker />}
                        </Styled.VoiceMessageText>
                      </Styled.VoiceMessage>
                    )}
                  </Styled.ContentContainer>
                </Styled.Content>
              </Styled.Container>
            ))}
          </Styled.Element>
        ))}
    </>
  )
}
