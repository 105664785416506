import styled from "styled-components"
import { Typography } from "components"

export const ModalTextStyled = styled(Typography.Body1)`
  text-align: center;
  padding: 0 16px;
`

export const WrapImageStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  min-width: 233px;
`

export const RootStyled = styled.div`
  display: flex;
  padding-top: 26px;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  max-width: 480px;
  gap: 8px;
  background: ${(props) => props.theme.colors.background[1]};
`

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const TextContent = styled(MainContent)`
  gap: 8px;
`

export const H4Styled = styled(Typography.H4)`
  text-align: center;
`

export const SubtitleStyled = styled(Typography.Body1)`
  text-align: center;
  color: ${(props) => props.theme.colors.text.secondary};
  margin: 0 24px;
`

export const FooterStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 96px;
  bottom: 0px;
  padding: 8px 16px;
  max-width: 480px;
`
