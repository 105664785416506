import styled from "styled-components"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.background[0]};
  flex-direction: column;
`
export const IFrame = styled.iframe<{ isLoading: boolean }>`
  ${(props) => props.isLoading && "display: none;"}
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  border: 0;
`

export const Skeleton = styled.div`
  width: 100%;
  height: 100%;
`
