import styled from "styled-components"

export const H1 = styled.h1<{ textAlign?: string }>`
  font-family: "SBSansUI Bold", serif;
  font-size: 40px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.text.primary};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}}`}
`

export const H2 = styled.h2<{ textAlign?: string }>`
  font-family: "SBSansUI Bold", serif;
  font-size: 28px;
  line-height: 34px;
  color: ${(props) => props.theme.colors.text.primary};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}}`}
`

export const H3 = styled.h3<{
  fontSize?: string
  lineHeight?: string
  textAlign?: string
}>`
  font-family: "SBSansUI Bold", serif;
  font-size: ${(props) => props.fontSize ?? 24}px;
  line-height: ${(props) => props.lineHeight ?? 32}px;
  ${(props) => props.textAlign && `text-align: ${props.textAlign}}`}
  color: ${(props) => props.theme.colors.text.primary};
`

export const H4 = styled.h4<{ textAlign?: string }>`
  font-family: "SBSansUI Bold", serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors.text.primary};
  text-align: ${(props) => props.textAlign ?? "inherit"};
`

export const H6 = styled.h6`
  font-family: "SBSansUI Bold", serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text.secondary};
`
